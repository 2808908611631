import { format, formatISO, parse, parseISO } from "date-fns";

// yyyy-MM-dd HH:mm:ss
export function isDateTime(dateString: string) {
  const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
  return regex.test(dateString);
}
export function isISODateWithTimeZone(dateString: string) {
  const isoRegex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?(?:[+-]\d{2}:\d{2}|Z)$/;
  return isoRegex.test(dateString);
}
// yyyy-MM-dd
export function isDate(dateString: string) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(dateString);
}

export const parseDateTimeOrISODateTime = (dateString: string): Date | null => {
  return isDateTime(dateString) || isISODateWithTimeZone(dateString)
    ? parseISO(dateString)
    : null;
};
export const parseDateToISODateTime = (dateString: string): Date | null => {
  return isDate(dateString) ? parseISO(dateString) : null;
};

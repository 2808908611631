import { useEffect, useState } from "react";

import { useAppRouteParams } from "@/AppRoutes";
import { Sheet, useGetSheet } from "@/domain/Sheet";
import {
  useAuthorizedSheets,
  useGetAuthorizedSheet,
} from "./useAuthorizedCollections";

// 参照先のシートのリストを取得するカスタムフック
export const useReferencedSheets = (): [
  (Sheet & { refVersionId: string })[],
  boolean,
] => {
  const { versionId } = useAppRouteParams();
  const [sheets, isLoadedSheets] = useAuthorizedSheets(versionId);
  const [referencedSheets, setReferencedSheets] = useState<
    (Sheet & { refVersionId: string })[]
  >([]);
  const getSheet = useGetAuthorizedSheet();
  const [isInProcess, setIsInProcess] = useState(false);

  useEffect(() => {
    setIsInProcess(true);
    (async () => {
      const referencedSheets: (Sheet & { refVersionId: string })[] = [];
      const promises = sheets.map(async (sheet) => {
        if (!sheet.referTo) {
          return null;
        }
        const referencedSheet = await getSheet({
          versionId: sheet.referTo.versionId,
          sheetId: sheet.referTo.sheetId,
        });
        if (referencedSheet) {
          return {
            ...referencedSheet,
            refVersionId: sheet.referTo.versionId,
          };
        }
        return null;
      });
      const results = await Promise.all(promises);
      for (const result of results) {
        if (result) {
          referencedSheets.push(result);
        }
      }
      setReferencedSheets(referencedSheets);
    })();
    setIsInProcess(false);
  }, [sheets, getSheet]);

  return [referencedSheets, !isInProcess && isLoadedSheets];
};

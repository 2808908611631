import loadable from "@loadable/component";

import { batchGanttDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeBatchGanttDemo: StrictViewType = {
  key: "saasBatchGantt",
  displayName: "ガントチャート（デモ）",
  dependSheets: batchGanttDependSheets,
  configSchemas: [],
  render: ViewRender as any,
};

import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKwsCSVView3s: StrictViewType = {
  key: "nshKwsCSVView3s",
  displayName: "NSH-KWS S4 CSV連携ビュー（3製用）",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "eo",
      displayName: "eo",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "eoUkishimaProduction",
          displayName: "EO浮島|生産量",
          type: "float",
        },
        {
          key: "eoUkishimaEOConsumptionTeg",
          displayName: "EO浮島|EO消費|TEG",
          type: "float",
        },
        {
          key: "eoUkishimaEOConsumptionEGUkishima",
          displayName: "EO浮島|EO消費|加水EG浮島",
          type: "float",
        },
        {
          key: "eoUkishimaSubProductionEO",
          displayName: "EO浮島|副生EO",
          type: "float",
        },
      ],
    },
    {
      key: "general",
      displayName: "general",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "calcCost156",
          displayName: "用役費浮島|O2消費",
          type: "float",
        },
      ],
    },
    {
      key: "eg",
      displayName: "eg",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "egUkishimaMegProduction",
          displayName: "EG浮島|MEG生産",
          type: "float",
        },
        {
          key: "egUkishimaDegProduction",
          displayName: "EG浮島|DEG生産",
          type: "float",
        },
        {
          key: "egUkishimaTegProduction",
          displayName: "EG浮島|TEG生産",
          type: "float",
        },
      ],
    },
    {
      key: "sft",
      displayName: "sft",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "sft30Production",
          displayName: "SFT|SFT30生産",
          type: "float",
        },
        {
          key: "sft33Production",
          displayName: "SFT|SFT33生産",
          type: "float",
        },
        {
          key: "sft70Production",
          displayName: "SFT|SFT70生産",
          type: "float",
        },
        {
          key: "sft90Production",
          displayName: "SFT|SFT90生産",
          type: "float",
        },
      ],
    },
    {
      key: "internalIdToItemCode",
      displayName: "internalIdToItemCode",
      dependSheetFields: [
        {
          key: "internalId",
          displayName: "内部ID",
          type: "string",
        },
        {
          key: "itemCode",
          displayName: "品目コード",
          type: "string",
        },
      ],
    },
    {
      key: "s4Master1234s",
      displayName: "s4Master1234s",
      dependSheetFields: [
        {
          key: "itemCode",
          displayName: "品目コード",
          type: "string",
        },
        {
          key: "itemText",
          displayName: "品目テキスト",
          type: "string",
        },
        {
          key: "stockArea",
          displayName: "保管場所",
          type: "string",
        },
        {
          key: "lotSize",
          displayName: "ロットサイズ",
          type: "integer",
        },
      ],
    },
  ],
  configSchemas: [],
};

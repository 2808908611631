import { IButtonStyles, PrimaryButton } from "@fluentui/react";
import React from "react";

import { firebaseSignIn } from "../../../../firebase/helper";

import aaLogo from "../../../../images/aa_logo.png";

import styles from "./index.module.css";

const ButtonStyle: IButtonStyles = {
  root: {
    width: "98px",
    height: "36px",
    borderRadius: "7px",
    backgroundColor: "#1D1D32",
  },
  rootHovered: { backgroundColor: "#4a4a5a" },
  rootPressed: { backgroundColor: "#1D1D32" },
  textContainer: { fontSize: 14 },
};

export const LoginView: React.FC = () => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.contentBox}>
          <div className={styles.logo}>
            <img src={aaLogo} height={50} />
          </div>
          <PrimaryButton
            text="ログイン"
            onClick={async () => {
              await firebaseSignIn({ isReplaceHistory: true });
            }}
            styles={ButtonStyle}
          />
        </div>
      </div>
    </div>
  );
};

import loadable from "@loadable/component";

import { rotationBasedAssignmentDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const rotationBasedAssignmentEditorView: StrictViewType = {
  key: "ngrKoubanRotationBasedAssignmentEditor",
  displayName: "NGR交番:予定勤務",
  render: ViewRender as any,
  dependSheets: rotationBasedAssignmentDependSheets,
  configSchemas: [],
};

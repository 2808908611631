import React from "react";

import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  ProgressBar,
  Spinner,
} from "@fluentui/react-components";
import { DialogInProgressParam } from "../hooks";
import { DialogPhaseType } from "../hooks";

import { useDialogStyles } from "@/styles";
import styles from "../index.module.css";

export const DialogInProgress: React.FC<
  {
    dialogPhase: DialogPhaseType;
  } & DialogInProgressParam
> = ({ title, subText, dialogPhase, count }) => {
  const dialogClasses = useDialogStyles();
  return (
    <Dialog open={dialogPhase === "in_progress"}>
      <DialogSurface className={dialogClasses.dialog}>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {subText}
            {count && count.total > 0 ? (
              <Field
                className={styles.progressing}
                validationMessage={`${count.current} / ${count.total}`}
                validationState="none"
              >
                <ProgressBar
                  value={count.current / count.total}
                  color="brand"
                />
              </Field>
            ) : (
              <Spinner size="small" className={styles.spinner} />
            )}
          </DialogContent>
          <DialogActions></DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

import {
  child,
  onDisconnect,
  onValue,
  ref,
  remove,
  set,
} from "firebase/database";
import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";

import { useAppRouteParams } from "../../../AppRoutes";
import { User } from "../../../domain/User";
import { database as db } from "../../../firebase";
import { useAppAuth } from "../../AppProvider/AppAuthProvider";

import { generateId } from "@/utils/generateId";

const sessionData: { [key: string]: string } = {};

export type AppSession = {
  id: string;
  createdAt: string;
  user: User;
  data: { [key: string]: string };
};

const ctx = createContext({
  sessions: [] as AppSession[],
  sessionId: "",
  updateSessionData: (id: string, chunk: any) => {
    //nop
  },
});

/**
 * @description 現在スプレッドシートUIに接続している全ユーザーの情報をFirebaseのRDBから取得する
 */
export const SessionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { organizationId } = useAppRouteParams();
  // 全セッションの状態管理
  const [sessions, setSessions] = useState([] as AppSession[]);

  // セッション情報のrefを作成
  const local = useRef({
    sessionId: generateId(),
    isSessionConnected: false,
  }).current;

  // セッションIDだけ取り出し
  const { sessionId } = local;
  const { user, isLoggedIn } = useAppAuth();
  const sessionsRef = ref(
    db,
    `app/spreadsheet_ui/v/1/organizations/${organizationId || "null"}/sessions`
  );

  // sessionIdで書き込む
  const sessionRef = child(sessionsRef, sessionId);

  const updateSessionData = (id: string, chunk: any) => {
    /**
     * @description セッションデータをアップデートする. どのバージョンのどのシートに誰が存在しているかを示す
     * @param id 参照しているシートのIDなど
     * @param chunk 参照しているシートの選択している行やセルのID
     */
    const encoded = JSON.stringify(chunk);
    sessionData[id] = encoded;
    if (local.isSessionConnected) {
      if (chunk !== null) {
        set(child(sessionRef, `data/${id}`), encoded).catch((error) => {
          throw error;
        });
      } else {
        remove(child(sessionRef, `data/${id}`)).catch((error) => {
          throw error;
        });
      }
    }
  };
  useEffect(() => {
    if (organizationId) {
      const unsubscribe = onValue(sessionsRef, (snapshot) => {
        // セッション情報を読み取り、sessionsの情報として更新する
        const data = snapshot.val() || {};
        const e = Object.entries(data).map(([id, val]) => ({
          id,
          ...(val as any),
        })) as any;
        setSessions(e);
      });
      // https://firebase.google.com/docs/database/web/offline-capabilities?hl=ja#section-connection-state
      const unsubscribe2 = onValue(ref(db, ".info/connected"), (snapshot) => {
        local.isSessionConnected = false;
        if (snapshot.val() == false) {
          return;
        }
        // ユーザ情報を書き込む
        onDisconnect(sessionRef)
          .remove()
          .then(() => {
            set(sessionRef, {
              createdAt: new Date().toISOString(),
              user: user,
              data: sessionData,
            })
              .then(() => {
                local.isSessionConnected = true;
              })
              .catch((error) => {
                throw error;
              });
          })
          .catch((error) => {
            throw error;
          });
      });
      // アンマウント時にセッション情報を削除する
      return () => {
        unsubscribe();
        unsubscribe2();
        // 該当ユーザーのセッションを丸ごと削除
        remove(sessionRef).catch((error) => {
          throw error;
        });
      };
    }
  }, [!!user, organizationId, isLoggedIn]);

  const value = useMemo(
    () => ({
      sessions,
      sessionId,
      updateSessionData,
    }),
    [sessions]
  );
  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};
export const useOrganizationSession = () => {
  return useContext(ctx);
};

import React from "react";

import { useAppRouteParams } from "../../../../AppRoutes";
import { LayoutTemplateReadonlyTextBox } from "../../../LayoutTemplate/helpers";

import { useAppOrgsAndInvitedOrgs } from "@/components/AppProvider/AppOrgsAndInvitedOrgsProvider";
import { useDialogStyles } from "@/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";

export const OrganizationInfoDialog: React.FC<{
  isVisibleDialog: boolean;
  closeDialog: () => void;
}> = ({ isVisibleDialog, closeDialog }) => {
  const { organizations } = useAppOrgsAndInvitedOrgs();
  const { organizationId } = useAppRouteParams();
  const organization = organizations.find(
    (org) => org.org_id === organizationId
  );
  const dialogClasses = useDialogStyles();

  return (
    <Dialog
      open={isVisibleDialog}
      onOpenChange={() => {
        if (!isVisibleDialog) return;
        closeDialog();
      }}
    >
      <DialogSurface className={dialogClasses.dialog}>
        <DialogBody>
          <DialogTitle>組織情報</DialogTitle>
          <DialogContent>
            <LayoutTemplateReadonlyTextBox
              title="組織名"
              text={`${organization?.name}`}
            />
            <LayoutTemplateReadonlyTextBox
              title="組織ID"
              text={`${organization?.org_id}`}
            />
            <LayoutTemplateReadonlyTextBox
              title="概要"
              text={`${organization?.description}`}
            />
            <LayoutTemplateReadonlyTextBox
              title="組織人数"
              text={`${organization?.num_org_members}`}
            />
            {/* <LayoutTemplateReadonlyTextBox
       title="組織プラン"
       text={`${organization?.org_kind}`}
     /> */}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                closeDialog();
              }}
            >
              閉じる
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

import React from "react";

import styles from "./index.module.css";

export const LayoutTemplateReadonlyTextBox: React.FC<{
  title: string;
  text: string;
  onClick?: () => void;
}> = ({ title, text, onClick }) => (
  <div className={styles.readContainer}>
    <div>{title}</div>
    <div
      className={styles.text}
      onClick={onClick}
      style={{
        cursor: onClick ? "pointer" : "default",
        textDecoration: onClick ? "underline" : "none",
      }}
    >
      {text}
    </div>
  </div>
);

export const LayoutTemplateDivider: React.FC = () => (
  <div className={styles.divider}></div>
);

export const LayoutTemplateFlexContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => <div className={styles.flexContainer}>{children}</div>;

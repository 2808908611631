import { ConnectMasterRaw } from "@/domain/ConnectMaster";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrl = "/connect_masters";

// 組織ごとの利用可能なConnectMasterをリストで取得する
export const getConnectMasters = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<ConnectMasterRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: ConnectMasterRaw[] }>(apiUrl, {
    ...apiOrganizationIdParams,
  });
  return response.data.items;
};

// ConnectMasterを個別に取得
export const getConnectMaster = async ({
  organizationId,
  connect_master_id,
}: {
  organizationId: string;
  connect_master_id: string;
}): Promise<ConnectMasterRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ item: ConnectMasterRaw }>(
    `${apiUrl}/${connect_master_id}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { MemberRaw } from "../../../domain/Member";
import { useAPI } from "../hooks";

const memberCtx = createContext<{
  members: MemberRaw[] | null;
  invitedMembers: MemberRaw[] | null;
  runReloadMembers: () => Promise<void>;
  isLoadedMembers: boolean;
}>({
  members: [],
  invitedMembers: [],
  runReloadMembers: async () => {
    // nop
  },
  isLoadedMembers: false,
});

/**
 * @description view, sheet, AppTopNavなどさまざまな場所で使うmembersデータを管理
 * セッションが書き換わる度に実行する
 */
export const MembersProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state,
    runReloadState: runReloadMembers,
    isLoadedState: isLoadedMembers,
  } = useAPI<{
    members: MemberRaw[];
    invitedMembers: MemberRaw[];
  }>("members");

  const { members, invitedMembers } = state ?? {
    members: null,
    invitedMembers: null,
  };

  const memberCtxValue = useMemo(
    () => ({
      members,
      invitedMembers,
      runReloadMembers,
      isLoadedMembers,
    }),
    [members, invitedMembers, runReloadMembers, isLoadedMembers]
  );

  return (
    <memberCtx.Provider value={memberCtxValue}>{children}</memberCtx.Provider>
  );
};

export const useOrganizationMembers = () => {
  return useContext(memberCtx);
};

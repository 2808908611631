import { OrganizationRaw } from "@/domain/Organization";
import { AccountRaw, AccountResponseRaw } from "../../domain/Account";
import { MemberRaw } from "../../domain/Member";
import { API_CLIENT, API_CLIENT_WITHOUT_IDTOKEN } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrl = "/admin";
const apiUrlAccount = apiUrl + "/accounts";
const apiUrlInvitationAccount = apiUrl + "/invitations" + apiUrlAccount;
// const apiUrlResendEmail = "/resend_email_verification";

export const getAdminAccounts = async (
  startAfterId?: string
): Promise<{ items: AccountRaw[]; next_start_after_id?: string }> => {
  const params = startAfterId
    ? { start_after_id: startAfterId, limit: 100 }
    : { limit: 100 };
  const response = await API_CLIENT.get<{
    items: AccountRaw[];
    next_start_after_id?: string;
  }>(apiUrlAccount, {
    params,
  });
  return response.data;
};
export const postAdminSearchAccountEmail = async ({
  email,
}: {
  email: AccountRaw["email"];
}): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    apiUrlAccount,
    { email },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const getAdminAccount = async ({
  uid,
}: {
  uid: AccountRaw["uid"];
}): Promise<AccountRaw> => {
  const response = await API_CLIENT.get<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}`
  );
  return response.data.item;
};
export const getAdminAccountOrganizations = async ({
  uid,
}: {
  uid: AccountRaw["uid"];
}): Promise<OrganizationRaw[]> => {
  const response = await API_CLIENT.get<{ items: OrganizationRaw[] }>(
    `${apiUrlAccount}/${uid}/organizations`
  );
  return response.data.items;
};
export const postAdminEditAccountName = async ({
  display_name,
  uid,
}: Pick<AccountRaw, "display_name" | "uid">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/display_name`,
    { display_name },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postAdminEditAccountEmail = async ({
  email,
  uid,
}: Pick<AccountRaw, "email" | "uid">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/email`,
    { email },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postAdminEditAccountDefaultOrgId = async ({
  default_org_id,
  uid,
}: Pick<AccountRaw, "default_org_id" | "uid">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/default_org_id`,
    { default_org_id },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postAdminEditAccountMfa = async ({
  use_mfa,
  uid,
}: Pick<AccountRaw, "use_mfa" | "uid">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/mfa`,
    { use_mfa },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postAdminEditAccountPicture = async ({
  picture_type,
  picture_color,
  picture_image_url,
  uid,
}: Partial<Pick<AccountRaw, "picture_color" | "picture_image_url">> &
  Pick<AccountRaw, "uid" | "picture_type">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const body =
    picture_type === "color"
      ? { picture_type, picture_color }
      : { picture_type, picture_image_url };
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/picture`,
    body,
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postAdminEditAccountUserKind = async ({
  user_kind,
  uid,
}: Pick<AccountRaw, "user_kind" | "uid">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/change_user_kind`,
    { user_kind },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postAdminEditAccountMaxNumOwnedOrgs = async ({
  max_num_owned_orgs,
  uid,
}: Pick<AccountRaw, "max_num_owned_orgs" | "uid">): Promise<AccountRaw> => {
  const { apiOrganizationIdParams, apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post(
    `${apiUrlAccount}/${uid}/change_max_num_owned_orgs`,
    { max_num_owned_orgs },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postAdminBlockAccount = async ({
  uid,
}: Pick<AccountRaw, "uid">): Promise<AccountRaw> => {
  const { apiOrganizationIdParams, apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post(`${apiUrlAccount}/${uid}/block`, {
    ...apiHeadersWithJson,
  });
  return response.data.item;
};
export const postAdminUnblockAccount = async ({
  uid,
}: Pick<AccountRaw, "uid">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/unblock`,
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postAdminRemoveAccount = async ({
  uid,
}: Pick<AccountRaw, "uid">): Promise<void> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  await API_CLIENT.post(`${apiUrlAccount}/${uid}/remove`, {
    ...apiHeadersWithJson,
  });
};
export const getAdminInvitationAccount = async ({
  uid,
}: {
  organizationId: string;
  uid: AccountRaw["uid"];
}): Promise<AccountRaw> => {
  const response = await API_CLIENT.get(`${apiUrlInvitationAccount}/${uid}`);
  return response.data.item;
};
export const getAdminInvitationAccounts = async (): Promise<AccountRaw[]> => {
  const response = await API_CLIENT.get<{ items: AccountRaw[] }>(
    apiUrlInvitationAccount
  );
  return response.data.items;
};
export const postAdminEditInvitationAccount = async ({
  accountRaw,
}: {
  accountRaw: AccountRaw;
}): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    apiUrlAccount,
    { ...accountRaw },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
// export const postAdminResendEmailVerification = async ({
//   idp_uid,
// }: {
//   idp_uid: MemberRaw["idp_uid"];
// }): Promise<void> => {
//   const { apiHeadersWithJson } = getAPIConfigs();
//   await API_CLIENT_WITHOUT_IDTOKEN.post(
//     apiUrlResendEmail,
//     { idp_uid },
//     {
//       ...apiHeadersWithJson,
//     }
//   );
// };

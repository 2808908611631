import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKwsCSVView1s: StrictViewType = {
  key: "nshKwsCSVView1s",
  displayName: "NSH-KWS S4 CSV連携ビュー（1製用）",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "eo",
      displayName: "eo",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "eoChidoriSubProductionEO",
          displayName: "EO千鳥|副生EO",
          type: "float",
        },
        {
          key: "eoChidoriProduction",
          displayName: "EO千鳥|生産量",
          type: "float",
        },
        {
          key: "eoChidoriEOConsumptionEGChidori",
          displayName: "EO千鳥|EO消費|加水EG千鳥",
          type: "float",
        },
      ],
    },
    {
      key: "general",
      displayName: "general",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "calcCost052",
          displayName: "用役費千鳥|O2消費",
          type: "float",
        },
      ],
    },
    {
      key: "eg",
      displayName: "eg",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "egChidoriMegProduction",
          displayName: "EG千鳥|MEG生産",
          type: "float",
        },
        {
          key: "egChidoriDegProduction",
          displayName: "EG千鳥|DEG生産",
          type: "float",
        },
      ],
    },
    {
      key: "ea",
      displayName: "ea",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "meaProduction",
          displayName: "EA|MEA生産",
          type: "float",
        },
        {
          key: "deaProduction",
          displayName: "EA|DEA生産",
          type: "float",
        },
        {
          key: "teaNProduction",
          displayName: "EA|TEA-N生産",
          type: "float",
        },
        {
          key: "teaSProduction",
          displayName: "EA|TEA-S生産",
          type: "float",
        },
      ],
    },
    {
      key: "ei",
      displayName: "ei",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "eiProduction",
          displayName: "EI|生産",
          type: "string",
        },
      ],
    },
    {
      key: "nvp",
      displayName: "nvp",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "nvpProduction",
          displayName: "NVP|生産",
          type: "string",
        },
      ],
    },
    {
      key: "internalIdToItemCode",
      displayName: "internalIdToItemCode",
      dependSheetFields: [
        {
          key: "internalId",
          displayName: "内部ID",
          type: "string",
        },
        {
          key: "itemCode",
          displayName: "品目コード",
          type: "string",
        },
      ],
    },
    {
      key: "s4Master1234s",
      displayName: "s4Master1234s",
      dependSheetFields: [
        {
          key: "itemCode",
          displayName: "品目コード",
          type: "string",
        },
        {
          key: "itemText",
          displayName: "品目テキスト",
          type: "string",
        },
        {
          key: "stockArea",
          displayName: "保管場所",
          type: "string",
        },
        {
          key: "lotSize",
          displayName: "ロットサイズ",
          type: "integer",
        },
      ],
    },
  ],
  configSchemas: [],
};

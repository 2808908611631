import { driverEditorView } from "./driverEditor";
import { educationView } from "./readonlyEducation";
import { frontOfficeView } from "./readonlyFrontOffice";
import { paidHolidayView } from "./readonlyPaidHoliday";
import { partPaidHolidayView } from "./readonlyPartPaidHoliday";
import { rotationBasedAssignmentEditorView } from "./rorationBasedAssignmentEditor";
import { skillEditorView } from "./skillEditor";
import { temporaryCourseSkillEditorView } from "./skillEditorTemporaryCourse";

import { ViewType } from "@/domain/ViewType";
import { driverTemporaryEditorView } from "./driverTemporaryEditor";
import { fileUploaderView } from "./fileUploader";
import { koubanView } from "./koubanView";
import { seniorParttimeCalendarView } from "./seniorParttimeCalendar";
import { rotationBasedAssignmentSkillEditorView } from "./skillEditorRotationBasedAssignment";
import { temporaryCourseEditorView } from "./temporaryCourseEditor";

/**
 * @description 各ビューの定義を列挙する。
 * 新しくviewを定義したらここに追加する
 */
export const viewTypesNgrKouban: ViewType<any, any>[] = [
  skillEditorView,
  driverEditorView,
  frontOfficeView,
  paidHolidayView,
  partPaidHolidayView,
  educationView,
  temporaryCourseSkillEditorView,
  rotationBasedAssignmentEditorView,
  temporaryCourseEditorView,
  driverTemporaryEditorView,
  rotationBasedAssignmentSkillEditorView,
  fileUploaderView,
  koubanView,
  seniorParttimeCalendarView,
];

import loadable from "@loadable/component";

import { educationViewDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const educationView: StrictViewType = {
  key: "ngrKoubanEducation",
  displayName: "NGR交番:教育・その他",
  render: ViewRender as any,
  dependSheets: educationViewDependSheets,
  configSchemas: [],
};

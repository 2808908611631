import { isLocalhost } from "./isLocalhost";

const prefix = "devFC_";
const lifetime = 1000 * 60;

export const devFastCache = {
  store(key: string, data: any) {},
  get(key: string, cb?: (data: any) => void): any {
    return null;
  },
};

if (process.env.NODE_ENV === "development" && isLocalhost()) {
  devFastCache.store = (key: string, data: any) => {
    sessionStorage.setItem(
      prefix + key,
      JSON.stringify({
        updatedAt: Date.now(),
        data,
      })
    );
  };
  devFastCache.get = (key: string, cb?: (data: any) => void) => {
    const json = sessionStorage.getItem(prefix + key);
    if (json === null) return null;
    const { data, updatedAt } = JSON.parse(json);
    if (updatedAt < Date.now() - lifetime) {
      return null;
    }
    if (cb) cb(data);
    return data;
  };
}

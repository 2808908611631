export const memberRoleList = [
  "org:admin",
  "org:editor",
  "org:viewer",
] as const;

export const memberKindList = [
  "personal:user",
  "client:user",
  "client:admin",
  "aa:system-admin",
  "aa:project-manager",
  "aa:algorithm",
  "aa:software",
  "aa:designer",
  "aa:user",
] as const;

// サーバーから返ってくるメンバのデータ
export type MemberRaw = {
  org_id: string;
  uid: string;
  idp_uid: string;
  email: string;
  display_name: string;
  email_verified: boolean;
  use_mfa: boolean;
  identity_provider: string;
  picture_type: string;
  picture_color: string;
  picture_image_url: string;
  role: (typeof memberRoleList)[number];
  member_kind: (typeof memberKindList)[number];
  description: string;
  is_disabled: boolean;
  is_new_user: boolean;
  created_at: string;
  updated_at: string;
};

// サーバーに投げるメンバのデータ
type MemberFormBase = {
  uid: string;
  idp_uid: string;
  is_new_user: boolean;
  email: string;
  role: string;
  member_kind: string;
  description: string;
};

export type MemberAddForm = Pick<MemberFormBase, "email" | "role"> &
  Partial<Pick<MemberFormBase, "description" | "member_kind">>;

export type MemberUpdateForm = Pick<
  MemberFormBase,
  "uid" | "idp_uid" | "is_new_user"
> &
  Partial<Pick<MemberFormBase, "description" | "role" | "member_kind">>;

export const initMemberRaw: MemberRaw = {
  org_id: "",
  uid: "",
  idp_uid: "",
  email: "",
  display_name: "",
  email_verified: false,
  use_mfa: false,
  identity_provider: "",
  picture_type: "",
  picture_color: "",
  picture_image_url: "",
  role: memberRoleList[1],
  member_kind: memberKindList[0],
  description: "",
  is_disabled: false,
  is_new_user: false,
  created_at: "",
  updated_at: "",
};

export const getIsAAMember = (member_kind: MemberRaw["member_kind"]) =>
  member_kind === "aa:system-admin" ||
  member_kind === "aa:project-manager" ||
  member_kind === "aa:algorithm" ||
  member_kind === "aa:software" ||
  member_kind === "aa:designer" ||
  member_kind === "aa:user";

// NOTE: 新規ユーザはUIDを持っていないのでidp_uidを識別子として使う
// しかし、idp_uidはパイプが文字列に入っており、DOMのkeyとしては使えないので、その時は取り除いて利用する
export const getMemberUserId = ({
  member,
  isParsedIdpUid,
}: {
  member: MemberRaw | MemberUpdateForm;
  isParsedIdpUid: boolean;
}) =>
  !member.is_new_user
    ? member.uid
    : isParsedIdpUid
      ? member.idp_uid.split("|")[1]
      : member.idp_uid;

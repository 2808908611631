import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { ConnectMasterRaw } from "@/domain/ConnectMaster";
import { useAdminAPI } from "../hooks";

const ctx = createContext<{
  adminOrganizationConnectMasters: ConnectMasterRaw[] | null;
  runReloadAdminOrganizationConnectMasters: () => Promise<void>;
  isLoadedAdminOrganizationConnectMasters: boolean;
}>({
  adminOrganizationConnectMasters: [],
  runReloadAdminOrganizationConnectMasters: async () => {
    // nop
  },
  isLoadedAdminOrganizationConnectMasters: false,
});

export const AdminOrganizationConnectMastersProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: adminOrganizationConnectMasters,
    runReloadState: runReloadAdminOrganizationConnectMasters,
    isLoadedState: isLoadedAdminOrganizationConnectMasters,
  } = useAdminAPI<ConnectMasterRaw[]>("connectMasters");

  const ctxValue = useMemo(
    () => ({
      adminOrganizationConnectMasters,
      runReloadAdminOrganizationConnectMasters,
      isLoadedAdminOrganizationConnectMasters,
    }),
    [
      adminOrganizationConnectMasters,
      runReloadAdminOrganizationConnectMasters,
      isLoadedAdminOrganizationConnectMasters,
    ]
  );

  return <ctx.Provider value={ctxValue}>{children}</ctx.Provider>;
};

/*
 * @description ひとつの組織のConnectマスタ一覧を取得する
 */
export const useAdminOrganizationConnectMaster = () => {
  return useContext(ctx);
};

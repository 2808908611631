import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { AppViewMasterRaw } from "../../../domain/AppViewMasters";
import { useAdminAPI } from "../hooks";

const ctx = createContext<{
  appViewMaster: AppViewMasterRaw | null;
  runReloadAppViewMaster: () => Promise<void>;
  isLoadedAppViewMaster: boolean;
}>({
  appViewMaster: null,
  runReloadAppViewMaster: async () => {
    //nop
  },
  isLoadedAppViewMaster: false,
});

// 個別のビューマスタの情報を取得する
export const AdminAppViewMasterProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: appViewMaster,
    runReloadState: runReloadAppViewMaster,
    isLoadedState: isLoadedAppViewMaster,
  } = useAdminAPI<AppViewMasterRaw>("appViewMaster");

  const ctxValue = useMemo(
    () => ({
      appViewMaster,
      runReloadAppViewMaster,
      isLoadedAppViewMaster,
    }),
    [appViewMaster, runReloadAppViewMaster, isLoadedAppViewMaster]
  );

  return <ctx.Provider value={ctxValue}>{children}</ctx.Provider>;
};

export const useAdminAppViewMaster = () => {
  return useContext(ctx);
};

import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "./App";
import "ress";
import "./fluentInit";

const root = createRoot(
  document.body.appendChild(document.createElement("div"))
);

const dynamicImport = async () => {
  const isDev = process.env.APP_ENV === "development";
  const isQA = process.env.APP_ENV === "qa";
  const isStg = process.env.APP_ENV === "staging";
  if (isDev || isQA || isStg) {
    const { EnvMessage } = await import("./utils/envMessage");
    return <EnvMessage isDev={isDev} isQA={isQA} isStg={isStg}/>;
  }
};

dynamicImport().then((EnvMessage) => {
  EnvMessage
    ? root.render(
        <>
          <App />
          {EnvMessage}
        </>
      )
    : root.render(<App />);
});

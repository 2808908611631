import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKwsCSVViewFinancial: StrictViewType = {
  key: "nshKwsCSVViewFinancial",
  displayName: "NSH-KWS S4 CSV連携ビュー（経理用）",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "general",
      displayName: "general",
      dependSheetFields: [
        {
          key: "chidoriGasPrice",
          displayName: "用役費千鳥|コスト計算|都市ガス単価",
          type: "float",
        },
        {
          key: "ukishimaGasPrice",
          displayName: "用役費浮島|コスト計算|都市ガス単価",
          type: "float",
        },
        {
          key: "ethyleneCostForEO",
          displayName: "原料コスト|EO用エチレン単価",
          type: "float",
        },
        {
          key: "ethyleneCostForEG",
          displayName: "原料コスト|EG用エチレン単価",
          type: "float",
        },
        {
          key: "chidoriElectricityPrice",
          displayName: "用役費千鳥|コスト計算|電力単価",
          type: "float",
        },
        {
          key: "ukishimaElectricityPrice",
          displayName: "用役費浮島|コスト計算|電力単価",
          type: "float",
        },
        {
          key: "ukishimaSteamPrice",
          displayName: "用役費浮島|コスト計算|蒸気単価",
          type: "float",
        },
      ],
    },
    {
      key: "internalIdToItemCode",
      displayName: "internalIdToItemCode",
      dependSheetFields: [
        {
          key: "internalId",
          displayName: "内部ID",
          type: "string",
        },
        {
          key: "itemCode",
          displayName: "品目コード",
          type: "string",
        },
      ],
    },
  ],
  configSchemas: [],
};

import {
  FirestoreCollectionProsessorOptions,
  createFirestoreCollectionProsessor,
} from "../firebase/firestore";

export type User = {
  id: string;
  uid: string;
  defaultOrgId: string | null;
  isDisabled: boolean;
};
type UserRaw = {
  id: string;
  uid: string;
  default_org_id: string | null;
  is_disabled: boolean;
};

const processorOptions: FirestoreCollectionProsessorOptions<
  User,
  UserRaw,
  Record<string, string>
> = {
  getCollectionPath: ({ organizationId, versionId, sheetId }) =>
    `/app/spreadsheet_ui/v/1/users`,
  toFirestore: ({ id, uid, defaultOrgId, isDisabled }) => ({
    id,
    uid,
    default_org_id: defaultOrgId,
    is_disabled: isDisabled,
  }),
  fromFirestore: ({ id, uid, default_org_id, is_disabled }) => ({
    id,
    uid,
    defaultOrgId: default_org_id,
    isDisabled: is_disabled,
  }),
};

export const {
  fromFirestore: userFromFirestore,
  // useItem: useUser,
  useGetAll: useGetUsers,
  useSet: useSetUser,
  useItems: useUsers,
} = createFirestoreCollectionProsessor(processorOptions);

import loadable from "@loadable/component";
import { getDependSheets } from "../../schema/allDependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeDeliveryPlanView: StrictViewType = {
  key: "planiumChemicalDeliveryPlanView",
  displayName: "オーダー一覧",
  render: ViewRender as any,
  dependSheets: getDependSheets([
    "shippingPlan",
    "salesInput",
    "itemMaster",
    "storageItemMaster",
    "params",
    "adjustmentInput",
    "batchTaskPlan",
    "batchTaskMaster",
    "businessDayCalendar",
    "batchTaskProcessTimeMaster",
    "deviceMaster",
    "purchasePlan",
    "batchTaskRawMaterialPlan",
    "batchTaskStoragePlan",
    "switchingBatchTaskMaster",
    "rawMaterialMaster",
    "scoreSummaries",
  ]),
  configSchemas: [],
};

import numfmt from "numfmt";

import { SheetScoreSummaryItem } from "../../../domain/SheetScoreSummary";

export const parseIntegerFloat = (
  value: string | null,
  valueType: Extract<SheetScoreSummaryItem["value_type"], "float" | "integer">
): number | null => {
  if (!value) return null;
  let parsedValue: number;
  parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) {
    parsedValue = numfmt(value)?.v || NaN;
  }
  if (valueType === "integer") {
    parsedValue = parseInt(String(parsedValue));
  }
  return !isNaN(parsedValue) ? parsedValue : null;
};

import loadable from "@loadable/component";

import { temporaryCourseDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const temporaryCourseEditorView: StrictViewType = {
  key: "ngrKoubanTemporaryCourseEditor",
  displayName: "NGR交番:貸切臨時",
  render: ViewRender as any,
  dependSheets: temporaryCourseDependSheets,
  configSchemas: [],
};

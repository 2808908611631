import { MemberRaw } from "../../../domain/Member";
import { randomColorFromSeed } from "../../../utils/randomColorFromSeed";

import {
  EditingDataOutlineInfo,
  FindOtherUserSession,
  OtherSessionData,
} from "./types";

export type SheetSessionData = {
  selectingRowIds: string[];
  selectingFieldId: string | null;
};

export const getVersionSessionKey = (versionId: string) =>
  "versions__" + versionId;
export const getViewSessionKey = (viewId: string) => "views__" + viewId;
export const getSheetSessionKey = (sheetId: string) => "sheets__" + sheetId;

/**
 * @description 他人や自分が操作している描画する
 */
export const getEditingDataOutlineInfo = <T extends { [key: string]: any }>({
  findOtherUserSession,
  otherUsersSessions,
  myselfSessionData = undefined,
  accessMembersRaw,
}: {
  findOtherUserSession: FindOtherUserSession<T>;
  otherUsersSessions: OtherSessionData<T>[];
  accessMembersRaw: MemberRaw[];
  myselfSessionData?: T;
}): EditingDataOutlineInfo => {
  // 自分の操作状態
  const isMyselfEditingCell = myselfSessionData
    ? findOtherUserSession(myselfSessionData)
    : false;
  // 他の人の対象セルの操作状態を抽出
  const otherUserSession = otherUsersSessions.find(({ data }) =>
    findOtherUserSession(data)
  );
  if (isMyselfEditingCell) {
    return { color: "#0084d1", name: null };
  } else if (otherUserSession) {
    const name =
      accessMembersRaw.find(({ uid }) => uid === otherUserSession.user.uid)
        ?.display_name || "unknown";
    return {
      color: randomColorFromSeed(otherUserSession.user.uid),
      name,
    };
  }
  return undefined;
};

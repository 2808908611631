import { viewTypeCSV4SAPView } from "./CSVView4sap";
import { viewTypeBatchGantt } from "./batchGantt";
import { viewTypeDeliveryPlanView } from "./deliveryPlan";
import { viewTypeItemStockView } from "./itemStockView";
import { viewTypePSIView } from "./psi";
import { viewTypeStorageStockView } from "./storageStockView";

import { ViewType } from "@/domain/ViewType";

/**
 * @description 各ビューの定義を列挙する。
 * 新しくviewを定義したらここに追加する
 */
export const viewTypesPlaniumChemical: ViewType<any, any>[] = [
  viewTypeDeliveryPlanView,
  viewTypeStorageStockView,
  viewTypeBatchGantt,
  viewTypeItemStockView,
  viewTypePSIView,
  viewTypeCSV4SAPView,
];

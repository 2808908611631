import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { Role } from "@/domain/roles";
import { useAdminAPI } from "../hooks";

const ctx = createContext<{
  roles: Role[] | null;
  runReloadRoles: () => Promise<void>;
  isLoadedRoles: boolean;
  isAdminMode: boolean;
}>({
  roles: null,
  runReloadRoles: async () => {
    // nop
  },
  isLoadedRoles: false,
  isAdminMode: true,
});

export const AdminOrganizationRolesProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: roles,
    runReloadState: runReloadRoles,
    isLoadedState: isLoadedRoles,
  } = useAdminAPI<Role[]>("roles");

  const ctxValue = useMemo(
    () => ({
      roles,
      runReloadRoles,
      isLoadedRoles,
      isAdminMode: true,
    }),
    [roles, runReloadRoles, isLoadedRoles]
  );

  return <ctx.Provider value={ctxValue}>{children}</ctx.Provider>;
};

export const useAdminOrganizationRoles = () => {
  return useContext(ctx);
};

import loadable from "@loadable/component";

import { frontOfficeViewDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const frontOfficeView: StrictViewType = {
  key: "ngrKoubanFrontOffice",
  displayName: "NGR交番:フロントオフィス",
  render: ViewRender as any,
  dependSheets: frontOfficeViewDependSheets,
  configSchemas: [],
};

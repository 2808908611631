import { DATETIME_WITH_SECONDS_FORMAT, DATE_FORMAT } from "@/config/date";

// 定義可能な型一覧
export type ServerTypeName =
  | "string"
  | "integer"
  | "float"
  | "date"
  | "date-time"
  | "boolean"
  | "enum"
  | "foreign"
  | "array"
  | "object";

export const allTypeList: ServerTypeName[] = [
  "string",
  "integer",
  "float",
  "date",
  "date-time",
  "boolean",
  "enum",
  "foreign",
  "array",
  "object",
];

/**
 * @description 自分を入れ子にできる. 例えばarray型を定義する場合は入れ子になる
 * @param type: 型名
 * @param type_fields :型の入れ子。主にarrayなどで使う
 * @param typeParameters: yyyy-MM-dd HH:mm:ssのフォーマットテキストなど、型固有のパラメータ。
 */
export type TypeSchema = {
  type: ServerTypeName;
  typeFields: TypeSchema[] | null;
  typeParameters: string[] | null;
};

// TypeSchemaのfirestore用
export type TypeSchemaRaw = {
  field_type: ServerTypeName;
  field_type_fields: TypeSchemaRaw[] | null;
  field_type_parameters: string[] | null;
  // type: types;
  // type_fields: TypeSchemaRaw[] | null;
  // type_parameters: string[] | null;
};

type ToClientTypeName<T> = T extends "date-time" ? "datetime" : T;
export const typeSchemaTemplates: {
  [key in ToClientTypeName<ServerTypeName>]: TypeSchema;
} = {
  string: {
    type: "string",
    typeFields: null,
    typeParameters: null,
  },
  integer: {
    type: "integer",
    typeFields: null,
    typeParameters: ["#,###"],
  },
  float: {
    type: "float",
    typeFields: null,
    typeParameters: ["#,##0.00"],
  },
  date: {
    type: "date",
    typeFields: null,
    typeParameters: [DATE_FORMAT],
  },
  datetime: {
    type: "date-time",
    typeFields: null,
    typeParameters: [DATETIME_WITH_SECONDS_FORMAT],
  },
  boolean: {
    type: "boolean",
    typeFields: null,
    typeParameters: null,
  },
  enum: {
    type: "enum",
    typeFields: null,
    typeParameters: ["a", "TypeA", "b", "TypeB"],
  },
  foreign: {
    type: "foreign",
    typeFields: null,
    typeParameters: ["NULL", "NULL"],
  },
  array: {
    type: "array",
    typeFields: [
      {
        type: "string",
        typeFields: null,
        typeParameters: null,
      },
    ],
    typeParameters: null,
  },
  object: {
    type: "object",
    typeFields: null,
    typeParameters: null,
  },
};

// front->firestoreにスキーマデータを変換する関数
export const encodeTypeSchema = ({
  type,
  typeFields,
  typeParameters,
}: TypeSchema): TypeSchemaRaw => {
  return {
    field_type: type,
    field_type_fields: typeFields?.map((c) => encodeTypeSchema(c)) || null,
    field_type_parameters: typeParameters ? [...typeParameters] : null,
    // type: type,
    // type_fields: typeFields?.map((c) => encodeTypeSchema(c)) || null,
    // type_parameters: typeParameters ? [...typeParameters] : null,
  };
};

// firestore=>frontにスキーマデータを変換を変換する関数
export const parseTypeSchema = ({
  field_type,
  field_type_fields,
  field_type_parameters,
}: // type,
// type_fields,
// type_parameters,
TypeSchemaRaw): TypeSchema => {
  return {
    type: field_type,
    typeFields: field_type_fields?.map((c) => parseTypeSchema(c)) || null,
    typeParameters: field_type_parameters ? [...field_type_parameters] : null,
    // type,
    // typeFields: type_fields?.map((c) => parseTypeSchema(c)) || null,
    // typeParameters: type_parameters ? [...type_parameters] : null,
  };
};

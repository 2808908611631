import {
  FrontendAddonWithVersionInfo,
  FrontendAddonWithVersionInfoRaw,
  parseFrontendAddonWithVersionInfoRaw,
} from "@/domain/FrontendAddon";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrl = "/frontend_addons";

// 組織ごとの利用可能なFrontendAddonをリストで取得する
export const getFrontendAddons = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<FrontendAddonWithVersionInfo[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{
    items: FrontendAddonWithVersionInfoRaw[];
  }>(apiUrl, {
    ...apiOrganizationIdParams,
  });
  return response.data.items.map((raw) =>
    parseFrontendAddonWithVersionInfoRaw(raw)
  );
};

import React from "react";

import styles from "./index.module.css";

export const AppLoginWaiting: React.FC = () => {
  return (
    <div className={styles.loadingApp}>
      ログイン中です...
      <div className={styles.bar}></div>
    </div>
  );
};

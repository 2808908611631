import {
  MemberAddForm,
  MemberRaw,
  MemberUpdateForm,
  getMemberUserId,
} from "../../domain/Member";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrlMembers = "/members";
const apiUrlInvitedMembers = "/invitations" + apiUrlMembers;

export const getMembers = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<MemberRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: MemberRaw[] }>(
    `${apiUrlMembers}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.items;
};
export const getMember = async ({
  organizationId,
  uid,
}: {
  organizationId: string;
  uid: MemberRaw["uid"];
}): Promise<MemberRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ item: MemberRaw }>(
    `${apiUrlMembers}/${uid}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};
export const postAddMember = async ({
  organizationId,
  memberAddForm,
}: {
  organizationId: string;
  memberAddForm: MemberAddForm;
}): Promise<MemberRaw> => {
  const { apiOrganizationIdParams, apiHeadersWithJson } =
    getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: MemberRaw }>(
    `${apiUrlMembers}`,
    memberAddForm,
    {
      ...apiHeadersWithJson,
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};
export const postEditMember = async ({
  organizationId,
  memberUpdateForm,
}: {
  organizationId: string;
  memberUpdateForm: MemberUpdateForm;
}): Promise<MemberRaw> => {
  const { apiOrganizationIdParams, apiHeadersWithJson } =
    getAPIConfigs(organizationId);
  const userId = memberUpdateForm.is_new_user
    ? memberUpdateForm.idp_uid
    : memberUpdateForm.uid;
  const response = await API_CLIENT.post<{ item: MemberRaw }>(
    `${apiUrlMembers}/${userId}`,
    memberUpdateForm,
    {
      ...apiHeadersWithJson,
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};
export const postDeleteMember = async ({
  organizationId,
  uid,
}: {
  organizationId: string;
  uid: MemberRaw["uid"];
}): Promise<MemberRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: MemberRaw }>(
    `${apiUrlMembers}/${uid}/delete`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};
export const postEnableMember = async ({
  organizationId,
  uid,
}: {
  organizationId: string;
  uid: MemberRaw["uid"];
}): Promise<MemberRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: MemberRaw }>(
    `${apiUrlMembers}/${uid}/enable`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};
export const postRemoveMember = async ({
  organizationId,
  uid,
}: {
  organizationId: string;
  uid: MemberRaw["uid"];
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  await API_CLIENT.post(`${apiUrlMembers}/${uid}/remove`, undefined, {
    ...apiOrganizationIdParams,
  });
};

export const getInvitedMembers = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<MemberRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: MemberRaw[] }>(
    `${apiUrlInvitedMembers}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.items;
};
export const postEditInvitedMember = async ({
  organizationId,
  memberUpdateForm,
}: {
  organizationId: string;
  memberUpdateForm: MemberUpdateForm;
}): Promise<MemberRaw> => {
  const userId = getMemberUserId({
    member: memberUpdateForm,
    isParsedIdpUid: false,
  });
  const { apiOrganizationIdParams, apiHeadersWithJson } =
    getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: MemberRaw }>(
    `${apiUrlInvitedMembers}/${userId}`,
    memberUpdateForm,
    {
      ...apiHeadersWithJson,
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};
export const postRemoveInvitedMember = async ({
  organizationId,
  member,
}: {
  organizationId: string;
  member: MemberRaw;
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const userId = getMemberUserId({
    member: member,
    isParsedIdpUid: false,
  });
  await API_CLIENT.post(`${apiUrlInvitedMembers}/${userId}/remove`, undefined, {
    ...apiOrganizationIdParams,
  });
};
export const postReinviteMember = async ({
  organizationId,
  member,
}: {
  organizationId: string;
  member: MemberRaw;
}): Promise<MemberRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const userId = getMemberUserId({
    member: member,
    isParsedIdpUid: false,
  });
  const response = await API_CLIENT.post<{ item: MemberRaw }>(
    `${apiUrlInvitedMembers}/${userId}/reinvite`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

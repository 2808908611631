import {
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";

import { isLocalhost } from "../utils/isLocalhost";

import { auth } from ".";

export const firebaseSignIn = async (param?: { isReplaceHistory: boolean }) => {
  const isReplaceHistory = param?.isReplaceHistory || false;
  /**
   * NOTE: signinWithRedirectはローカルで対応できないため、signInWithPopupを利用
   * https://cloud.google.com/identity-platform/docs/web/redirect-best-practices?hl=ja
   * https://github.com/firebase/firebase-js-sdk/issues/7342
   */
  if (!isLocalhost()) {
    // リダイレクト後に戻ってくる先を変更
    // NOTE: Auth0からのリダイレクト時に余分なパラメータ削除
    isReplaceHistory && history.replaceState(null, document.title, "/");
    // カスタムプロバイダとして、firebaseで設定したauth0テナントのログイン画面にリダイレクトする
    await signInWithRedirect(auth, new OAuthProvider("oidc.auth0-idp"));
  } else {
    const result = await signInWithPopup(
      auth,
      new OAuthProvider("oidc.auth0-idp")
    );
    if (result) {
      location.href = "/";
    }
  }
};

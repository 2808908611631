export function isLocalhost() {
  const hostname = self.location.hostname;
  const localhostStrArr = ["localhost", "127.0.0.1"];
  for (let i: number = 0; i < localhostStrArr.length; i++) {
    if (localhostStrArr[i] === hostname) {
      return true;
    }
  }
  return false;
}

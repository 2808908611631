import { useAppRouteParams } from "@/AppRoutes";
import { usePermissions } from "@/components/InOrganizationProvider/PermissionsProvider";
import { useVersions } from "@/components/InOrganizationProvider/VersionsProvider";
import { AllActionsType } from "@/domain/roles";

export const useVersionPermission = (
  action: AllActionsType
): [boolean, boolean] => {
  const { versionId } = useAppRouteParams();
  const { hasVersionActionPermission, isLoadedMe } = usePermissions();
  const { versions, isLoadedVersions } = useVersions();
  const version = versions.find((v) => v.id === versionId);
  if (!versionId) return [false, true];
  return [
    hasVersionActionPermission(version ?? null, action),
    isLoadedVersions && isLoadedMe,
  ];
};

export const useSheetPermission = (
  action: AllActionsType,
  sheetId: string
): [boolean, boolean] => {
  const { versionId } = useAppRouteParams();
  const { hasSheetActionPermission, isLoadedMe } = usePermissions();
  const { versions, isLoadedVersions } = useVersions();
  const version = versions.find((v) => v.id === versionId);
  return [
    hasSheetActionPermission(version ?? null, sheetId, action),
    isLoadedVersions && isLoadedMe,
  ];
};

import {
  ContextualMenu,
  ContextualMenuItemType,
  DefaultButton,
  DirectionalHint,
  FontIcon,
  IContextualMenuItem,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import classNames from "classnames";
import { compareDesc } from "date-fns";
import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";

import { useAppRouteId } from "../../../AppRoutes";
import { useAppRouteParams } from "../../../AppRoutes";
import {
  CLOSED_SIDE_MENU_WIDTH,
  MAXIMUM_RESIZE_SIDE_MENU_WIDTH,
  MINIMUM_RESIZE_SIDE_MENU_WIDTH,
  OPEN_SIDE_MENU_WIDTH,
} from "../../../const";
import { useContextMenuItemsVersionsMenu } from "../../../contextItems/hooks";
import { Version, checkIsPublishedByFileUpload } from "../../../domain/Version";
import { checkIsMfaSettingRequired } from "../../../helpers/checkIsMfaSettingRequired";
import { useResizeHandler } from "../../../hooks/useResizeHandler";
import aaLogo from "../../../images/aa_logo_white.png";
import optiumLogo from "../../../images/optium_logo_white.png";
import planiumLogo from "../../../images/planium_logo_white.png";
import { contextMenuStyle } from "../../../styles";
import { themeFileOptimizeButton } from "../../../themes";
import { useAppAccount } from "../../AppProvider/AppAccountProvider";
import { DialogPhaseType } from "../../Dialog/helpers/hooks";
import { LoadingCover, LoadingCoverWithoutText } from "../../LoadingCover";
import {
  SortableTmpMenuSet,
  TmpMenuSetChevronDownIcon,
  TmpMenuSetDivider,
} from "../helpers";

import { AppSideNavGlobalVersions } from "./AppSideNavGlobalVersions";
import { FileOptimizeDialog } from "./FileOptimizeDialog";
import { OrganizationInfoDialog } from "./OrganizationInfoDialog";
import { OrganizationSwitchDialog } from "./OrganizationSwitchDialog";
import styles from "./index.module.css";
import { VisibleOrganizationDialog } from "./typs";

import { useAppOrgsAndInvitedOrgs } from "@/components/AppProvider/AppOrgsAndInvitedOrgsProvider";
import { useOrganization } from "@/components/InOrganizationProvider/OrganizationProvider";
import { usePermissions } from "@/components/InOrganizationProvider/PermissionsProvider";
import { useVersionCategories } from "@/components/InOrganizationProvider/VersionCategoriesProvider";
import { useVersions } from "@/components/InOrganizationProvider/VersionsProvider";
import { parseDateTimeOrISODateTime } from "@/utils/dateFnsHelper";

const MENU_COLLAPSE_DURATION = 175;

/**
 * @description メニュ-画面左に常にあるメニュー.
 * バージョン画面や、トップページで、表示内容が変わる
 * 管理者モードが存在する
 * todo: 状態やCSSが散らかってきたので、整理したい
 * todo: AA developerも権限は管理者モードは開けなくする
 */
export const AppSideNavGlobal: React.FC<{ isAdminMode?: boolean }> = ({
  isAdminMode = false,
}) => {
  const appRouteId = useAppRouteId();
  const navigate = useNavigate();
  const { account } = useAppAccount();
  const { organizationId } = useAppRouteParams();
  const { versions, isLoadedVersions } = useVersions();
  const { hasPermission } = usePermissions();
  const hasVersionCategoriesReadPermission = hasPermission(
    "version_categories",
    "read"
  );
  const hasAlgorithmVersionsReadPermission = hasPermission(
    "algorithm_versions",
    "read"
  );

  const { versionCategories, isLoadedVersionCategories } =
    useVersionCategories();
  const { organization: memberOrganization } = useOrganization();
  const {
    organizations: appOrganizations,
    isLoadedOrganizations,
    defaultOrgServiceType,
  } = useAppOrgsAndInvitedOrgs();
  const appOrganization = appOrganizations.find(
    (org) => org.org_id === organizationId
  );
  const organization = memberOrganization || appOrganization;
  const { isAADeveloperOrAbove } = useAppAccount();

  const isMfaSettingRequired = checkIsMfaSettingRequired(account);

  const sortedVersions = useMemo(
    () =>
      versions
        .filter((v) => !v.isDisabled)
        .sort((a, b) => {
          const _a = parseDateTimeOrISODateTime(a.publishedAt);
          const _b = parseDateTimeOrISODateTime(b.publishedAt);
          return _a && _b ? compareDesc(_a, _b) : 0;
        }),
    [versions]
  );
  const versionsToBeDisplayed = useMemo(
    () =>
      organization?.is_sheet_enabled
        ? sortedVersions
        : sortedVersions.filter((v) => checkIsPublishedByFileUpload(v)),
    [organization, sortedVersions]
  );

  // カテゴリごとにバージョンを分類
  const displayedVersionsMap: Map<string, Version[]> = useMemo(() => {
    if (!versionCategories) {
      return new Map();
    }
    const map = new Map(
      versionCategories?.map(({ id, name }) => [
        name,
        versionsToBeDisplayed.filter((v) => v.category === id),
      ])
    );
    // 付与されたカテゴリが取得されていない場合、もしくはカテゴリを付与されていない場合は「未分類」に入れる
    map.set(
      "未分類",
      versionsToBeDisplayed.filter(
        (v) => !versionCategories?.map(({ id }) => id).includes(v.category)
      )
    );
    return map;
  }, [
    organization,
    versions,
    versionCategories,
    hasVersionCategoriesReadPermission,
  ]);

  const [menuWidth, setMenuWidth] = useState(OPEN_SIDE_MENU_WIDTH);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  /**
   * Dialog
   */
  const [visibleOrganizationDialog, setVisibleOrganizationDialog] =
    useState<VisibleOrganizationDialog>(null);
  const closeOrganizationDialog = () => setVisibleOrganizationDialog(null);
  const [dialogPhase, setDialogPhase] = useState<DialogPhaseType>(null);

  // アニメーションに利用する状態
  // NOTE: アニメーションと同じ時間で状態を変化させることで文字変化の違和感を消す
  const [menuNames, setMenuNames] = useState<{
    dashboard: string;
    versionList: string;
    versionsSummary: string;
  } | null>({
    dashboard: "ダッシュボード",
    versionList: "バージョン一覧",
    versionsSummary: "バージョン比較",
  });
  const [availableLogo, setAvailableLogo] = useState<string>(aaLogo);
  useEffect(() => {
    setAvailableLogo(() => {
      if (defaultOrgServiceType === "optium") {
        return optiumLogo;
      } else if (defaultOrgServiceType === "planium") {
        return planiumLogo;
      } else {
        return aaLogo;
      }
    });
  }, [defaultOrgServiceType]);

  const [isVisibleOptimizeButton, setIsVisibleOptimizeButton] = useState(true);
  const [isVisibleVersions, setIsVisibleVersions] = useState(true);

  /**
   * context
   */
  const topMenuNodeId = useId();
  const versionMenuNodeId = useId();
  const [contexMenuType, setContexMenuType] = useState<
    "top" | "version" | null
  >(null);
  const topContextMenuItems: IContextualMenuItem[] = useMemo(() => {
    const items = [
      {
        key: "section1",
        text: "組織の切り替え",
        onClick: () => {
          setVisibleOrganizationDialog("switch");
        },
        iconProps: {
          iconName: "Switch",
          style: { color: "var(--figma-color-lightBlack)" },
        },
      },
      {
        key: "divider_0",
        text: "-",
        itemType: ContextualMenuItemType.Divider,
      },
      {
        key: "section2",
        text: "組織情報",
        onClick: () => {
          setVisibleOrganizationDialog("info");
        },
        disabled: organizationId ? false : true,
        iconProps: {
          iconName: "info",
          style: { color: "var(--figma-color-lightBlack)" },
        },
      },
      {
        key: "section3",
        text: "組織設定",
        onClick: () => {
          navigate(`/organizations/${organizationId}/organization_setting`);
        },
        disabled: !organizationId,
        iconProps: {
          iconName: "settings",
          style: { color: "var(--figma-color-lightBlack)" },
        },
      },
      {
        key: "section4",
        text: "ヘルプ",
        disabled: defaultOrgServiceType !== "planium",
        iconProps: {
          iconName: "Help",
          style: { color: "var(--figma-color-lightBlack)" },
        },
        onClick: () => {
          defaultOrgServiceType === "planium" &&
            window.open(
              "https://algo-artis.notion.site/Planium-28a9ccb5a97c4915a1752ae59aa78b85",
              "_blank",
              "noopener,noreferrer"
            );
        },
      },
      {
        key: "divider_1",
        text: "-",
        itemType: ContextualMenuItemType.Divider,
      },
      {
        key: "section5",
        text: "アルゴリズムバージョン",
        onClick: () => {
          navigate(`/organizations/${organizationId}/algorithm_versions`);
        },
        disabled: !organizationId || !hasAlgorithmVersionsReadPermission,
        iconProps: {
          iconName: "PageList",
          style: { color: "var(--figma-color-lightBlack)" },
        },
      },
      {
        key: "section6",
        text: "フロントエンドアドオン",
        onClick: () => {
          navigate(`/organizations/${organizationId}/frontend_addons`);
        },
        disabled: !organizationId || !isAADeveloperOrAbove,
        iconProps: {
          iconName: "Documentation",
          style: { color: "var(--figma-color-lightBlack)" },
        },
      },
    ];
    return items;
  }, [
    organizationId,
    setVisibleOrganizationDialog,
    account,
    hasAlgorithmVersionsReadPermission,
    defaultOrgServiceType,
  ]);

  const versionContextMenuItems: IContextualMenuItem[] =
    useContextMenuItemsVersionsMenu();

  /**
   * @description メニュートップの、組織名やコンテキストメニュー表示部
   * 組織外ではロゴを表示する
   */
  const renderFirstMenu = useCallback(() => {
    const wrapper = (element: React.ReactElement) => (
      <div className={styles.firstMenu}>
        <div
          className={styles.content}
          onMouseDown={() => {
            setContexMenuType((prev) => (!prev ? "top" : null));
          }}
          id={topMenuNodeId}
          style={{
            ...(contexMenuType === "top"
              ? { backgroundColor: "var(--figma-color-sideMenuHover)" }
              : {}),
          }}
        >
          {element}
        </div>
      </div>
    );
    /**
     * 組織外
     */
    if (!organizationId) {
      if (!isLoadedOrganizations) {
        return isMenuCollapsed ? <LoadingCoverWithoutText /> : <LoadingCover />;
      }
      return wrapper(
        <div className={styles.icons}>
          {isMenuCollapsed || menuWidth < 173 ? null : (
            <div className={styles.logo}>
              <img src={availableLogo} height={25} alt="Logo" />
            </div>
          )}
          <TmpMenuSetChevronDownIcon />
        </div>
      );
    } else {
      /**
       * 組織内
       */
      if (!isLoadedOrganizations) {
        return isMenuCollapsed ? <LoadingCoverWithoutText /> : <LoadingCover />;
      }

      return wrapper(
        <>
          {!isMenuCollapsed ? (
            <div className={styles.textWithTooltip}>
              <TooltipHost
                content={organization?.name}
                hidden={isMenuCollapsed || contexMenuType === "top"}
              >
                <div className={styles.title}>{organization?.name}</div>
              </TooltipHost>
            </div>
          ) : null}
          <div
            className={classNames({
              [styles.rightIcon]: !isMenuCollapsed,
            })}
          >
            <TmpMenuSetChevronDownIcon />
          </div>
        </>
      );
    }
  }, [
    menuWidth,
    topMenuNodeId,
    contexMenuType,
    organization,
    isLoadedOrganizations,
    organizationId,
    isMenuCollapsed,
  ]);

  /**
   * @description ダッシュボード、バージョン一覧のリンク
   */
  const renderSecondMenu = useCallback(() => {
    const body = (() => {
      /**
       * 組織外
       */
      if (!organizationId) {
        return null;
      } else {
        /**
         * 組織内
         */
        // 組織情報ロード中 or 権限がなければ何も表示しない
        if (
          !isLoadedOrganizations ||
          !hasPermission("optimize", "write") ||
          !hasPermission("evaluate", "write")
        )
          return null;
        return organization?.is_file_optimize_enabled ? (
          <>
            <TmpMenuSetDivider />
            <div className={styles.innerRootMenu}>
              {isVisibleOptimizeButton && menuWidth > 160 ? (
                <div style={{ padding: "0 15px" }}>
                  <DefaultButton
                    text="ファイル最適化"
                    theme={themeFileOptimizeButton}
                    iconProps={{ iconName: "Upload" }}
                    styles={{
                      root: {
                        borderRadius: "10px",
                        height: "40px",
                        padding: "0 62px",
                        width: "100%",
                        maxWidth: "230px",
                      },
                      label: {
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      },
                    }}
                    onClick={() => setDialogPhase("confirm")}
                  />
                </div>
              ) : (
                <SortableTmpMenuSet
                  id={"Upload"}
                  iconName="Upload"
                  iconColor="#ebebeb"
                  onClick={() => setDialogPhase("confirm")}
                />
              )}
            </div>
          </>
        ) : null;
      }
    })();
    return <>{body}</>;
  }, [
    organization,
    isLoadedOrganizations,
    menuNames,
    organizationId,
    appRouteId,
    isMenuCollapsed,
    isMfaSettingRequired,
    isVisibleOptimizeButton,
    menuWidth,
    hasPermission,
  ]);
  const renderThirdMenu = useCallback(() => {
    const body = (() => {
      /**
       * 組織外
       */
      if (!organizationId) {
        return (
          <>
            <SortableTmpMenuSet
              id={"account"}
              text={`${isMenuCollapsed ? "" : "アカウント設定"}`}
              to={"/account_setting"}
              iconName="Contact"
              active={appRouteId === "accountSetting"}
              isDisableTooltip={true}
            />
            <SortableTmpMenuSet
              id={"organizations"}
              text={`${isMenuCollapsed ? "" : "あなたの参加組織一覧"}`}
              to={"/organizations"}
              iconName="Org"
              active={appRouteId === "organizations"}
              isDisableTooltip={true}
            />
            <SortableTmpMenuSet
              id={"organization_create"}
              text={`${isMenuCollapsed ? "" : "新規組織作成"}`}
              to={"/organization_create"}
              iconName="CirclePlus"
              active={appRouteId === "organizationCreate"}
              isDisableTooltip={true}
            />
          </>
        );
      } else {
        /**
         * 組織内
         */
        // 組織情報ロード中は何も表示しない
        if (!isLoadedOrganizations) return null;
        return (
          <>
            <SortableTmpMenuSet
              id={"PageList"}
              text={menuNames?.versionList ?? ""}
              to={organizationId ? `/organizations/${organizationId}` : ""}
              iconName="PageList"
              active={appRouteId === "organizationTop"}
              isDisableTooltip={true}
            />
            {/* ファイル最適化が有効かつシート機能が無効な場合を除き、バージョン比較を表示 */}
            {!(
              organization?.is_file_optimize_enabled &&
              !organization?.is_sheet_enabled
            ) && (
              <SortableTmpMenuSet
                id={"VersionsSummary"}
                text={menuNames?.versionsSummary ?? ""}
                to={
                  organizationId
                    ? `/organizations/${organizationId}/versions_summary_comparison`
                    : ""
                }
                iconName="StackIndicator"
                active={appRouteId === "versionsSummaryComparison"}
                isDisableTooltip={true}
              />
            )}
          </>
        );
      }
    })();
    return <div className={styles.innerRootMenu}>{body}</div>;
  }, [
    organization,
    isLoadedOrganizations,
    menuNames,
    organizationId,
    appRouteId,
    isMenuCollapsed,
    isMfaSettingRequired,
    isVisibleOptimizeButton,
  ]);

  /**
   * @description バージョン一覧の文字列やデフォルト組織遷移リンクなど
   */
  const renderSupplement = useCallback(() => {
    /**
     * 組織外
     */
    if (!organizationId) {
      //MFA設定済み &デフォルト組織が存在する、ときは表示
      const isShowGoToDefaulOrg =
        !isMfaSettingRequired && account?.default_org_id;
      if (!isShowGoToDefaulOrg) return null;
      return (
        <div className={styles.goToDefaulOrg}>
          <SortableTmpMenuSet
            id={"def_organization"}
            text={`${isMenuCollapsed ? "" : "組織に戻る"}`}
            to={`/organizations/${account.default_org_id}`}
            iconName="ChromeBack"
          />
        </div>
      );
    } else {
      /**
       * 組織内
       */
      // メニュークローズ時 or  組織ロード中何も表示しない
      const isHideVersionList =
        isMenuCollapsed || !menuNames || !isLoadedOrganizations;
      const isEnableButtonAction = organization?.is_sheet_enabled;
      if (isHideVersionList) return null;
      return (
        <div
          className={classNames(styles.versionListName, {
            [styles.hoverItem]: isEnableButtonAction,
          })}
          id={versionMenuNodeId}
          onMouseDown={() => {
            if (!isEnableButtonAction) return;
            setContexMenuType((prev) => (!prev ? "version" : null));
          }}
          style={{
            ...(contexMenuType === "version"
              ? { backgroundColor: "var(--figma-color-sideMenuHover)" }
              : {}),
          }}
        >
          <div className={styles.label}>{menuNames.versionList}</div>
          {isEnableButtonAction ? (
            <FontIcon className={styles.versionAddButton} iconName={"Add"} />
          ) : null}
        </div>
      );
    }
  }, [
    isMenuCollapsed,
    menuNames,
    versionMenuNodeId,
    contexMenuType,
    isLoadedOrganizations,
    organizationId,
    account,
    isMfaSettingRequired,
    menuWidth,
    organization,
  ]);

  const renderInOrganizationMenu = useCallback(() => {
    if (!organizationId || isMenuCollapsed || !isVisibleVersions) return null;
    if (!isLoadedVersions || !isLoadedVersionCategories) {
      return <LoadingCoverWithoutText />;
    }
    return versionCategories ? (
      Array.from(displayedVersionsMap).map(([key, value]) => (
        <AppSideNavGlobalVersions key={key} title={key} versions={value} />
      ))
    ) : (
      <AppSideNavGlobalVersions title="" versions={versionsToBeDisplayed} />
    );
  }, [
    organizationId,
    isMenuCollapsed,
    isVisibleVersions,
    isLoadedVersions,
    isLoadedVersionCategories,
    versionCategories,
    displayedVersionsMap,
    versionsToBeDisplayed,
  ]);

  const renderAdminMenu = useCallback(() => {
    return (
      <>
        <div className={styles.firstMenu}>
          <div
            className={classNames(styles.content, {
              [styles.isAdminContent]: isAdminMode,
            })}
          >
            {`${isMenuCollapsed ? "A" : "ALGO ARTIS"}`}
          </div>
        </div>
        <TmpMenuSetDivider />
        <div className={styles.secondRoot}>
          <div className={styles.innerRootMenu}>
            <SortableTmpMenuSet
              id={"admin_organization"}
              to={"/admin/organizations"}
              text={`${isMenuCollapsed ? "" : "組織"}`}
              iconName={"Org"}
              iconColor="#000"
              isDisableTooltip={true}
              isAdminMode={isAdminMode}
            />
            <SortableTmpMenuSet
              id={"admin_accounts"}
              to={"/admin/accounts"}
              text={`${isMenuCollapsed ? "" : "アカウント"}`}
              iconName={"Contact"}
              iconColor="#000"
              isDisableTooltip={true}
              isAdminMode={isAdminMode}
            />
            <SortableTmpMenuSet
              id={"admin_view"}
              to={"/admin/viewmasters"}
              text={`${isMenuCollapsed ? "" : "ビューマスタ"}`}
              iconName={"AnalyticsView"}
              iconColor="#000"
              isDisableTooltip={true}
              isAdminMode={isAdminMode}
            />
            <SortableTmpMenuSet
              id={"admin_connect"}
              to={"/admin/connectmasters"}
              text={`${isMenuCollapsed ? "" : "Connectマスタ"}`}
              iconName={"PlugConnected"}
              iconColor="#000"
              isDisableTooltip={true}
              isAdminMode={isAdminMode}
            />
          </div>
          {!isMfaSettingRequired && account?.default_org_id ? (
            <>
              <TmpMenuSetDivider />
              <div className={styles.goToDefaulOrg}>
                <SortableTmpMenuSet
                  id={"admin_go_account"}
                  text={`${isMenuCollapsed ? "" : "組織に戻る"}`}
                  to={`/organizations/${account?.default_org_id}`}
                  iconName={"ChromeBack"}
                  iconColor="#000"
                  isDisableTooltip={true}
                  isAdminMode={isAdminMode}
                />
              </div>
            </>
          ) : null}
        </div>
      </>
    );
  }, [isAdminMode, isMenuCollapsed, isMfaSettingRequired, account]);

  /**
   * resizeSideMenu
   */
  const onMouseDownCaptureClose = useCallback(() => {
    setIsMenuCollapsed((prev) => !prev);
    if (!isMenuCollapsed) {
      setMenuNames(null);
      setIsVisibleOptimizeButton(false);
      setIsVisibleVersions(false);
    } else {
      window.setTimeout(function () {
        setMenuNames({
          dashboard: "ダッシュボード",
          versionList: "バージョン一覧",
          versionsSummary: "バージョン比較",
        });
        setIsVisibleOptimizeButton(true);
        setIsVisibleVersions(true);
      }, MENU_COLLAPSE_DURATION);
    }
  }, [isMenuCollapsed]);

  const rootRef = useRef<HTMLDivElement>(null);
  const onResizeHandlerMouseDown = useResizeHandler({
    type: "right",
    targetRef: rootRef,
    onResizeStart: () => {
      setIsResizing(true);
    },
    onResize: (width, height) => {
      if (
        width <= MINIMUM_RESIZE_SIDE_MENU_WIDTH ||
        width >= MAXIMUM_RESIZE_SIDE_MENU_WIDTH
      )
        return;
      setMenuWidth(width);
    },
    onResizeEnd: () => {
      setIsResizing(false);
    },
  });

  return (
    <div key={`${organization?.org_id}-nav-global`}>
      <div
        className={classNames(styles.root, {
          [styles.isResizing]: isResizing,
          [styles.isCollapsed]: isMenuCollapsed,
          [styles.isAdmin]: isAdminMode,
        })}
        style={{
          width: `${isMenuCollapsed ? CLOSED_SIDE_MENU_WIDTH : menuWidth}px`,
          minWidth: `${CLOSED_SIDE_MENU_WIDTH}px`,
        }}
        ref={rootRef}
      >
        {isAdminMode ? (
          renderAdminMenu()
        ) : (
          <>
            {renderFirstMenu()}
            {renderSecondMenu()}
            <TmpMenuSetDivider />
            {renderThirdMenu()}
            <TmpMenuSetDivider />
            {renderSupplement()}
            <div className={styles.secondRoot}>
              {renderInOrganizationMenu()}
            </div>
          </>
        )}
        <TmpMenuSetDivider />
        <div
          className={styles.thirdRoot}
          onMouseDownCapture={onMouseDownCaptureClose}
        >
          <div className={styles.chevron}>
            <FontIcon
              iconName={
                !isMenuCollapsed ? "DoubleChevronLeft" : "DoubleChevronRight"
              }
            />
          </div>
        </div>
        <div
          className={styles.rootResizeHandler}
          onMouseDown={() => {
            if (isMenuCollapsed) return;
            onResizeHandlerMouseDown();
          }}
        />
      </div>
      <ContextualMenu
        styles={contextMenuStyle}
        target={`#${topMenuNodeId}`}
        directionalHint={DirectionalHint.bottomRightEdge}
        items={topContextMenuItems}
        hidden={contexMenuType !== "top"}
        onDismiss={() => {
          setContexMenuType(null);
        }}
      />
      <ContextualMenu
        styles={contextMenuStyle}
        target={`#${versionMenuNodeId}`}
        directionalHint={DirectionalHint.leftBottomEdge}
        items={versionContextMenuItems}
        hidden={contexMenuType !== "version"}
        onDismiss={() => {
          setContexMenuType(null);
        }}
      />
      <OrganizationSwitchDialog
        isVisibleDialog={visibleOrganizationDialog === "switch"}
        closeDialog={closeOrganizationDialog}
      />
      <OrganizationInfoDialog
        isVisibleDialog={visibleOrganizationDialog === "info"}
        closeDialog={closeOrganizationDialog}
      />
      <FileOptimizeDialog
        dialogPhase={dialogPhase}
        setDialogPhase={setDialogPhase}
      />
    </div>
  );
};

import { PaidHolidayViewTypeDependSheet } from "./params";

const paidHolidayDependSheet: PaidHolidayViewTypeDependSheet = {
  key: "workPlanToday",
  displayName: "当日勤務予定",
  dependSheetFields: [
    {
      key: "officeCode",
      displayName: "事業所コード",
      type: "string",
    },
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "workCode",
      displayName: "業務コード",
      type: "string",
    },
    {
      key: "workCodeSeq",
      displayName: "枝番",
      type: "integer",
    },
    {
      key: "workBeginTime",
      displayName: "開始時間",
      type: "string",
    },
    {
      key: "workEndTime",
      displayName: "終了時間",
      type: "string",
    },
    {
      key: "name",
      displayName: "氏名",
      type: "string",
    },
    {
      key: "category",
      displayName: "カテゴリ",
      type: "string",
    },
  ],
};

const driverMasterDependSheet: PaidHolidayViewTypeDependSheet = {
  key: "driverMaster",
  displayName: "乗務員",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "driverName",
      displayName: "氏名",
      type: "string",
    },
  ],
};

export const paidHolidayViewDependSheets = [
  paidHolidayDependSheet,
  driverMasterDependSheet,
];

import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { OrganizationRaw } from "../../../domain/Organization";
import { useAPI } from "../hooks";

const organizationCtx = createContext<{
  organization: OrganizationRaw | null;
  runReloadOrganization: () => Promise<void>;
  isLoadedOrganization: boolean;
}>({
  organization: null,
  runReloadOrganization: async () => {
    // nop
  },
  isLoadedOrganization: false,
});

/**
 * @description サイドメニュー, 組織設定画面などさまざまな場所で使う組織データを管理
 */
export const OrganizationProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: organization,
    runReloadState: runReloadOrganization,
    isLoadedState: isLoadedOrganization,
  } = useAPI<OrganizationRaw>("organizations");

  const organizationCtxValue = useMemo(
    () => ({
      organization,
      runReloadOrganization,
      isLoadedOrganization,
    }),
    [organization, isLoadedOrganization]
  );

  return (
    <organizationCtx.Provider value={organizationCtxValue}>
      {children}
    </organizationCtx.Provider>
  );
};

export const useOrganization = () => {
  return useContext(organizationCtx);
};

import React, {
  useMemo,
  useState,
  ReactNode,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";

import { OrganizationRaw } from "../../../domain/Organization";

import { getAdminOrganizations } from "@/api/admin/organization";
import { getAPIErrorDetailAndMessage } from "@/api/helpers";

const ctx = createContext<{
  adminOrganizations: OrganizationRaw[] | null;
  nextStartAfterId: string | null;
  setStartAfterId: React.Dispatch<React.SetStateAction<string | null>>;
  runReloadAdminOrganizations: () => Promise<void>;
  isLoadedAdminOrganizations: boolean;
}>({
  adminOrganizations: null,
  nextStartAfterId: null,
  setStartAfterId: () => {
    //nop
  },
  runReloadAdminOrganizations: async () => {
    // nop
  },
  isLoadedAdminOrganizations: false,
});

export const AdminOrganizationsProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [adminOrganizations, setAdminOrganizations] = useState<
    OrganizationRaw[] | null
  >(null);
  // reloadを走らせるための始点Id コンポーネントから受け取る
  const [startAfterId, setStartAfterId] = useState<string | null>(null);
  // 次の始点Id コンポーネントで使用
  const [nextStartAfterId, setNextStartAfterId] = useState<string | null>(null);
  const [, setError] = useState<unknown>(null);
  const [isInProgress, setIsInProgress] = useState(false);

  const runReloadAdminOrganizations = useCallback(async () => {
    try {
      setIsInProgress(true);
      const response = await getAdminOrganizations(startAfterId ?? undefined);
      setAdminOrganizations(response);
      setNextStartAfterId(response.at(-1)?.org_id ?? null);
    } catch (err: unknown) {
      setError(() => {
        if (typeof err == "object") {
          const e = {
            ...err,
            message: getAPIErrorDetailAndMessage(err),
          } as Error;
          throw e;
        } else {
          throw new Error("不明なエラー");
        }
      });
    } finally {
      setIsInProgress(false);
    }
  }, [startAfterId]);

  const ctxValue = useMemo(
    () => ({
      adminOrganizations,
      nextStartAfterId,
      setStartAfterId,
      runReloadAdminOrganizations,
      isLoadedAdminOrganizations: !isInProgress,
    }),
    [
      adminOrganizations,
      nextStartAfterId,
      runReloadAdminOrganizations,
      isInProgress,
    ]
  );

  useEffect(() => {
    runReloadAdminOrganizations();
  }, [runReloadAdminOrganizations]);

  return <ctx.Provider value={ctxValue}>{children}</ctx.Provider>;
};

export const useAdminOrganizations = () => {
  return useContext(ctx);
};

import { viewTypeBatchGanttDemo } from "./batchGantt";
import { viewTypeDeliveryPlanDemo } from "./deliveryPlan";
import { viewTypeItemStockViewDemo } from "./itemStockView";
import { viewTypeStorageStockViewDemo } from "./storageStockView";

import { ViewType } from "@/domain/ViewType";

/**
 * @description 各ビューの定義を列挙する。
 * 新しくviewを定義したらここに追加する
 */
export const viewTypesPlaniumChemicalDemo: ViewType<any, any>[] = [
  viewTypeDeliveryPlanDemo,
  viewTypeStorageStockViewDemo,
  viewTypeBatchGanttDemo,
  viewTypeItemStockViewDemo,
];

import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKwsCSVView2sEast: StrictViewType = {
  key: "nshKwsCSVView2sEast",
  displayName: "NSH-KWS S4 CSV連携ビュー（2製東地区用）",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "eoa",
      displayName: "eoa",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "eoaProduction",
          displayName: "24K417N|合計生産量",
          type: "float",
        },
      ],
    },
    {
      key: "bhea",
      displayName: "bhea",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "bheaPurityProduction",
          displayName: "BHEA|高純度|生産",
          type: "float",
        },
        {
          key: "bheaNormalProduction",
          displayName: "BHEA|通常品|生産",
          type: "float",
        },
        {
          key: "bheaProduction1",
          displayName: "BHEA|合計|生産1",
          type: "float",
        },
      ],
    },
    {
      key: "internalIdToItemCode",
      displayName: "internalIdToItemCode",
      dependSheetFields: [
        {
          key: "internalId",
          displayName: "内部ID",
          type: "string",
        },
        {
          key: "itemCode",
          displayName: "品目コード",
          type: "string",
        },
      ],
    },
    {
      key: "s4Master1234s",
      displayName: "s4Master1234s",
      dependSheetFields: [
        {
          key: "itemCode",
          displayName: "品目コード",
          type: "string",
        },
        {
          key: "itemText",
          displayName: "品目テキスト",
          type: "string",
        },
        {
          key: "stockArea",
          displayName: "保管場所",
          type: "string",
        },
        {
          key: "lotSize",
          displayName: "ロットサイズ",
          type: "integer",
        },
      ],
    },
  ],
  configSchemas: [],
};

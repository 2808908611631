import {
  FirestoreCollectionProsessorOptions,
  createFirestoreCollectionProsessor,
} from "../firebase/firestore";

export type ReferredFrom = {
  id: string;
  versionId: string;
  sheetId: string;
};

export type ReferredFromRaw = {
  id: string;
  version_id: string;
  sheet_id: string;
};

const processorOptions: FirestoreCollectionProsessorOptions<
  ReferredFrom,
  ReferredFromRaw,
  Record<string, string>
> = {
  getCollectionPath: ({ organizationId, versionId, sheetId }) =>
    `/app/spreadsheet_ui/v/1/organizations/${organizationId}/versions/${versionId}/sheets/${sheetId}/referred_from`,
  toFirestore: ({ id, versionId, sheetId }) => ({
    id,
    version_id: versionId,
    sheet_id: sheetId,
  }),
  fromFirestore: ({ id, version_id, sheet_id }) => ({
    id,
    versionId: version_id,
    sheetId: sheet_id,
  }),
};

export const {
  useGet: useGetReferredFrom,
  useGetAll: useGetReferredFroms,
  useSet: useSetReferredFrom,
  useDelete: useDeleteReferredFrom,
  useItems: useReferredFrom,
} = createFirestoreCollectionProsessor(processorOptions);

import React, {
  useMemo,
  ReactNode,
  createContext,
  useContext,
  useEffect,
} from "react";

import { Version, useVersionsQuery } from "@/domain/Version";
import {
  ComplexFirestoreQuerySetting,
  FirestoreQuerySetting,
} from "@/firebase/firestore";
import { usePermissions } from "../PermissionsProvider";

const versionsCtx = createContext<{
  versions: Version[];
  isLoadedVersions: boolean;
}>({
  versions: [],
  isLoadedVersions: false,
});

// 認可されたバージョン一覧を取得し各所で提供するプロバイダ
export const VersionsProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [versions, isLoadedVersions] = useAuthorizedVersions();
  const versionsCtxValue = useMemo(
    () => ({
      versions,
      isLoadedVersions,
    }),
    [versions, isLoadedVersions]
  );

  return (
    <versionsCtx.Provider value={versionsCtxValue}>
      {children}
    </versionsCtx.Provider>
  );
};

export const useVersions = () => {
  return useContext(versionsCtx);
};

// 自身のロールで許可されたバージョンのみを取得する
const useAuthorizedVersions = (): [Version[], boolean] => {
  const { me, isLoadedMe } = usePermissions();
  const { permissions } = me || {};
  const hasVersionsReadPermission = permissions?.versions.read;
  const authorizedCategoryIds = permissions?.versions_version_category.read;
  const authorizedVersionIds = permissions?.version.read;
  const query: ComplexFirestoreQuerySetting | null = hasVersionsReadPermission
    ? null
    : {
        logicalOp: "or",
        ops:
          authorizedCategoryIds?.length || authorizedVersionIds?.length
            ? [
                ...(authorizedCategoryIds?.map((category) => ({
                  fieldPath: "category",
                  opStr: "==" as const,
                  value: category,
                })) ?? []),
                ...(authorizedVersionIds?.map(
                  (version) =>
                    ({
                      fieldPath: "id",
                      opStr: "==" as const,
                      value: version,
                    }) as FirestoreQuerySetting<string>
                ) ?? []),
              ]
            : [
                // 何ともマッチしないクエリを発行
                {
                  fieldPath: "id",
                  opStr: "==",
                  value: null,
                },
              ],
      };

  const [versions, isLoadedVersions, _querySetting, changeQuerySetting] =
    useVersionsQuery({}, query === null ? undefined : async () => query);

  useEffect(() => {
    changeQuerySetting(query);
  }, [permissions]);

  return [versions, isLoadedMe && isLoadedVersions];
};

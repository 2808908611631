import { generateId } from "./generateId";

class SubscriptionCacheBagListeners<CA extends unknown[]> {
  map: Map<string, (...args: CA) => void>;
  list: ((...args: CA) => void)[];
  constructor() {
    this.map = new Map<string, (...args: CA) => void>();
    this.list = [];
  }
  add(id: string, callback: (...args: CA) => void) {
    this.map.set(id, callback);
    this.list.push(callback);
  }
  remove(id: string) {
    const callback = this.map.get(id);
    this.map.delete(id);
    this.list = this.list.filter((cb) => cb !== callback);
  }
}
class SubscriptionCacheBag<O extends {}, CA extends unknown[]> {
  manager: SubscriptionCacheManager<O, CA>;
  key: string;
  listeners: SubscriptionCacheBagListeners<CA>;
  getInitialResponse: () => [boolean, () => CA] | [boolean, () => CA, number];
  unsubscribe: () => void;
  constructor(
    manager: SubscriptionCacheManager<O, CA>,
    subscribeId: string,
    key: string,
    options: O,
    callback: (...args: CA) => void
  ) {
    this.manager = manager;
    this.key = key;
    this.listeners = new SubscriptionCacheBagListeners();
    this.listeners.add(subscribeId, callback);
    const [getInitialResponse, unsubscribe] = manager.subscribeFn(
      key,
      (...args: CA) => {
        this.listeners.list.forEach((cb) => cb(...args));
      },
      options
    );
    this.getInitialResponse = getInitialResponse;
    this.unsubscribe = unsubscribe;
  }
  add(subscribeId: string, callback: (...args: CA) => void) {
    this.listeners.add(subscribeId, callback);
    if (this.listeners.list.length === 1) {
      this.manager.stopGrace(this.key);
    }
    const [hasInitial, computeInitialResponse] = this.getInitialResponse();
    if (hasInitial) callback(...computeInitialResponse());
  }
  remove(subscribeId: string) {
    this.listeners.remove(subscribeId);
    if (this.listeners.list.length === 0) {
      this.manager.startGrace(this.key);
    }
  }
}
type SubscirbeFn<O, CA extends unknown[]> = (
  key: string,
  callback: (...args: CA) => void,
  options: O
) => [() => [boolean, () => CA] | [boolean, () => CA, number], () => void];
// [shouldCallbackCurrentDataFirst, currentData, cost]
// cost === undefined ? LRUAlgorithm : KeepHighCostOne
export class SubscriptionCacheManager<O extends {}, CA extends unknown[]> {
  subscribeFn: SubscirbeFn<O, CA>;
  graceTime: number;
  maxGraces: number;
  activeBags: Map<string, SubscriptionCacheBag<O, CA>>;
  graceBags: { key: string; timeout: NodeJS.Timeout }[];
  constructor(
    subscribeFn: SubscirbeFn<O, CA>,
    { graceTime = 1000, maxGraces = Infinity } = {}
  ) {
    this.subscribeFn = subscribeFn;
    this.graceTime = graceTime;
    this.maxGraces = maxGraces;
    this.activeBags = new Map();
    this.graceBags = [];
  }
  subscribe(key: string, options: O, callback: (...args: CA) => void) {
    const subscribeId = generateId();
    let bag = this.activeBags.get(key);
    if (!bag) {
      bag = new SubscriptionCacheBag(this, subscribeId, key, options, callback);
      this.activeBags.set(key, bag);
    } else {
      bag.add(subscribeId, callback);
    }
    return () => {
      bag.remove(subscribeId);
    };
  }
  unsubscribeNow(key: string) {
    this.stopGrace(key);
    const bag = this.activeBags.get(key);
    if (bag) {
      bag.unsubscribe();
      this.activeBags.delete(key);
    }
  }
  startGrace(key: string) {
    const graceBag = {
      key,
      timeout: setTimeout(() => {
        this.unsubscribeNow(key);
      }, this.graceTime),
    };
    this.graceBags.push(graceBag);
    if (this.graceBags.length > this.maxGraces) {
      if (this.activeBags.get(key)!.getInitialResponse()[2] !== undefined) {
        this.graceBags.sort(
          (a, b) =>
            (this.activeBags.get(a.key)!.getInitialResponse()[2] ?? Infinity) -
            (this.activeBags.get(b.key)!.getInitialResponse()[2] ?? Infinity)
        );
      }

      this.unsubscribeNow(this.graceBags[0].key);
    }
  }
  stopGrace(key: string) {
    const graceBag = this.graceBags.find((bag) => bag.key === key);
    if (graceBag) {
      clearTimeout(graceBag.timeout);
      this.graceBags = this.graceBags.filter((bag) => bag.key !== key);
    }
  }
}

import loadable from "@loadable/component";

import { TypeSchema, allTypeList } from "../../../../domain/TypeSchema";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

const disableRowColumnTypes: TypeSchema["type"][] = [
  "float",
  "boolean",
  "array",
  "foreign",
  "object",
];
const disableDataTypes: TypeSchema["type"][] = [
  "boolean",
  "array",
  "foreign",
  "object",
];
/**
 * @description 複数の値を自由に投入可能なmatrixビュー
 * todo クエリ設定を入れる
 */
export const viewTypeMatrixMultiType: StrictViewType = {
  key: "matrixMultiType",
  displayName: "matrixビュー(複数型対応)",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "matrixSheet",
      displayName: "データシート",
      dependSheetFields: [
        {
          key: "id1",
          displayName: "id1",
          type: allTypeList.filter((v) => !disableRowColumnTypes.includes(v)),
        },
        {
          key: "id2",
          displayName: "id2",
          type: allTypeList.filter((v) => !disableRowColumnTypes.includes(v)),
        },
        {
          key: "data",
          displayName: "data",
          type: allTypeList.filter((v) => !disableDataTypes.includes(v)),
        },
      ],
    },
  ],
  configSchemas: [],
};

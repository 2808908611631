import loadable from "@loadable/component";

import { partPaidHolidayViewDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const partPaidHolidayView: StrictViewType = {
  key: "ngrKoubanPartPaidHoliday",
  displayName: "NGR交番:勤務不可時間帯",
  render: ViewRender as any,
  dependSheets: partPaidHolidayViewDependSheets,
  configSchemas: [],
};

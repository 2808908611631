import { AccountRaw, AccountResponseRaw } from "../../domain/Account";
import { MemberRaw } from "../../domain/Member";
import { API_CLIENT, API_CLIENT_WITHOUT_IDTOKEN } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrlAccount = "/accounts";
const apiUrlInvitationAccount = "/invitations" + apiUrlAccount;
const apiUrlResendEmail = "/resend_email_verification";

export const getAccount = async ({
  uid,
}: {
  uid: AccountRaw["uid"];
}): Promise<AccountRaw> => {
  const response = await API_CLIENT.get<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}`
  );
  return response.data.item;
};
export const postEditAccountName = async ({
  display_name,
  uid,
}: Pick<AccountRaw, "display_name" | "uid">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/display_name`,
    { display_name },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postEditAccountEmail = async ({
  email,
  uid,
}: Pick<AccountRaw, "email" | "uid">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/email`,
    { email },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postEditAccountMfa = async ({
  use_mfa,
  uid,
}: Pick<AccountRaw, "use_mfa" | "uid">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/mfa`,
    { use_mfa },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postChangeAccountDefaultOrgId = async ({
  default_org_id,
  uid,
}: Pick<AccountRaw, "default_org_id" | "uid">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/default_org_id`,
    { default_org_id },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postChangeAccountPicture = async ({
  picture_type,
  picture_color,
  picture_image_url,
  uid,
}: Partial<Pick<AccountRaw, "picture_color" | "picture_image_url">> &
  Pick<AccountRaw, "uid" | "picture_type">): Promise<AccountRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const body =
    picture_type === "color"
      ? { picture_type, picture_color }
      : { picture_type, picture_image_url };
  const response = await API_CLIENT.post<{ item: AccountRaw }>(
    `${apiUrlAccount}/${uid}/picture`,
    body,
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postResendEmailVerification = async ({
  idp_uid,
}: {
  idp_uid: MemberRaw["idp_uid"];
}): Promise<void> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  await API_CLIENT_WITHOUT_IDTOKEN.post(
    apiUrlResendEmail,
    { idp_uid },
    {
      ...apiHeadersWithJson,
    }
  );
};

import { ViewMasterRaw } from "../../domain/ViewMaster";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrl = "/view_masters";

// 組織ごとの利用可能なビューをリストで取得する
export const getViewMasters = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<ViewMasterRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: ViewMasterRaw[] }>(apiUrl, {
    ...apiOrganizationIdParams,
  });
  return response.data.items;
};

// ビューを個別に取得
export const getViewMaster = async ({
  organizationId,
  view_master_id,
}: {
  organizationId: string;
  view_master_id: string;
}): Promise<ViewMasterRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ item: ViewMasterRaw }>(
    `${apiUrl}/${view_master_id}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

import loadable from "@loadable/component";

import { s2GanttChartDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKws2sGanttChart: StrictViewType = {
  key: "nshKws2sGanttChart",
  displayName: "NSH-KWS 第二製造課ガントチャート",
  render: ViewRender as any,
  dependSheets: s2GanttChartDependSheets,
  configSchemas: [],
  isDisabledEditHistoryShortcuts: true,
};

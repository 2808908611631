import { DemoPlantViewTypeDependSheet } from "./params";
/**
 * ViewType定義時引数
 */
const demoPlantlinesDependSheet: DemoPlantViewTypeDependSheet = {
  key: "lines",
  displayName: "生産ライン",
  dependSheetFields: [
    {
      key: "line_group",
      displayName: "ライン名",
      type: "string",
    },
    {
      key: "line_name",
      displayName: "系統",
      type: "string",
    },
    {
      key: "cost_stop_per_day",
      displayName: "停止中コスト/日（千円）",
      type: "float",
    },
    {
      key: "is_exist_parallel_line",
      displayName: "並列ラインの有無",
      type: "boolean",
    },
  ],
};
const demoPlantTasksDependSheet: DemoPlantViewTypeDependSheet = {
  key: "tasks",
  displayName: "タスク",
  dependSheetFields: [
    {
      key: "task_id",
      displayName: "タスクID",
      type: "string",
    },
    {
      key: "date_produce_start",
      displayName: "タスク開始日",
      type: "date",
    },
    {
      key: "date_produce_end",
      displayName: "タスク終了日",
      type: "date",
    },
    {
      key: "task_type",
      displayName: "項目種別",
      type: "string",
    },
    {
      key: "stop_cause",
      displayName: "停止理由",
      type: "string",
    },
    {
      key: "line_name",
      displayName: "生産ライン名",
      type: "string",
    },
    {
      key: "product_name",
      displayName: "製品名",
      type: "string",
    },
    {
      key: "packing_type",
      displayName: "荷姿名",
      type: "string",
    },
    {
      key: "is_locked",
      displayName: "固定設定",
      type: "boolean",
    },
    {
      key: "is_switch_occured_first",
      displayName: "開始日切り替え",
      type: "boolean",
    },
    {
      key: "is_switch_occured_last",
      displayName: "終了日切り替え",
      type: "boolean",
    },
  ],
};
const demoPlantProductsDependSheet: DemoPlantViewTypeDependSheet = {
  key: "products",
  displayName: "製品",
  dependSheetFields: [
    {
      key: "product_name",
      displayName: "製品名",
      type: "string",
    },
    {
      key: "packing_type",
      displayName: "荷姿名",
      type: "string",
    },
    {
      key: "default_warehouse_name",
      displayName: "格納倉庫",
      type: "string",
    },
    {
      key: "price_per_ton",
      displayName: "価格/t（千円）",
      type: "float",
    },
    {
      key: "tons_per_unit",
      displayName: "荷姿単位トン数",
      type: "float",
    },
    {
      key: "storage_days_low",
      displayName: "基準在庫日数_下限値（日）",
      type: "float",
    },
    {
      key: "storage_days_high",
      displayName: "基準在庫日数_上限値（日）",
      type: "float",
    },
    {
      key: "storage_days_proper",
      displayName: "基準在庫日数_適正在庫（日）",
      type: "float",
    },
  ],
};
const demoPlantLineProductsDependSheet: DemoPlantViewTypeDependSheet = {
  key: "lineProducts",
  displayName: "生産能力",
  dependSheetFields: [
    {
      key: "line_name",
      displayName: "系統",
      type: "string",
    },
    {
      key: "product_name",
      displayName: "製品名",
      type: "string",
    },
    {
      key: "packing_type",
      displayName: "荷姿名",
      type: "string",
    },
    {
      key: "is_producible",
      displayName: "生産可否",
      type: "boolean",
    },
    {
      key: "cost_materials_per_ton",
      displayName: "原料費/t（千円）",
      type: "float",
    },
    {
      key: "co2_emission_kg_per_ton",
      displayName: "CO2排出量/t（kg）",
      type: "float",
    },
    {
      key: "tons_regular_per_day",
      displayName: "通常生産トン数/日",
      type: "float",
    },
    {
      key: "tons_stop_day",
      displayName: "停止生産トン数/日",
      type: "float",
    },
    {
      key: "tons_restart_day",
      displayName: "再稼働生産トン数/日",
      type: "float",
    },
    {
      key: "cost_regular_per_day",
      displayName: "通常コスト/日（千円）",
      type: "float",
    },
    {
      key: "cost_stop_timing",
      displayName: "停止コスト/日（千円）",
      type: "float",
    },
    {
      key: "cost_restart_timing",
      displayName: "再稼働コスト/日（千円）",
      type: "float",
    },
  ],
};
const demoPlantLineSwitchesDependSheet: DemoPlantViewTypeDependSheet = {
  key: "lineSwitches",
  displayName: "切替",
  dependSheetFields: [
    {
      key: "line_name",
      displayName: "系統",
      type: "string",
    },
    {
      key: "product_name_before",
      displayName: "切替前製品名",
      type: "string",
    },
    {
      key: "packing_type_before",
      displayName: "切替前荷姿名",
      type: "string",
    },
    {
      key: "product_name_after",
      displayName: "切替後製品名",
      type: "string",
    },
    {
      key: "packing_type_after",
      displayName: "切替後荷姿名",
      type: "string",
    },
    {
      key: "is_switchable",
      displayName: "切替可否",
      type: "boolean",
    },
    {
      key: "cost_switch",
      displayName: "切替作業コスト（千円）",
      type: "float",
    },
    {
      key: "tons_switch_day_before",
      displayName: "切替前製品の生産トン数",
      type: "float",
    },
    {
      key: "tons_switch_day_after",
      displayName: "切替後製品の生産トン数",
      type: "float",
    },
    {
      key: "hours_switch_task_before",
      displayName: "製品の切替前の所用時間",
      type: "float",
    },
    {
      key: "hours_switch_task_after",
      displayName: "製品の切替後の所用時間",
      type: "float",
    },
  ],
};
const demoPlantInitialStockProductsDependSheet: DemoPlantViewTypeDependSheet = {
  key: "initialStockProducts",
  displayName: "製品初期在庫",
  dependSheetFields: [
    {
      key: "date",
      displayName: "日付",
      type: "string",
      // type: "date",
    },
    {
      key: "product_name",
      displayName: "製品名",
      type: "string",
    },
    {
      key: "packing_type",
      displayName: "荷姿名",
      type: "string",
    },
    {
      key: "tons",
      displayName: "数量 (t)",
      type: "float",
    },
    {
      key: "warehouse_name",
      displayName: "保管倉庫",
      type: "string",
    },
  ],
};
const demoPlantSalesSchedulesDependSheet: DemoPlantViewTypeDependSheet = {
  key: "salesSchedules",
  displayName: "販売計画",
  dependSheetFields: [
    {
      key: "date_sales",
      displayName: "出荷日",
      type: "string",
      // type: "date",
    },
    {
      key: "product_name",
      displayName: "製品名",
      type: "string",
    },
    {
      key: "packing_type",
      displayName: "荷姿名",
      type: "string",
    },
    {
      key: "tons",
      displayName: "数量 (t)",
      type: "float",
    },
    {
      key: "sales_destination_name",
      displayName: "出荷先企業名",
      type: "string",
    },
    {
      key: "price_per_ton",
      displayName: "価格/t（千円）",
      type: "float",
    },
    {
      key: "sales_certainty_kind",
      displayName: "受注確度",
      type: "float",
    },
  ],
};
export const demoPlantDependSheets = [
  demoPlantlinesDependSheet,
  demoPlantTasksDependSheet,
  demoPlantProductsDependSheet,
  demoPlantLineProductsDependSheet,
  demoPlantLineSwitchesDependSheet,
  demoPlantInitialStockProductsDependSheet,
  demoPlantSalesSchedulesDependSheet,
];

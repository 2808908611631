import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeStorageStockViewDemo: StrictViewType = {
  key: "saasStorageStockView",
  displayName: "保管所在庫推移ビュー（デモ）",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "storageItemStockSheet",
      displayName: "保管所品目在庫推移",
      dependSheetFields: [
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "storageName",
          displayName: "保管所名",
          type: "string",
        },
        {
          key: "itemName",
          displayName: "品目",
          type: "string",
        },
        {
          key: "quantity",
          displayName: "在庫量",
          type: "float",
        },
      ],
    },
    {
      key: "storageItemMasterSheet",
      displayName: "保管所品目マスタ",
      dependSheetFields: [
        { key: "storageName", displayName: "保管所名", type: "string" },
        { key: "itemName", displayName: "品目", type: "string" },
      ],
    },
    {
      key: "storageMasterSheet",
      displayName: "保管所マスタ",
      dependSheetFields: [
        { key: "storageName", displayName: "保管所名", type: "string" },
        { key: "unit", displayName: "単位", type: "string" },
        { key: "lowerBound", displayName: "下限量", type: "float" },
        { key: "upperBound", displayName: "上限量", type: "float" },
        {
          key: "itemStorageType",
          displayName: "品目保管所タイプ",
          type: "string",
        },
      ],
    },
    {
      key: "alertSheet",
      displayName: "アラートデータ",
      dependSheetFields: [
        {
          key: "startDate",
          displayName: "開始日",
          type: "date-time",
        },
        { key: "endDate", displayName: "終了日", type: "date-time" },
        {
          key: "alertCategory",
          displayName: "アラートのカテゴリ",
          type: "string",
        },
        {
          key: "alertDetail",
          displayName: "アラートの詳細",
          type: "string",
        },
        {
          key: "alertSummary",
          displayName: "アラートの概要",
          type: "string",
        },
        {
          key: "viewInfo",
          displayName: "アラートの情報",
          type: "string",
        },
      ],
    },
  ],
  configSchemas: [
    {
      key: "itemStorageType",
      displayName: "品目保管所タイプ(','区切りで複数入力可)",
      type: "string",
      typeFields: null,
      typeParameters: null,
    },
  ],
};

import { ViewTypeDependSheet } from "@/domain/ViewTypeDependSheet";
import { SpreadSheetSetting } from "../../types";
type RowKeys = {
  [k in keyof viewtypeContents]: keyof viewtypeContents[k] & string;
};
type SheetKey = keyof RowKeys & string;

export type viewtypeContents = {
  setting: {
    optimizationStartDay: string;
    optimizationEndDay: string;
    optimizationEo: string;
    optimizationEg: string;
    optimizationNvp: string;
    optimizationEa: string;
    optimizationEi: string;
    optimizationBhea: string;
    optimizationEoa: string;
    optimizationSft: string;
    optimization4s: string;
    optimization4sNoTank: string;
    timeCoefficient123s: string;
    timeCoefficient4s: string;
    maxDaysToDivideCalculation: string;
    maxDaysToOptimizeCalculation: string;
    annualPlanMode: string;
  };
  parameter: {
    nonEgRateChidori: string;
    nonEgRateUkishima: string;
    egYieldChidori: string;
    egYieldUkishima: string;
    megRateChidori: string;
    megRateUkishima: string;
    etUtilizationRateChidori: string;
    catalystAmountChidori: string;
    feedWaterTemperatureChidori: string;
    gasEnthalpyChidori: string;
    ceSChidori: string;
    ceTChidori: string;
    steamCorrectionChidori: string;
    steamCorrectionEa: string;
    totalEgSteamConsumptionChidori: string;
    eoStopCostPerDayChidori: string;
    eoStopCostRestartChidori: string;
    etUtilizationRateUkishima: string;
    catalystAmountUkishima: string;
    feedWaterTemperatureUkishima: string;
    gasEnthalpyUkishima: string;
    ceSUkishima: string;
    ceTUkishima: string;
    steamCorrectionUkishima: string;
    totalEgSteamConsumptionUkishima: string;
    eoStopCostPerDayUkishima: string;
    eoStopCostRestartUkishima: string;
    score4sLowerLimit: string;
    score4sUpperLimit: string;
    score4sTargetStockMid: string;
    score4sTargetStockEnd: string;
    score4sEoConsumptionLowerLimit: string;
    score4sEoConsumptionUpperLimit: string;
    score4sEoConsumptionEven: string;
    score4sSwitchingTimes: string;
    sft70And90ContinuousStopDaysLowerLimit: string;
    sft70And90ContinuousStopDaysUpperLimit: string;
    sft70ContinuousOperationDaysLowerLimit: string;
    sft70ContinuousOperationDaysUpperLimit: string;
    sft90ContinuousOperationDaysLowerLimit: string;
    sft90ContinuousOperationDaysUpperLimit: string;
    nvpContinuousStopDaysLowerLimit: string;
    nvpContinuousStopDaysUpperLimit: string;
    nvpContinuousOperationDaysLowerLimit: string;
    nvpContinuousOperationDaysUpperLimit: string;
    eaContinuousStopDaysLowerLimit: string;
    eaContinuousStopDaysUpperLimit: string;
    eaContinuousOperationDaysLowerLimit: string;
    eaContinuousOperationDaysUpperLimit: string;
  };
  penalty_weight: {
    item1: string;
    item2: string;
    item3: string;
    penaltyCoefficient: string;
  };
  catalyst_parameter: {
    factory: string;
    catalystId: string;
    target: string;
    startEo: string;
    coefficientA: string;
    coefficientB: string;
    coefficientC: string;
  };
  tank_master_123s: {
    department: string;
    tank_id: string;
    tank_name: string;
    product: string;
    facility: string;
    purchase: string;
    shipment: string;
    export: string;
    selfConsumption: string;
    transferShipmentUpperLimit: string;
    transferAcceptanceUpperLimit: string;
    daysFromProductionToStorage: string;
    daysFromTankTransferShipmentToStorage: string;
    daysFromProductionFacilityStorageToShipment: string;
    daysFromTankTransferStorageToShipment: string;
    safetyUpperLimitStock: string;
    safetyLowerLimitStock: string;
    targetUpperLimitStock: string;
    targetLowerLimitStock: string;
    physicalUpperLimitPenalty: string;
    physicalLowerLimitPenalty: string;
    safetyUpperLimitPenalty: string;
    safetyLowerLimitPenalty: string;
    targetUpperLimitPenalty: string;
    targetLowerLimitPenalty: string;
  };
  transport_master_123s: {
    product: string;
    sourceTankName: string;
    destinationTankName: string;
    reverseDirection: string;
    basicPenalty: string;
    variablePenalty: string;
    isAllTransport: string;
    unitTransportAmount: string;
    holidayOnly: string;
  };
  facilities_master_123s: {
    department: string;
    facilityName: string;
    productionAmountSplit: string;
    productionPatternMinimumDays: string;
    acceptanceRatioMinimumDays: string;
    productionPatternChangePenaltyCoefficient: string;
    acceptanceRatioChangePenaltyCoefficient: string;
  };
  item_master_123s: {
    department: string;
    consumeDepartment: string;
    itemName: string;
    isRawMaterialPurchase: string;
    itemId: string;
  };
  item_category_master_123s: {
    department: string;
    itemCategory: string;
    id: string;
  };
  productSheetWithout4sMetaData: {
    item_id: string;
    en_item_name: string;
    item_name: string;
    item_kind: string;
    data_type: string;
    enum_params: string;
    algorithm_kind: string;
    target_item_category: string;
    target_item: string;
    consume_item_category: string;
    consume_item: string;
    reactor: string;
    tank_id: string;
    department: string;
    output_kind: string;
  };
  bheaIsVariable: {
    date: string;
    bheaEOConsumption: string;
    bheaBatchIgnoringMaintenance: string;
    bheaISHighPurityIgnoringMaintenance: string;
  };
  eoaIsVariable: {
    date: string;
    eoConsumption: string;
    A12501ProductionBatch: string;
    A12502ProductionBatch: string;
    shippingEast: string;
    shippingEastModified: string;
  };
  bheaTankIsVariable: { [key: string]: string };
  eoaTankIsVariable: { [key: string]: string };
  bhea: {
    date: string;
    bheaEOConsumption: string;
    bheaMaximumBatch: string;
    bheaHighPurityMaximumBatch: string;
    bheaBatchIgnoringMaintenance: string;
    bheaISHighPurityIgnoringMaintenance: string;
    bheaBatch: string;
    bheaAccumulationBatchExchangingDemister: string;
    bheaExchangingDemister: string;
    bheaAccumulationBatchExchangingValve: string;
    bheaExchangingValve: string;
    bheaHighPurityBatch: string;
    bheaTotalProduction1: string;
    bheaTotalProduction2: string;
    bheaNormalProduction1: string;
    bheaNormalProduction2: string;
    bheaHighPurityProduction1: string;
    bheaHighPurityProduction2: string;
    bheaHighPurityProduction: string;
    bheaNormalProduction: string;
    bheaWastedPerBatch: string;
    bheaDailyWasted: string;
    bheaAccumulationMonthlyWastedLimit: string;
    bheaAccumulationMonthlyWasted: string;
    bheaMonthlyWastedViolation: string;
  };
  eoa: {
    date: string;
    A12502ProductionPattern: string;
    A12501MaximumBatch: string;
    A12502MaximumBatch: string;
    A12501ProductionBatch: string;
    A12502ProductionBatch: string;
    eoConsumption: string;
    "24k417nTotalProduction": string;
    "24k417nShipping": string;
    EvaporationA12501: string;
    DeaerationA12501: string;
    EvaporationA12502: string;
    DeaerationA12502: string;
    TotalDailyWasted: string;
    MonthlyWastedAccumulationLimit: string;
    MonthlyWastedLimit: string;
    MonthlyWastedViolation: string;
    calcohol40A12501Consumption: string;
    calcohol40A12502Consumption: string;
    calcohol40Consumption: string;
    calcohol40Arrival: string;
    calcohol40Stock: string;
    calcohol20A12501Consumption: string;
    calcohol20A12502Consumption: string;
    calcohol20Consumption: string;
    calcohol20Arrival: string;
    calcohol20Stock: string;
    poA12501Consumption: string;
    poA12502Consumption: string;
    poConsumption: string;
    poArrival: string;
    poStock: string;
    kohA12501Consumption: string;
    kohA12502Consumption: string;
    kohConsumption: string;
    kohArrival: string;
    kohStock: string;
    lactate12501Consumption: string;
    lactateA12502Consumption: string;
    lactateConsumption: string;
    lactateArrival: string;
    lactateStock: string;
    shippingEast: string;
    shippingEastModified: string;
    shipping4s: string;
    shortage4s: string;
  };
  general: {
    date: string;
    holiday: string;
    chidoriCatalystID: string;
    ukishimaCatalystID: string;
    chidoriAirFlow: string;
    ukishimaAirFlow: string;
    chidoriSummationEO: string;
    chidoriElectricityPrice: string;
    chidoriGasPrice: string;
    chidoriSteamPrice: string;
    ukishimaSummationEO: string;
    ukishimaElectricityPrice: string;
    ukishimaGasPrice: string;
    ukishimaSteamPrice: string;
    ukishimaEOConsumption4s: string;
    ukishimaMaximumEOConsumption4s: string;
    ukishimaMinimumEOConsumption4s: string;
    sft30Consumption4s: string;
    sft33Consumption4s: string;
    sft70Consumption4s: string;
    sft90Consumption4s: string;
    shortage4s24k417n: string;
    ethyleneCostForEO: string;
    ethyleneCostForEG: string;
    EOMarginalProfitPerTonne: string;
    domesticMegMarginalProfitPerTonne: string;
    exportMegMarginalProfitPerTonne: string;
    domesticDegMarginalProfitPerTonne: string;
    exportDegMarginalProfitPerTonne: string;
    tegMarginalProfitPerTonne: string;
    traegMarginalProfitPerTonne: string;
    nparaffinCost: string;
    ammoniaCost: string;
    calcCost000: string;
    calcCost001: string;
    calcCost002: string;
    calcCost003: string;
    calcCost004: string;
    calcCost005: string;
    calcCost006: string;
    calcCost007: string;
    calcCost008: string;
    calcCost009: string;
    calcCost010: string;
    calcCost011: string;
    calcCost012: string;
    calcCost013: string;
    calcCost014: string;
    calcCost015: string;
    calcCost016: string;
    calcCost017: string;
    calcCost018: string;
    calcCost019: string;
    calcCost020: string;
    calcCost021: string;
    calcCost022: string;
    calcCost023: string;
    calcCost024: string;
    calcCost025: string;
    calcCost026: string;
    calcCost027: string;
    calcCost028: string;
    calcCost029: string;
    calcCost030: string;
    calcCost031: string;
    calcCost032: string;
    calcCost033: string;
    calcCost034: string;
    calcCost035: string;
    calcCost036: string;
    calcCost037: string;
    calcCost038: string;
    calcCost039: string;
    calcCost040: string;
    calcCost041: string;
    calcCost042: string;
    calcCost043: string;
    calcCost044: string;
    calcCost045: string;
    calcCost046: string;
    calcCost047: string;
    calcCost048: string;
    calcCost049: string;
    calcCost050: string;
    calcCost051: string;
    calcCost052: string;
    calcCost053: string;
    calcCost054: string;
    calcCost055: string;
    calcCost056: string;
    calcCost057: string;
    calcCost058: string;
    calcCost059: string;
    calcCost060: string;
    calcCost061: string;
    calcCost062: string;
    calcCost063: string;
    calcCost064: string;
    calcCost065: string;
    calcCost066: string;
    calcCost067: string;
    calcCost068: string;
    calcCost069: string;
    calcCost070: string;
    calcCost071: string;
    calcCost072: string;
    calcCost073: string;
    calcCost074: string;
    calcCost075: string;
    calcCost076: string;
    calcCost077: string;
    calcCost078: string;
    calcCost079: string;
    calcCost080: string;
    calcCost081: string;
    calcCost082: string;
    calcCost083: string;
    calcCost084: string;
    calcCost085: string;
    calcCost086: string;
    calcCost087: string;
    calcCost088: string;
    calcCost089: string;
    calcCost090: string;
    calcCost091: string;
    calcCost092: string;
    calcCost093: string;
    calcCost094: string;
    calcCost095: string;
    calcCost096: string;
    calcCost097: string;
    calcCost098: string;
    calcCost099: string;
    calcCost100: string;
    calcCost101: string;
    calcCost102: string;
    calcCost103: string;
    calcCost104: string;
    calcCost105: string;
    calcCost106: string;
    calcCost107: string;
    calcCost108: string;
    calcCost109: string;
    calcCost110: string;
    calcCost111: string;
    calcCost112: string;
    calcCost113: string;
    calcCost114: string;
    calcCost115: string;
    calcCost116: string;
    calcCost117: string;
    calcCost118: string;
    calcCost119: string;
    calcCost120: string;
    calcCost121: string;
    calcCost122: string;
    calcCost123: string;
    calcCost124: string;
    calcCost125: string;
    calcCost126: string;
    calcCost127: string;
    calcCost128: string;
    calcCost129: string;
    calcCost130: string;
    calcCost131: string;
    calcCost132: string;
    calcCost133: string;
    calcCost134: string;
    calcCost135: string;
    calcCost136: string;
    calcCost137: string;
    calcCost138: string;
    calcCost139: string;
    calcCost140: string;
    calcCost141: string;
    calcCost142: string;
    calcCost143: string;
    calcCost144: string;
    calcCost145: string;
    calcCost146: string;
    calcCost147: string;
    calcCost148: string;
    calcCost149: string;
    calcCost150: string;
    calcCost151: string;
    calcCost152: string;
    calcCost153: string;
    calcCost154: string;
    calcCost155: string;
    calcCost156: string;
    calcCost157: string;
    calcCost158: string;
    calcCost159: string;
    calcCost160: string;
    calcCost161: string;
    calcCost162: string;
    calcCost163: string;
    calcCost164: string;
    calcCost165: string;
    calcCost166: string;
    calcCost167: string;
    calcCost168: string;
    calcCost169: string;
    calcCost170: string;
    calcCost171: string;
    calcCost172: string;
    calcCost173: string;
    calcCost174: string;
    calcCost175: string;
    calcCost176: string;
    calcCost177: string;
    calcCost178: string;
    calcCost179: string;
    calcCost180: string;
    calcCost181: string;
    calcCost182: string;
    calcCost183: string;
    calcCost184: string;
    calcCost185: string;
    calcCost186: string;
    calcCost187: string;
    calcCost188: string;
    calcCost189: string;
    calcCost190: string;
    calcCost191: string;
    calcCost192: string;
    calcCost193: string;
    calcCost194: string;
    calcCost195: string;
    calcCost196: string;
    calcCost197: string;
    calcCost198: string;
    calcCost199: string;
    calcCost200: string;
    calcCost201: string;
    calcCost202: string;
    calcCost203: string;
    calcCost204: string;
    calcCost205: string;
    calcCost206: string;
    calcCost207: string;
    calcCost210: string;
    calcCost211: string;
    calcCost212: string;
    calcCost213: string;
    calcCost214: string;
    calcCost215: string;
    calcCost216: string;
    calcCost217: string;
    calcCost218: string;
    calcCost219: string;
    calcCost220: string;
    calcCost221: string;
    calcCost222: string;
  };
  bheaTank: { [key: string]: string };
  eoaTank: { [key: string]: string };
  production_123s_summary: {
    general: string;
    eo: string;
    eg: string;
    nvp: string;
    ea: string;
    ei: string;
    bhea: string;
    sft: string;
    eoa: string;
    filter: string;
  };
  s4_master_1234s: {
    itemCode: string;
    itemText: string;
    version: string;
    stockArea: string;
    lotSize: string;
  };
  internal_id_to_item_code: {
    internalID: string;
    itemText: string;
  };
  spreadsheet_column_filter: {
    "1s": string;
    "2sEid": string;
    "2sEast": string;
    "3s": string;
    "4s": string;
    Management: string;
  };
  selected_date_range: {
    "1s": string;
    "2sEid": string;
    "2sEast": string;
    "3s": string;
    "4s": string;
    Management: string;
  };
  s2_basic_info: {
    time_base_default: string;
    time_base_for_material_po: string;
  };
  s2_product_master: {
    id: string;
    name: string;
  };
  s2_device_master: {
    id: string;
    name: string;
  };
  s2_device_group_master: {
    id: string;
    name: string;
    sort_number: string;
  };
  s2_device_group_device_mapping_master: {
    device_group_id: string;
    device_id: string;
    sort_in_devide_group: string;
  };
  s2_batch_process_set_detail_process_master: {
    batch_process_set_id: string;
    step_in_batch_process_set: string;
    name: string;
    default_required_time: string;
    default_relative_start_timing_from_first_step: string;
    device_type: string;
    device_id: string;
    device_group_id: string;
    is_blank: string;
    is_first: string;
    next_step_in_batch_process_set: string;
    process_gantt_chart_color: string;
  };
  s2_batch_process_set_master: {
    id: string;
    name: string;
    default_start_times: string;
    product_id: string;
    device_group_id: string;
    total_steps_in_batch_process_set: string;
    max_batch_per_day: string;
    default_required_time: string;
  };
  s2_batch_production_plan: {
    date: string;
    batch_process_set_id: string;
    times_batch_process_set: string;
  };
  s2_batch_process_plan: {
    comment: string;
    batch_process_set_id: string;
    time_start_step_1: string;
    time_start_step_2: string;
    time_start_step_3: string;
    time_start_step_4: string;
    time_start_step_5: string;
    time_start_step_6: string;
    time_start_step_7: string;
    time_start_step_8: string;
    time_start_step_9: string;
    time_start_step_10: string;
    time_start_step_11: string;
    time_start_step_12: string;
    time_start_step_13: string;
    time_start_step_14: string;
    time_start_step_15: string;
    time_start_step_16: string;
    time_start_step_17: string;
    time_start_step_18: string;
    time_start_step_19: string;
    time_start_step_20: string;
    time_start_step_21: string;
    time_start_step_22: string;
    time_start_step_23: string;
    time_start_step_24: string;
    time_start_step_25: string;
    time_start_step_26: string;
    time_finish_batch_process_set: string;
  };
  s2_material_master: {
    id: string;
    name: string;
    sort_number: string;
  };
  s2_batch_process_set_detail_process_material_consumption_master: {
    material_id: string;
    batch_process_set_id: string;
    step_in_batch_process_set: string;
    consumption: string;
    timing_consumption: string;
  };
  s2_batch_process_set_detail_process_waste_liquid_master: {
    batch_process_set_id: string;
    step_in_batch_process_set: string;
    waste_liquid_amount: string;
    timing_waste_liquid: string;
  };
  s2_alert_master: {
    alert_explanation: string;
    alert_id: string;
    alert_level: string;
    alert_message_template: string;
    alert_name: string;
    alert_target_list: string;
    data_cooperation_kind: string;
  };
  s2_batch_count_mapping_master: {
    batch_process_set_id: string;
    receive_condition: string;
    sheet_name: string;
    sheet_column_name: string;
    locked_sheet_name: string;
    is_enabled: string;
  };
  s2_material_mapping_master: {
    info_kind: string;
    material_id: string;
    batch_process_set_id_list: string;
    sheet_name: string;
    sheet_column_name: string;
    locked_sheet_name: string;
  };
  s2_waste_liquid_mapping_master: {
    info_kind: string;
    waste_liquid_target_list: string;
    sheet_name: string;
    sheet_column_name: string;
  };
};
export const dependSheets: ViewTypeDependSheet<SheetKey, RowKeys>[] = [
  {
    key: "setting",
    displayName: "setting",
    dependSheetFields: [
      {
        key: "optimizationStartDay",
        displayName: "最適化開始日",
        type: "date",
      },
      {
        key: "optimizationEndDay",
        displayName: "最適化終了日",
        type: "date",
      },
      {
        key: "optimizationEo",
        displayName: "最適化_eo",
        type: "boolean",
      },
      {
        key: "optimizationEg",
        displayName: "最適化_eg",
        type: "boolean",
      },
      {
        key: "optimizationNvp",
        displayName: "最適化_nvp",
        type: "boolean",
      },
      {
        key: "optimizationEa",
        displayName: "最適化_ea",
        type: "boolean",
      },
      {
        key: "optimizationEi",
        displayName: "最適化_ei",
        type: "boolean",
      },
      {
        key: "optimizationBhea",
        displayName: "最適化_bhea",
        type: "boolean",
      },
      {
        key: "optimizationEoa",
        displayName: "最適化_eoa",
        type: "boolean",
      },
      {
        key: "optimizationSft",
        displayName: "最適化_sft",
        type: "boolean",
      },
      {
        key: "optimization4s",
        displayName: "最適化_4s",
        type: "boolean",
      },
      {
        key: "optimization4sNoTank",
        displayName: "最適化_4s_タンク無し製品",
        type: "boolean",
      },
      {
        key: "timeCoefficient123s",
        displayName: "実行時間係数(4製以外)",
        type: "float",
      },
      {
        key: "timeCoefficient4s",
        displayName: "実行時間係数(4製)",
        type: "float",
      },
      {
        key: "maxDaysToDivideCalculation",
        displayName: "分割計算時最大分割間隔日数",
        type: "integer",
      },
      {
        key: "maxDaysToOptimizeCalculation",
        displayName: "分割計算時最大最適化日数",
        type: "integer",
      },
      {
        key: "annualPlanMode",
        displayName: "年間計画モード",
        type: "boolean",
      },
    ],
  },
  {
    key: "parameter",
    displayName: "parameter",
    dependSheetFields: [
      {
        key: "nonEgRateChidori",
        displayName: "非EG化率_千鳥",
        type: "float",
      },
      {
        key: "nonEgRateUkishima",
        displayName: "非EG化率_浮島",
        type: "float",
      },
      {
        key: "egYieldChidori",
        displayName: "MEG-Y計算用EG収率_千鳥",
        type: "float",
      },
      {
        key: "egYieldUkishima",
        displayName: "MEG-Y計算用EG収率_浮島",
        type: "float",
      },
      {
        key: "megRateChidori",
        displayName: "MEG-Y計算用MEG率_千鳥",
        type: "float",
      },
      {
        key: "megRateUkishima",
        displayName: "MEG-Y計算用MEG率_浮島",
        type: "float",
      },
      {
        key: "etUtilizationRateChidori",
        displayName: "Et利用率_千鳥",
        type: "float",
      },
      {
        key: "catalystAmountChidori",
        displayName: "触媒量_千鳥",
        type: "float",
      },
      {
        key: "feedWaterTemperatureChidori",
        displayName: "給水温度_千鳥",
        type: "float",
      },
      {
        key: "gasEnthalpyChidori",
        displayName: "gasエンタルピー_千鳥",
        type: "float",
      },
      {
        key: "ceSChidori",
        displayName: "CE_S_千鳥",
        type: "float",
      },
      {
        key: "ceTChidori",
        displayName: "CE_T_千鳥",
        type: "float",
      },
      {
        key: "steamCorrectionChidori",
        displayName: "蒸気補正値_千鳥",
        type: "float",
      },
      {
        key: "steamCorrectionEa",
        displayName: "EA用蒸気補正値",
        type: "float",
      },
      {
        key: "totalEgSteamConsumptionChidori",
        displayName: "全量EO期間EG消費蒸気_千鳥",
        type: "float",
      },
      {
        key: "eoStopCostPerDayChidori",
        displayName: "EO停止コスト1日あたり単価_千鳥",
        type: "float",
      },
      {
        key: "eoStopCostRestartChidori",
        displayName: "EO停止コスト再スタート単価_千鳥",
        type: "float",
      },
      {
        key: "etUtilizationRateUkishima",
        displayName: "Et利用率_浮島",
        type: "float",
      },
      {
        key: "catalystAmountUkishima",
        displayName: "触媒量_浮島",
        type: "float",
      },
      {
        key: "feedWaterTemperatureUkishima",
        displayName: "給水温度_浮島",
        type: "float",
      },
      {
        key: "gasEnthalpyUkishima",
        displayName: "gasエンタルピー_浮島",
        type: "float",
      },
      {
        key: "ceSUkishima",
        displayName: "CE_S_浮島",
        type: "float",
      },
      {
        key: "ceTUkishima",
        displayName: "CE_T_浮島",
        type: "float",
      },
      {
        key: "steamCorrectionUkishima",
        displayName: "蒸気補正値_浮島",
        type: "float",
      },
      {
        key: "totalEgSteamConsumptionUkishima",
        displayName: "全量EO期間EG消費蒸気_浮島",
        type: "float",
      },
      {
        key: "eoStopCostPerDayUkishima",
        displayName: "EO停止コスト1日あたり単価_浮島",
        type: "float",
      },
      {
        key: "eoStopCostRestartUkishima",
        displayName: "EO停止コスト再スタート単価_浮島",
        type: "float",
      },
      {
        key: "score4sLowerLimit",
        displayName: "4製スコア_在庫下限",
        type: "float",
      },
      {
        key: "score4sUpperLimit",
        displayName: "4製スコア_在庫上限",
        type: "float",
      },
      {
        key: "score4sTargetStockMid",
        displayName: "4製スコア_目標在庫期中",
        type: "float",
      },
      {
        key: "score4sTargetStockEnd",
        displayName: "4製スコア_目標在庫期末",
        type: "float",
      },
      {
        key: "score4sEoConsumptionLowerLimit",
        displayName: "4製スコア_EO消費下限",
        type: "float",
      },
      {
        key: "score4sEoConsumptionUpperLimit",
        displayName: "4製スコア_EO消費上限",
        type: "float",
      },
      {
        key: "score4sEoConsumptionEven",
        displayName: "4製スコア_EO消費平準化",
        type: "float",
      },
      {
        key: "score4sSwitchingTimes",
        displayName: "4製スコア_切替回数",
        type: "float",
      },
      {
        key: "sft70And90ContinuousStopDaysLowerLimit",
        displayName: "SFT70&90_連続停止日数下限",
        type: "integer",
      },
      {
        key: "sft70And90ContinuousStopDaysUpperLimit",
        displayName: "SFT70&90_連続停止日数上限",
        type: "integer",
      },
      {
        key: "sft70ContinuousOperationDaysLowerLimit",
        displayName: "SFT70_連続稼働日数下限",
        type: "integer",
      },
      {
        key: "sft70ContinuousOperationDaysUpperLimit",
        displayName: "SFT70_連続稼働日数上限",
        type: "integer",
      },
      {
        key: "sft90ContinuousOperationDaysLowerLimit",
        displayName: "SFT90_連続稼働日数下限",
        type: "integer",
      },
      {
        key: "sft90ContinuousOperationDaysUpperLimit",
        displayName: "SFT90_連続稼働日数上限",
        type: "integer",
      },
      {
        key: "nvpContinuousStopDaysLowerLimit",
        displayName: "NVP_連続停止日数下限",
        type: "integer",
      },
      {
        key: "nvpContinuousStopDaysUpperLimit",
        displayName: "NVP_連続停止日数上限",
        type: "integer",
      },
      {
        key: "nvpContinuousOperationDaysLowerLimit",
        displayName: "NVP_連続稼働日数下限",
        type: "integer",
      },
      {
        key: "nvpContinuousOperationDaysUpperLimit",
        displayName: "NVP_連続稼働日数上限",
        type: "integer",
      },
      {
        key: "eaContinuousStopDaysLowerLimit",
        displayName: "EA_連続停止日数下限",
        type: "integer",
      },
      {
        key: "eaContinuousStopDaysUpperLimit",
        displayName: "EA_連続停止日数上限",
        type: "integer",
      },
      {
        key: "eaContinuousOperationDaysLowerLimit",
        displayName: "EA_連続稼働日数下限",
        type: "integer",
      },
      {
        key: "eaContinuousOperationDaysUpperLimit",
        displayName: "EA_連続稼働日数上限",
        type: "integer",
      },
    ],
  },
  {
    key: "penalty_weight",
    displayName: "penalty_weight",
    dependSheetFields: [
      {
        key: "item1",
        displayName: "項目1",
        type: "string",
      },
      {
        key: "item2",
        displayName: "項目2",
        type: "string",
      },
      {
        key: "item3",
        displayName: "項目3",
        type: "string",
      },
      {
        key: "penaltyCoefficient",
        displayName: "ペナルティ倍率",
        type: "float",
      },
    ],
  },
  {
    key: "catalyst_parameter",
    displayName: "catalyst_parameter",
    dependSheetFields: [
      {
        key: "factory",
        displayName: "工場",
        type: "enum",
      },
      {
        key: "catalystId",
        displayName: "触媒ID",
        type: "float",
      },
      {
        key: "target",
        displayName: "計算対象",
        type: "enum",
      },
      {
        key: "startEo",
        displayName: "区間開始積算EO量",
        type: "float",
      },
      {
        key: "coefficientA",
        displayName: "係数A",
        type: "float",
      },
      {
        key: "coefficientB",
        displayName: "係数B",
        type: "float",
      },
      {
        key: "coefficientC",
        displayName: "係数C",
        type: "float",
      },
    ],
  },
  {
    key: "tank_master_123s",
    displayName: "tank_master_123s",
    dependSheetFields: [
      {
        key: "department",
        displayName: "部署",
        type: "enum",
      },
      {
        key: "tank_id",
        displayName: "タンクID",
        type: "string",
      },
      {
        key: "tank_name",
        displayName: "タンク名",
        type: "string",
      },
      {
        key: "product",
        displayName: "製品",
        type: "string",
      },
      {
        key: "facility",
        displayName: "生産設備",
        type: "enum",
      },
      {
        key: "purchase",
        displayName: "購入",
        type: "boolean",
      },
      {
        key: "shipment",
        displayName: "出荷",
        type: "enum",
      },
      {
        key: "export",
        displayName: "輸出",
        type: "boolean",
      },
      {
        key: "selfConsumption",
        displayName: "自消",
        type: "enum",
      },
      {
        key: "transferShipmentUpperLimit",
        displayName: "移送出荷上限",
        type: "float",
      },
      {
        key: "transferAcceptanceUpperLimit",
        displayName: "移送受け入れ上限",
        type: "float",
      },
      {
        key: "daysFromProductionToStorage",
        displayName: "生産から入庫までの日数",
        type: "float",
      },
      {
        key: "daysFromTankTransferShipmentToStorage",
        displayName: "タンク間移送出庫から入庫までの日数",
        type: "float",
      },
      {
        key: "daysFromProductionFacilityStorageToShipment",
        displayName: "生産設備入庫から出庫可能までの日数",
        type: "float",
      },
      {
        key: "daysFromTankTransferStorageToShipment",
        displayName: "タンク間移送入庫から出庫可能までの日数",
        type: "float",
      },
      {
        key: "safetyUpperLimitStock",
        displayName: "安全上限在庫有無",
        type: "boolean",
      },
      {
        key: "safetyLowerLimitStock",
        displayName: "安全下限在庫有無",
        type: "boolean",
      },
      {
        key: "targetUpperLimitStock",
        displayName: "目標上限在庫有無",
        type: "boolean",
      },
      {
        key: "targetLowerLimitStock",
        displayName: "目標下限在庫有無",
        type: "boolean",
      },
      {
        key: "physicalUpperLimitPenalty",
        displayName: "物理上限ペナルティ",
        type: "float",
      },
      {
        key: "physicalLowerLimitPenalty",
        displayName: "物理下限ペナルティ",
        type: "float",
      },
      {
        key: "safetyUpperLimitPenalty",
        displayName: "安全上限ペナルティ",
        type: "float",
      },
      {
        key: "safetyLowerLimitPenalty",
        displayName: "安全下限ペナルティ",
        type: "float",
      },
      {
        key: "targetUpperLimitPenalty",
        displayName: "目標上限ペナルティ",
        type: "float",
      },
      {
        key: "targetLowerLimitPenalty",
        displayName: "目標下限ペナルティ",
        type: "float",
      },
    ],
  },
  {
    key: "transport_master_123s",
    displayName: "transport_master_123s",
    dependSheetFields: [
      {
        key: "product",
        displayName: "製品",
        type: "string",
      },
      {
        key: "sourceTankName",
        displayName: "移送元タンク名",
        type: "string",
      },
      {
        key: "destinationTankName",
        displayName: "移送先タンク名",
        type: "string",
      },
      {
        key: "reverseDirection",
        displayName: "逆方向への移送",
        type: "boolean",
      },
      {
        key: "basicPenalty",
        displayName: "基本ペナルティ",
        type: "float",
      },
      {
        key: "variablePenalty",
        displayName: "従量ペナルティ",
        type: "float",
      },
      {
        key: "isAllTransport",
        displayName: "全量移送要否",
        type: "boolean",
      },
      {
        key: "unitTransportAmount",
        displayName: "単位移送量",
        type: "float",
      },
      {
        key: "holidayOnly",
        displayName: "休日のみ",
        type: "boolean",
      },
    ],
  },
  {
    key: "facilities_master_123s",
    displayName: "facilities_master_123s",
    dependSheetFields: [
      {
        key: "department",
        displayName: "部署",
        type: "enum",
      },
      {
        key: "facilityName",
        displayName: "生産設備名",
        type: "string",
      },
      {
        key: "productionAmountSplit",
        displayName: "生産量分割可否",
        type: "boolean",
      },
      {
        key: "productionPatternMinimumDays",
        displayName: "生産パターン最低維持日数",
        type: "float",
      },
      {
        key: "acceptanceRatioMinimumDays",
        displayName: "受け入れ割合最低維持日数",
        type: "float",
      },
      {
        key: "productionPatternChangePenaltyCoefficient",
        displayName: "生産パターン変化ペナルティ係数",
        type: "float",
      },
      {
        key: "acceptanceRatioChangePenaltyCoefficient",
        displayName: "受け入れ割合変化ペナルティ係数",
        type: "float",
      },
    ],
  },
  {
    key: "item_master_123s",
    displayName: "item_master_123s",
    dependSheetFields: [
      {
        key: "department",
        displayName: "部署",
        type: "enum",
      },
      {
        key: "consumeDepartment",
        displayName: "消費部署",
        type: "string",
      },
      {
        key: "itemName",
        displayName: "品目名",
        type: "string",
      },
      {
        key: "isRawMaterialPurchase",
        displayName: "原材料購買フラグ",
        type: "boolean",
      },
      {
        key: "itemId",
        displayName: "品目ID",
        type: "string",
      },
    ],
  },
  {
    key: "item_category_master_123s",
    displayName: "item_category_master_123s",
    dependSheetFields: [
      {
        key: "department",
        displayName: "部署",
        type: "enum",
      },
      {
        key: "itemCategory",
        displayName: "品目カテゴリ",
        type: "string",
      },
      {
        key: "id",
        displayName: "品目カテゴリid",
        type: "string",
      },
    ],
  },
  {
    key: "productSheetWithout4sMetaData",
    displayName: "4s以外製品シートメタデータ",
    dependSheetFields: [
      {
        key: "item_id",
        displayName: "項目ID",
        type: "float",
      },
      {
        key: "en_item_name",
        displayName: "英語項目名",
        type: "string",
      },
      {
        key: "item_name",
        displayName: "項目名",
        type: "string",
      },
      {
        key: "item_kind",
        displayName: "項目種別",
        type: "string",
      },
      {
        key: "data_type",
        displayName: "データ型",
        type: "enum",
      },
      {
        key: "enum_params",
        displayName: "enum選択肢",
        type: "string",
      },
      {
        key: "algorithm_kind",
        displayName: "分類",
        type: "string",
      },
      {
        key: "target_item_category",
        displayName: "対象品目カテゴリ",
        type: "string",
      },
      {
        key: "target_item",
        displayName: "対象品目",
        type: "string",
      },
      {
        key: "consume_item_category",
        displayName: "対象品目を消費する品目カテゴリ",
        type: "string",
      },
      {
        key: "consume_item",
        displayName: "対象品目を消費する品目",
        type: "string",
      },
      {
        key: "reactor",
        displayName: "装置",
        type: "string",
      },
      {
        key: "tank_id",
        displayName: "関連タンクID",
        type: "string",
      },
      {
        key: "department",
        displayName: "部署",
        type: "string",
      },
      {
        key: "output_kind",
        displayName: "出力種別",
        type: "string",
      },
    ],
  },
  {
    key: "bheaIsVariable",
    displayName: "bheaIsVariable",
    dependSheetFields: [
      {
        key: "date",
        displayName: "date",
        type: "date",
      },
      {
        key: "bheaEOConsumption",
        displayName: "BHEA|EO消費",
        type: "boolean",
      },
      {
        key: "bheaBatchIgnoringMaintenance",
        displayName: "BHEA|生産バッチ数(メンテ未考慮)",
        type: "boolean",
      },
      {
        key: "bheaISHighPurityIgnoringMaintenance",
        displayName: "BHEA|高純度有無(メンテ未考慮)",
        type: "boolean",
      },
    ],
  },
  {
    key: "eoaIsVariable",
    displayName: "eoaIsVariable",
    dependSheetFields: [
      {
        key: "date",
        displayName: "date",
        type: "date",
      },
      {
        key: "eoConsumption",
        displayName: "24K417N|EO消費",
        type: "boolean",
      },
      {
        key: "A12501ProductionBatch",
        displayName: "24K417N|A-12501|生産バッチ数",
        type: "boolean",
      },
      {
        key: "A12502ProductionBatch",
        displayName: "24K417N|A-12502|生産バッチ数",
        type: "boolean",
      },
      {
        key: "shippingEast",
        displayName: "24K417N|出荷(東地区)",
        type: "boolean",
      },
      {
        key: "shippingEastModified",
        displayName: "24K417N|出荷(東地区)(補正後)",
        type: "boolean",
      },
    ],
  },
  {
    key: "bheaTankIsVariable",
    displayName: "bheaTankIsVariable",
    isReadAll: true,
  },
  {
    key: "eoaTankIsVariable",
    displayName: "eoaTankIsVariable",
    isReadAll: true,
  },
  {
    key: "bhea",
    displayName: "bhea",
    dependSheetFields: [
      {
        key: "date",
        displayName: "date",
        type: "date",
      },
      {
        key: "bheaEOConsumption",
        displayName: "BHEA|EO消費",
        type: "float",
      },
      {
        key: "bheaMaximumBatch",
        displayName: "BHEA|最大バッチ数",
        type: "float",
      },
      {
        key: "bheaHighPurityMaximumBatch",
        displayName: "BHEA|最大高純度バッチ数",
        type: "float",
      },
      {
        key: "bheaBatchIgnoringMaintenance",
        displayName: "BHEA|生産バッチ数(メンテ未考慮)",
        type: "float",
      },
      {
        key: "bheaISHighPurityIgnoringMaintenance",
        displayName: "BHEA|高純度有無(メンテ未考慮)",
        type: "float",
      },
      {
        key: "bheaBatch",
        displayName: "BHEA|生産バッチ数",
        type: "float",
      },
      {
        key: "bheaAccumulationBatchExchangingDemister",
        displayName: "BHEA|累計バッチ数(デミスター交換)",
        type: "float",
      },
      {
        key: "bheaExchangingDemister",
        displayName: "BHEA|デミスター交換指定",
        type: "string",
      },
      {
        key: "bheaAccumulationBatchExchangingValve",
        displayName: "BHEA|累計バッチ数(バルブ交換)",
        type: "float",
      },
      {
        key: "bheaExchangingValve",
        displayName: "BHEA|バルブ交換指定",
        type: "string",
      },
      {
        key: "bheaHighPurityBatch",
        displayName: "BHEA|高純度バッチ数",
        type: "float",
      },
      {
        key: "bheaTotalProduction1",
        displayName: "BHEA|合計|生産1",
        type: "float",
      },
      {
        key: "bheaTotalProduction2",
        displayName: "BHEA|合計|生産2",
        type: "float",
      },
      {
        key: "bheaNormalProduction1",
        displayName: "BHEA|通常品|生産1",
        type: "float",
      },
      {
        key: "bheaNormalProduction2",
        displayName: "BHEA|通常品|生産2",
        type: "float",
      },
      {
        key: "bheaHighPurityProduction1",
        displayName: "BHEA|高純度|生産1",
        type: "float",
      },
      {
        key: "bheaHighPurityProduction2",
        displayName: "BHEA|高純度|生産2",
        type: "float",
      },
      {
        key: "bheaHighPurityProduction",
        displayName: "BHEA|高純度|生産",
        type: "float",
      },
      {
        key: "bheaNormalProduction",
        displayName: "BHEA|通常品|生産",
        type: "float",
      },
      {
        key: "bheaWastedPerBatch",
        displayName: "BHEA|バッチあたり廃液量",
        type: "float",
      },
      {
        key: "bheaDailyWasted",
        displayName: "BHEA|当日廃液量",
        type: "float",
      },
      {
        key: "bheaAccumulationMonthlyWastedLimit",
        displayName: "BHEA|累計月間廃液量上限",
        type: "float",
      },
      {
        key: "bheaAccumulationMonthlyWasted",
        displayName: "BHEA|累計月間廃液量",
        type: "float",
      },
      {
        key: "bheaMonthlyWastedViolation",
        displayName: "BHEA|月間廃液量違反",
        type: "float",
      },
    ],
  },
  {
    key: "eoa",
    displayName: "eoa",
    dependSheetFields: [
      {
        key: "date",
        displayName: "date",
        type: "date",
      },
      {
        key: "A12502ProductionPattern",
        displayName: "24K417N|A-12502|生産パターン",
        type: "string",
      },
      {
        key: "A12501MaximumBatch",
        displayName: "24K417N|A-12501|最大バッチ数",
        type: "float",
      },
      {
        key: "A12502MaximumBatch",
        displayName: "24K417N|A-12502|最大バッチ数",
        type: "float",
      },
      {
        key: "A12501ProductionBatch",
        displayName: "24K417N|A-12501|生産バッチ数",
        type: "float",
      },
      {
        key: "A12502ProductionBatch",
        displayName: "24K417N|A-12502|生産バッチ数",
        type: "float",
      },
      {
        key: "eoConsumption",
        displayName: "24K417N|EO消費",
        type: "float",
      },
      {
        key: "24k417nTotalProduction",
        displayName: "24K417N|合計生産量",
        type: "float",
      },
      {
        key: "24k417nShipping",
        displayName: "24K417N|出荷(全量)",
        type: "float",
      },
      {
        key: "EvaporationA12501",
        displayName: "24K417N|脱水|A-12501バッチあたり廃液量",
        type: "float",
      },
      {
        key: "DeaerationA12501",
        displayName: "24K417N|脱気|A-12501バッチあたり廃液量",
        type: "float",
      },
      {
        key: "EvaporationA12502",
        displayName: "24K417N|脱水|A-12502バッチあたり廃液量",
        type: "float",
      },
      {
        key: "DeaerationA12502",
        displayName: "24K417N|脱気|A-12502バッチあたり廃液量",
        type: "float",
      },
      {
        key: "TotalDailyWasted",
        displayName: "24K417N|当日廃液量",
        type: "float",
      },
      {
        key: "MonthlyWastedAccumulationLimit",
        displayName: "24K417N|累計月間廃液量上限",
        type: "float",
      },
      {
        key: "MonthlyWastedLimit",
        displayName: "24K417N|累計月間廃液量",
        type: "float",
      },
      {
        key: "MonthlyWastedViolation",
        displayName: "24K417N|月間廃液量違反",
        type: "float",
      },
      {
        key: "calcohol40A12501Consumption",
        displayName: "24K417N|カルコール40|A-12501バッチあたり消費量(kg)",
        type: "float",
      },
      {
        key: "calcohol40A12502Consumption",
        displayName: "24K417N|カルコール40|A-12502バッチあたり消費量(kg)",
        type: "float",
      },
      {
        key: "calcohol40Consumption",
        displayName: "24K417N|カルコール40|当日消費量(kg)",
        type: "float",
      },
      {
        key: "calcohol40Arrival",
        displayName: "24K417N|カルコール40|入荷(kg)",
        type: "float",
      },
      {
        key: "calcohol40Stock",
        displayName: "24K417N|カルコール40|在庫(kg)",
        type: "float",
      },
      {
        key: "calcohol20A12501Consumption",
        displayName: "24K417N|カルコール20|A-12501バッチあたり消費量(kg)",
        type: "float",
      },
      {
        key: "calcohol20A12502Consumption",
        displayName: "24K417N|カルコール20|A-12502バッチあたり消費量(kg)",
        type: "float",
      },
      {
        key: "calcohol20Consumption",
        displayName: "24K417N|カルコール20|当日消費量(kg)",
        type: "float",
      },
      {
        key: "calcohol20Arrival",
        displayName: "24K417N|カルコール20|入荷(kg)",
        type: "float",
      },
      {
        key: "calcohol20Stock",
        displayName: "24K417N|カルコール20|在庫(kg)",
        type: "float",
      },
      {
        key: "poA12501Consumption",
        displayName: "24K417N|PO|A-12501バッチあたり消費量(kg)",
        type: "float",
      },
      {
        key: "poA12502Consumption",
        displayName: "24K417N|PO|A-12502バッチあたり消費量(kg)",
        type: "float",
      },
      {
        key: "poConsumption",
        displayName: "24K417N|PO|当日消費量(kg)",
        type: "float",
      },
      {
        key: "poArrival",
        displayName: "24K417N|PO|入荷(kg)",
        type: "float",
      },
      {
        key: "poStock",
        displayName: "24K417N|PO|在庫(kg)",
        type: "float",
      },
      {
        key: "kohA12501Consumption",
        displayName: "24K417N|KOH|A-12501バッチあたり消費量(kg)",
        type: "float",
      },
      {
        key: "kohA12502Consumption",
        displayName: "24K417N|KOH|A-12502バッチあたり消費量(kg)",
        type: "float",
      },
      {
        key: "kohConsumption",
        displayName: "24K417N|KOH|当日消費量(kg)",
        type: "float",
      },
      {
        key: "kohArrival",
        displayName: "24K417N|KOH|入荷(kg)",
        type: "float",
      },
      {
        key: "kohStock",
        displayName: "24K417N|KOH|在庫(kg)",
        type: "float",
      },
      {
        key: "lactate12501Consumption",
        displayName: "24K417N|乳酸|A-12501バッチあたり消費量(kg)",
        type: "float",
      },
      {
        key: "lactateA12502Consumption",
        displayName: "24K417N|乳酸|A-12502バッチあたり消費量(kg)",
        type: "float",
      },
      {
        key: "lactateConsumption",
        displayName: "24K417N|乳酸|当日消費量(kg)",
        type: "float",
      },
      {
        key: "lactateArrival",
        displayName: "24K417N|乳酸|入荷(kg)",
        type: "float",
      },
      {
        key: "lactateStock",
        displayName: "24K417N|乳酸|在庫(kg)",
        type: "float",
      },
      {
        key: "shippingEast",
        displayName: "24K417N|出荷(東地区)",
        type: "float",
      },
      {
        key: "shippingEastModified",
        displayName: "24K417N|出荷(東地区)(補正後)",
        type: "float",
      },
      {
        key: "shipping4s",
        displayName: "24K417N|出荷(4製)",
        type: "float",
      },
      {
        key: "shortage4s",
        displayName: "24K417N|4製不足分",
        type: "float",
      },
    ],
  },
  {
    key: "general",
    displayName: "general",
    dependSheetFields: [
      {
        key: "date",
        displayName: "date",
        type: "date",
      },
      {
        key: "holiday",
        displayName: "休日",
        type: "string",
      },
      {
        key: "chidoriCatalystID",
        displayName: "用役費千鳥|触媒ID",
        type: "float",
      },
      {
        key: "ukishimaCatalystID",
        displayName: "用役費浮島|触媒ID",
        type: "float",
      },
      {
        key: "chidoriAirFlow",
        displayName: "用役費千鳥|風量",
        type: "float",
      },
      {
        key: "ukishimaAirFlow",
        displayName: "用役費浮島|風量",
        type: "float",
      },
      {
        key: "chidoriSummationEO",
        displayName: "用役費千鳥|EO|積算EO",
        type: "float",
      },
      {
        key: "chidoriElectricityPrice",
        displayName: "用役費千鳥|コスト計算|電力単価",
        type: "float",
      },
      {
        key: "chidoriGasPrice",
        displayName: "用役費千鳥|コスト計算|都市ガス単価",
        type: "float",
      },
      {
        key: "chidoriSteamPrice",
        displayName: "用役費千鳥|コスト計算|蒸気単価",
        type: "float",
      },
      {
        key: "ukishimaSummationEO",
        displayName: "用役費浮島|EO|積算EO",
        type: "float",
      },
      {
        key: "ukishimaElectricityPrice",
        displayName: "用役費浮島|コスト計算|電力単価",
        type: "float",
      },
      {
        key: "ukishimaGasPrice",
        displayName: "用役費浮島|コスト計算|都市ガス単価",
        type: "float",
      },
      {
        key: "ukishimaSteamPrice",
        displayName: "用役費浮島|コスト計算|蒸気単価",
        type: "float",
      },
      {
        key: "ukishimaEOConsumption4s",
        displayName: "EO浮島|EO消費4s",
        type: "float",
      },
      {
        key: "ukishimaMaximumEOConsumption4s",
        displayName: "EO浮島|最大EO消費4s",
        type: "float",
      },
      {
        key: "ukishimaMinimumEOConsumption4s",
        displayName: "EO浮島|最小EO消費4s",
        type: "float",
      },
      {
        key: "sft30Consumption4s",
        displayName: "SFT|SFT30消費4s",
        type: "float",
      },
      {
        key: "sft33Consumption4s",
        displayName: "SFT|SFT33消費4s",
        type: "float",
      },
      {
        key: "sft70Consumption4s",
        displayName: "SFT|SFT70消費4s",
        type: "float",
      },
      {
        key: "sft90Consumption4s",
        displayName: "SFT|SFT90消費4s",
        type: "float",
      },
      {
        key: "shortage4s24k417n",
        displayName: "24K417N|不足量4s",
        type: "float",
      },
      {
        key: "ethyleneCostForEO",
        displayName: "原料コスト|EO用エチレン単価",
        type: "float",
      },
      {
        key: "ethyleneCostForEG",
        displayName: "原料コスト|EG用エチレン単価",
        type: "float",
      },
      {
        key: "EOMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益EO",
        type: "float",
      },
      {
        key: "domesticMegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益MEG国内",
        type: "float",
      },
      {
        key: "exportMegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益MEG輸出",
        type: "float",
      },
      {
        key: "domesticDegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益DEG国内",
        type: "float",
      },
      {
        key: "exportDegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益DEG輸出",
        type: "float",
      },
      {
        key: "tegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益TEG",
        type: "float",
      },
      {
        key: "traegMarginalProfitPerTonne",
        displayName: "利益計算|トンあたり限界利益TraEG",
        type: "float",
      },
      {
        key: "nparaffinCost",
        displayName: "原料コスト|ノルマルパラフィン単価",
        type: "float",
      },
      {
        key: "ammoniaCost",
        displayName: "原料コスト|アンモニア単価",
        type: "float",
      },
      {
        key: "calcCost000",
        displayName: "用役費総合計",
        type: "float",
      },
      {
        key: "calcCost001",
        displayName: "原料コスト|EO用エチレン費用千鳥",
        type: "float",
      },
      {
        key: "calcCost002",
        displayName: "原料コスト|EO用エチレン費用浮島",
        type: "float",
      },
      {
        key: "calcCost003",
        displayName: "原料コスト|EG用エチレン費用千鳥",
        type: "float",
      },
      {
        key: "calcCost004",
        displayName: "原料コスト|EG用エチレン費用浮島",
        type: "float",
      },
      {
        key: "calcCost005",
        displayName: "利益計算|限界利益EO千鳥",
        type: "float",
      },
      {
        key: "calcCost006",
        displayName: "利益計算|限界利益EO浮島",
        type: "float",
      },
      {
        key: "calcCost007",
        displayName: "利益計算|限界利益MEG千鳥国内",
        type: "float",
      },
      {
        key: "calcCost008",
        displayName: "利益計算|限界利益MEG浮島国内",
        type: "float",
      },
      {
        key: "calcCost009",
        displayName: "利益計算|限界利益MEG千鳥輸出",
        type: "float",
      },
      {
        key: "calcCost010",
        displayName: "利益計算|限界利益MEG浮島輸出",
        type: "float",
      },
      {
        key: "calcCost011",
        displayName: "利益計算|限界利益DEG千鳥国内",
        type: "float",
      },
      {
        key: "calcCost012",
        displayName: "利益計算|限界利益DEG浮島国内",
        type: "float",
      },
      {
        key: "calcCost013",
        displayName: "利益計算|限界利益DEG千鳥輸出",
        type: "float",
      },
      {
        key: "calcCost014",
        displayName: "利益計算|限界利益DEG浮島輸出",
        type: "float",
      },
      {
        key: "calcCost015",
        displayName: "利益計算|限界利益TEG浮島",
        type: "float",
      },
      {
        key: "calcCost016",
        displayName: "利益計算|限界利益TraEG浮島",
        type: "float",
      },
      {
        key: "calcCost017",
        displayName: "原料コスト|ノルマルパラフィン費用",
        type: "float",
      },
      {
        key: "calcCost018",
        displayName: "原料コスト|アンモニア費用",
        type: "float",
      },
      {
        key: "calcCost019",
        displayName: "用役費千鳥|EO|Et投入量",
        type: "float",
      },
      {
        key: "calcCost020",
        displayName: "用役費千鳥|EO|生成EO",
        type: "float",
      },
      {
        key: "calcCost021",
        displayName: "用役費千鳥|EO|CE(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost022",
        displayName: "用役費千鳥|EO|CE(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost023",
        displayName: "用役費千鳥|EO|選択率(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost024",
        displayName: "用役費千鳥|EO|熱水温度(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost025",
        displayName: "用役費千鳥|EO|選択率(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost026",
        displayName: "用役費千鳥|EO|熱水温度(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost027",
        displayName: "用役費千鳥|反応熱|EO生成",
        type: "float",
      },
      {
        key: "calcCost028",
        displayName: "用役費千鳥|反応熱|CO2生成",
        type: "float",
      },
      {
        key: "calcCost029",
        displayName: "用役費千鳥|熱水蒸気潜熱",
        type: "float",
      },
      {
        key: "calcCost030",
        displayName: "用役費千鳥|発生蒸気量",
        type: "float",
      },
      {
        key: "calcCost031",
        displayName: "用役費千鳥|生産計画の値|選択率",
        type: "float",
      },
      {
        key: "calcCost032",
        displayName: "用役費千鳥|生産計画の値|生成EO",
        type: "float",
      },
      {
        key: "calcCost033",
        displayName: "用役費千鳥|計算には使用しない|ｶﾞｽｴﾝﾀﾙﾋﾟｰ",
        type: "float",
      },
      {
        key: "calcCost034",
        displayName: "用役費千鳥|反応熱|給水量",
        type: "float",
      },
      {
        key: "calcCost035",
        displayName: "用役費千鳥|反応熱|吸水温度",
        type: "float",
      },
      {
        key: "calcCost036",
        displayName: "用役費千鳥|反応熱|吸水顕熱",
        type: "float",
      },
      {
        key: "calcCost037",
        displayName: "用役費千鳥|反応熱|error.",
        type: "float",
      },
      {
        key: "calcCost038",
        displayName: "用役費千鳥|加水EO量",
        type: "float",
      },
      {
        key: "calcCost039",
        displayName: "用役費千鳥|精製EO",
        type: "float",
      },
      {
        key: "calcCost040",
        displayName: "用役費千鳥|熱水温度",
        type: "float",
      },
      {
        key: "calcCost041",
        displayName: "用役費千鳥|蒸気圧力",
        type: "float",
      },
      {
        key: "calcCost042",
        displayName: "用役費千鳥|発生蒸気",
        type: "float",
      },
      {
        key: "calcCost043",
        displayName: "用役費千鳥|ｺｼﾞｪﾈ|都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost044",
        displayName: "用役費千鳥|ｺｼﾞｪﾈ|都市ｶﾞｽ(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost045",
        displayName: "用役費千鳥|ｺｼﾞｪﾈ|発電量",
        type: "float",
      },
      {
        key: "calcCost046",
        displayName: "用役費千鳥|ｺｼﾞｪﾈ|発電量(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost047",
        displayName: "用役費千鳥|ｺｼﾞｪﾈ|熱回収",
        type: "float",
      },
      {
        key: "calcCost048",
        displayName: "用役費千鳥|A-4010|都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost049",
        displayName: "用役費千鳥|A-4010|都市ｶﾞｽ(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost050",
        displayName: "用役費千鳥|A-4020|都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost051",
        displayName: "用役費千鳥|A-4020|都市ｶﾞｽ(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost052",
        displayName: "用役費千鳥|O2消費",
        type: "float",
      },
      {
        key: "calcCost053",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|KC-10",
        type: "float",
      },
      {
        key: "calcCost054",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|KC-10(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost055",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|KC-40",
        type: "float",
      },
      {
        key: "calcCost056",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|KC-40(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost057",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|LCO2-1",
        type: "float",
      },
      {
        key: "calcCost058",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|LCO2-2",
        type: "float",
      },
      {
        key: "calcCost059",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|O2低圧",
        type: "float",
      },
      {
        key: "calcCost060",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|O2低圧(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost061",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|DO-1(発電量)",
        type: "float",
      },
      {
        key: "calcCost062",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|DO-1(発電量)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost063",
        displayName: "用役費千鳥|O2ﾌﾟﾗﾝﾄ(電力)|O2ﾌﾟﾗﾝﾄ合計",
        type: "float",
      },
      {
        key: "calcCost064",
        displayName: "用役費千鳥|EO系|T-4103(0.2M)",
        type: "float",
      },
      {
        key: "calcCost065",
        displayName: "用役費千鳥|EO系|T-4103(0.2M)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost066",
        displayName: "用役費千鳥|EO系|T-4106(0.2M)",
        type: "float",
      },
      {
        key: "calcCost067",
        displayName: "用役費千鳥|EO系|K-4160B",
        type: "float",
      },
      {
        key: "calcCost068",
        displayName: "用役費千鳥|EO系|T-4109(0.9M)",
        type: "float",
      },
      {
        key: "calcCost069",
        displayName: "用役費千鳥|EO系|T-4109(0.9M)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost070",
        displayName: "用役費千鳥|EO系|EO系合計",
        type: "float",
      },
      {
        key: "calcCost071",
        displayName: "用役費千鳥|EO系|K-4165A",
        type: "float",
      },
      {
        key: "calcCost072",
        displayName: "用役費千鳥|EO系|K-4165A(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost073",
        displayName: "用役費千鳥|EO系|K-4165B",
        type: "float",
      },
      {
        key: "calcCost074",
        displayName: "用役費千鳥|EO系|K-4165B(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost075",
        displayName: "用役費千鳥|EO系|EO電力合計",
        type: "float",
      },
      {
        key: "calcCost076",
        displayName: "用役費千鳥|EG系|消費蒸気(0.9M)",
        type: "float",
      },
      {
        key: "calcCost077",
        displayName: "用役費千鳥|EG系|消費蒸気(0.9M)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost078",
        displayName: "用役費千鳥|EG系|消費蒸気(0.2M)",
        type: "float",
      },
      {
        key: "calcCost079",
        displayName: "用役費千鳥|EG系|消費蒸気(0.2M)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost080",
        displayName: "用役費千鳥|EA系|EA稼働",
        type: "float",
      },
      {
        key: "calcCost081",
        displayName: "用役費千鳥|EA系|消費蒸気(0.9M)",
        type: "float",
      },
      {
        key: "calcCost082",
        displayName: "用役費千鳥|EA系|消費蒸気(0.2M)",
        type: "float",
      },
      {
        key: "calcCost083",
        displayName: "用役費千鳥|EI系|生産量",
        type: "float",
      },
      {
        key: "calcCost084",
        displayName: "用役費千鳥|EI系|消費蒸気(0.9M)",
        type: "float",
      },
      {
        key: "calcCost085",
        displayName: "用役費千鳥|EI系|消費蒸気(0.2M)",
        type: "float",
      },
      {
        key: "calcCost086",
        displayName: "用役費千鳥|NVP系|消費蒸気",
        type: "float",
      },
      {
        key: "calcCost087",
        displayName: "用役費千鳥|EID|消費蒸気",
        type: "float",
      },
      {
        key: "calcCost088",
        displayName: "用役費千鳥|用役集計|発生蒸気",
        type: "float",
      },
      {
        key: "calcCost089",
        displayName: "用役費千鳥|用役集計|0.9M消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost090",
        displayName: "用役費千鳥|用役集計|0.9M消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost091",
        displayName: "用役費千鳥|用役集計|0.9M固定蒸気",
        type: "float",
      },
      {
        key: "calcCost092",
        displayName: "用役費千鳥|用役集計|0.9M→2.2M換算消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost093",
        displayName: "用役費千鳥|用役集計|0.9M→2.2M換算消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost094",
        displayName: "用役費千鳥|用役集計|0.2M消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost095",
        displayName: "用役費千鳥|用役集計|0.2M消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost096",
        displayName: "用役費千鳥|用役集計|0.2M固定蒸気",
        type: "float",
      },
      {
        key: "calcCost097",
        displayName: "用役費千鳥|用役集計|0.2M→2.2M換算消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost098",
        displayName: "用役費千鳥|用役集計|0.2M→2.2M換算消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost099",
        displayName: "用役費千鳥|計算ベース|購入蒸気",
        type: "float",
      },
      {
        key: "calcCost100",
        displayName: "用役費千鳥|計算ベース|ﾊﾟｰｼﾞ蒸気",
        type: "float",
      },
      {
        key: "calcCost101",
        displayName: "用役費千鳥|計算ベース|購入蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost102",
        displayName: "用役費千鳥|計算ベース|購入蒸気(定常運転+スタート停止)",
        type: "float",
      },
      {
        key: "calcCost103",
        displayName: "用役費千鳥|計算ベース|0.9M換算購入蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost104",
        displayName: "用役費千鳥|計算ベース|0.9M換算購入蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost105",
        displayName: "用役費千鳥|計算ベース|都市ｶﾞｽ購入",
        type: "float",
      },
      {
        key: "calcCost106",
        displayName: "用役費千鳥|計算ベース|換算後",
        type: "float",
      },
      {
        key: "calcCost107",
        displayName: "用役費千鳥|計算ベース|消費電力",
        type: "float",
      },
      {
        key: "calcCost108",
        displayName: "用役費千鳥|コスト計算|電力コスト",
        type: "float",
      },
      {
        key: "calcCost109",
        displayName: "用役費千鳥|コスト計算|都市ガスコスト",
        type: "float",
      },
      {
        key: "calcCost110",
        displayName: "用役費千鳥|コスト計算|蒸気コスト",
        type: "float",
      },
      {
        key: "calcCost111",
        displayName: "用役費千鳥|コスト計算|EO停止コスト",
        type: "float",
      },
      {
        key: "calcCost112",
        displayName: "用役費千鳥|コスト計算|合計コスト",
        type: "float",
      },
      {
        key: "calcCost113",
        displayName: "用役費浮島|EO|Et投入量",
        type: "float",
      },
      {
        key: "calcCost114",
        displayName: "用役費浮島|EO|生成EO",
        type: "float",
      },
      {
        key: "calcCost115",
        displayName: "用役費浮島|EO|CE(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost116",
        displayName: "用役費浮島|EO|CE(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost117",
        displayName: "用役費浮島|EO|選択率(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost118",
        displayName: "用役費浮島|EO|熱水温度(400T/D換算)",
        type: "float",
      },
      {
        key: "calcCost119",
        displayName: "用役費浮島|EO|選択率(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost120",
        displayName: "用役費浮島|EO|熱水温度(投入Et量における値)",
        type: "float",
      },
      {
        key: "calcCost121",
        displayName: "用役費浮島|反応熱|EO生成",
        type: "float",
      },
      {
        key: "calcCost122",
        displayName: "用役費浮島|反応熱|CO2生成",
        type: "float",
      },
      {
        key: "calcCost123",
        displayName: "用役費浮島|熱水蒸気潜熱",
        type: "float",
      },
      {
        key: "calcCost124",
        displayName: "用役費浮島|発生蒸気量",
        type: "float",
      },
      {
        key: "calcCost125",
        displayName: "用役費浮島|生産計画の値|選択率",
        type: "float",
      },
      {
        key: "calcCost126",
        displayName: "用役費浮島|生産計画の値|生成EO",
        type: "float",
      },
      {
        key: "calcCost127",
        displayName: "用役費浮島|ｶﾞｽｴﾝﾀﾙﾋﾟｰ",
        type: "float",
      },
      {
        key: "calcCost128",
        displayName: "用役費浮島|給水量",
        type: "float",
      },
      {
        key: "calcCost129",
        displayName: "用役費浮島|吸水温度",
        type: "float",
      },
      {
        key: "calcCost130",
        displayName: "用役費浮島|吸水顕熱",
        type: "float",
      },
      {
        key: "calcCost131",
        displayName: "用役費浮島|error.",
        type: "float",
      },
      {
        key: "calcCost132",
        displayName: "用役費浮島|加水EO量",
        type: "float",
      },
      {
        key: "calcCost133",
        displayName: "用役費浮島|T合稼働",
        type: "float",
      },
      {
        key: "calcCost134",
        displayName: "用役費浮島|A-9処理増分",
        type: "float",
      },
      {
        key: "calcCost135",
        displayName: "用役費浮島|精製EO",
        type: "float",
      },
      {
        key: "calcCost136",
        displayName: "用役費浮島|熱水温度",
        type: "float",
      },
      {
        key: "calcCost137",
        displayName: "用役費浮島|蒸気圧力",
        type: "float",
      },
      {
        key: "calcCost138",
        displayName: "用役費浮島|発生蒸気",
        type: "float",
      },
      {
        key: "calcCost139",
        displayName: "用役費浮島|発生蒸気|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost140",
        displayName: "用役費浮島|消費蒸気|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost141",
        displayName: "用役費浮島|K-2006|投入蒸気",
        type: "float",
      },
      {
        key: "calcCost142",
        displayName: "用役費浮島|K-2006|発生-FI2412",
        type: "float",
      },
      {
        key: "calcCost143",
        displayName: "用役費浮島|K-2006(見直し)|投入蒸気",
        type: "float",
      },
      {
        key: "calcCost144",
        displayName: "用役費浮島|K-2006(見直し)|発電量",
        type: "float",
      },
      {
        key: "calcCost145",
        displayName: "用役費浮島|K-2006(見直し)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost146",
        displayName: "用役費浮島|A-2001|都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost147",
        displayName: "用役費浮島|A-2001|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost148",
        displayName: "用役費浮島|4O+4X|消費電力",
        type: "float",
      },
      {
        key: "calcCost149",
        displayName: "用役費浮島|4O+4X|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost150",
        displayName: "用役費浮島|EG系|0.9M 消費",
        type: "float",
      },
      {
        key: "calcCost151",
        displayName: "用役費浮島|ﾛｰﾋｰﾄ(0.9M)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost152",
        displayName: "用役費浮島|リボイラー(0.2M)|CO2放散塔蒸気",
        type: "float",
      },
      {
        key: "calcCost153",
        displayName: "用役費浮島|リボイラー(0.2M)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost154",
        displayName: "用役費浮島|FI2056|FI2056",
        type: "float",
      },
      {
        key: "calcCost155",
        displayName: "用役費浮島|FI2056|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost156",
        displayName: "用役費浮島|O2消費",
        type: "float",
      },
      {
        key: "calcCost157",
        displayName: "用役費浮島|O2ﾌﾟﾗﾝﾄ|消費電力",
        type: "float",
      },
      {
        key: "calcCost158",
        displayName: "用役費浮島|O2ﾌﾟﾗﾝﾄ|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost159",
        displayName: "用役費浮島|LCO2系|LCO2ﾛｰﾄﾞ",
        type: "float",
      },
      {
        key: "calcCost160",
        displayName: "用役費浮島|LCO2系|消費電力",
        type: "float",
      },
      {
        key: "calcCost161",
        displayName: "用役費浮島|LCO2系|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost162",
        displayName: "用役費浮島|X系|EO放散塔",
        type: "float",
      },
      {
        key: "calcCost163",
        displayName: "用役費浮島|X系|EO放散塔 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost164",
        displayName: "用役費浮島|X系|FI2119",
        type: "float",
      },
      {
        key: "calcCost165",
        displayName: "用役費浮島|X系|FI2119 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost166",
        displayName: "用役費浮島|X系|X系その他",
        type: "float",
      },
      {
        key: "calcCost167",
        displayName: "用役費浮島|X系|X系その他 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost168",
        displayName: "用役費浮島|X系|FI2008",
        type: "float",
      },
      {
        key: "calcCost169",
        displayName: "用役費浮島|X系|FI2008 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost170",
        displayName: "用役費浮島|EG系(0.9MPa)|0.9M 消費",
        type: "float",
      },
      {
        key: "calcCost171",
        displayName: "用役費浮島|EG系(0.9MPa)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost172",
        displayName: "用役費浮島|EG系(0.2MPa)|FC2217",
        type: "float",
      },
      {
        key: "calcCost173",
        displayName: "用役費浮島|EG系(0.2MPa)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost174",
        displayName: "用役費浮島|EG系(2.5MPa)|T-2402蒸気",
        type: "float",
      },
      {
        key: "calcCost175",
        displayName: "用役費浮島|EG系(2.5MPa)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost176",
        displayName: "用役費浮島|EG系(2.5M→0.9MPa換算)|T-2402蒸気",
        type: "float",
      },
      {
        key: "calcCost177",
        displayName: "用役費浮島|EG系(2.5M→0.9MPa換算)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost178",
        displayName: "用役費浮島|EG系(都市ガス)|DT 都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost179",
        displayName: "用役費浮島|EG系(都市ガス)|ｽﾀｰﾄ停止",
        type: "float",
      },
      {
        key: "calcCost180",
        displayName: "用役費浮島|HA系(SFT30)|消費EO",
        type: "float",
      },
      {
        key: "calcCost181",
        displayName: "用役費浮島|HA系(HEO)|消費EO",
        type: "float",
      },
      {
        key: "calcCost182",
        displayName: "用役費浮島|HA系(SFT30)|消費蒸気(0.2M)",
        type: "float",
      },
      {
        key: "calcCost183",
        displayName: "用役費浮島|HA系(SFT30)|消費蒸気(0.2M) (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost184",
        displayName: "用役費浮島|HA系(SFT30)|消費蒸気(0.9M)",
        type: "float",
      },
      {
        key: "calcCost185",
        displayName: "用役費浮島|HA系(SFT30)|消費蒸気(0.9M) (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost186",
        displayName: "用役費浮島|HA系(SFT30)|消費電力",
        type: "float",
      },
      {
        key: "calcCost187",
        displayName: "用役費浮島|HA系(SFT30)|消費電力 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost188",
        displayName: "用役費浮島|HA系(HEO)|消費電力",
        type: "float",
      },
      {
        key: "calcCost189",
        displayName: "用役費浮島|HA系(HEO)|消費電力 (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost190",
        displayName: "用役費浮島|HA系(SFT30)|都市ｶﾞｽ",
        type: "float",
      },
      {
        key: "calcCost191",
        displayName: "用役費浮島|HA系(SFT30)|都市ｶﾞｽ (ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost192",
        displayName: "用役費浮島|4S|消費EO",
        type: "float",
      },
      {
        key: "calcCost193",
        displayName: "用役費浮島|4S|消費蒸気(0.2M)",
        type: "float",
      },
      {
        key: "calcCost194",
        displayName: "用役費浮島|4S|消費蒸気(0.2M)(ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost195",
        displayName: "用役費浮島|4S|消費蒸気(0.9M)",
        type: "float",
      },
      {
        key: "calcCost196",
        displayName: "用役費浮島|4S|消費蒸気(0.9M)(ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost197",
        displayName: "用役費浮島|4S|消費電力",
        type: "float",
      },
      {
        key: "calcCost198",
        displayName: "用役費浮島|4S|消費電力(ｽﾀｰﾄ停止)",
        type: "float",
      },
      {
        key: "calcCost199",
        displayName: "用役費浮島|用役集計|発生蒸気",
        type: "float",
      },
      {
        key: "calcCost200",
        displayName: "用役費浮島|用役集計|0.9M消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost201",
        displayName: "用役費浮島|用役集計|0.9M消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost202",
        displayName: "用役費浮島|用役集計|0.9M→2.2M換算消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost203",
        displayName: "用役費浮島|用役集計|0.9M→2.2M換算消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost204",
        displayName: "用役費浮島|用役集計|0.2M消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost205",
        displayName: "用役費浮島|用役集計|0.2M消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost206",
        displayName: "用役費浮島|用役集計|0.2M→2.2M換算消費蒸気(定常運転)",
        type: "float",
      },
      {
        key: "calcCost207",
        displayName: "用役費浮島|用役集計|0.2M→2.2M換算消費蒸気(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost210",
        displayName: "用役費浮島|計算ベース|蒸気パージ",
        type: "float",
      },
      {
        key: "calcCost211",
        displayName: "用役費浮島|計算ベース|蒸気購入量(定常運転)",
        type: "float",
      },
      {
        key: "calcCost212",
        displayName: "用役費浮島|計算ベース|蒸気購入量(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost213",
        displayName: "用役費浮島|計算ベース|蒸気購入量(定常運転+スタート停止)",
        type: "float",
      },
      {
        key: "calcCost214",
        displayName: "用役費浮島|計算ベース|蒸気購入量(0.9M換算)(定常運転)",
        type: "float",
      },
      {
        key: "calcCost215",
        displayName: "用役費浮島|計算ベース|蒸気購入量(0.9M換算)(スタート停止)",
        type: "float",
      },
      {
        key: "calcCost216",
        displayName: "用役費浮島|計算ベース|電気購入量",
        type: "float",
      },
      {
        key: "calcCost217",
        displayName: "用役費浮島|計算ベース|都市ｶﾞｽ購入",
        type: "float",
      },
      {
        key: "calcCost218",
        displayName: "用役費浮島|コスト計算|電力コスト",
        type: "float",
      },
      {
        key: "calcCost219",
        displayName: "用役費浮島|コスト計算|都市ガスコスト",
        type: "float",
      },
      {
        key: "calcCost220",
        displayName: "用役費浮島|コスト計算|蒸気コスト",
        type: "float",
      },
      {
        key: "calcCost221",
        displayName: "用役費浮島|コスト計算|EO停止コスト",
        type: "float",
      },
      {
        key: "calcCost222",
        displayName: "用役費浮島|コスト計算|合計コスト",
        type: "float",
      },
    ],
  },
  {
    key: "bheaTank",
    displayName: "bheaTank",
    isReadAll: true,
  },
  {
    key: "eoaTank",
    displayName: "eoaTank",
    isReadAll: true,
  },
  {
    key: "production_123s_summary",
    displayName: "production_123s_summary",
    dependSheetFields: [
      {
        key: "general",
        displayName: "general",
        type: "string",
      },
      {
        key: "eo",
        displayName: "eo",
        type: "string",
      },
      {
        key: "eg",
        displayName: "eg",
        type: "string",
      },
      {
        key: "nvp",
        displayName: "nvp",
        type: "string",
      },
      {
        key: "ea",
        displayName: "ea",
        type: "string",
      },
      {
        key: "ei",
        displayName: "ei",
        type: "string",
      },
      {
        key: "bhea",
        displayName: "bhea",
        type: "string",
      },
      {
        key: "sft",
        displayName: "sft",
        type: "string",
      },
      {
        key: "eoa",
        displayName: "eoa",
        type: "string",
      },
      {
        key: "filter",
        displayName: "filter",
        type: "string",
      },
    ],
  },
  {
    key: "s4_master_1234s",
    displayName: "s4_master_1234s",
    dependSheetFields: [
      {
        key: "itemCode",
        displayName: "品目コード",
        type: "string",
      },
      {
        key: "itemText",
        displayName: "品目テキスト",
        type: "string",
      },
      {
        key: "version",
        displayName: "製造バージョン",
        type: "string",
      },
      {
        key: "stockArea",
        displayName: "保管場所",
        type: "string",
      },
      {
        key: "lotSize",
        displayName: "ロットサイズ",
        type: "float",
      },
    ],
  },
  {
    key: "internal_id_to_item_code",
    displayName: "internal_id_to_item_code",
    dependSheetFields: [
      {
        key: "internalID",
        displayName: "内部ID",
        type: "string",
      },
      {
        key: "itemText",
        displayName: "品目コード",
        type: "string",
      },
    ],
  },
  {
    key: "spreadsheet_column_filter",
    displayName: "spreadsheet_column_filter",
    dependSheetFields: [
      {
        key: "1s",
        displayName: "1s列フィルタ",
        type: "string",
      },
      {
        key: "2sEid",
        displayName: "2sEid列フィルタ",
        type: "string",
      },
      {
        key: "2sEast",
        displayName: "2sEast列フィルタ",
        type: "string",
      },
      {
        key: "3s",
        displayName: "3s列フィルタ",
        type: "string",
      },
      {
        key: "4s",
        displayName: "4s列フィルタ",
        type: "string",
      },
      {
        key: "Management",
        displayName: "生産管理センターの列フィルタ",
        type: "string",
      },
    ],
  },
  {
    key: "selected_date_range",
    displayName: "selected_date_range",
    dependSheetFields: [
      {
        key: "1s",
        displayName: "1s日付範囲",
        type: "string",
      },
      {
        key: "2sEid",
        displayName: "2sEid日付範囲",
        type: "string",
      },
      {
        key: "2sEast",
        displayName: "2sEast日付範囲",
        type: "string",
      },
      {
        key: "3s",
        displayName: "3s日付範囲",
        type: "string",
      },
      {
        key: "4s",
        displayName: "4s日付範囲",
        type: "string",
      },
      {
        key: "Management",
        displayName: "Management日付範囲",
        type: "string",
      },
    ],
  },
  {
    key: "s2_basic_info",
    displayName: "2S 基本情報",
    dependSheetFields: [
      {
        key: "time_base_default",
        displayName: "基準時間",
        type: "string",
      },
      {
        key: "time_base_for_material_po",
        displayName: "原料 PO 向け基準時間",
        type: "string",
      },
    ],
  },
  {
    key: "s2_product_master",
    displayName: "2S 製品マスタ",
    dependSheetFields: [
      {
        key: "id",
        displayName: "製品ID",
        type: "string",
      },
      {
        key: "name",
        displayName: "製品名",
        type: "string",
      },
    ],
  },
  {
    key: "s2_device_master",
    displayName: "2S 設備マスタ",
    dependSheetFields: [
      {
        key: "id",
        displayName: "設備ID",
        type: "string",
      },
      {
        key: "name",
        displayName: "設備名",
        type: "string",
      },
    ],
  },
  {
    key: "s2_device_group_master",
    displayName: "2S 設備グループマスタ",
    dependSheetFields: [
      {
        key: "id",
        displayName: "設備グループID",
        type: "string",
      },
      {
        key: "name",
        displayName: "設備グループ名",
        type: "string",
      },
      {
        key: "sort_number",
        displayName: "ソート",
        type: "integer",
      },
    ],
  },
  {
    key: "s2_device_group_device_mapping_master",
    displayName: "2S 設備グループ・設備対応マスタ",
    dependSheetFields: [
      {
        key: "device_group_id",
        displayName: "設備グループID",
        type: "string",
      },
      {
        key: "device_id",
        displayName: "設備ID",
        type: "string",
      },
      {
        key: "sort_in_devide_group",
        displayName: "グループ内ソート",
        type: "integer",
      },
    ],
  },
  {
    key: "s2_batch_process_set_detail_process_master",
    displayName: "2S 工程セット詳細工程マスタ",
    dependSheetFields: [
      {
        key: "batch_process_set_id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "step_in_batch_process_set",
        displayName: "工程セット内工程番号",
        type: "integer",
      },
      {
        key: "name",
        displayName: "詳細工程名",
        type: "string",
      },
      {
        key: "default_required_time",
        displayName: "デフォルト所要時間",
        type: "integer",
      },
      {
        key: "default_relative_start_timing_from_first_step",
        displayName: "デフォルト開始タイミング",
        type: "integer",
      },
      {
        key: "device_type",
        displayName: "設備タイプ",
        type: "enum",
      },
      {
        key: "device_id",
        displayName: "設備ID",
        type: "string",
      },
      {
        key: "device_group_id",
        displayName: "設備グループID",
        type: "string",
      },
      {
        key: "is_blank",
        displayName: "隙間かどうか",
        type: "boolean",
      },
      {
        key: "is_first",
        displayName: "最初の工程かどうか",
        type: "boolean",
      },
      {
        key: "next_step_in_batch_process_set",
        displayName: "次の工程番号",
        type: "integer",
      },
      {
        key: "process_gantt_chart_color",
        displayName: "工程背景色",
        type: "enum",
      },
    ],
  },
  {
    key: "s2_batch_process_set_master",
    displayName: "2S 工程セットマスタ",
    dependSheetFields: [
      {
        key: "id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "name",
        displayName: "工程セット名",
        type: "string",
      },
      {
        key: "default_start_times",
        displayName: "開始時間配列",
        type: "array",
      },
      {
        key: "product_id",
        displayName: "製品ID",
        type: "string",
      },
      {
        key: "device_group_id",
        displayName: "設備グループID",
        type: "string",
      },
      {
        key: "total_steps_in_batch_process_set",
        displayName: "工程セット内全工程数",
        type: "integer",
      },
      {
        key: "max_batch_per_day",
        displayName: "1日あたりの最大バッチ数",
        type: "integer",
      },
      {
        key: "default_required_time",
        displayName: "基準所要時間",
        type: "integer",
      },
    ],
  },
  {
    key: "s2_batch_production_plan",
    displayName: "2S バッチ生産計画",
    dependSheetFields: [
      {
        key: "date",
        displayName: "日付",
        type: "date",
      },
      {
        key: "batch_process_set_id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "times_batch_process_set",
        displayName: "バッチ回数",
        type: "integer",
      },
    ],
  },
  {
    key: "s2_batch_process_plan",
    displayName: "2S 工程計画",
    dependSheetFields: [
      {
        key: "comment",
        displayName: "コメント",
        type: "string",
      },
      {
        key: "batch_process_set_id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "time_start_step_1",
        displayName: "ステップ 1 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_2",
        displayName: "ステップ 2 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_3",
        displayName: "ステップ 3 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_4",
        displayName: "ステップ 4 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_5",
        displayName: "ステップ 5 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_6",
        displayName: "ステップ 6 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_7",
        displayName: "ステップ 7 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_8",
        displayName: "ステップ 8 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_9",
        displayName: "ステップ 9 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_10",
        displayName: "ステップ 10 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_11",
        displayName: "ステップ 11 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_12",
        displayName: "ステップ 12 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_13",
        displayName: "ステップ 13 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_14",
        displayName: "ステップ 14 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_15",
        displayName: "ステップ 15 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_16",
        displayName: "ステップ 16 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_17",
        displayName: "ステップ 17 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_18",
        displayName: "ステップ 18 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_19",
        displayName: "ステップ 19 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_20",
        displayName: "ステップ 20 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_21",
        displayName: "ステップ 21 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_22",
        displayName: "ステップ 22 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_23",
        displayName: "ステップ 23 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_24",
        displayName: "ステップ 24 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_25",
        displayName: "ステップ 25 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_26",
        displayName: "ステップ 26 開始時間",
        type: "date-time",
      },
      {
        key: "time_finish_batch_process_set",
        displayName: "工程セット終了時間",
        type: "date-time",
      },
    ],
  },
  {
    key: "s2_material_master",
    displayName: "2S 原料マスタ",
    dependSheetFields: [
      {
        key: "id",
        displayName: "原料ID",
        type: "string",
      },
      {
        key: "name",
        displayName: "原料名",
        type: "string",
      },
      {
        key: "sort_number",
        displayName: "ソート",
        type: "integer",
      },
    ],
  },
  {
    key: "s2_batch_process_set_detail_process_material_consumption_master",
    displayName: "2S 工程セット詳細工程原料消費マスタ",
    dependSheetFields: [
      {
        key: "material_id",
        displayName: "原料ID",
        type: "string",
      },
      {
        key: "batch_process_set_id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "step_in_batch_process_set",
        displayName: "工程セット内工程番号",
        type: "float",
      },
      {
        key: "consumption",
        displayName: "消費量",
        type: "float",
      },
      {
        key: "timing_consumption",
        displayName: "消費タイミング",
        type: "enum",
      },
    ],
  },
  {
    key: "s2_batch_process_set_detail_process_waste_liquid_master",
    displayName: "2S 工程セット詳細工程廃液マスタ",
    dependSheetFields: [
      {
        key: "batch_process_set_id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "step_in_batch_process_set",
        displayName: "工程セット内工程番号",
        type: "integer",
      },
      {
        key: "waste_liquid_amount",
        displayName: "廃液量",
        type: "float",
      },
      {
        key: "timing_waste_liquid",
        displayName: "廃液排出タイミング",
        type: "enum",
      },
    ],
  },
  {
    key: "s2_alert_master",
    displayName: "2S アラートマスタ",
    dependSheetFields: [
      {
        key: "alert_explanation",
        displayName: "アラート説明",
        type: "string",
      },
      {
        key: "alert_id",
        displayName: "アラートID",
        type: "string",
      },
      {
        key: "alert_level",
        displayName: "アラートレベル",
        type: "enum",
      },
      {
        key: "alert_message_template",
        displayName: "メッセージテンプレート",
        type: "string",
      },
      {
        key: "alert_name",
        displayName: "アラート名",
        type: "string",
      },
      {
        key: "alert_target_list",
        displayName: "アラート対象リスト",
        type: "array",
      },
      {
        key: "data_cooperation_kind",
        displayName: "データ連携種別",
        type: "enum",
      },
    ],
  },
  {
    key: "s2_batch_count_mapping_master",
    displayName: "2S バッチ数マッピングマスタ",
    dependSheetFields: [
      {
        key: "batch_process_set_id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "receive_condition",
        displayName: "受け入れ条件",
        type: "enum",
      },
      {
        key: "sheet_name",
        displayName: "シート名",
        type: "enum",
      },
      {
        key: "sheet_column_name",
        displayName: "シート列名",
        type: "string",
      },
      {
        key: "locked_sheet_name",
        displayName: "固定シート名",
        type: "enum",
      },
      {
        key: "is_enabled",
        displayName: "有効",
        type: "boolean",
      },
    ],
  },
  {
    key: "s2_material_mapping_master",
    displayName: "2S 原料マッピングマスタ",
    dependSheetFields: [
      {
        key: "info_kind",
        displayName: "情報種別",
        type: "enum",
      },
      {
        key: "material_id",
        displayName: "原料ID",
        type: "string",
      },
      {
        key: "batch_process_set_id_list",
        displayName: "工程セットIDリスト",
        type: "array",
      },
      {
        key: "sheet_name",
        displayName: "シート名",
        type: "enum",
      },
      {
        key: "sheet_column_name",
        displayName: "シート列名",
        type: "string",
      },
      {
        key: "locked_sheet_name",
        displayName: "固定シート名",
        type: "enum",
      },
    ],
  },
  {
    key: "s2_waste_liquid_mapping_master",
    displayName: "2S 廃液マッピングマスタ",
    dependSheetFields: [
      {
        key: "info_kind",
        displayName: "情報種別",
        type: "enum",
      },
      {
        key: "waste_liquid_target_list",
        displayName: "廃液対象リスト",
        type: "array",
      },
      {
        key: "sheet_name",
        displayName: "シート名",
        type: "enum",
      },
      {
        key: "sheet_column_name",
        displayName: "シート列名",
        type: "string",
      },
    ],
  },
];
export const loadSkipSheets: {
  sheetKey: keyof viewtypeContents;
  sheetFieldSchemaName: string;
}[] = [];
export const sheetTypes: {
  sheetKey: keyof viewtypeContents;
  sheetType: "setting" | "master" | "plan" | "unuse" | "summary" | "edit_only";
}[] = [
  {
    sheetKey: "setting",
    sheetType: "edit_only",
  },
  {
    sheetKey: "parameter",
    sheetType: "setting",
  },
  {
    sheetKey: "penalty_weight",
    sheetType: "setting",
  },
  {
    sheetKey: "catalyst_parameter",
    sheetType: "master",
  },
  {
    sheetKey: "tank_master_123s",
    sheetType: "master",
  },
  {
    sheetKey: "transport_master_123s",
    sheetType: "master",
  },
  {
    sheetKey: "facilities_master_123s",
    sheetType: "master",
  },
  {
    sheetKey: "item_master_123s",
    sheetType: "edit_only",
  },
  {
    sheetKey: "item_category_master_123s",
    sheetType: "edit_only",
  },
  {
    sheetKey: "productSheetWithout4sMetaData",
    sheetType: "edit_only",
  },
  {
    sheetKey: "bheaIsVariable",
    sheetType: "edit_only",
  },
  {
    sheetKey: "eoaIsVariable",
    sheetType: "edit_only",
  },
  {
    sheetKey: "bheaTankIsVariable",
    sheetType: "edit_only",
  },
  {
    sheetKey: "eoaTankIsVariable",
    sheetType: "edit_only",
  },
  {
    sheetKey: "bhea",
    sheetType: "plan",
  },
  {
    sheetKey: "eoa",
    sheetType: "plan",
  },
  {
    sheetKey: "general",
    sheetType: "plan",
  },
  {
    sheetKey: "bheaTank",
    sheetType: "plan",
  },
  {
    sheetKey: "eoaTank",
    sheetType: "plan",
  },
  {
    sheetKey: "production_123s_summary",
    sheetType: "edit_only",
  },
  {
    sheetKey: "s4_master_1234s",
    sheetType: "master",
  },
  {
    sheetKey: "internal_id_to_item_code",
    sheetType: "edit_only",
  },
  {
    sheetKey: "spreadsheet_column_filter",
    sheetType: "edit_only",
  },
  {
    sheetKey: "selected_date_range",
    sheetType: "edit_only",
  },
  {
    sheetKey: "s2_basic_info",
    sheetType: "master",
  },
  {
    sheetKey: "s2_product_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_device_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_device_group_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_device_group_device_mapping_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_batch_process_set_detail_process_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_batch_process_set_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_batch_production_plan",
    sheetType: "plan",
  },
  {
    sheetKey: "s2_batch_process_plan",
    sheetType: "plan",
  },
  {
    sheetKey: "s2_material_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_batch_process_set_detail_process_material_consumption_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_batch_process_set_detail_process_waste_liquid_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_alert_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_batch_count_mapping_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_material_mapping_master",
    sheetType: "master",
  },
  {
    sheetKey: "s2_waste_liquid_mapping_master",
    sheetType: "master",
  },
];
export const spreadSheetSettings: { [k: string]: SpreadSheetSetting } = {
  setting: {
    name: "setting",
    sheetType: "edit_only",
    sheetKeys: ["setting"],
    columns: "all",
    queryLoadSheets: [],
  },
  parameter: {
    name: "parameter",
    sheetType: "setting",
    sheetKeys: ["parameter"],
    columns: "all",
    queryLoadSheets: [],
  },
  penalty_weight: {
    name: "penalty_weight",
    sheetType: "setting",
    sheetKeys: ["penalty_weight"],
    columns: "all",
    queryLoadSheets: [],
  },
  catalyst_parameter: {
    name: "catalyst_parameter",
    sheetType: "master",
    sheetKeys: ["catalyst_parameter"],
    columns: "all",
    queryLoadSheets: [],
  },
  tank_master_123s: {
    name: "tank_master_123s",
    sheetType: "master",
    sheetKeys: ["tank_master_123s"],
    columns: "all",
    queryLoadSheets: [],
  },
  transport_master_123s: {
    name: "transport_master_123s",
    sheetType: "master",
    sheetKeys: ["transport_master_123s"],
    columns: "all",
    queryLoadSheets: [],
  },
  facilities_master_123s: {
    name: "facilities_master_123s",
    sheetType: "master",
    sheetKeys: ["facilities_master_123s"],
    columns: "all",
    queryLoadSheets: [],
  },
  item_master_123s: {
    name: "item_master_123s",
    sheetType: "edit_only",
    sheetKeys: ["item_master_123s"],
    columns: "all",
    queryLoadSheets: [],
  },
  item_category_master_123s: {
    name: "item_category_master_123s",
    sheetType: "edit_only",
    sheetKeys: ["item_category_master_123s"],
    columns: "all",
    queryLoadSheets: [],
  },
  productSheetWithout4sMetaData: {
    name: "productSheetWithout4sMetaData",
    sheetType: "edit_only",
    sheetKeys: ["productSheetWithout4sMetaData"],
    columns: "all",
    queryLoadSheets: [],
  },
  bheaIsVariable: {
    name: "bheaIsVariable",
    sheetType: "edit_only",
    sheetKeys: ["bheaIsVariable"],
    columns: "all",
    queryLoadSheets: [],
  },
  eoaIsVariable: {
    name: "eoaIsVariable",
    sheetType: "edit_only",
    sheetKeys: ["eoaIsVariable"],
    columns: "all",
    queryLoadSheets: [],
  },
  bheaTankIsVariable: {
    name: "bheaTankIsVariable",
    sheetType: "edit_only",
    sheetKeys: ["bheaTankIsVariable"],
    columns: "all",
    queryLoadSheets: [],
  },
  eoaTankIsVariable: {
    name: "eoaTankIsVariable",
    sheetType: "edit_only",
    sheetKeys: ["eoaTankIsVariable"],
    columns: "all",
    queryLoadSheets: [],
  },
  bhea: {
    name: "bhea",
    sheetType: "plan",
    sheetKeys: ["bhea"],
    columns: "all",
    queryLoadSheets: [],
  },
  eoa: {
    name: "eoa",
    sheetType: "plan",
    sheetKeys: ["eoa"],
    columns: "all",
    queryLoadSheets: [],
  },
  general: {
    name: "general",
    sheetType: "plan",
    sheetKeys: ["general"],
    columns: "all",
    queryLoadSheets: [],
  },
  bheaTank: {
    name: "bheaTank",
    sheetType: "plan",
    sheetKeys: ["bheaTank"],
    columns: "all",
    queryLoadSheets: [],
  },
  eoaTank: {
    name: "eoaTank",
    sheetType: "plan",
    sheetKeys: ["eoaTank"],
    columns: "all",
    queryLoadSheets: [],
  },
  production_123s_summary: {
    name: "production_123s_summary",
    sheetType: "edit_only",
    sheetKeys: ["production_123s_summary"],
    columns: "all",
    queryLoadSheets: [],
  },
  s4_master_1234s: {
    name: "s4_master_1234s",
    sheetType: "master",
    sheetKeys: ["s4_master_1234s"],
    columns: "all",
    queryLoadSheets: [],
  },
  internal_id_to_item_code: {
    name: "internal_id_to_item_code",
    sheetType: "edit_only",
    sheetKeys: ["internal_id_to_item_code"],
    columns: "all",
    queryLoadSheets: [],
  },
  spreadsheet_column_filter: {
    name: "spreadsheet_column_filter",
    sheetType: "edit_only",
    sheetKeys: ["spreadsheet_column_filter"],
    columns: "all",
    queryLoadSheets: [],
  },
  selected_date_range: {
    name: "selected_date_range",
    sheetType: "edit_only",
    sheetKeys: ["selected_date_range"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_basic_info: {
    name: "s2_basic_info",
    sheetType: "master",
    sheetKeys: ["s2_basic_info"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_product_master: {
    name: "s2_product_master",
    sheetType: "master",
    sheetKeys: ["s2_product_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_device_master: {
    name: "s2_device_master",
    sheetType: "master",
    sheetKeys: ["s2_device_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_device_group_master: {
    name: "s2_device_group_master",
    sheetType: "master",
    sheetKeys: ["s2_device_group_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_device_group_device_mapping_master: {
    name: "s2_device_group_device_mapping_master",
    sheetType: "master",
    sheetKeys: ["s2_device_group_device_mapping_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_batch_process_set_detail_process_master: {
    name: "s2_batch_process_set_detail_process_master",
    sheetType: "master",
    sheetKeys: ["s2_batch_process_set_detail_process_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_batch_process_set_master: {
    name: "s2_batch_process_set_master",
    sheetType: "master",
    sheetKeys: ["s2_batch_process_set_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_batch_production_plan: {
    name: "s2_batch_production_plan",
    sheetType: "plan",
    sheetKeys: ["s2_batch_production_plan"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_batch_process_plan: {
    name: "s2_batch_process_plan",
    sheetType: "plan",
    sheetKeys: ["s2_batch_process_plan"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_material_master: {
    name: "s2_material_master",
    sheetType: "master",
    sheetKeys: ["s2_material_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_batch_process_set_detail_process_material_consumption_master: {
    name: "s2_batch_process_set_detail_process_material_consumption_master",
    sheetType: "master",
    sheetKeys: [
      "s2_batch_process_set_detail_process_material_consumption_master",
    ],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_batch_process_set_detail_process_waste_liquid_master: {
    name: "s2_batch_process_set_detail_process_waste_liquid_master",
    sheetType: "master",
    sheetKeys: ["s2_batch_process_set_detail_process_waste_liquid_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_alert_master: {
    name: "s2_alert_master",
    sheetType: "master",
    sheetKeys: ["s2_alert_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_batch_count_mapping_master: {
    name: "s2_batch_count_mapping_master",
    sheetType: "master",
    sheetKeys: ["s2_batch_count_mapping_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_material_mapping_master: {
    name: "s2_material_mapping_master",
    sheetType: "master",
    sheetKeys: ["s2_material_mapping_master"],
    columns: "all",
    queryLoadSheets: [],
  },
  s2_waste_liquid_mapping_master: {
    name: "s2_waste_liquid_mapping_master",
    sheetType: "master",
    sheetKeys: ["s2_waste_liquid_mapping_master"],
    columns: "all",
    queryLoadSheets: [],
  },

  "2S東-24K417N在庫管理表": {
    name: "2S東-24K417N在庫管理表",
    sheetType: "multiple",
    sheetKeys: ["eoa", "eoaTank"],
    innerJoinKey: "date",
    columns: [
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "date",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "24k417nTotalProduction",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "shippingEast",
        displayName: "G-56出荷",
      },
      {
        type: "normal",
        sheetKey: "eoaTank",
        originalSheetColumn: "24K417N|tankAllAmount",
      },
    ],
    queryLoadSheets: [],
  },

  "2S東-BHEA在庫管理表": {
    name: "2S東-BHEA在庫管理表",
    sheetType: "multiple",
    sheetKeys: ["bhea", "bheaTank"],
    innerJoinKey: "date",
    columns: [
      {
        type: "normal",
        sheetKey: "bhea",
        originalSheetColumn: "date",
      },
      {
        type: "normal",
        sheetKey: "bhea",
        originalSheetColumn: "bheaHighPurityBatch",
      },
      {
        type: "normal",
        sheetKey: "bhea",
        originalSheetColumn: "bheaBatch",
      },
      {
        type: "normal",
        sheetKey: "bhea",
        originalSheetColumn: "bheaHighPurityProduction1",
      },
      {
        type: "normal",
        sheetKey: "bhea",
        originalSheetColumn: "bheaHighPurityProduction2",
      },
      {
        type: "normal",
        sheetKey: "bheaTank",
        originalSheetColumn: "D-11040A|acceptance",
      },
      {
        type: "normal",
        sheetKey: "bheaTank",
        originalSheetColumn: "D-11040A|shipment",
      },
      {
        type: "normal",
        sheetKey: "bheaTank",
        originalSheetColumn: "D-11040A|inventory",
      },
      {
        type: "normal",
        sheetKey: "bheaTank",
        originalSheetColumn: "D-11040B|acceptance",
      },
      {
        type: "normal",
        sheetKey: "bheaTank",
        originalSheetColumn: "D-11040B|shipment",
      },
      {
        type: "normal",
        sheetKey: "bheaTank",
        originalSheetColumn: "D-11040B|inventory",
      },
    ],
    queryLoadSheets: [],
  },

  "2S東-原料購買": {
    name: "2S東-原料購買",
    sheetType: "multiple",
    sheetKeys: ["eoa"],
    innerJoinKey: "date",
    columns: [
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "date",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "calcohol40Consumption",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "calcohol40Arrival",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "calcohol40Stock",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "calcohol20Consumption",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "calcohol20Arrival",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "calcohol20Stock",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "poConsumption",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "poArrival",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "poStock",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "kohConsumption",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "kohArrival",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "kohStock",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "lactateConsumption",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "lactateArrival",
      },
      {
        type: "normal",
        sheetKey: "eoa",
        originalSheetColumn: "lactateStock",
      },
    ],
    queryLoadSheets: [],
  },
};

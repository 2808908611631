import React from "react";

import { Sheet, useSetSheet } from "../../../../../domain/Sheet";
import {
  useGetSheetDataRows,
  useSheetDataRowUtils,
} from "../../../../../domain/SheetDataRow";
import {
  useGetSheetFieldSchemas,
  useSetSheetFieldSchema,
} from "../../../../../domain/SheetFieldSchema";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";
import { duplicateRecursiveSheetData } from "../helper";

import {
  createNewDisplayNameOrThrow,
  createNewSheetNameOrThrow,
} from "@/helpers/createNewName";
import { generateId } from "@/utils/generateId";

export const DialogSheetDuplicate: React.FC<
  {
    sheets: Sheet[];
    selectedSheetIds: string[];
    setSelectedSheetIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  sheets,
  selectedSheetIds,
  setSelectedSheetIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const setSheet = useSetSheet();
  const setSheetFieldSchema = useSetSheetFieldSchema();
  const sheetDataRowUtils = useSheetDataRowUtils();

  const getSheetDataRows = useGetSheetDataRows();
  const getSheetFieldSchemas = useGetSheetFieldSchemas();

  const { renderDialogs, setIsInProgress, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const newSheetId = generateId();
  const sheetId = selectedSheetIds[0];
  const duplicateSheet = sheets.find((c) => c.id === sheetId);

  const runDuplicate = async () => {
    try {
      if (!duplicateSheet) return;
      setDialogPhase("in_progress");

      const newDisplayName = createNewDisplayNameOrThrow(
        sheets.map((s) => s.displayName),
        duplicateSheet.displayName
      );
      const newName = createNewSheetNameOrThrow(
        sheets.map((s) => s.name),
        duplicateSheet.name
      );

      await duplicateRecursiveSheetData({
        duplicateSheet,
        getSheetDataRows,
        getSheetFieldSchemas,
        setSheetFieldSchema,
        setSheet,
        sheetDataRowUtils,
        displayName: newDisplayName,
        name: newName,
        sort: sheets.length + 1,
        newSheetId,
      });
      setSelectedSheetIds([]);
      setDialogPhase("success");
    } catch (error) {
      showErrorAPIDialog(error);
    }
  };
  const messages = getDialogMessages({
    isSome: false,
    actionType: "duplicate",
    targetType: "sheet",
  });
  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runDuplicate,
          onClickCancel: () => {
            setSelectedSheetIds([]);
          },
          ...messages.confirm,
        },
        inProgress: {
          ...messages.inProgress,
        },
        success: { ...messages.success },
        errorAPI: {
          ...messages.error,
        },
      })}
    </>
  );
};

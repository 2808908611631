import loadable from "@loadable/component";

import { skillEditorDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const skillEditorView: StrictViewType = {
  key: "ngrKoubanSkillEditor",
  displayName: "NGR交番:スキル編集画面",
  render: ViewRender as any,
  dependSheets: skillEditorDependSheets,
  configSchemas: [],
};

import { FileUploaderViewTypeDependSheet } from "./params";

const driverDependSheet: FileUploaderViewTypeDependSheet = {
  key: "driver",
  displayName: "乗務員",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "driverName",
      displayName: "氏名",
      type: "string",
    },
  ],
};

const rorationBasedAssignmentDependSheet: FileUploaderViewTypeDependSheet = {
  key: "rotationBasedAssignment",
  displayName: "予定勤務データ",
  dependSheetFields: [
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "version",
      displayName: "バージョン",
      type: "string",
    },
    {
      key: "daygroup",
      displayName: "運行区分",
      type: "string",
    },
    {
      key: "workCode",
      displayName: "勤務コード",
      type: "string",
    },
    {
      key: "workCodeSeq",
      displayName: "勤務コード枝番",
      type: "integer",
    },
    {
      key: "workBeginTime",
      displayName: "開始時間",
      type: "string",
    },
    {
      key: "workEndTime",
      displayName: "終了時間",
      type: "string",
    },
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
  ],
};

const workOvertimeHistoryDependSheet: FileUploaderViewTypeDependSheet = {
  key: "workOvertimeHistory",
  displayName: "時間外勤務実績",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "workOverTime",
      displayName: "残業時間",
      type: "string",
    },
  ],
};
const workHistoryPlanFullTimeDependSheet: FileUploaderViewTypeDependSheet = {
  key: "workHistoryPlanFullTime",
  displayName: "フルタイムの勤務履歴予定",
  dependSheetFields: [
    {
      key: "officeCode",
      displayName: "事業所コード",
      type: "string",
    },
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "workCode",
      displayName: "業務コード",
      type: "string",
    },
    {
      key: "workCodeSeq",
      displayName: "枝番",
      type: "string",
    },
    {
      key: "workBeginTime",
      displayName: "開始時間",
      type: "string",
    },
    {
      key: "workEndTime",
      displayName: "終了時間",
      type: "string",
    },
  ],
};

const workHistoryPlanPartTimeDependSheet: FileUploaderViewTypeDependSheet = {
  key: "workHistoryPlanPartTime",
  displayName: "契約乗務員の勤務履歴予定",
  dependSheetFields: [
    {
      key: "officeCode",
      displayName: "事業所コード",
      type: "string",
    },
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "workCode",
      displayName: "業務コード",
      type: "string",
    },
    {
      key: "workCodeSeq",
      displayName: "枝番",
      type: "integer",
    },
    {
      key: "workBeginTime",
      displayName: "開始時間",
      type: "string",
    },
    {
      key: "workEndTime",
      displayName: "終了時間",
      type: "string",
    },
  ],
};

const workPlanTodayDependSheet: FileUploaderViewTypeDependSheet = {
  key: "workPlanToday",
  displayName: "当日勤務予定",
  dependSheetFields: [
    {
      key: "officeCode",
      displayName: "事業所コード",
      type: "string",
    },
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "workCode",
      displayName: "業務コード",
      type: "string",
    },
    {
      key: "workCodeSeq",
      displayName: "枝番",
      type: "string",
    },
    {
      key: "workBeginTime",
      displayName: "開始時間",
      type: "string",
    },
    {
      key: "workEndTime",
      displayName: "終了時間",
      type: "string",
    },
    {
      key: "name",
      displayName: "氏名",
      type: "string",
    },
    {
      key: "category",
      displayName: "カテゴリ",
      type: "string",
    },
  ],
};

export const fileUploadDependSheets = [
  driverDependSheet,
  rorationBasedAssignmentDependSheet,
  workOvertimeHistoryDependSheet,
  workHistoryPlanFullTimeDependSheet,
  workHistoryPlanPartTimeDependSheet,
  workPlanTodayDependSheet,
];

import loadable from "@loadable/component";
import { allDependSheets } from "../../../ssui/types/fields";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeHkrShipTasksByContract: StrictViewType = {
  key: "hkrShipTasksByContract",
  displayName: "銘柄視点配船表",
  render: ViewRender as any,
  dependSheets: allDependSheets,
  configSchemas: [],
  isDisabledEditHistoryShortcuts: true,
};

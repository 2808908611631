import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKwsCSVView2sEid: StrictViewType = {
  key: "nshKwsCSVView2sEid",
  displayName: "NSH-KWS S4 CSV連携ビュー（2製EID用）",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "rawMaterialPurchase2sEid",
      displayName: "rawMaterialPurchase2sEid",
      isReadAll: true,
    },
    {
      key: "s4Master1234s",
      displayName: "s4Master1234s",
      dependSheetFields: [
        {
          key: "itemCode",
          displayName: "品目コード",
          type: "string",
        },
        {
          key: "itemText",
          displayName: "品目テキスト",
          type: "string",
        },
        {
          key: "stockArea",
          displayName: "保管場所",
          type: "string",
        },
        {
          key: "lotSize",
          displayName: "ロットサイズ",
          type: "integer",
        },
      ],
    },
  ],
  configSchemas: [],
};

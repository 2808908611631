import { isEqual } from "lodash";

import { SheetDataRow } from "./SheetDataRow";
import { SheetFieldSchema } from "./SheetFieldSchema";
import { TypeSchema } from "./TypeSchema";

import { parseIntegerFloat } from "@/components/AppSideNav/helpers/parser";

export const sheetScoreSummaryNames = [
  "name",
  "parent",
  "value",
  "value_type",
  "unit",
  "sort",
  "is_higher_rated",
] as const;

export type SheetScoreSummaryNames = (typeof sheetScoreSummaryNames)[number];

// 最適化スコアサマリの想定するfirestoreに登録されたスキーマのnameとtype
// value_typeはstring、もしくはenumの値を取る
const baseSheetScoreSummarySchemas = [
  { name: "name", type: "string" },
  { name: "parent", type: "string" },
  { name: "value", type: "string" },
  { name: "sort", type: "integer" },
  { name: "is_higher_rated", type: "boolean" },
  { name: "unit", type: "string" },
] as const;

// value_typeがstringで表現されている場合
export const sheetScoreSummarySchemasString = [
  ...baseSheetScoreSummarySchemas,
  { name: "value_type", type: "string" },
];

// value_typeがenumで表現されている場合
export const sheetScoreSummarySchemasEnum = [
  ...baseSheetScoreSummarySchemas,
  { name: "value_type", type: "enum" },
];

// firestoreからパースし、実際に描画する最適化サマリ型
export type SheetScoreSummaryItem = {
  name: string;
  parent: string;
  value: string;
  value_type:
    | Extract<
        TypeSchema["type"],
        "string" | "integer" | "float" | "boolean" | "enum"
      >
    | "null"; // データの型指定.nullの場合はネストする親になるだけ
  unit: string; //単位
  sort: number;
  is_higher_rated?: boolean;
};
export const initialSheetScoreSummaryItem: SheetScoreSummaryItem = {
  name: "",
  parent: "",
  value: "",
  value_type: "null",
  unit: "",
  sort: 0,
  is_higher_rated: undefined,
};

export const parseScoreValue = ({
  value,
  value_type,
}: Pick<SheetScoreSummaryItem, "value" | "value_type">): string => {
  if (value === null) {
    return "-";
  } else if (value_type === "string" || value_type === "enum") {
    return value ?? "-";
  } else if (value_type === "integer" || value_type === "float") {
    const parsedValue: number | null = parseIntegerFloat(value, value_type);
    return parsedValue !== null ? parsedValue.toLocaleString() : "-";
  } else if (value_type === "boolean") {
    return value === "true" ? "true" : "false";
  }
  return "-";
};

export const getSummary = (
  sheetDataRows: SheetDataRow[],
  sheetFieldSchemas: SheetFieldSchema[]
): SheetScoreSummaryItem[] => {
  if (sheetDataRows.length === 0 || sheetFieldSchemas.length === 0) return [];
  const nameAndTypes = sheetFieldSchemas
    .map(({ name, type }) => ({
      name,
      type,
    }))
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  // 想定しているSheetFieldSchemaと異なればnull
  // NOTE: sortして順序一致
  if (
    !isEqual(
      nameAndTypes,
      [...sheetScoreSummarySchemasString].sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
    ) &&
    !isEqual(
      nameAndTypes,
      [...sheetScoreSummarySchemasEnum].sort((a, b) => {
        return a.name.localeCompare(b.name);
      })
    )
  ) {
    return [];
  }
  const sheetScoreSummaryItems = sheetDataRows
    .map(({ sheetDataCells }) => {
      // stringで保存されているFirestore上のシートデータを、SheetFieldSchemaで定義された型にparse
      const objList = Object.entries({
        ...sheetDataCells,
      })
        .map(([sheetFieldId, value]) => {
          const schema = sheetFieldSchemas.find(
            ({ id }) => id === sheetFieldId
          )!;
          if (!schema) return undefined;
          const { name, type } = schema;
          // NOTE: string | integer | float | boolean | enum以外の型は想定しないのでundefinedとする
          if (type === "integer" || type === "float") {
            const parsedNumber = parseIntegerFloat(String(value), "integer");
            if (parsedNumber === null) {
              return undefined;
            }
            return [name, parsedNumber];
          } else if (type === "string" || type === "enum") {
            return [name, value];
          } else if (type === "boolean") {
            return [name, value.toLowerCase() === "true" ? true : false];
          }
          return undefined;
        })
        .filter((c): c is NonNullable<typeof c> => c !== undefined);
      if (objList.length === 0) return undefined;
      const item = Object.fromEntries(objList) as SheetScoreSummaryItem;
      // NOTE: sheetDataCellsには、スキーマの値が未定義の可能性がある。そのため、その値を補完する必要がある
      return {
        ...initialSheetScoreSummaryItem,
        ...item,
      } as SheetScoreSummaryItem;
    })
    .filter((c): c is NonNullable<typeof c> => c !== undefined)
    .sort((a, b) => Math.sign(a.sort - b.sort));
  return sheetScoreSummaryItems;
};

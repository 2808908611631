import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

import {
  VersionCategoryRaw,
  VersionCategoryRawForm,
} from "@/domain/VersionCategories";

const adminAPIURLVersionCategories = "/admin/version_categories";

// 組織IDを指定してバージョンカテゴリ一覧を取得
export const getAdminVersionCategories = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<VersionCategoryRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: VersionCategoryRaw[] }>(
    `${adminAPIURLVersionCategories}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.items;
};

// 個別のバージョンカテゴリを取得
export const getAdminVersionCategory = async ({
  versionCategoryId,
  organizationId,
}: {
  versionCategoryId: VersionCategoryRaw["id"];
  organizationId: string;
}): Promise<VersionCategoryRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ item: VersionCategoryRaw }>(
    `${adminAPIURLVersionCategories}/${versionCategoryId}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// バージョンカテゴリを作成
export const postAdminCreateVersionCategory = async ({
  versionCategoryRawForm,
  organizationId,
}: {
  versionCategoryRawForm: VersionCategoryRawForm;
  organizationId: string;
}): Promise<VersionCategoryRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: VersionCategoryRaw }>(
    `${adminAPIURLVersionCategories}`,
    versionCategoryRawForm,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// バージョンカテゴリを更新
export const postAdminEditVersionCategory = async ({
  versionCategoryId,
  versionCategoryRawForm,
  organizationId,
}: {
  versionCategoryId: string;
  versionCategoryRawForm: VersionCategoryRawForm;
  organizationId: string;
}): Promise<VersionCategoryRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: VersionCategoryRaw }>(
    `${adminAPIURLVersionCategories}/${versionCategoryId}`,
    versionCategoryRawForm,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// バージョンカテゴリを論理削除
export const postAdminDisableVersionCategory = async ({
  versionCategoryId,
  organizationId,
}: {
  versionCategoryId: string;
  organizationId: string;
}): Promise<VersionCategoryRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: VersionCategoryRaw }>(
    `${adminAPIURLVersionCategories}/${versionCategoryId}/delete`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// 組織IDを指定してバージョンカテゴリを有効化
export const postAdminEnableVersionCategory = async ({
  versionCategoryId,
  organizationId,
}: {
  versionCategoryId: string;
  organizationId: string;
}): Promise<VersionCategoryRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: VersionCategoryRaw }>(
    `${adminAPIURLVersionCategories}/${versionCategoryId}/enable`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// バージョンカテゴリを物理削除
export const postAdminRemoveVersionCategory = async ({
  versionCategoryId,
  organizationId,
}: {
  versionCategoryId: string;
  organizationId: string;
}) => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  await API_CLIENT.post<{ item: VersionCategoryRaw }>(
    `${adminAPIURLVersionCategories}/${versionCategoryId}/remove`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
};

import { DefaultButton, DefaultPalette, PrimaryButton } from "@fluentui/react";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useNavigate } from "react-router";

import { useAppRouteParams } from "../../../../AppRoutes";
import {
  Version,
  checkIsPublishedByFileUpload,
} from "../../../../domain/Version";
import { themePrimaryRed } from "../../../../themes";
import { DialogPhaseType } from "../../helpers/hooks";
import { ReferredFromState } from "../helper";

import { DialogVersionDelete } from "./DialogVersionDelete";
import { DialogVersionDeleteSome } from "./DialogVersionDeleteSome";
import { DialogVersionDuplicate } from "./DialogVersionDuplicate";
import { DialogVersionDuplicateSome } from "./DialogVersionDuplicateSome";
import { DialogVersionEnable } from "./DialogVersionEnable";
import { DialogVersionEnableSome } from "./DialogVersionEnableSome";
import { DialogVersionRemove } from "./DialogVersionRemove";
import { DialogVersionRemoveSome } from "./DialogVersionRemoveSome";

import { useAppNotificationManager } from "@/components/AppProvider/AppNotificationProvider";
import { useAppOrgsAndInvitedOrgs } from "@/components/AppProvider/AppOrgsAndInvitedOrgsProvider";
import { ContextMenuItem } from "@/components/GridTable/helpers";
import { usePermissions } from "@/components/InOrganizationProvider/PermissionsProvider";
import { useVersions } from "@/components/InOrganizationProvider/VersionsProvider";
import { useGetReferredFroms } from "@/domain/ReferredFrom";
import { Sheet, useGetSheets } from "@/domain/Sheet";

/**
 * @description 複数の場所で利用されるcontextItemsとダイアログを生成
 */
export const useDialogContextMenuItemsVersion = () => {
  const { organizations } = useAppOrgsAndInvitedOrgs();
  const { organizationId } = useAppRouteParams();
  const organization = organizations.find(
    (org) => org.org_id === organizationId
  );
  const [selectedVersionIds, setSelectedVersionIds] = useState([] as string[]);
  const { hasVersionActionPermission, hasPermission } = usePermissions();
  const hasVersionsWritePermission = hasPermission("versions", "write");
  const { versions } = useVersions();
  const navigate = useNavigate();
  const { showErrorNotification } = useAppNotificationManager();
  const getSheets = useGetSheets();
  const getReferredFroms = useGetReferredFroms();
  const [isIncludingReferencedSheets, setIsIncludingReferencedSheets] =
    useState<ReferredFromState>("waiting");

  // 選択バージョンが被参照シートを含んでいるかどうかをチェック
  useEffect(() => {
    if (selectedVersionIds.length === 0) {
      setIsIncludingReferencedSheets("waiting");
      return;
    }
    (async () => {
      try {
        const versionSheetsMap = new Map<string, Sheet[]>();
        for (const versionId of selectedVersionIds) {
          const sheets = await getSheets({ versionId });
          versionSheetsMap.set(versionId, sheets);
        }
        const referredFroms = await Promise.all(
          Array.from(versionSheetsMap.entries()).map(
            async ([versionId, sheets]) =>
              await Promise.all(
                sheets.map((sheet) =>
                  getReferredFroms({ versionId, sheetId: sheet.id })
                )
              )
          )
        );
        if (referredFroms.flat().some((r) => r.length > 0)) {
          setIsIncludingReferencedSheets("including");
        } else {
          setIsIncludingReferencedSheets("notIncluding");
        }
      } catch (error) {
        showErrorNotification("シート参照のチェックに失敗しました", error);
        setIsIncludingReferencedSheets("notIncluding");
      }
    })();

    return () => setIsIncludingReferencedSheets("waiting");
  }, [selectedVersionIds]);

  const [deleteDialogPhaseType, setDeleteDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [enableDialogPhaseType, setEnableDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [duplicateDialogPhaseType, setDuplicateDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [removeDialogPhaseType, setRemoveDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [deleteSomeDialogPhaseType, setDeleteSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [enableSomeDialogPhaseType, setEnableSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [duplicateSomeDialogPhaseType, setDuplicateSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [removeSomeDialogPhaseType, setRemoveSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);

  const getContextMenuItemsVersion = useCallback(
    (version: Version): ContextMenuItem[] => {
      const hasVersionWritePermission = hasVersionActionPermission(
        version,
        "write"
      );
      return [
        {
          key: "k0",
          text: "バージョン情報を確認・編集",
          // iconProps: { iconName: "DuplicateRow" },
          onClick: () => {
            navigate(
              `/organizations/${organizationId}/versions/${version.id}/version_info`
            );
          },
          disabled: false,
        },
        ...(organization?.is_sheet_enabled
          ? [
              {
                key: "k1",
                text: "バージョンを複製",
                // iconProps: { iconName: "DuplicateRow" },
                onClick: () => {
                  setSelectedVersionIds([version.id]);
                  setDuplicateDialogPhaseType("confirm");
                },
                disabled:
                  !hasVersionsWritePermission ||
                  checkIsPublishedByFileUpload(version),
              },
            ]
          : []),
        {
          key: "k2",
          text: "バージョンを削除",
          onClick: () => {
            setSelectedVersionIds([version.id]);
            setDeleteDialogPhaseType("confirm");
          },
          disabled: !hasVersionWritePermission,
          style: {
            color: hasVersionWritePermission ? DefaultPalette.red : "",
          },
        },
      ];
    },
    [hasVersionsWritePermission, hasVersionActionPermission, organization]
  );

  // 論理削除されたバージョンだけ利用可能
  const getContextMenuItemsDisableVersion = useCallback(
    (version: Version): ContextMenuItem[] => {
      const hasVersionWritePermission = hasVersionActionPermission(
        version,
        "write"
      );
      const hasVersionDeletePermission = hasVersionActionPermission(
        version,
        "delete"
      );
      return [
        {
          key: "k1",
          text: "復元",
          onClick: () => {
            setSelectedVersionIds([version.id]);
            setEnableDialogPhaseType("confirm");
          },
          disabled: !hasVersionWritePermission,
        },
        {
          key: "k2",
          text: "完全削除",
          onClick: () => {
            setSelectedVersionIds([version.id]);
            setRemoveDialogPhaseType("confirm");
          },
          disabled: !hasVersionDeletePermission,
          style: {
            color: hasVersionDeletePermission ? DefaultPalette.red : "",
          },
        },
      ];
    },
    [hasVersionActionPermission]
  );

  const isDialogReady = isIncludingReferencedSheets !== "waiting";

  const renderDialogsVersion = useCallback(
    (versions: Version[]) => {
      return (
        <>
          <DialogVersionDuplicate
            versions={versions}
            selectedVersionIds={selectedVersionIds}
            setSelectedVersionIds={setSelectedVersionIds}
            dialogPhase={duplicateDialogPhaseType}
            setDialogPhase={setDuplicateDialogPhaseType}
          />
          {/* メッセージちらつきをおさえるための処理 */}
          {isDialogReady && (
            <DialogVersionDelete
              versions={versions}
              selectedVersionIds={selectedVersionIds}
              setSelectedVersionIds={setSelectedVersionIds}
              isIncludingReferencedSheets={isIncludingReferencedSheets}
              dialogPhase={deleteDialogPhaseType}
              setDialogPhase={setDeleteDialogPhaseType}
            />
          )}
          <DialogVersionEnable
            versions={versions}
            selectedVersionIds={selectedVersionIds}
            setSelectedVersionIds={setSelectedVersionIds}
            dialogPhase={enableDialogPhaseType}
            setDialogPhase={setEnableDialogPhaseType}
          />
          <DialogVersionRemove
            versions={versions}
            setSelectedVersionIds={setSelectedVersionIds}
            selectedVersionIds={selectedVersionIds}
            dialogPhase={removeDialogPhaseType}
            setDialogPhase={setRemoveDialogPhaseType}
          />
          <DialogVersionDuplicateSome
            versions={versions}
            selectedVersionIds={selectedVersionIds}
            setSelectedVersionIds={setSelectedVersionIds}
            dialogPhase={duplicateSomeDialogPhaseType}
            setDialogPhase={setDuplicateSomeDialogPhaseType}
          />
          {/* メッセージちらつきをおさえるための処理 */}
          {isDialogReady && (
            <DialogVersionDeleteSome
              versions={versions}
              selectedVersionIds={selectedVersionIds}
              setSelectedVersionIds={setSelectedVersionIds}
              isIncludingReferencedSheets={isIncludingReferencedSheets}
              dialogPhase={deleteSomeDialogPhaseType}
              setDialogPhase={setDeleteSomeDialogPhaseType}
            />
          )}
          <DialogVersionEnableSome
            versions={versions}
            selectedVersionIds={selectedVersionIds}
            setSelectedVersionIds={setSelectedVersionIds}
            dialogPhase={enableSomeDialogPhaseType}
            setDialogPhase={setEnableSomeDialogPhaseType}
          />
          <DialogVersionRemoveSome
            versions={versions}
            setSelectedVersionIds={setSelectedVersionIds}
            selectedVersionIds={selectedVersionIds}
            dialogPhase={removeSomeDialogPhaseType}
            setDialogPhase={setRemoveSomeDialogPhaseType}
          />
        </>
      );
    },
    [
      selectedVersionIds,
      deleteDialogPhaseType,
      removeDialogPhaseType,
      duplicateDialogPhaseType,
      enableDialogPhaseType,
      deleteSomeDialogPhaseType,
      removeSomeDialogPhaseType,
      duplicateSomeDialogPhaseType,
      enableSomeDialogPhaseType,
      isIncludingReferencedSheets,
    ]
  );

  // 複数バージョン操作
  const isDuplicateableVersionIds = useMemo(
    () =>
      selectedVersionIds.filter((id) => {
        const v = versions.find(({ id: vid }) => vid === id);
        // NOTE: ファイル最適化で作成されたバージョンは複製不可能
        return v ? !checkIsPublishedByFileUpload(v) : false;
      }),
    [versions, selectedVersionIds]
  );
  // 選択バージョンの全てに対しwrite権限がなければ削除不可
  const isDisableDeleteVersions =
    selectedVersionIds.length === 0 ||
    !selectedVersionIds.every((versionId) =>
      hasVersionActionPermission(
        versions.find(({ id }) => id === versionId) ?? null,
        "write"
      )
    );
  // 選択バージョンの全てに対しdelete権限がなければ完全削除不可
  const isDisablRemoveVersions =
    selectedVersionIds.length === 0 ||
    !selectedVersionIds.every((versionId) =>
      hasVersionActionPermission(
        versions.find(({ id }) => id === versionId) ?? null,
        "delete"
      )
    );
  // versions:writeがなければ複製不可
  const isDisableDuplicateVersions =
    !hasVersionsWritePermission ||
    organization?.is_sheet_enabled !== true ||
    isDuplicateableVersionIds.length === 0;

  const renderDuplicateVersionsButton = useCallback(
    () => (
      <DefaultButton
        text="複製"
        onClick={() => {
          setDuplicateSomeDialogPhaseType("confirm");
        }}
        disabled={isDisableDuplicateVersions}
      />
    ),
    [isDisableDuplicateVersions]
  );
  const renderDeleteVersionsButton = useCallback(
    () => (
      <PrimaryButton
        text="削除"
        onClick={() => {
          setDeleteSomeDialogPhaseType("confirm");
        }}
        theme={themePrimaryRed}
        disabled={isDisableDeleteVersions}
      />
    ),
    [selectedVersionIds, isDisableDeleteVersions]
  );
  const renderEnableVersionsButton = useCallback(
    () => (
      <DefaultButton
        text="復元"
        onClick={() => {
          setEnableSomeDialogPhaseType("confirm");
        }}
        disabled={isDisableDeleteVersions}
      />
    ),
    [isDisableDeleteVersions]
  );
  const renderRemoveVersionsButton = useCallback(
    () => (
      <PrimaryButton
        text="完全削除"
        onClick={() => {
          setRemoveSomeDialogPhaseType("confirm");
        }}
        theme={themePrimaryRed}
        disabled={isDisablRemoveVersions}
      />
    ),
    [isDisablRemoveVersions]
  );

  return {
    renderDialogsVersion,
    getContextMenuItemsVersion,
    getContextMenuItemsDisableVersion,
    selectedVersionIds,
    setSelectedVersionIds,
    renderDuplicateVersionsButton,
    renderDeleteVersionsButton,
    renderEnableVersionsButton,
    renderRemoveVersionsButton,
  };
};

import { useMemo } from "react";

import { MemberRaw } from "../../../domain/Member";
import { Version } from "../../../domain/Version";
import { useOrganizationMembers } from "../MembersProvider";

import { OtherSessionData } from "./types";

import { AppSession, useOrganizationSession } from ".";

const prefixList = ["views__", "sheets__", "versions__"] as const;
type Prefix = (typeof prefixList)[number];

const getAccessIdFromKeys = (prefix: Prefix, keys: string[]) => {
  return [...keys]
    .filter((v) => v.indexOf(prefix) !== -1)
    .map((v) => v.replace(prefix, ""))[0];
};

/**
 * @description 組織内、同じバージョン内で、同じsheet,viewにアクセスしているユーザのUID一覧を取得する
 * members:readがない場合は空配列
 */
export const useAccessUsersInVersion = ({
  prefix,
  targetVersionId,
  targetDataId,
}: {
  prefix: Prefix;
  targetVersionId: string;
  targetDataId: string;
}): MemberRaw[] => {
  const { sessions } = useOrganizationSession();
  const { members } = useOrganizationMembers();

  const targetSessions = sessions.filter((session) => {
    if (!session.data) return;
    const keys = Object.keys(session.data);
    const accessDataId = getAccessIdFromKeys(prefix, keys);
    const accessVersionId = getAccessIdFromKeys(prefixList.slice(-1)[0], keys);
    return accessDataId === targetDataId && targetVersionId === accessVersionId;
  });
  const accessUserUIDs = targetSessions
    .filter(({ user }) => user?.uid)
    .map(({ user: { uid } }) => uid);
  const membersMemo = members
    ?.map(({ uid }) => uid)
    .sort()
    .join("__");
  const accessUserUIDsMemo = accessUserUIDs
    .map((uid) => uid)
    .sort()
    .join("__");
  return useMemo(() => {
    return accessUserUIDs
      .map((targetUID) => members?.find(({ uid }) => uid === targetUID))
      .filter((v): v is MemberRaw => v !== undefined);
  }, [accessUserUIDsMemo, membersMemo]);
};

/**
 * @description 同じ組織、同じversion内で、指定したsheet,viewにアクセスしているユーザ情報を取得する.
 * @alias T view,sheetなどでsessionに記録するデータ型
 */
export const useOtherSessionsData = <T extends { [key: string]: any }>(
  sessions: AppSession[],
  sessionKey: string,
  sessionId: string,
  versionId: Version["id"]
): OtherSessionData<T>[] => {
  const otherSessionsData = useMemo(
    () =>
      sessions
        .filter((session) => {
          if (!session.data) return;
          const keys = Object.keys(session.data);
          const accessVersionId = getAccessIdFromKeys(
            prefixList.slice(-1)[0],
            keys
          );
          return (
            session.data?.[sessionKey] &&
            session.id !== sessionId &&
            versionId === accessVersionId
          );
        })
        .map(({ id, data, user }) => {
          return {
            id,
            data: JSON.parse(data[sessionKey]) as T,
            user,
          };
        }),
    [sessions, sessionId, sessionKey]
  );
  return otherSessionsData;
};

export const accountUserKindList = [
  "personal:free",
  "personal:pro",
  "personal:admin",
  "client:admin",
  "client:user",
  "aa:system-admin",
  "aa:admin",
  "aa:developer",
  "aa:user",
  "archived",
] as const;

export type AccountRaw = {
  uid: string;
  email: string;
  display_name: string;
  email_verified: boolean;
  use_mfa: boolean | null;
  identity_provider: string;
  latest_logined_at: string;
  logins_count: number;
  user_kind: (typeof accountUserKindList)[number];
  default_org_id: string;
  max_num_owned_orgs: number | null;
  num_owned_orgs: number;
  picture_type: "color" | "image";
  picture_color: string;
  picture_image_url: string;
  is_setup_finished: string;
  is_disabled: boolean;
  created_at: string;
  updated_at: string;
};
export type AccountResponseRaw = Pick<
  AccountRaw,
  | "uid"
  | "display_name"
  | "email"
  | "picture_color"
  | "picture_image_url"
  | "picture_type"
>;

export const getIsAAMemberOrAbove = (account: AccountRaw | null) => {
  if (!account) return false;
  const { user_kind } = account;
  return (
    user_kind === "aa:system-admin" ||
    user_kind === "aa:admin" ||
    user_kind === "aa:user" ||
    user_kind === "aa:developer"
  );
};

export const getIsAAAdminOrAbove = (account: AccountRaw | null) => {
  if (!account) return false;
  const { user_kind } = account;
  return user_kind === "aa:system-admin" || user_kind === "aa:admin";
};
export const getIsAADeveloperOrAbove = (account: AccountRaw | null) => {
  if (!account) return false;
  const { user_kind } = account;
  return (
    user_kind === "aa:developer" ||
    user_kind === "aa:system-admin" ||
    user_kind === "aa:admin"
  );
};

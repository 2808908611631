import { loadEncodingJapanese, loadJSZip } from "./extLoader";

export const escapeCSVValue = (val: string) => {
  // , または " を含む場合、文字列をダブルクォーテーションで囲む & " を "" に置換
  // https://tex2e.github.io/rfc-translater/html/rfc4180.html#2--Definition-of-the-CSV-Format
  if (val.match(/[,"]/)) {
    return `"${val.replace(/"/g, '""')}"`;
  }
  return val;
};

const executeDownload = (fileName: string, blobURL: string) => {
  const dlAnchor = document.createElement("a");
  document.body.appendChild(dlAnchor);
  dlAnchor.style.display = "none";
  dlAnchor.href = blobURL;
  dlAnchor.download = fileName;
  dlAnchor.click();
  window.URL.revokeObjectURL(blobURL);
  document.body.removeChild(dlAnchor);
};

export const downloadCSV = async (filename: string, csv: string) => {
  const Encoding = await loadEncodingJapanese();
  const data = Uint8Array.from(
    Encoding.convert(Encoding.stringToCode(csv), "SJIS", "UNICODE")
  );
  const blobURL = window.URL.createObjectURL(new Blob([data]));
  executeDownload(filename, blobURL);
};

/**
 * @description オブジェクト配列をCSVファイルとしてDLする
 */
export async function downloadAsCSV(
  filename: string,
  header: { [key: string]: string },
  rows: { [key: string]: string }[]
): Promise<void> {
  const Encoding = await loadEncodingJapanese();
  const keys = Object.keys(header);
  const csvText = [
    Object.values(header).map(escapeCSVValue),
    ...rows.map((c) => keys.map((k) => escapeCSVValue(c[k]))),
  ]
    .map((c) => c.join(","))
    .join("\r\n");

  const data = Uint8Array.from(
    Encoding.convert(Encoding.stringToCode(csvText), "SJIS", "UNICODE")
  );
  const blobURL = window.URL.createObjectURL(
    new Blob([data], { type: "text/csv" })
  );
  executeDownload(filename, blobURL);
}

/**
 * @description 複数のCSVファイルをzipにまとめてDLする
 * 配列処理のため、型で縛らず、内部でkeyを判定する
 */
export async function downloadSomeCSVAsZip(
  data: {
    filename: string;
    header: { [K in string]: string };
    rows: { [key: string]: string }[];
  }[]
): Promise<void> {
  const Encoding = await loadEncodingJapanese();
  const JSZip = await loadJSZip();
  const zip = new JSZip();

  for (const { filename, header, rows } of data) {
    if (rows.length === 0) continue;

    const keys = Object.keys(header);
    const rowKeys = Object.keys(rows[0]);

    if (
      keys.length !== rowKeys.length ||
      !keys.every((key) => rowKeys.includes(key))
    ) {
      throw new Error("Keys in header and rows do not match.");
    }

    const csvText = [
      Object.values(header).map(escapeCSVValue),
      ...rows.map((c) => keys.map((k) => escapeCSVValue(c[k]))),
    ]
      .map((c) => c.join(","))
      .join("\r\n");

    const csvData = Encoding.convert(
      Encoding.stringToCode(csvText),
      "SJIS",
      "UNICODE"
    );

    zip.file(filename, csvData);
  }
  const blob = await zip.generateAsync({ type: "blob" });
  const blobURL = window.URL.createObjectURL(blob);
  executeDownload("csv_data.zip", blobURL);
}

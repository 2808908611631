import { useAppRouteParams } from "@/AppRoutes";
import { getFrontendAddons } from "@/api/frontendAddons";
import { LoadingCover } from "@/components/LoadingCover";
import { ViewType } from "@/domain/ViewType";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMatch, useNavigate, useRoutes } from "react-router";
import {
  SpreadsheetUIFrontendAddonManagerContextProvider,
  useSpreadsheetUIFrontendAddonManager,
} from "./adapter";

const FRONTEND_ADDON_BUCKET_ID = (() => {
  switch (process.env.APP_ENV) {
    case "staging":
      return "aa-sheets-ui-stg-gcp-frontend-addon";
    case "production":
      return "aa-sheets-ui-prod-gcp-frontend-addon";
    default:
      return "aa-sandbox-sys-ram-gcp-frontend-addon";
    // return "aa-sheets-ui-dev-gcp-frontend-addon";
  }
})();

const getFrontendAddonBaseUrl = (pathPrefix: string) =>
  `https://storage.googleapis.com/${FRONTEND_ADDON_BUCKET_ID}/${pathPrefix}`;

const loadAddonQueue: {
  [key: string]: {
    pathPrefix: string;
    job: Promise<void>;
  };
} = {};
const loadAddon = ({ id, pathPrefix }: { id: string; pathPrefix: string }) => {
  if (loadAddonQueue[id] && loadAddonQueue[id].pathPrefix === pathPrefix) {
    return loadAddonQueue[id].job;
  }
  const job = new Promise<void>((resolve) => {
    const script = document.createElement("script");
    script.addEventListener("load", () => {
      document.head.removeChild(script);
      resolve();
    });
    script.async = true;
    script.src = getFrontendAddonBaseUrl(pathPrefix) + "/index.js";
    document.head.appendChild(script);
  });
  loadAddonQueue[id] = {
    pathPrefix,
    job,
  };
  return job;
};

const ctx = createContext<{
  addons: ReturnType<typeof useSpreadsheetUIFrontendAddonManager>["addons"];
  viewTypes: ViewType<any, any>[];
}>({
  addons: {},
  viewTypes: [],
});

const AppFrontendAddonProviderBase: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const organizationId =
    useMatch("/organizations/:organizationId/*")?.params.organizationId ?? null;
  const [[activeAddonIds, addonInfoLoaded], setActiveAddonIdsState] = useState<
    [string[], boolean]
  >([[], false]);
  useEffect(() => {
    let _setActiveAddonIdsState = setActiveAddonIdsState;
    if (organizationId) {
      (async () => {
        const addons = await getFrontendAddons({ organizationId });
        _setActiveAddonIdsState([addons.map((addon) => addon.id), true]);
        await Promise.all(addons.map((addon) => loadAddon(addon)));
      })();
    } else {
      _setActiveAddonIdsState([[], true]);
    }
    return () => {
      _setActiveAddonIdsState([[], false]);
      _setActiveAddonIdsState = () => {
        // nop
      };
    };
  }, [organizationId]);
  const loadedAddons = useSpreadsheetUIFrontendAddonManager().addons;
  const loadedActiveAddons = useMemo(
    () =>
      Object.values(loadedAddons).filter((addon) =>
        activeAddonIds.includes(addon.id)
      ),
    [loadedAddons, activeAddonIds]
  );
  const isAllActiveAddonsLoaded =
    loadedActiveAddons.length === activeAddonIds.length;

  const ctxValue = useMemo(
    () => ({
      addons: loadedAddons,
      viewTypes: loadedActiveAddons.reduce(
        (acc, addon) => [...acc, ...addon.viewTypes],
        [] as ViewType<any, any>[]
      ),
    }),
    [loadedAddons, loadedActiveAddons]
  );

  return isAllActiveAddonsLoaded && addonInfoLoaded ? (
    <ctx.Provider value={ctxValue}>{children}</ctx.Provider>
  ) : (
    <div style={{ height: "100vh" }}>
      <LoadingCover />
    </div>
  );
};

export const AppFrontendAddonProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <SpreadsheetUIFrontendAddonManagerContextProvider>
      <AppFrontendAddonProviderBase>{children}</AppFrontendAddonProviderBase>
    </SpreadsheetUIFrontendAddonManagerContextProvider>
  );
};

export const useActiveFrontendAddons = () => useContext(ctx);

import { BatchGanttViewTypeDependSheet } from "./params";
/**
 * ViewType定義時引数
 */
const batchGanttAfterBatchTaskPlanDependSheet: BatchGanttViewTypeDependSheet = {
  key: "afterBatchTaskPlan",
  displayName: "連続タスク計画",
  dependSheetFields: [
    {
      key: "batchTaskId",
      displayName: "バッチタスクID",
      type: "string",
    },
    {
      key: "afterBatchTaskId",
      displayName: "連続工程ID",
      type: "string",
    },
  ],
};

const batchGanttAlertDependSheet: BatchGanttViewTypeDependSheet = {
  key: "alert",
  displayName: "アラート詳細",
  dependSheetFields: [
    {
      key: "id",
      displayName: "アラート詳細ID",
      type: "string",
    },
    {
      key: "startDateTime",
      displayName: "開始時刻",
      type: "date-time",
    },
    {
      key: "endDateTime",
      displayName: "終了時刻",
      type: "date-time",
    },
    {
      key: "category",
      displayName: "アラートカテゴリ",
      type: "string",
    },
    {
      key: "message",
      displayName: "詳細",
      type: "string",
    },
    {
      key: "level",
      displayName: "アラートレベル",
      type: "string",
    },
    {
      key: "viewInfo",
      displayName: "アラート情報",
      type: "string",
    },
    {
      key: "simpleMessage",
      displayName: "概要",
      type: "string",
    },
  ],
};
const batchGanttBatchTaskMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "batchTaskMaster",
  displayName: "バッチタスクマスタ",
  dependSheetFields: [
    {
      key: "id",
      displayName: "id",
      type: "string",
    },
    {
      key: "batchTaskName",
      displayName: "バッチタスク名",
      type: "string",
    },
    {
      key: "deviceName",
      displayName: "使用装置",
      type: "string",
    },
    {
      key: "mainItemName",
      displayName: "主品目",
      type: "string",
    },
    {
      key: "quantityPerBatch",
      displayName: "1バッチにおける生成量",
      type: "float",
    },
    {
      key: "bomVersion",
      displayName: "BOMのバージョン指定",
      type: "integer",
    },
    {
      key: "preProcessMinutes",
      displayName: "前処理時間",
      type: "float",
    },
    {
      key: "postProcessMinutes",
      displayName: "後処理時間",
      type: "float",
    },
    {
      key: "taskDeviceType",
      displayName: "task_device_type",
      type: "string",
    },
  ],
};

const batchGanttBatchTaskPlanDependSheet: BatchGanttViewTypeDependSheet = {
  key: "batchTaskPlan",
  displayName: "バッチタスク計画",
  dependSheetFields: [
    {
      key: "batchTaskId",
      displayName: "バッチタスクID",
      type: "string",
    },
    {
      key: "batchTaskName",
      displayName: "バッチタスク名",
      type: "string",
    },
    {
      key: "startDateTime",
      displayName: "開始時刻",
      type: "date-time",
    },
    {
      key: "endDateTime",
      displayName: "終了時刻",
      type: "date-time",
    },
    {
      key: "batchCount",
      displayName: "バッチ数",
      type: "integer",
    },
    {
      key: "quantity",
      displayName: "生成量",
      type: "float",
    },
  ],
};

const batchGanttBatchTaskProcessTimeMasterDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "batchTaskProcessTimeMaster",
    displayName: "バッチタスク所要時間マスタ",
    dependSheetFields: [
      {
        key: "batchTaskName",
        displayName: "バッチタスク名",
        type: "string",
      },
      {
        key: "batchCount",
        displayName: "バッチ数",
        type: "integer",
      },
      {
        key: "processMinutes",
        displayName: "所要時間[min]",
        type: "float",
      },
    ],
  };

const batchGanttBomByproductItemMasterDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "bomByproductItemMaster",
    displayName: "BOM副産物マスタ",
    dependSheetFields: [
      {
        key: "itemName",
        displayName: "品目名",
        type: "string",
      },
      {
        key: "version",
        displayName: "バージョン",
        type: "integer",
      },
      {
        key: "byproductItemName",
        displayName: "副産物名",
        type: "string",
      },
      {
        key: "quantity",
        displayName: "生成量",
        type: "float",
      },
    ],
  };

const batchGanttBomMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "bomMaster",
  displayName: "BOM",
  dependSheetFields: [
    {
      key: "itemName",
      displayName: "主品目",
      type: "string",
    },
    {
      key: "version",
      displayName: "バージョン",
      type: "integer",
    },
    {
      key: "quantity",
      displayName: "生成量",
      type: "float",
    },
  ],
};

const batchGanttDeviceMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "deviceMaster",
  displayName: "装置マスタ",
  dependSheetFields: [
    {
      key: "id",
      displayName: "id",
      type: "string",
    },
    {
      key: "deviceName",
      displayName: "装置名",
      type: "string",
    },
    {
      key: "taskDeviceType",
      displayName: "タスク装置タイプ",
      type: "string",
    },
  ],
};

const batchGanttDeviceMaintenanceInputDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "deviceMaintenanceInput",
    displayName: "装置メンテナンス計画",
    dependSheetFields: [
      {
        key: "deviceName",
        displayName: "装置名",
        type: "string",
      },
      {
        key: "startDateTime",
        displayName: "メンテナンス開始時刻",
        type: "date",
      },
      {
        key: "endDateTime",
        displayName: "メンテナンス終了時刻",
        type: "date",
      },
    ],
  };

const batchGanttItemMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "itemMaster",
  displayName: "品目マスタ",
  dependSheetFields: [
    {
      key: "itemName",
      displayName: "品目",
      type: "string",
    },
    {
      key: "unit",
      displayName: "単位",
      type: "string",
    },
    {
      key: "itemStorageType",
      displayName: "品目保管所タイプ",
      type: "string",
    },
  ],
};

const batchGanttOperationTimeInputDependSheet: BatchGanttViewTypeDependSheet = {
  key: "operationTimeInput",
  displayName: "稼働時間",
  dependSheetFields: [
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "operationStartTime",
      displayName: "稼働開始時刻",
      type: "string",
    },
    {
      key: "operationEndTime",
      displayName: "稼働終了時刻",
      type: "string",
    },
  ],
};

const batchGanttStorageItemMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "storageItemMaster",
  displayName: "保管所品目マスタ",
  dependSheetFields: [
    {
      key: "storageName",
      displayName: "保管所",
      type: "string",
    },
    {
      key: "itemName",
      displayName: "保管できる品目",
      type: "string",
    },
  ],
};

const batchGanttSwitchingBatchTaskMasterDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "switchingBatchTaskMaster",
    displayName: "バッチタスク切り替えマスタ",
    dependSheetFields: [
      {
        key: "batchTaskNameBefore",
        displayName: "切り替え前",
        type: "string",
      },
      {
        key: "batchTaskNameAfter",
        displayName: "切り替え後",
        type: "string",
      },
      {
        key: "switchingMinutes",
        displayName: "切り替え時間",
        type: "float",
      },
      {
        key: "switchingCost",
        displayName: "切り替え費用",
        type: "float",
      },
    ],
  };

const batchGanttTaskDeviceTypeMasterDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "taskDeviceTypeMaster",
    displayName: "タスク装置タイプマスタ",
    dependSheetFields: [
      {
        key: "taskDeviceType",
        displayName: "タイプ",
        type: "string",
      },
      {
        key: "taskLabel",
        displayName: "タスクラベル",
        type: "string",
      },
      {
        key: "deviceLabel",
        displayName: "装置ラベル",
        type: "string",
      },
    ],
  };

export const batchGanttDependSheets = [
  batchGanttAfterBatchTaskPlanDependSheet,
  batchGanttAlertDependSheet,
  batchGanttBatchTaskMasterDependSheet,
  batchGanttBatchTaskPlanDependSheet,
  batchGanttBatchTaskProcessTimeMasterDependSheet,
  batchGanttBomByproductItemMasterDependSheet,
  batchGanttBomMasterDependSheet,
  batchGanttDeviceMasterDependSheet,
  batchGanttDeviceMaintenanceInputDependSheet,
  batchGanttItemMasterDependSheet,
  batchGanttOperationTimeInputDependSheet,
  batchGanttStorageItemMasterDependSheet,
  batchGanttSwitchingBatchTaskMasterDependSheet,
  batchGanttTaskDeviceTypeMasterDependSheet,
];

import loadable from "@loadable/component";

import { paidHolidayViewDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const paidHolidayView: StrictViewType = {
  key: "ngrKoubanPaidHoliday",
  displayName: "NGR交番:有給休暇",
  render: ViewRender as any,
  dependSheets: paidHolidayViewDependSheets,
  configSchemas: [],
};

import React, { useState } from "react";
import { useNavigate } from "react-router";

import { useAppRouteParams } from "../../../../../AppRoutes";
import { Version, useSetVersion } from "../../../../../domain/Version";
import { useRecursiveDeleteVersion } from "../../../../../domain/Version";
import { ManagedTransaction } from "../../../../../firebase/firestore";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";

import { useDeleteReferredFrom } from "@/domain/ReferredFrom";
import { useGetSheets } from "@/domain/Sheet";

export const DialogVersionRemoveSome: React.FC<
  {
    versions: Version[];
    selectedVersionIds: string[];
    setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  versions,
  selectedVersionIds,
  setSelectedVersionIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const { organizationId, versionId: nowVersionId } = useAppRouteParams();
  const navigate = useNavigate();
  const recursiveDeleteVersion = useRecursiveDeleteVersion();
  const getSheets = useGetSheets();
  const deleteReferredFrom = useDeleteReferredFrom();

  const selectedVersions = versions.filter(({ id }) =>
    selectedVersionIds.includes(id)
  );

  const { renderDialogs, setIsInProgress, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });
  const progressTotal = selectedVersionIds.length;
  const [progressCount, setProgressCount] = useState(0);

  const runRemove = async () => {
    if (selectedVersions.length > 0) {
      setProgressCount(0);
      try {
        if (selectedVersionIds.includes(nowVersionId)) {
          navigate(`/organizations/${organizationId}`);
        }
        setDialogPhase("in_progress");

        // 削除対象のバージョン内の、${referencingVersionId}:${referencingSheetId}をキー、
        // referenced sheetのversionIdとsheetIdを値とするMap
        const referencedSheetsMap = new Map<
          string,
          { versionId: string; sheetId: string }
        >();
        for (const selectedVersionId of selectedVersionIds) {
          const sheets = await getSheets({ versionId: selectedVersionId });
          sheets
            .filter(({ referTo }) => !!referTo)
            .forEach(({ id, referTo }) =>
              referencedSheetsMap.set(`${selectedVersionId}:${id}`, referTo!)
            );
        }

        const deleteQueueList = await Promise.all(
          selectedVersionIds.map(
            async (versionId) => await recursiveDeleteVersion(versionId, {})
          )
        );
        await ManagedTransaction.runTransaction(async (transaction) => {
          // 参照先のreferred_fromを削除
          for (const [
            connectedId,
            { versionId: refVersionId, sheetId: refSheetId },
          ] of referencedSheetsMap.entries()) {
            await deleteReferredFrom(connectedId, {
              versionId: refVersionId,
              sheetId: refSheetId,
            });
          }

          for (const deleteQueue of deleteQueueList) {
            await deleteQueue.exec(transaction);
            setProgressCount((cnt) => cnt + 1);
          }
        });
        setSelectedVersionIds([]);
        setDialogPhase("success");
      } catch (error) {
        showErrorAPIDialog(error);
      }
    }
  };
  const messages = getDialogMessages({
    isSome: true,
    actionType: "remove",
    targetType: "version",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runRemove,
          onClickCancel: () => {
            setSelectedVersionIds([]);
          },
          isThemeRed: true,
          ...messages.confirm,
        },
        inProgress: {
          ...messages.inProgress,
          count: {
            current: progressCount,
            total: progressTotal,
          },
        },
        success: {
          ...messages.success,
        },
        errorAPI: { ...messages.error },
      })}
    </>
  );
};

import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { OrganizationRaw } from "../../../domain/Organization";
import { useAdminAPI } from "../hooks";

const ctx = createContext<{
  organization: OrganizationRaw | null;
  runReloadOrganization: () => Promise<void>;
  isLoadedOrganization: boolean;
  isAdminMode: boolean;
}>({
  organization: null,
  runReloadOrganization: async () => {
    // nop
  },
  isLoadedOrganization: false,
  isAdminMode: true,
});

export const AdminOrganizationProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: organization,
    runReloadState: runReloadOrganization,
    isLoadedState: isLoadedOrganization,
  } = useAdminAPI<OrganizationRaw>("organization");

  const ctxValue = useMemo(
    () => ({
      organization,
      runReloadOrganization,
      isLoadedOrganization,
      isAdminMode: true,
    }),
    [organization, runReloadOrganization, isLoadedOrganization]
  );

  return <ctx.Provider value={ctxValue}>{children}</ctx.Provider>;
};

export const useAdminOrganization = () => {
  return useContext(ctx);
};

import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKwsSummary: StrictViewType = {
  key: "nshKwsSummary",
  displayName: "NSH-KWS サマリ",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "production123sSummary",
      displayName: "サマリ",
      dependSheetFields: [
        {
          key: "eo",
          displayName: "eo",
          type: "string",
        },
        {
          key: "eg",
          displayName: "eg",
          type: "string",
        },
        {
          key: "ei",
          displayName: "ei",
          type: "string",
        },
        {
          key: "ea",
          displayName: "ea",
          type: "string",
        },
        {
          key: "nvp",
          displayName: "nvp",
          type: "string",
        },
        {
          key: "sft",
          displayName: "sft",
          type: "string",
        },
        {
          key: "eoa",
          displayName: "eoa",
          type: "string",
        },
        {
          key: "bhea",
          displayName: "bhea",
          type: "string",
        },
        {
          key: "filter",
          displayName: "filter",
          type: "string",
        },
        {
          key: "general",
          displayName: "general",
          type: "string",
        },
      ],
    },
    {
      key: "productSheetWithout4sMetaData",
      displayName: "メタデータ",
      dependSheetFields: [
        {
          key: "item_id",
          displayName: "item_id",
          type: "float",
        },
        {
          key: "en_item_name",
          displayName: "en_item_name",
          type: "string",
        },
        {
          key: "item_name",
          displayName: "item_name",
          type: "string",
        },
        {
          key: "item_kind",
          displayName: "item_kind",
          type: "string",
        },
        {
          key: "data_type",
          displayName: "data_type",
          type: "enum",
        },
        {
          key: "enum_params",
          displayName: "enum_params",
          type: "string",
        },
        {
          key: "algorithm_kind",
          displayName: "algorithm_kind",
          type: "string",
        },
        {
          key: "target_item_category",
          displayName: "target_item_category",
          type: "string",
        },
        {
          key: "target_item",
          displayName: "target_item",
          type: "string",
        },
        {
          key: "consume_item_category",
          displayName: "consume_item_category",
          type: "string",
        },
        {
          key: "consume_item",
          displayName: "consume_item",
          type: "string",
        },
        {
          key: "reactor",
          displayName: "reactor",
          type: "string",
        },
        {
          key: "tank_id",
          displayName: "tank_id",
          type: "string",
        },
        {
          key: "department",
          displayName: "department",
          type: "string",
        },
        {
          key: "output_kind",
          displayName: "output_kind",
          type: "string",
        },
      ],
    },
  ],
  configSchemas: [],
};

import React from "react";

import { AdminAccountsProvider } from "./AdminAccountsProvider";
import { AdminAppConnectMastersProvider } from "./AdminAppConnectMastersProvider";
import { AdminAppViewMasterProvider } from "./AdminAppViewMasterProvider";
import { AdminAppViewMastersProvider } from "./AdminAppViewMastersProvider";
import { AdminOrganizationConnectMastersProvider } from "./AdminOrganizationConnectMastersProvider";
import { AdminOrganizationMembersProvider } from "./AdminOrganizationMembersProvider";
import { AdminOrganizationProvider } from "./AdminOrganizationProvider";
import { AdminOrganizationRolesProvider } from "./AdminOrganizationRolesProvider";
import { AdminOrganizationVersionCategoriesProvider } from "./AdminOrganizationVersionCategoriesProvider";
import { AdminOrganizationViewMastersProvider } from "./AdminOrganizationViewMastersProvider";
import { AdminOrganizationsProvider } from "./AdminOrganizationsProvider";

/**
 * @description 管理者ビュー向けのプロバイダ
 */
export const AdminProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <AdminOrganizationsProvider>
      <AdminAccountsProvider>
        <AdminAppViewMastersProvider>
          <AdminAppConnectMastersProvider>
            <AdminOrganizationProvider>
              <AdminOrganizationViewMastersProvider>
                <AdminAppViewMasterProvider>
                  <AdminOrganizationMembersProvider>
                    <AdminOrganizationVersionCategoriesProvider>
                      <AdminOrganizationRolesProvider>
                        <AdminOrganizationConnectMastersProvider>
                          {children}
                        </AdminOrganizationConnectMastersProvider>
                      </AdminOrganizationRolesProvider>
                    </AdminOrganizationVersionCategoriesProvider>
                  </AdminOrganizationMembersProvider>
                </AdminAppViewMasterProvider>
              </AdminOrganizationViewMastersProvider>
            </AdminOrganizationProvider>
          </AdminAppConnectMastersProvider>
        </AdminAppViewMastersProvider>
      </AdminAccountsProvider>
    </AdminOrganizationsProvider>
  );
};

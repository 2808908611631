import loadable from "@loadable/component";

import { skillEditorTemporaryCourseDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const temporaryCourseSkillEditorView: StrictViewType = {
  key: "ngrKoubanTemporaryCourseSkillEditor",
  displayName: "NGR交番:貸切スキル編集画面",
  render: ViewRender as any,
  dependSheets: skillEditorTemporaryCourseDependSheets,
  configSchemas: [],
};

import React, { ReactNode } from "react";

import styles from "./AppBaseLayout.module.css";
import { AppNotifications } from "./components/AppNotifications";
import { AppSideNavGlobal } from "./components/AppSideNav/AppSideNavGlobal";
import { AppSideNavLocal } from "./components/AppSideNav/AppSideNavLocal";
import { ErrorWrapper } from "./components/ErrorWrapper";

export const AppBaseLayout: React.FC<{
  children: ReactNode;
  isVisibleSideNav?: boolean;
  isAdminMode?: boolean;
}> = ({ children, isVisibleSideNav = true, isAdminMode = false }) => {
  /**
   * @description 各URLで必須で適用されるコンポーネント.AppRoutesで利用
   * @param children: PageSheet、PageOrganizationTopなど各ページの主要コンポーネント
   */
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {isVisibleSideNav ? (
        <>
          <AppSideNavGlobal isAdminMode={isAdminMode} />
          <AppSideNavLocal />
        </>
      ) : null}
      <div
        className={styles.mainContent}
        style={{ flex: 1, minWidth: 0, overflowY: "auto" }}
      >
        <ErrorWrapper>{children}</ErrorWrapper>
      </div>
      <AppNotifications />
    </div>
  );
};

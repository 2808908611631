import type Encoding from "encoding-japanese";
import type JSZip from "jszip";

let _encodingLoader: Promise<typeof Encoding> | null = null;
export const loadEncodingJapanese = () => {
  if (!_encodingLoader) {
    _encodingLoader = new Promise((r) =>
      import("encoding-japanese").then((Encoding) => r(Encoding))
    );
  }
  return _encodingLoader;
};

let _jszipLoader: Promise<JSZip> | null = null;
export const loadJSZip = () => {
  if (!_jszipLoader) {
    _jszipLoader = new Promise((r) =>
      import("jszip").then((jszip) => r(jszip.default))
    );
  }
  return _jszipLoader;
};

import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import { CheckmarkCircle24Filled } from "@fluentui/react-icons";
import { useDialogStyles } from "../../../../styles";
import { DialogSuccessParam } from "../hooks";
import styles from "../index.module.css";

export const DialogSuccess: React.FC<DialogSuccessParam> = ({
  displayMessage,
  dialogPhase,
  setDialogPhase,
  onClick,
  buttonText,
  displaySubText,
  externalButtonInfo,
}) => {
  const dialogClasses = useDialogStyles();

  return (
    <Dialog open={dialogPhase === "success"}>
      <DialogSurface className={dialogClasses.dialog}>
        <DialogBody>
          <DialogTitle>
            <div className={styles.titleWithIcon}>
              <CheckmarkCircle24Filled className={styles.successIcon} />
              {displayMessage}
            </div>
          </DialogTitle>
          <DialogContent>
            {displaySubText && (
              <div className={styles.dialogSubText}>{displaySubText}</div>
            )}
          </DialogContent>
          <DialogActions>
            {externalButtonInfo && (
              <Button
                appearance="primary"
                onClick={() => {
                  setDialogPhase(null);
                  externalButtonInfo.onClick();
                }}
              >
                {externalButtonInfo.buttonText}
              </Button>
            )}
            <Button
              onClick={() => {
                onClick && onClick();
                setDialogPhase(null);
              }}
            >
              {buttonText ?? "戻る"}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

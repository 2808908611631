import {
  OrganizationRaw,
  OrganizationsItemRaw,
} from "../../domain/Organization";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrl = "/admin";
const apiUrlOrganizations = apiUrl + "/organizations";
const apiUrlInivitaionsOrganizations =
  apiUrl + "/invitations" + apiUrlOrganizations;

export const getAdminOrganization = async ({
  organizationId,
}: {
  organizationId: OrganizationRaw["org_id"];
}): Promise<OrganizationRaw> => {
  const response = await API_CLIENT.get<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}`,
    {}
  );
  return response.data.item;
};

export const getAdminOrganizations = async (
  startAfterId?: string
): Promise<OrganizationRaw[]> => {
  const params = startAfterId
    ? { start_after_id: startAfterId, limit: 100 }
    : { limit: 100 };
  const response = await API_CLIENT.get<{
    items: OrganizationRaw[];
  }>(apiUrlOrganizations, {
    params,
  });
  return response.data.items;
};

export const postAdminEditOrganization = async ({
  organizationId,
  organizationRawForm,
}: {
  organizationId: OrganizationRaw["org_id"];
  organizationRawForm: OrganizationRaw;
}): Promise<OrganizationRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}`,
    organizationRawForm,
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};

export const postAdminCreateOrganization = async ({
  organizationRawForm,
}: {
  organizationRawForm: OrganizationRaw;
}): Promise<OrganizationRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}`,
    organizationRawForm,
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};

export const postAdminEditMaxNumOrgMembers = async ({
  organizationId,
  max_num_org_members,
}: {
  organizationId: OrganizationRaw["org_id"];
  max_num_org_members: OrganizationRaw["max_num_org_members"];
}): Promise<OrganizationRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}/change_max_num_org_members`,
    { max_num_org_members },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};

export const postAdminEditOrgKind = async ({
  organizationId,
  org_kind,
}: {
  organizationId: OrganizationRaw["org_id"];
  org_kind: OrganizationRaw["org_kind"];
}): Promise<OrganizationRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}/change_org_kind`,
    { org_kind },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};

export const postAdminEditIsMfaRequired = async ({
  organizationId,
  is_mfa_required,
}: {
  organizationId: OrganizationRaw["org_id"];
  is_mfa_required: OrganizationRaw["is_mfa_required"];
}): Promise<OrganizationRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}/is_mfa_required`,
    { is_mfa_required },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};

export const postAdminEditIsEnabledFileOptimize = async ({
  organizationId,
  is_file_optimize_enabled,
}: {
  organizationId: OrganizationRaw["org_id"];
  is_file_optimize_enabled: OrganizationRaw["is_file_optimize_enabled"];
}): Promise<OrganizationRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}/is_file_optimize_enabled`,
    { is_file_optimize_enabled },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};

export const postAdminEditIsEnabledSheet = async ({
  organizationId,
  is_sheet_enabled,
}: {
  organizationId: OrganizationRaw["org_id"];
  is_sheet_enabled: OrganizationRaw["is_sheet_enabled"];
}): Promise<OrganizationRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}/is_sheet_enabled`,
    { is_sheet_enabled },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};

export const postAdminEditOrganizationOptimizationType = async ({
  organizationId,
  optimization_type,
}: {
  organizationId: OrganizationRaw["org_id"];
  optimization_type: OrganizationRaw["optimization_type"];
}): Promise<OrganizationRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}/change_optimization_type`,
    { optimization_type },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};

// not implemented
export const postAdminDeleteOrganization = async ({
  organizationId,
}: {
  organizationId: OrganizationRaw["org_id"];
}): Promise<OrganizationRaw> => {
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}/delete`
  );
  return response.data.item;
};

// not implemented
export const postAdminEnableOrganization = async ({
  organizationId,
}: {
  organizationId: OrganizationRaw["org_id"];
}): Promise<OrganizationRaw> => {
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}/enable`
  );
  return response.data.item;
};

// not implemented
export const postAdminRemoveOrganization = async ({
  organizationId,
}: {
  organizationId: OrganizationRaw["org_id"];
}): Promise<void> => {
  await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}/remove`
  );
};

export const getAdminInvitationsOrganizations = async () => {
  const response = await API_CLIENT.get(`${apiUrlInivitaionsOrganizations}`);
  return response.data.items;
};

export const postAdminAcceptIvitationOrganization = async ({
  organizationId,
  is_accept,
}: {
  organizationId: OrganizationRaw["org_id"];
  is_accept: boolean;
}): Promise<OrganizationsItemRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: OrganizationsItemRaw }>(
    `${apiUrlInivitaionsOrganizations}/${organizationId}`,
    { is_accept },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};

import loadable from "@loadable/component";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";
import {
  Params,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";

import { AppBaseLayout } from "./AppBaseLayout";
import { getAccount } from "./api/accounts";
import { useAppAccount } from "./components/AppProvider/AppAccountProvider";
import { useAppAuth } from "./components/AppProvider/AppAuthProvider";
import { useAppOrgsAndInvitedOrgs } from "./components/AppProvider/AppOrgsAndInvitedOrgsProvider";
import { InOrganizationProvider } from "./components/InOrganizationProvider/Index";
import { useOrganization } from "./components/InOrganizationProvider/OrganizationProvider";
import { ViewContextProvider } from "./components/ViewRenderer/ViewContextProvider";
import { checkIsMfaSettingRequired } from "./helpers/checkIsMfaSettingRequired";

import { AdminProvider } from "@/components/AdminProvider";

const PageInitialSetting = loadable(() =>
  import("./pages/PageInitialSetting").then((c) => c.PageInitialSetting)
);
const PageAccountSetting = loadable(() =>
  import("./pages/PageAccountSetting").then((c) => c.PageAccountSetting)
);
const PageOrganizations = loadable(() =>
  import("./pages/PageOrganizations").then((c) => c.PageOrganizations)
);
const PageOrganizationCreate = loadable(() =>
  import("./pages/PageOrganizationCreate").then((c) => c.PageOrganizationCreate)
);
const PageOrganizationTop = loadable(() =>
  import("./pages/PageOrganizationTop").then((c) => c.PageOrganizationTop)
);
const PageOrganizationSetting = loadable(() =>
  import("./pages/PageOrganizationSetting").then(
    (c) => c.PageOrganizationSetting
  )
);
const PageVersionsSummaryComparison = loadable(() =>
  import("./pages/PageVersionsSummaryComparison").then(
    (c) => c.PageVersionsSummaryComparison
  )
);
const PageAlgorithmVersions = loadable(() =>
  import("./pages/PageAlgorithmVersions").then((c) => c.PageAlgorithmVersions)
);
const PageAlgorithmVersionCreate = loadable(() =>
  import("./pages/PageAlgorithmVersionCreate").then(
    (c) => c.PageAlgorithmVersionCreate
  )
);
const PageAlgorithmVersionEdit = loadable(() =>
  import("./pages/PageAlgorithmVersionEdit").then(
    (c) => c.PageAlgorithmVersionEdit
  )
);
const PageFrontendAddons = loadable(() =>
  import("./pages/PageFrontendAddons").then((c) => c.PageFrontendAddons)
);
const PageFrontendAddonCreate = loadable(() =>
  import("./pages/PageFrontendAddonCreate").then(
    (c) => c.PageFrontendAddonCreate
  )
);
const PageFrontendAddonEdit = loadable(() =>
  import("./pages/PageFrontendAddonEdit").then((c) => c.PageFrontendAddonEdit)
);
const PageVersionTop = loadable(() =>
  import("./pages/PageVersionTop").then((c) => c.PageVersionTop)
);
const PageVersionCreate = loadable(() =>
  import("./pages/PageVersionCreate").then((c) => c.PageVersionCreate)
);
const PageVersionInfo = loadable(() =>
  import("./pages/PageVersionInfo").then((c) => c.PageVersionInfo)
);
const PageSheet = loadable(() =>
  import("./pages/PageSheet").then((c) => c.PageSheet)
);
const PageSheetCreate = loadable(() =>
  import("./pages/PageSheetCreate").then((c) => c.PageSheetCreate)
);
const PageView = loadable(() =>
  import("./pages/PageView").then((c) => c.PageView)
);
const PageViewEdit = loadable(() =>
  import("./pages/PageViewEdit").then((c) => c.PageViewEdit)
);
const PageMembersInvite = loadable(() =>
  import("./pages/PageMembersInvite").then((c) => c.PageMembersInvite)
);
const PageUploadSheetSchema = loadable(() =>
  import("./pages/PageUploadSheetSchema").then((c) => c.PageUploadSheetSchema)
);
const PageUploadSheetData = loadable(() =>
  import("./pages/PageUploadSheetData").then((c) => c.PageUploadSheetData)
);
const PageUploadViews = loadable(() =>
  import("./pages/PageUploadViews").then((c) => c.PageUploadViews)
);
const PageAdminOrganizations = loadable(() =>
  import("./pages/PageAdminOrganizations").then((c) => c.PageAdminOrganizations)
);
const PageAdminViewMasters = loadable(() =>
  import("./pages/PageAdminViewMasters").then((c) => c.PageAdminViewMasters)
);
const PageAdminViewMaster = loadable(() =>
  import("./pages/PageAdminViewMaster").then((c) => c.PageAdminViewMaster)
);
const PageAdminAppConnectMasters = loadable(() =>
  import("./pages/PageAdminAppConnectMasters").then(
    (c) => c.PageAdminAppConnectMasters
  )
);
const PageAdminOrganizationSetting = loadable(() =>
  import("./pages/PageAdminOrganizationSetting").then(
    (c) => c.PageAdminOrganizationSetting
  )
);
const PageAdminMembersInvite = loadable(() =>
  import("./pages/PageAdminMembersInvite").then((c) => c.PageAdminMembersInvite)
);
const PageAdminOrganizationCreate = loadable(() =>
  import("./pages/PageAdminOrganizationCreate").then(
    (c) => c.PageAdminOrganizationCreate
  )
);
const PageAdminViewMasterCreate = loadable(() =>
  import("./pages/PageAdminViewMasterCreate").then(
    (c) => c.PageAdminViewMasterCreate
  )
);
const PageAdminAccounts = loadable(() =>
  import("./pages/PageAdminAccounts").then((c) => c.PageAdminAccounts)
);
const PageConnectSetting = loadable(() =>
  import("./pages/PageConnectSetting").then((c) => c.PageConnectSetting)
);
const PageConnectCreate = loadable(() =>
  import("./pages/PageConnectCreate").then((c) => c.PageConnectCreate)
);

const Page404 = loadable(() =>
  import("./pages/Page404").then((c) => c.Page404)
);

/**
 * @description / のURLにアクセスしたときに誘導する先
 */
const RedirectToActualTop: React.FC = () => {
  const navigate = useNavigate();
  const appAuth = useAppAuth();
  const { account } = useAppAccount();
  const initProcess = async () => {
    if (!appAuth.user) return;
    const isMfaSettingRequired = checkIsMfaSettingRequired(
      account ||
        (await getAccount({
          uid: appAuth.user.uid,
        }))
    );
    // MFA未設定 or デフォルトの組織が未設定
    // 初期設定画面へ
    if (isMfaSettingRequired || !appAuth.user.defaultOrgId) {
      navigate(`/initial_setting`);
      return;
    }
    navigate(`/organizations/${appAuth.user.defaultOrgId}`);
  };
  useEffect(() => {
    initProcess();
  }, []);
  return null;
};

const PageInitialSettingWithRedirectHandler: React.FC = () => {
  const navigate = useNavigate();
  const appAuth = useAppAuth();
  const { organizations } = useAppOrgsAndInvitedOrgs();
  const { account } = useAppAccount();
  const [isLoading, setIsLoading] = React.useState(true);

  const initProcess = async () => {
    if (!appAuth.user) return;
    const isMfaSettingRequired = checkIsMfaSettingRequired(
      account ||
        (await getAccount({
          uid: appAuth.user.uid,
        }))
    );
    if (
      (!isMfaSettingRequired && appAuth.user?.defaultOrgId) ||
      organizations.length !== 0
    ) {
      navigate(`/organizations/${appAuth.user?.defaultOrgId}`);
      return;
    }
    setIsLoading(false);
  };
  useEffect(() => {
    initProcess();
  }, []);
  return isLoading ? null : <PageInitialSetting />;
};

/**
 * 初回MFAが未設定の場合は initial_setting へリダイレクトする
 * @param children
 * @constructor
 */
const InitialMFASettingRedirectHandler: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { account } = useAppAccount();
  const navigate = useNavigate();
  const appAuth = useAppAuth();
  const [isLoading, setIsLoading] = React.useState(true);

  const initProcess = async () => {
    if (!appAuth.user) return;
    const isMfaSettingRequired = checkIsMfaSettingRequired(
      account ||
        (await getAccount({
          uid: appAuth.user.uid,
        }))
    );
    if (isMfaSettingRequired) {
      navigate("/initial_setting");
      return;
    }
    setIsLoading(false);
  };
  useEffect(() => {
    initProcess();
  }, []);

  return isLoading ? null : <>{children}</>;
};

/**
 * 初回組織設定が未完了の場合は initial_setting へリダイレクトする
 * @param children
 * @constructor
 */
const InitialOrganizationSettingRedirectHandler: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const appAuth = useAppAuth();
  const [isLoading, setIsLoading] = React.useState(true);
  const { organizations, isLoadedOrganizations } = useAppOrgsAndInvitedOrgs();

  const initProcess = async () => {
    if (!appAuth.user) return;
    if (organizations.length === 0) {
      navigate("/initial_setting");
      return;
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (isLoadedOrganizations) {
      initProcess();
    }
  }, [isLoadedOrganizations]);

  return isLoading ? null : <>{children}</>;
};

export const useAppRouteParams = () => {
  /**
   * 現在のURLパラメータを取得する
   */
  const params = useParams<
    | "organization_id"
    | "version_id"
    | "sheet_id"
    | "user_id"
    | "view_id"
    | "algorithm_version_id"
    | "view_master_id"
    | "connect_id"
    | "frontend_addon_id"
  >() as Required<
    Params<
      | "organization_id"
      | "version_id"
      | "sheet_id"
      | "user_id"
      | "view_id"
      | "algorithm_version_id"
      | "view_master_id"
      | "connect_id"
      | "frontend_addon_id"
    >
  >;
  return useMemo(
    () => ({
      organizationId: params.organization_id!,
      versionId: params.version_id!,
      sheetId: params.sheet_id!,
      userId: params.user_id!,
      viewId: params.view_id!,
      algorithmVersionId: params.algorithm_version_id!,
      viewMasterId: params.view_master_id!,
      connectId: params.connect_id!,
      frontendAddonId: params.frontend_addon_id!,
    }),
    [params]
  );
};

/**
 * 専用UI機能がOFFの場合は バージョントップなど へリダイレクトする
 * @param children
 * @constructor
 */
const EnableSheetRedirectHandler: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const { organizations, isLoadedOrganizations } = useAppOrgsAndInvitedOrgs();
  const { versionId, organizationId } = useAppRouteParams();
  const organization = organizations.find(
    (org) => org.org_id === organizationId
  );
  const [isLoading, setIsLoading] = React.useState(true);

  const initProcess = async () => {
    if (organization?.is_sheet_enabled !== true) {
      if (versionId) {
        navigate(`/organizations/${organizationId}/versions/${versionId}`);
      } else {
        navigate(`/organizations/${organizationId}`);
      }
      return;
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (isLoadedOrganizations) {
      initProcess();
    }
  }, [isLoadedOrganizations]);

  return isLoading ? null : <>{children}</>;
};

/**
 * Admin権限がなければ、バージョントップ へリダイレクトする
 * @param children
 * @constructor
 */
const AdminViewRedirectHandler: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  const { isAAAdminOrAbove, isLoadedAccount, account } = useAppAccount();
  const [isLoading, setIsLoading] = React.useState(true);

  const initProcess = async () => {
    if (!isLoadedAccount) return;
    if (isAAAdminOrAbove !== true) {
      if (account?.default_org_id) {
        navigate(`/organizations/${account?.default_org_id}`);
        return;
      }
      navigate("/initial_setting");
      return;
    }
    setIsLoading(false);
  };
  useEffect(() => {
    initProcess();
  }, []);

  return isLoading ? null : <>{children}</>;
};

type RedirectHandlerType =
  | "MFASetting"
  | "AdminView"
  | "OrganizationSetting"
  | "EnableSheet";

type AppRoutesDefine = {
  [key: string]: {
    path: string;
    searchParams?: {
      [key: string]: {
        key: string;
        values?: {
          [key: string]: string;
        };
      };
    };
    redirectHandlers?: readonly RedirectHandlerType[];
    isAdminMode?: boolean;
    element: ReactNode;
  };
};
export const appRoutesDefine = {
  initialSetting: {
    path: "/initial_setting",
    element: <PageInitialSettingWithRedirectHandler />,
  },
  accountSetting: {
    path: "/account_setting",
    redirectHandlers: ["MFASetting"],
    element: <PageAccountSetting />,
  },
  adminOrganizations: {
    path: "/admin/organizations",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminOrganizations />,
  },
  adminViewMasters: {
    path: "/admin/viewmasters",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminViewMasters />,
  },
  adminViewMaster: {
    path: "/admin/viewmasters/:view_master_id",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminViewMaster />,
  },
  adminAppConnectMasters: {
    path: "/admin/connectmasters",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminAppConnectMasters />,
  },
  adminOrganizationSetting: {
    path: "/admin/organizations/:organization_id",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminOrganizationSetting tabId="organization_info" />,
  },
  adminOrganizationSettingOrganizationInfo: {
    path: "/admin/organizations/:organization_id/organization_info",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminOrganizationSetting tabId="organization_info" />,
  },
  adminOrganizationSettingFunctions: {
    path: "/admin/organizations/:organization_id/functions",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminOrganizationSetting tabId="functions" />,
  },
  adminOrganizationSettingMembers: {
    path: "/admin/organizations/:organization_id/members",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminOrganizationSetting tabId="members" />,
  },
  adminOrganizationMembersInvite: {
    path: "/admin/organizations/:organization_id/members/invite",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminMembersInvite />,
  },
  adminOrganizationSettingVersionCategories: {
    path: "/admin/organizations/:organization_id/version_categories",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminOrganizationSetting tabId="version_categories" />,
  },
  adminOrganizationSettingConnects: {
    path: "/admin/organizations/:organization_id/connects",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminOrganizationSetting tabId="connects" />,
  },
  adminOrganizationSettingRoles: {
    path: "/admin/organizations/:organization_id/roles",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminOrganizationSetting tabId="roles" />,
  },
  adminOrganizationSettingViews: {
    path: "/admin/organizations/:organization_id/views",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminOrganizationSetting tabId="views" />,
  },
  adminOrganizationSettingConnectMasters: {
    path: "/admin/organizations/:organization_id/connect_masters",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminOrganizationSetting tabId="connect_masters" />,
  },
  adminAccounts: {
    path: "/admin/accounts",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminAccounts />,
  },
  adminorganizationcreate: {
    path: "admin/organization_create",
    redirectHandlers: ["MFASetting", "OrganizationSetting", "AdminView"],
    element: <PageAdminOrganizationCreate />,
  },
  adminViewMasterCreate: {
    path: "/admin/viewmasters_create",
    redirectHandlers: ["MFASetting", "AdminView"],
    isAdminMode: true,
    element: <PageAdminViewMasterCreate />,
  },
  organizations: {
    path: "/organizations",
    redirectHandlers: ["MFASetting"],
    element: <PageOrganizations />,
  },
  organizationCreate: {
    path: "/organization_create",
    redirectHandlers: ["MFASetting"],
    element: <PageOrganizationCreate />,
  },
  organizationTop: {
    path: "/organizations/:organization_id",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageOrganizationTop />,
  },
  organizationSetting: {
    path: "/organizations/:organization_id/organization_setting",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageOrganizationSetting tabId="organization_info" />,
  },
  organizationSettingOrganizationInfo: {
    path: "/organizations/:organization_id/organization_setting/organization_info",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageOrganizationSetting tabId="organization_info" />,
  },
  organizationSettingFunctions: {
    path: "/organizations/:organization_id/organization_setting/functions",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageOrganizationSetting tabId="functions" />,
  },
  organizationSettingMembers: {
    path: "/organizations/:organization_id/organization_setting/members",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageOrganizationSetting tabId="members" />,
  },
  organizationSettingVersionCategories: {
    path: "/organizations/:organization_id/organization_setting/version_categories",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageOrganizationSetting tabId="version_categories" />,
  },
  // organizationSettingRoles: {
  //   path: "/organizations/:organization_id/organization_setting/roles",
  //   redirectHandlers: ["MFASetting", "OrganizationSetting"],
  //   element: <PageOrganizationSetting tabId="roles" />,
  // },
  organizationSettingConnects: {
    path: "/organizations/:organization_id/organization_setting/connects",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageOrganizationSetting tabId="connects" />,
  },
  versionsSummaryComparison: {
    path: "/organizations/:organization_id/versions_summary_comparison",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageVersionsSummaryComparison />,
  },
  algorithmVersions: {
    path: "/organizations/:organization_id/algorithm_versions",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageAlgorithmVersions />,
  },
  algorithmVersionCreate: {
    path: "/organizations/:organization_id/algorithm_versions/create",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageAlgorithmVersionCreate />,
  },
  algorithmVersionEdit: {
    path: "/organizations/:organization_id/algorithm_versions/:algorithm_version_id/algorithm_version_edit",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageAlgorithmVersionEdit />,
  },
  frontendAddons: {
    path: "/organizations/:organization_id/frontend_addons",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageFrontendAddons />,
  },
  frontendAddonCreate: {
    path: "/organizations/:organization_id/frontend_addons/create",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageFrontendAddonCreate />,
  },
  frontendAddonEdit: {
    path: "/organizations/:organization_id/frontend_addons/:frontend_addon_id/frontend_addon_edit",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageFrontendAddonEdit />,
  },
  versionTop: {
    path: "/organizations/:organization_id/versions/:version_id",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageVersionTop />,
  },
  versionCreate: {
    path: "/organizations/:organization_id/versions/create",
    redirectHandlers: ["MFASetting", "OrganizationSetting", "EnableSheet"],
    element: <PageVersionCreate />,
  },
  versionInfo: {
    path: "/organizations/:organization_id/versions/:version_id/version_info",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageVersionInfo />,
  },
  sheet: {
    path: "/organizations/:organization_id/versions/:version_id/sheets/:sheet_id",
    redirectHandlers: ["MFASetting", "OrganizationSetting", "EnableSheet"],
    element: <PageSheet />,
  },
  sheetCreate: {
    path: "/organizations/:organization_id/versions/:version_id/sheet_create",
    redirectHandlers: ["MFASetting", "OrganizationSetting", "EnableSheet"],
    element: <PageSheetCreate />,
  },
  view: {
    path: "/organizations/:organization_id/versions/:version_id/views/:view_id",
    redirectHandlers: ["MFASetting", "OrganizationSetting", "EnableSheet"],
    element: (
      <ViewContextProvider>
        <PageView />
      </ViewContextProvider>
    ),
  },
  viewEdit: {
    path: "/organizations/:organization_id/versions/:version_id/views/:view_id/edit",
    searchParams: {
      gobacktoviewaftercancel: {
        key: "gobacktoviewafercancel",
        values: { true: "true" },
      },
    },
    redirectHandlers: ["MFASetting", "OrganizationSetting", "EnableSheet"],
    element: <PageViewEdit />,
  },
  membersInvite: {
    path: "/organizations/:organization_id/members/invite",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageMembersInvite />,
  },
  uploadSheetSchema: {
    path: "/organizations/:organization_id/versions/upload_sheet_schema",
    redirectHandlers: ["MFASetting", "OrganizationSetting", "EnableSheet"],
    element: <PageUploadSheetSchema />,
  },
  uploadSheetData: {
    path: "/organizations/:organization_id/versions/:version_id/upload_sheet_data",
    searchParams: {
      alwaysoverwrite: {
        key: "alwaysoverwrite",
        values: { true: "true" },
      },
    },
    redirectHandlers: ["MFASetting", "OrganizationSetting", "EnableSheet"],
    element: <PageUploadSheetData />,
  },
  uploadView: {
    path: "/organizations/:organization_id/versions/:version_id/upload_views",
    redirectHandlers: ["MFASetting", "OrganizationSetting", "EnableSheet"],
    element: <PageUploadViews />,
  },
  connectSetting: {
    path: "/organizations/:organization_id/connects/:connect_id",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageConnectSetting />,
  },
  connectCreate: {
    path: "/organizations/:organization_id/connect_create",
    redirectHandlers: ["MFASetting", "OrganizationSetting"],
    element: <PageConnectCreate />,
  },
  notFound: {
    path: "*",
    redirectHandlers: ["OrganizationSetting"],
    element: <Page404 />,
  },
} as const satisfies AppRoutesDefine;

const applyInitialOrganizationSettingRedirectHandler = (element: ReactNode) => {
  return (
    <InitialOrganizationSettingRedirectHandler>
      {element}
    </InitialOrganizationSettingRedirectHandler>
  );
};

const applyInitialMFASettingRedirectHandler = (element: ReactNode) => {
  return (
    <InitialMFASettingRedirectHandler>
      {element}
    </InitialMFASettingRedirectHandler>
  );
};

const applyEnableSheetRedirectHandler = (element: ReactNode) => {
  return <EnableSheetRedirectHandler>{element}</EnableSheetRedirectHandler>;
};
const applyRedirectHandler = (
  element: ReactNode,
  redirectHandlers?: readonly RedirectHandlerType[]
) => {
  let el = element;

  if (redirectHandlers?.includes("OrganizationSetting")) {
    el = applyInitialOrganizationSettingRedirectHandler(el);
  }
  if (redirectHandlers?.includes("MFASetting")) {
    el = applyInitialMFASettingRedirectHandler(el);
  }
  if (redirectHandlers?.includes("EnableSheet")) {
    el = applyEnableSheetRedirectHandler(el);
  }

  return el;
};
export type AppRouteId = keyof typeof appRoutesDefine;
const routes = Object.entries(appRoutesDefine as AppRoutesDefine).map(
  ([routeId, { path, element, redirectHandlers }]) => ({
    routeIdCtxValue: { routeId: routeId as AppRouteId },
    path,
    isAdminView: redirectHandlers?.find((c) => c === "AdminView") !== undefined,
    element: applyRedirectHandler(element, redirectHandlers),
  })
);

const routeIdCtx = createContext<{ routeId: AppRouteId }>({
  routeId: "initialSetting",
});
export const useAppRouteId = (): AppRouteId => {
  return useContext(routeIdCtx).routeId;
};

const invisibleSideNavRouteIds: AppRouteId[] = ["initialSetting"];

export const AppRoutes: React.FC = () => {
  const { isAAAdminOrAbove } = useAppAccount();
  return (
    <Routes>
      <Route path="/" element={<RedirectToActualTop />} />
      {routes.map(({ routeIdCtxValue, path, element, isAdminView }) => {
        const isAdminMode = isAdminView && isAAAdminOrAbove;
        const Provider = isAdminMode ? AdminProvider : InOrganizationProvider;
        const isVisibleSideNav = !invisibleSideNavRouteIds.includes(
          routeIdCtxValue.routeId as AppRouteId
        );

        return (
          <Route
            key={path}
            path={path}
            element={
              <routeIdCtx.Provider value={routeIdCtxValue}>
                <Provider>
                  <AppBaseLayout
                    isVisibleSideNav={isVisibleSideNav}
                    isAdminMode={isAdminMode}
                  >
                    {element}
                  </AppBaseLayout>
                </Provider>
              </routeIdCtx.Provider>
            }
          />
        );
      })}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

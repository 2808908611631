import { generateId } from "@/utils/generateId";
import { Button } from "@fluentui/react-components";
import { ErrorCircle20Filled } from "@fluentui/react-icons";
import React, { ErrorInfo } from "react";
import { DialogErrorOffline } from "../Dialog/helpers/DialogErrorOffline";

import styles from "./index.module.css";

/**
 * @description 汎用的なError boundary。
 * 1. URL変更時に走る再レンダリング時にはstateを初期化する。これによりURLを変更すると一旦エラー画面は消える
 * 2. 非同期処理のエラーもここでキャッチして処理する
 * 3. 状態復帰するために再ログインボタンを追加できるようにしている
 */
export class ErrorWrapper extends React.Component<
  {
    children: React.ReactNode;
    isRenderAccountSettingButton?: boolean;
    isRenderLogoutButton?: boolean;
    isRenderLoginButton?: boolean;
  },
  {
    id: string;
    error: Error | null;
    errorInfo: ErrorInfo | null;
    previousLocation: string;
  }
> {
  constructor(props: {
    children: null;
    isRenderAccountSettingButton?: boolean;
    isRenderLogoutButton?: boolean;
    isRenderLoginButton?: boolean;
  }) {
    super(props);
    this.state = { id: "", error: null, errorInfo: null, previousLocation: "" };
  }

  static getDerivedStateFromError(error: Error) {
    return { id: "", error, errorInfo: null };
  }
  componentDidMount() {
    // URLを設定
    this.setState((state) => ({
      ...state,
      previousLocation: window.location.hash,
    }));
  }
  // URL変更などで再レンダリングされたら状態を初期化する
  componentDidUpdate() {
    const currentLocation = window.location.hash;
    if (currentLocation !== this.state.previousLocation) {
      this.setState({
        id: "",
        error: null,
        errorInfo: null,
        previousLocation: currentLocation,
      });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const id = generateId();
    this.setState({ id, error, errorInfo });
  }

  render() {
    const {
      isRenderAccountSettingButton,
      isRenderLogoutButton,
      isRenderLoginButton,
    } = this.props; // 追加
    if (this.state.error) {
      return (
        <div className={styles.errorDisplayWrapper}>
          <RuntimeErrorDisplay
            errorId={this.state.id}
            error={this.state.error}
            errorInfo={this.state.errorInfo}
          />
          <div className={styles.buttonContainer}>
            {isRenderAccountSettingButton && (
              <Button
                onClick={() => {
                  location.href = "/#/account_setting";
                }}
              >
                アカウント設定画面へ
              </Button>
            )}
            {isRenderLoginButton && (
              <Button
                onClick={() => {
                  location.href = "/";
                }}
              >
                再ログイン
              </Button>
            )}
            {isRenderLogoutButton && (
              <Button
                onClick={() => {
                  // @ts-ignore
                  window.signOut();
                }}
              >
                ログアウト
              </Button>
            )}
          </div>
          {!navigator.onLine ? <DialogErrorOffline /> : null}
        </div>
      );
    }

    return this.props.children;
  }
}

const RuntimeErrorDisplay: React.FC<{
  errorId: string;
  error: Error;
  errorInfo?: ErrorInfo | null;
}> = ({ errorId, error, errorInfo }) => {
  const errorName = error?.name;
  const splitErrorMessage = error?.message.match(/.{1,200}/g);
  const splitErrorStack = error?.stack?.match(/.{1,250}/g);
  const componentStack = errorInfo?.componentStack;

  return (
    <div className={styles.container}>
      <div className={styles.errorInfoContainer}>
        <div className={styles.errorInfoHeading}>
          <ErrorCircle20Filled className={styles.errorIcon} />
          エラーが発生しました。
        </div>
        <div className={styles.errorInfoDescription}>
          大変お手数ですが、下記エラー情報を組織管理者へお知らせください。
          <div className={styles.errorSummary}>
            {errorId && <div>追跡用ID: {errorId}</div>}
            {errorName && <div>エラー名: {errorName}</div>}
            {splitErrorMessage &&
              splitErrorMessage.map((message, index) => (
                <div key={index}>{message}</div>
              ))}
          </div>
          <details className={styles.details}>
            <summary>エラー情報詳細</summary>
            <>
              {(splitErrorStack || componentStack) && (
                <div className={styles.errorDetails}>
                  {[...(splitErrorStack ?? []), componentStack].map(
                    (message, index) => (
                      <pre key={index}>{message}</pre>
                    )
                  )}
                </div>
              )}
            </>
          </details>
        </div>
      </div>
    </div>
  );
};

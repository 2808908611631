import loadable from "@loadable/component";
import { getDependSheets } from "../../schema/allDependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeCSV4SAPView: StrictViewType = {
  key: "planiumChemicalSAPCSVView",
  displayName: "SAP用CSVダウンロードビュー",
  render: ViewRender as any,
  dependSheets: getDependSheets([
    "itemMaster",
    "bomMaster",
    "batchTaskMaster",
    "batchTaskPlan",
    "batchTaskStoragePlan",
    "batchTaskProcessTimeMaster",
    "storageItemMaster",
    "storageMaster",
    "purchasePlan",
    "params",
  ]),
  configSchemas: [],
};

import { getSinglyLinkedSheetDataRowList } from "@/helpers/getSinglyLinkedSheetDataRowList";
import {
  Sheet,
  useRecursiveDeleteSheet,
  useSetSheet,
} from "../../../../domain/Sheet";
import {
  useGetSheetDataRows,
  useSheetDataRowUtils,
} from "../../../../domain/SheetDataRow";
import {
  SheetFieldSchema,
  useGetSheetFieldSchemas,
  useSetSheetFieldSchema,
} from "../../../../domain/SheetFieldSchema";
import { ManagedTransaction } from "../../../../firebase/firestore";

import { useDeleteReferredFrom } from "@/domain/ReferredFrom";

export const duplicateRecursiveSheetData = async ({
  duplicateSheet,
  getSheetDataRows,
  getSheetFieldSchemas,
  setSheet,
  setSheetFieldSchema,
  sheetDataRowUtils,
  displayName,
  name,
  sort,
  newSheetId,
}: {
  newSheetId: string;
  duplicateSheet: Sheet;
  getSheetDataRows: ReturnType<typeof useGetSheetDataRows>;
  getSheetFieldSchemas: ReturnType<typeof useGetSheetFieldSchemas>;
  setSheet: ReturnType<typeof useSetSheet>;
  setSheetFieldSchema: ReturnType<typeof useSetSheetFieldSchema>;
  sheetDataRowUtils: ReturnType<typeof useSheetDataRowUtils>;
  displayName: Sheet["displayName"];
  name: Sheet["name"];
  sort: number;
}) => {
  const sheetId = duplicateSheet.id;

  const sheetDataRows = await getSheetDataRows({ sheetId });
  const sheetFieldSchemas = await getSheetFieldSchemas({ sheetId });

  // クエリ状態は反映させずに単方向リストを取得
  const updatedDuplicateSheet = {
    ...duplicateSheet,
    isSheetQueryOn: false,
  };

  const { rows } = getSinglyLinkedSheetDataRowList(
    updatedDuplicateSheet,
    sheetDataRows
  );

  await setSheet(
    {
      ...duplicateSheet,
      firstRowId: null,
      id: newSheetId,
      displayName,
      name,
      sort,
    },
    {},
    {}
  );
  await ManagedTransaction.runTransaction(async (transaction) => {
    for (const sheetFieldSchema of sheetFieldSchemas) {
      await setSheetFieldSchema(
        {
          ...sheetFieldSchema,
        },
        { sheetId: newSheetId },
        { transaction }
      );
    }
    const rowsWithoutId = rows.map(({ id, ...rest }) => rest);
    await sheetDataRowUtils.addRowsByData(rowsWithoutId, {
      sheetId: newSheetId,
    });
  });
};

export const deleteRecursiveSheetData = async ({
  sheets,
  selectedSheetIds,
  recursiveDeleteSheet,
  deleteReferredFrom,
  versionId,
  setSheet,
  setProgressCount,
}: {
  sheets: Sheet[];
  selectedSheetIds: string[];
  recursiveDeleteSheet: ReturnType<typeof useRecursiveDeleteSheet>;
  deleteReferredFrom: ReturnType<typeof useDeleteReferredFrom>;
  versionId: string;
  setSheet: ReturnType<typeof useSetSheet>;
  setProgressCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const restSheets = sheets.filter(({ id }) => !selectedSheetIds.includes(id));
  const deleteQueueList = await Promise.all(
    selectedSheetIds.map(
      async (sheetId) => await recursiveDeleteSheet(sheetId, {})
    )
  );
  // 1. referencing sheetがあった場合、referenced sheetのreferred_fromを先に削除
  // 2. 対象のシートを全削除
  // 3. ソート番号を振り直す
  await ManagedTransaction.runTransaction(async (transaction) => {
    await Promise.all(
      sheets
        .filter((s) => selectedSheetIds.includes(s.id) && !!s.referTo)
        .map(
          async (s) =>
            await deleteReferredFrom(
              `${versionId}:${s.id}`,
              {
                versionId: s.referTo!.versionId,
                sheetId: s.referTo!.sheetId,
              },
              { transaction }
            )
        )
    );
    setProgressCount(0);
    for (const deleteQueue of deleteQueueList) {
      await deleteQueue.exec(transaction);
      setProgressCount((cnt) => cnt + 1);
    }
    for (const [index, sheet] of restSheets.entries()) {
      await setSheet(
        { ...sheet, sort: index },
        { sheetId: sheet.id },
        { transaction }
      );
    }
  });
};

import loadable from "@loadable/component";
import { getDependSheets } from "../../schema/allDependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeStorageStockView: StrictViewType = {
  key: "planiumChemicalStorageStockView",
  displayName: "保管所在庫推移ビュー",
  render: ViewRender as any,
  dependSheets: getDependSheets([
    "batchTaskPlan",
    "shippingPlan",
    "purchasePlan",
    "businessDayCalendar",
    "adjustmentInput",
    "salesInput",
    "batchTaskMaster",
    "params",
    "batchTaskStoragePlan",
    "batchTaskRawMaterialPlan",
    "itemMaster",
    "deviceMaster",
    "batchTaskProcessTimeMaster",
    "switchingBatchTaskMaster",
    "utilityCostTypeMaster",
    "batchTaskUtilityMaster",
    "deviceMaintenanceInput",
    "storageMaster",
    "stockCriteriaMaster",
    "storageItemMaster",
  ]),
  configSchemas: [
    {
      key: "itemStorageType",
      displayName: "品目保管所タイプ(','区切りで複数入力可)",
      type: "string",
      typeFields: null,
      typeParameters: null,
    },
  ],
};

import {
  FirestoreCollectionProsessorOptions,
  createFirestoreCollectionProsessor,
} from "../firebase/firestore";

import "./View";
import "./Sheet";

export const versionOptimizeStatusesInProgress = [
  "queued",
  "queued_optimization",
  "in_progress_evaluation",
  "in_progress_optimization",
] as const;

export const versionOptimizeStatusesFinished = [
  "success_evaluation",
  "success_optimization",
  "failure_in_optimization",
  "failure_input_evaluation",
  "failure_exception",
] as const;

export const versionOptimizeStatuses = [
  ...versionOptimizeStatusesInProgress,
  ...versionOptimizeStatusesFinished,
] as const;

export type VersionOptimizeStatus =
  | (typeof versionOptimizeStatuses)[number]
  | null;
export type OptimizeStatusesText =
  | "処理待機中"
  | "評価実行中"
  | "評価成功"
  | "最適化待機中"
  | "最適化実行中"
  | "最適化成功"
  | "最適化失敗"
  | "入力検証失敗"
  | "エラー";
export type OptimizeStatusesTextForPlanium =
  | "処理待機中"
  | "評価実行中"
  | "評価成功"
  | "自動ドラフト待機中"
  | "自動ドラフト実行中"
  | "自動ドラフト成功"
  | "自動ドラフト失敗"
  | "入力検証失敗"
  | "エラー";

type PublishedByOperationKinds =
  | "create"
  | "upload"
  | "evaluate"
  | "optimize"
  | "file_optimize"
  | "file_evaluate";
type PublishedByOperationKindsText =
  | "新規作成"
  | "ファイルアップロード"
  | "評価"
  | "最適化"
  | "ファイル最適化"
  | "ファイル評価";
type PublishedByOperationKindsTextForPlanium =
  | "新規作成"
  | "ファイルアップロード"
  | "評価"
  | "自動ドラフト"
  | "ファイル最適化"
  | "ファイル評価";

export const versionPublishedByOperationKinds: {
  [k in PublishedByOperationKinds]: PublishedByOperationKindsText;
} = {
  create: "新規作成",
  upload: "ファイルアップロード",
  evaluate: "評価",
  optimize: "最適化",
  file_optimize: "ファイル最適化",
  file_evaluate: "ファイル評価",
} as const;
export const versionPublishedByOperationKindsForPlanium: {
  [k in PublishedByOperationKinds]: PublishedByOperationKindsTextForPlanium;
} = {
  create: "新規作成",
  upload: "ファイルアップロード",
  evaluate: "評価",
  optimize: "自動ドラフト",
  file_optimize: "ファイル最適化",
  file_evaluate: "ファイル評価",
} as const;

export const versionOptimizeStatusTextForNull = "初期状態" as const;

export const versionOptimizeStatusList: {
  [key in Exclude<VersionOptimizeStatus, null>]: OptimizeStatusesText;
} = {
  queued: "処理待機中",
  queued_optimization: "最適化待機中",
  in_progress_evaluation: "評価実行中",
  in_progress_optimization: "最適化実行中",
  success_evaluation: "評価成功",
  success_optimization: "最適化成功",
  failure_in_optimization: "最適化失敗",
  failure_input_evaluation: "入力検証失敗",
  failure_exception: "エラー",
} as const;
export const versionOptimizeStatusListForPlanium: {
  [key in Exclude<VersionOptimizeStatus, null>]: OptimizeStatusesTextForPlanium;
} = {
  queued: "処理待機中",
  queued_optimization: "自動ドラフト待機中",
  in_progress_evaluation: "評価実行中",
  in_progress_optimization: "自動ドラフト実行中",
  success_evaluation: "評価成功",
  success_optimization: "自動ドラフト成功",
  failure_in_optimization: "自動ドラフト失敗",
  failure_input_evaluation: "入力検証失敗",
  failure_exception: "エラー",
} as const;

export type Version = {
  id: string;
  comment: string | null;
  version: string;
  title: string;
  createdAt: string;
  publishedAt: string;
  editedUsersUids: string[];
  isDisabled: boolean;
  // バージョンカテゴリのID
  category: string;
  lastModifiedAt: string;
  optimizeAlgorithmVersion: string | null;
  optimizeFinishedAt: string | null;
  optimizeFromVersion: string | null;
  // 古いバージョンはoptimizeJobDirのみを持っている場合がある
  // 現状optimizeJobWorkingDirとoptmizeJobDirは同値
  optimizeJobDir: null;
  optimizeJobWorkingDir: null;
  optimizeJobId: null;
  optimizeScoreByUser: null;
  optimizeWeightType: null;
  optimizeStatus: VersionOptimizeStatus;
  publishedByOperation: PublishedByOperationKinds;
  schemaVersion: string;
};

type VersionRaw = {
  id: string;
  comment: string | null;
  version: string;
  title: string;
  created_at: string;
  published_at: string;
  edited_users_uids: string[];
  is_disabled: boolean;
  category: string;
  last_modified_at: string;
  optimize_algorithm_version: string | null;
  optimize_finished_at: string | null;
  optimize_from_version: string | null;
  optimize_job_dir: null;
  optimize_job_working_dir: null;
  optimize_job_id: null;
  optimize_score_by_user: null;
  optimize_weight_type: null;
  optimize_status: VersionOptimizeStatus;
  published_by_operation: PublishedByOperationKinds;
  schema_version: string;
};

export const checkIsPublishedByFileUpload = (version: Version | null) => {
  if (!version) return false;
  const { publishedByOperation } = version;
  return (
    publishedByOperation === "file_optimize" ||
    publishedByOperation === "file_evaluate"
  );
};

export const checkIsEnableDownloadResult = (version: Version | null) => {
  if (!version) return false;
  const { optimizeStatus } = version;
  return (
    optimizeStatus === "success_evaluation" ||
    optimizeStatus === "success_optimization" ||
    optimizeStatus === "failure_input_evaluation"
  );
};

export const checkIsOptimizingFinished = (version: Version | null) => {
  if (!version) return false;
  const { optimizeStatus } = version;
  if (!optimizeStatus) return true;
  return (
    optimizeStatus === "success_evaluation" ||
    optimizeStatus === "success_optimization" ||
    optimizeStatus === "failure_in_optimization" ||
    optimizeStatus === "failure_input_evaluation" ||
    optimizeStatus === "failure_exception"
  );
};

const processorOptions: FirestoreCollectionProsessorOptions<
  Version,
  VersionRaw,
  Record<string, string>
> = {
  getCollectionPath: ({ organizationId }) =>
    `/app/spreadsheet_ui/v/1/organizations/${organizationId}/versions`,
  toFirestore: ({
    id,
    comment,
    version,
    title,
    createdAt,
    publishedAt,
    editedUsersUids,
    isDisabled,
    category,
    lastModifiedAt,
    optimizeAlgorithmVersion,
    optimizeFinishedAt,
    optimizeFromVersion,
    optimizeJobDir,
    optimizeJobWorkingDir,
    optimizeJobId,
    optimizeScoreByUser,
    optimizeWeightType,
    optimizeStatus,
    publishedByOperation,
    schemaVersion,
  }) => ({
    id,
    comment,
    version,
    title,
    created_at: createdAt,
    published_at: publishedAt,
    edited_users_uids: editedUsersUids,
    is_disabled: isDisabled,
    category,
    last_modified_at: lastModifiedAt,
    optimize_algorithm_version: optimizeAlgorithmVersion,
    optimize_finished_at: optimizeFinishedAt,
    // 古いバージョンはoptimizeFromVersionを持っていない場合があるためケア
    optimize_from_version: optimizeFromVersion ?? null,
    optimize_job_dir: optimizeJobDir,
    // 古いバージョンはoptimizeJobWorkingDirを持っていない場合がある
    // その場合、optimizeJobDirが存在すればそこからコピーする
    optimize_job_working_dir: optimizeJobWorkingDir ?? optimizeJobDir ?? null,
    optimize_job_id: optimizeJobId,
    optimize_score_by_user: optimizeScoreByUser,
    optimize_weight_type: optimizeWeightType,
    optimize_status: optimizeStatus,
    published_by_operation: publishedByOperation,
    schema_version: schemaVersion,
  }),
  fromFirestore: ({
    id,
    comment,
    version,
    title,
    created_at,
    published_at,
    edited_users_uids,
    is_disabled,
    category,
    last_modified_at,
    optimize_algorithm_version,
    optimize_finished_at,
    optimize_from_version,
    optimize_job_dir,
    optimize_job_working_dir,
    optimize_job_id,
    optimize_score_by_user,
    optimize_weight_type,
    optimize_status,
    published_by_operation,
    schema_version,
  }) => ({
    id,
    comment,
    version,
    title,
    createdAt: created_at,
    publishedAt: published_at,
    editedUsersUids: edited_users_uids,
    isDisabled: is_disabled,
    category,
    lastModifiedAt: last_modified_at,
    optimizeAlgorithmVersion: optimize_algorithm_version,
    optimizeFinishedAt: optimize_finished_at,
    optimizeFromVersion: optimize_from_version,
    optimizeJobDir: optimize_job_dir,
    optimizeJobWorkingDir: optimize_job_working_dir,
    optimizeJobId: optimize_job_id,
    optimizeScoreByUser: optimize_score_by_user,
    optimizeWeightType: optimize_weight_type,
    optimizeStatus: optimize_status,
    publishedByOperation: published_by_operation,
    schemaVersion: schema_version,
  }),
};

export const {
  useGet: useGetVersion,
  useSet: useSetVersion,
  useRecursiveDelete: useRecursiveDeleteVersion,
  useItem: useVersion,
  useItemsQuery: useVersionsQuery,
} = createFirestoreCollectionProsessor(processorOptions);

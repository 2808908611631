type SpreadsheetUIFrontendAddonContextData = {
  id: string;
  displayName: string;
  version: string;
  revision: string;
  viewTypes: ViewType<any, any>[];
  viewTypeExamples: (ViewTypeExample<any, any> | undefined)[];
};
type SpreadsheetUIFrontendAddonContextInitInfo = any;
type ViewTypeExample<T, R> = any;
type ViewTypeSeed = any;
declare global {
  interface Window {
    __SPREADSHEET_UI_FRONTEND_ADDON__?:
      | {
          queue: SpreadsheetUIFrontendAddonContextInitInfo[];
          registerAddon?: (
            initInfo: SpreadsheetUIFrontendAddonContextInitInfo
          ) => Promise<void> | void;
        }
      | {
          queue: never;
          registerAddon?: (
            initInfo: SpreadsheetUIFrontendAddonContextInitInfo
          ) => Promise<void> | void;
        };
  }
}

window.__SPREADSHEET_UI_FRONTEND_ADDON__ =
  window.__SPREADSHEET_UI_FRONTEND_ADDON__ || {
    queue: [],
  };

import { ViewType } from "@/domain/ViewType";
import React, {
  ReactNode,
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { TinyEmitter } from "tiny-emitter";
import styles from "./adapter.module.css";

const extEmitter = new TinyEmitter();
type ManagerState = {
  addons: { [key: string]: SpreadsheetUIFrontendAddonContextData };
};
const SpreadsheetUIFrontendAddonManagerContext =
  React.createContext<ManagerState>({
    addons: {},
  });
let rootManagerState: ManagerState = { addons: {} };
const updateManagerState = (state: ManagerState) => {
  rootManagerState = {
    ...rootManagerState,
    ...state,
    addons: {
      ...rootManagerState.addons,
      ...state.addons,
    },
  };
  extEmitter.emit("update", rootManagerState);
};

class SpreadsheetUIFrontendAddonContext {
  data: SpreadsheetUIFrontendAddonContextData;
  constructor(
    data: Pick<
      SpreadsheetUIFrontendAddonContext["data"],
      "id" | "displayName" | "version" | "revision"
    > &
      Omit<
        Partial<SpreadsheetUIFrontendAddonContext["data"]>,
        "id" | "displayName" | "version" | "revision"
      >
  ) {
    this.data = {
      viewTypes: [],
      viewTypeExamples: [],
      ...data,
    };
    this.onUpdated();
  }
  onUpdated() {
    updateManagerState({
      addons: {
        [this.data.id]: this.data,
      },
    });
  }
  registerViewType(
    { mount, update, unmount, ...viewTypeParts }: ViewTypeSeed,
    debugInfo?: { example: ViewTypeExample<any, any> }
  ) {
    const StaticRoot = memo(
      ({ rootRef }: { rootRef: React.RefObject<HTMLDivElement> }) => (
        <div ref={rootRef} />
      ),
      () => true
    );
    const render: ViewType<any, any>["render"] = (viewProps) => {
      const refRoot = useRef<HTMLDivElement>(null);
      const refIsMounted = useRef(false);
      useLayoutEffect(() => {
        mount(refRoot.current!, viewProps);
        refIsMounted.current = true;
        return () => {
          unmount(refRoot.current!);
          refIsMounted.current = false;
        };
      }, []);
      useEffect(() => {
        setTimeout(() => {
          if (refIsMounted.current) {
            update(refRoot.current!, viewProps);
          }
        }, 0);
      }, [viewProps]);
      return (
        <div className={styles.rootWrapper}>
          <StaticRoot rootRef={refRoot} />
        </div>
      );
    };
    const viewType: ViewType<any, any> = {
      ...viewTypeParts,
      render,
    };
    const viewTypeExample = debugInfo?.example;
    this.data = {
      ...this.data,
      viewTypes: [...this.data.viewTypes, viewType],
      viewTypeExamples: [...this.data.viewTypeExamples, viewTypeExample],
    };
    this.onUpdated();
  }
}

window.__SPREADSHEET_UI_FRONTEND_ADDON__.registerAddon = async (
  initInfo: SpreadsheetUIFrontendAddonContextInitInfo
) => {
  const ctx = new SpreadsheetUIFrontendAddonContext({
    id: initInfo.id,
    displayName: initInfo.displayName,
    version: initInfo.version,
    revision: initInfo.revision,
  });
  await initInfo.init(ctx);
};

export const SpreadsheetUIFrontendAddonManagerContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [managerState, setManagerState] = React.useState(rootManagerState);
  useEffect(() => {
    const onUpdate = (state: ManagerState) => {
      setManagerState(state);
    };
    extEmitter.on("update", onUpdate);
    if (managerState !== rootManagerState) {
      setManagerState(rootManagerState);
    }
    return () => {
      extEmitter.off("update", onUpdate);
    };
  }, []);
  return (
    <SpreadsheetUIFrontendAddonManagerContext.Provider value={managerState}>
      {children}
    </SpreadsheetUIFrontendAddonManagerContext.Provider>
  );
};
export const useSpreadsheetUIFrontendAddonManager = () =>
  useContext(SpreadsheetUIFrontendAddonManagerContext);

for (const init of window.__SPREADSHEET_UI_FRONTEND_ADDON__.queue) {
  window.__SPREADSHEET_UI_FRONTEND_ADDON__.registerAddon(init);
}

import { MessageBarIntent } from "@fluentui/react-components";
import React, { useCallback, useContext, useMemo, useState } from "react";

import { getAPIErrorDetailOrMesssage } from "@/api/helpers";
import { generateId } from "@/utils/generateId";

const appNotificationStateContext = React.createContext<{
  notifications: AppNotification[];
}>({
  notifications: [],
});
const appNotificationManagerContext = React.createContext<{
  showInfoNotification: (msg: string) => void;
  showSuccessNotification: (msg: string) => void;
  showErrorNotification: (description: string, error?: unknown) => void;
}>({
  showInfoNotification() {
    //nop
  },
  showSuccessNotification() {
    //nop
  },
  showErrorNotification() {
    //nop
  },
});

export function useAppNotificationManager() {
  return useContext(appNotificationManagerContext);
}
export function useAppNotificationState() {
  return useContext(appNotificationStateContext);
}

// メッセージの長さに応じて表示期間を計算する
function computeShowDuration(text: string) {
  let asciiLength = 0;
  let nonAsciiLength = 0;
  for (const char of text) {
    const code = char.codePointAt(0) || 0;
    if (code < 0x80) {
      asciiLength++;
    } else {
      nonAsciiLength++;
    }
  }
  // NOTE: 最低3800msecは表示し、さらに文字列の長さで表示秒数を加算する
  return 3800 + Math.floor((asciiLength / 12 + nonAsciiLength / 4) * 1000);
}

export type AppNotification = {
  id: string;
  type: MessageBarIntent;
  msg: string;
  hideTimeout: NodeJS.Timeout;
  hideNotification: () => void;
  onNotificationMouseEnter: () => void;
  onNotificationMouseLeave: () => void;
  onNotificationClick: () => void;
  fixed: boolean;
  disposed: boolean;
};

/**
 * @description fluentUIのMessageBarで表示するNotificationを管理する
 * 通知は自動的に一定の時間が経過すると自動的に閉じられる。
 * 通知をマウスでホバーしたり、クリックしたりすると、表示期間が延長される
 * （クリックした場合はクローズするまで消えなくなる)
 *
 */
export const AppNotificationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [notifications, _setNotifications] = useState<AppNotification[]>([]);

  // stateへのnotification追加
  const _addNotification = (notification: AppNotification) => {
    _setNotifications((notifications) => [...notifications, notification]);
  };
  // 該当IDのnotificationのstateを更新
  const _updateNotification = (
    id: string,
    updater: (notification: AppNotification) => AppNotification
  ) => {
    _setNotifications((notifications) => {
      const index = notifications.findIndex((c) => c.id === id);
      if (notifications[index].disposed) return notifications;
      return [
        ...notifications.slice(0, index),
        updater(notifications[index]),
        ...notifications.slice(index + 1),
      ];
    });
  };

  /**
   * @description hideNotificationの内部で実行される関数
   * notificationを画面から隠し、さらにstateから消す機能
   * hideNotificationは自体は、messageBarのonDissmissの時に使われる
   */
  const _hideNotification = (id: string) => {
    _updateNotification(id, (notification) => {
      // 対象notificationのタイマー処理を終了し、即座にdisposed=trueに更新する
      // notification.hideTimeout=setTimeout(() => {
      //  _hideNotification(id);
      //}, computeShowDuration(msg))
      clearTimeout(notification.hideTimeout);
      return {
        ...notification,
        disposed: true,
      };
    });
    // 1秒後に対象IDのnotificationを状態から削除
    setTimeout(() => {
      _setNotifications((notifications) => {
        const newNotifications = [...notifications];
        const index = newNotifications.findIndex((c) => c.id === id);
        newNotifications.splice(index, 1);
        return newNotifications;
      });
    }, 1000);
  };
  const _onNotificationMouseEnter = (id: string) => {
    _updateNotification(id, (notification) => {
      clearTimeout(notification.hideTimeout);
      return notification;
    });
  };
  const _onNotificationMouseLeave = (id: string) => {
    _updateNotification(id, (notification) => {
      clearTimeout(notification.hideTimeout);
      if (notification.fixed) return notification;
      return {
        ...notification,
        hideTimeout: setTimeout(() => {
          _hideNotification(id);
        }, computeShowDuration(notification.msg)),
        showDismiss: false,
      };
    });
  };
  // クリックをすると、時間経過で消えないようになる
  const _onNotificationClick = (id: string) => {
    _updateNotification(id, (notification) => {
      clearTimeout(notification.hideTimeout);
      return {
        ...notification,
        showDismiss: true,
        fixed: true,
      };
    });
  };

  // 実際に各コンポーネントが利用するメソッド
  const showNotification = useCallback(
    (type: MessageBarIntent, msg: string) => {
      const id = generateId();
      // state追加
      // NOTE: setTimeoutは定義時に実行される
      _addNotification({
        id,
        type,
        msg,
        hideTimeout: setTimeout(() => {
          _hideNotification(id);
        }, computeShowDuration(msg)),
        hideNotification: () => {
          _hideNotification(id);
        },
        onNotificationMouseEnter: () => {
          _onNotificationMouseEnter(id);
        },
        onNotificationMouseLeave: () => {
          _onNotificationMouseLeave(id);
        },
        onNotificationClick: () => {
          _onNotificationClick(id);
        },
        fixed: false,
        disposed: false,
      });
    },
    []
  );

  const showInfoNotification = useCallback(
    (msg: string) => {
      showNotification("info", msg);
    },
    [showNotification]
  );

  const showSuccessNotification = useCallback(
    (msg: string) => {
      showNotification("success", msg);
    },
    [showNotification]
  );

  const showErrorNotification = useCallback(
    (description: string, error?: unknown) => {
      if (error) {
        const { errorMessage } = getAPIErrorDetailOrMesssage(error);
        showNotification("error", `${description}: ${errorMessage}`);
      } else {
        showNotification("error", description);
      }
    },
    [showNotification]
  );

  const state = useMemo(() => ({ notifications }), [notifications]);
  const manager = useMemo(
    () => ({
      showInfoNotification,
      showSuccessNotification,
      showErrorNotification,
    }),
    [showInfoNotification, showSuccessNotification, showErrorNotification]
  );
  return (
    <appNotificationManagerContext.Provider value={manager}>
      <appNotificationStateContext.Provider value={state}>
        {children}
      </appNotificationStateContext.Provider>
    </appNotificationManagerContext.Provider>
  );
};

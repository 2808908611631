import { createTheme } from "@fluentui/react";
import {
  BrandVariants,
  Theme,
  createLightTheme,
} from "@fluentui/react-components";

export const themePrimaryRed = createTheme({
  palette: {
    themePrimary: "#d83b01",
    themeLighterAlt: "#fdf6f3",
    themeLighter: "#f9dcd1",
    themeLight: "#f4beaa",
    themeTertiary: "#e8825d",
    themeSecondary: "#dd4f1b",
    themeDarkAlt: "#c33400",
    themeDark: "#a52c00",
    themeDarker: "#792000",
  },
});

export const themeFileOptimizeButton = createTheme({
  palette: {
    neutralPrimary: "#ebebeb",
    neutralSecondaryAlt: "#ebebeb",
    white: "#060521",
  },
});

// neutralDarkは矢
// neutralPrimayはカレンダーの文字の色
// neutralSecondaryはカレンダー画面外の色
// neutralTertiaryAltはdisableのカレンダー日付
// themePrimaryはカレンダーのハイライト
// neutralLighterがカレンダーの選んだ時のハイライト
// neutralLightがカレンダーの選択日
//  neutralTertiaryAlt: "#04040c",
//  neutralLighter: "#05050f",
//  neutralLight: "#05050e",
export const themePrimaryDark = createTheme({
  palette: {
    themeDarker: "#79a3e5",
    themeDark: "#5085dc",
    themeDarkAlt: "#3472d6",
    themePrimary: "#2264D1",
    themeSecondary: "#1d59b8",
    themeTertiary: "#143c7d",
    themeLight: "#0a1e3f",
    themeLighter: "#051021",
    themeLighterAlt: "#010408",
    black: "#f8f8f8",
    neutralDark: "#f4f4f4",
    neutralPrimary: "#f4f4f4",
    neutralPrimaryAlt: "#dadada",
    neutralSecondary: "#d0d0d0",
    neutralSecondaryAlt: "#cccccc",
    neutralTertiary: "#c8c8c8",
    neutralTertiaryAlt: "#9590A0",
    neutralQuaternary: "#17172c",
    neutralQuaternaryAlt: "rgba(196, 196, 196, 0.2)",
    neutralLight: "rgba(106, 106, 159, 0.3)",
    neutralLighter: "rgba(106, 106, 159, 0.3)",
    neutralLighterAlt: "#05050f",
    white: "#05050F",
  },
});

// fluentUI v9 theme
// base color: #1F56E3 (--figma-color-theme-primary)
const ssuiBrand: BrandVariants = {
  10: "#020206",
  20: "#14152A",
  30: "#1C214C",
  40: "#202B69",
  50: "#233688",
  60: "#2441A8",
  70: "#224DC9",
  80: "#2A59E4",
  90: "#4D67E7",
  100: "#6775EA",
  110: "#7C84ED",
  120: "#8F93F0",
  130: "#A2A3F3",
  140: "#B3B2F6",
  150: "#C4C3F8",
  160: "#D5D3FA",
};

export const lightTheme: Theme = {
  ...createLightTheme(ssuiBrand),
  // ボタン等の角丸
  borderRadiusMedium: "8px",
  // フォント
  fontFamilyBase: "Noto Sans JP",
  // フォントサイズ（シートの行の高さに合わせるためこのサイズに設定）
  fontSizeBase300: "1.57rem",
  lineHeightBase300: "1.4",
  // fluentUIコンポーネントの文字色
  // --figma-color-innerText
  colorNeutralForeground1: "#201f1e",
  // fluentUIコンポーネントの背景色
  colorNeutralBackground1: "#fff",
};

import { OptimizeResponseRaw } from "../../domain/Optimize";
import { OptimizeFormFile, OptimizeFormRaw } from "../../domain/Optimize";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrlOptmize = "/optimize";
const apiUrlEvaluate = "/evaluate";

/**
 * Optimize, Evaluate
 */

export const postOptimize = async ({
  organizationId,
  target_version,
  algorithm_version,
  title,
  comment,
  save_type,
}: {
  organizationId: string;
} & OptimizeFormRaw): Promise<OptimizeResponseRaw> => {
  const { apiOrganizationIdParams, apiHeadersWithJson } =
    getAPIConfigs(organizationId);
  const apiParams = {
    params: {
      ...apiOrganizationIdParams?.params,
      version: target_version,
      save_type,
    },
  };

  const response = await API_CLIENT.post<OptimizeResponseRaw>(
    `${apiUrlOptmize}`,
    {
      algorithm_version,
      title,
      comment,
    },
    {
      ...apiHeadersWithJson,
      ...apiParams,
    }
  );
  return response.data;
};

// ファイルアップロードによる最適化
// カテゴリIdを投げることで、作成されたバージョンのカテゴリを指定可能
// (初期値は空文字列=「未分類」）
export const postFileOptimize = async ({
  organizationId,
  title,
  comment,
  file,
  algorithm_version,
  category,
}: {
  organizationId: string;
} & OptimizeFormFile): Promise<OptimizeResponseRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const form = new FormData();
  form.append("file", file);
  form.append("title", title);
  form.append("comment", comment);
  form.append("algorithm_version", algorithm_version);
  form.append("file_name", file.name);

  const response = await API_CLIENT.post<OptimizeResponseRaw>(
    `${apiUrlOptmize}/file`,
    form,
    {
      params: {
        ...apiOrganizationIdParams?.params,
        category,
      },
    }
  );
  return response.data;
};

export const postEvaluate = async ({
  organizationId,
  target_version,
  algorithm_version,
  title,
  comment,
  save_type,
}: {
  organizationId: string;
} & OptimizeFormRaw): Promise<OptimizeResponseRaw> => {
  const { apiOrganizationIdParams, apiHeadersWithJson } =
    getAPIConfigs(organizationId);
  const apiParams = {
    params: {
      ...apiOrganizationIdParams?.params,
      version: target_version,
      save_type,
    },
  };

  const response = await API_CLIENT.post<OptimizeResponseRaw>(
    `${apiUrlEvaluate}`,
    {
      algorithm_version,
      title,
      comment,
    },
    {
      ...apiHeadersWithJson,
      ...apiParams,
    }
  );
  return response.data;
};

// ファイルアップロードによる評価
// カテゴリIdを投げることで、作成されたバージョンのカテゴリを指定可能
// (初期値は空文字列=「未分類」）
export const postFileEvaluate = async ({
  organizationId,
  title,
  comment,
  file,
  algorithm_version,
  category,
}: {
  organizationId: string;
} & OptimizeFormFile): Promise<OptimizeResponseRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const form = new FormData();
  form.append("file", file);
  form.append("title", title);
  form.append("comment", comment);
  form.append("algorithm_version", algorithm_version);
  form.append("file_name", file.name);

  const response = await API_CLIENT.post<OptimizeResponseRaw>(
    `${apiUrlEvaluate}/file`,
    form,
    {
      params: {
        ...apiOrganizationIdParams?.params,
        category: category,
      },
    }
  );
  return response.data;
};

import { ConnectMasterRaw } from "@/domain/ConnectMaster";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrl = "admin/connect_masters";

// 組織ごとに利用可能なConnectのリスト表示
export const getAdminConnectMasters = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<ConnectMasterRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: ConnectMasterRaw[] }>(apiUrl, {
    ...apiOrganizationIdParams,
  });
  return response.data.items;
};
export const getAdminConnectMaster = async ({
  organizationId,
  connectMasterId,
}: {
  organizationId: string;
  connectMasterId: string;
}): Promise<ConnectMasterRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ item: ConnectMasterRaw }>(
    `${apiUrl}/${connectMasterId}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postAdminEnableConnectMaster = async ({
  organizationId,
  connectMasterId,
}: {
  organizationId: string;
  connectMasterId: string;
}): Promise<ConnectMasterRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: ConnectMasterRaw }>(
    `${apiUrl}/${connectMasterId}/enable`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};
export const postAdminDisableConnectMaster = async ({
  organizationId,
  connectMasterId,
}: {
  organizationId: string;
  connectMasterId: string;
}): Promise<ConnectMasterRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: ConnectMasterRaw }>(
    `${apiUrl}/${connectMasterId}/disable`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

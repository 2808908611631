import { format, formatISO, parse, parseISO } from "date-fns";
import { ja } from "date-fns/locale/ja";
import React, { useState } from "react";

import { Version, useSetVersion } from "../../../../../domain/Version";
import { ManagedTransaction } from "../../../../../firebase/firestore";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";

import { DATETIME_WITH_SECONDS_FORMAT } from "@/config/date";

export const DialogVersionEnableSome: React.FC<
  {
    versions: Version[];
    selectedVersionIds: string[];
    setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  versions,
  selectedVersionIds,
  setSelectedVersionIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const setVersion = useSetVersion();

  const selectedVersions = versions.filter(({ id }) =>
    selectedVersionIds.includes(id)
  );

  const { renderDialogs, setIsInProgress, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });
  const progressTotal = selectedVersionIds.length;
  const [progressCount, setProgressCount] = useState(0);

  const runEnable = async () => {
    if (selectedVersions.length > 0) {
      setProgressCount(0);
      setDialogPhase("in_progress");
      const lastModifiedAt = format(new Date(), DATETIME_WITH_SECONDS_FORMAT, {
        locale: ja,
      });
      try {
        await ManagedTransaction.runTransaction(async (transaction) => {
          for (const version of selectedVersions) {
            await setVersion(
              {
                ...version,
                lastModifiedAt,
                isDisabled: false,
              },
              {},
              { transaction }
            );
            setProgressCount((cnt) => cnt + 1);
          }
        });
        setDialogPhase("success");
      } catch (error) {
        showErrorAPIDialog(error);
      }
    }
  };
  const messages = getDialogMessages({
    isSome: true,
    actionType: "enable",
    targetType: "version",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runEnable,
          onClickCancel: () => {
            setSelectedVersionIds([]);
          },
          ...messages.confirm,
        },
        inProgress: {
          ...messages.inProgress,
          count: {
            current: progressCount,
            total: progressTotal,
          },
        },
        success: {
          ...messages.success,
          onClick: () => {
            setSelectedVersionIds([]);
          },
        },
        errorAPI: {
          ...messages.error,
        },
      })}
    </>
  );
};

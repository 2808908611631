import React, {
  useMemo,
  useState,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";

import { useAppRouteParams } from "../../../AppRoutes";

import { getAPIErrorDetailAndMessage } from "@/api/helpers";
import { getMe } from "@/api/me";
import { useAppAccount } from "@/components/AppProvider/AppAccountProvider";
import { Version } from "@/domain/Version";
import { Me, MeRaw } from "@/domain/me";
import {
  AllActionsType,
  PermissionsKey,
  PermissionsWithIdKey,
  orgAdminRolePermissions,
  orgEditorRolePermissions,
  orgViewerRolePermissions,
} from "@/domain/roles";
import { parsePermissions } from "@/helpers/permissions";

const defaultRoles = {
  "org:admin": orgAdminRolePermissions,
  "org:editor": orgEditorRolePermissions,
  "org:viewer": orgViewerRolePermissions,
};

const permissionsCtx = createContext<{
  me: Me | null;
  isLoadedMe: boolean;
  runReloadMe: () => Promise<void>;
  hasPermission: (
    permission: PermissionsKey,
    action: AllActionsType
  ) => boolean;
  hasPermissionWithId: (
    permission: PermissionsWithIdKey,
    action: AllActionsType,
    id: string
  ) => boolean;
  hasVersionSheetPermission: (
    action: AllActionsType,
    id: { versionId: string; sheetId: string }
  ) => boolean;
  hasVersionActionPermission: (
    version: Version | null,
    action: AllActionsType
  ) => boolean;
  hasSheetActionPermission: (
    version: Version | null,
    sheetId: string,
    action: AllActionsType
  ) => boolean;
}>({
  me: null,
  isLoadedMe: false,
  runReloadMe: async () => {},
  hasPermission: (permission, action) => false,
  hasPermissionWithId: (permission, action, id) => false,
  hasVersionSheetPermission: (permission, action) => false,
  hasVersionActionPermission: (version, action) => false,
  hasSheetActionPermission: (version, sheetId, action) => false,
});

// 自身のメンバ情報およびロール情報を取得し各所に提供する
export const PermissionsProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { account } = useAppAccount();
  const { organizationId } = useAppRouteParams();
  const [me, setMe] = useState<Me | null>(null);
  const [isInProgress, setIsInProgress] = useState(false);
  const [_, setError] = useState<unknown>(null);

  const runReloadMe = useCallback(async () => {
    if (!account || !organizationId) return;
    setIsInProgress(true);
    try {
      const res: MeRaw = await getMe({ organizationId, uid: account.uid });
      const parsedMe: Me = {
        ...res,
        permissions: res.role
          ? parsePermissions(res.role.permissions)
          : defaultRoles[res.member.role],
      };
      setMe(parsedMe);
    } catch (err) {
      setError(() => {
        if (typeof err == "object") {
          const e = {
            ...err,
            message: getAPIErrorDetailAndMessage(err),
          } as Error;
          throw e;
        } else {
          throw new Error("不明なエラー");
        }
      });
    } finally {
      setIsInProgress(false);
    }
  }, [account, organizationId]);

  // ユーザー自身が「permissionに」「actionsを」行う権限を持っているかどうかを判定する
  const hasPermission = useCallback(
    (permission: PermissionsKey, action: AllActionsType) => {
      if (!me) return false;
      return me.permissions[permission][action] ?? false;
    },
    [me]
  );

  // ユーザー自身が「id付きpermissionに」「actionsを」行う権限を持っているかどうかを判定する
  const hasPermissionWithId = useCallback(
    (permission: PermissionsWithIdKey, action: AllActionsType, id: string) => {
      if (!me) return false;
      return me.permissions[permission][action].includes(id);
    },
    [me]
  );

  // version_sheetの権限を持っているかどうかを判定する
  const hasVersionSheetPermission = useCallback(
    (action: AllActionsType, id: { versionId: string; sheetId: string }) => {
      if (!me) return false;
      return me.permissions.version_sheet[action].includes(id);
    },
    [me]
  );

  // 任意のバージョンに対しての任意のアクションの権限判定
  const hasVersionActionPermission = useCallback(
    (version: Version | null, action: AllActionsType) => {
      if (!version) return false;
      return (
        hasPermission("versions", action) ||
        hasPermissionWithId(
          "versions_version_category",
          action,
          version.category
        ) ||
        hasPermissionWithId("version", action, version.id)
      );
    },
    [hasPermission, hasPermissionWithId]
  );

  // 任意のシートに対しての任意のアクションの権限判定
  const hasSheetActionPermission = useCallback(
    (version: Version | null, sheetId: string, action: AllActionsType) => {
      if (!version) return false;
      return (
        hasPermission("versions", action) ||
        hasPermissionWithId(
          "versions_version_category",
          action,
          version.category ?? ""
        ) ||
        hasPermissionWithId("version", action, version.id) ||
        hasPermissionWithId("sheet", action, sheetId) ||
        hasVersionSheetPermission(action, { versionId: version.id, sheetId })
      );
    },
    [hasPermission, hasPermissionWithId, hasVersionSheetPermission]
  );

  useEffect(() => {
    runReloadMe();
  }, [runReloadMe]);

  const permissionsCtxValue = useMemo(
    () => ({
      me,
      isLoadedMe: !isInProgress,
      runReloadMe,
      hasPermission,
      hasPermissionWithId,
      hasVersionSheetPermission,
      hasVersionActionPermission,
      hasSheetActionPermission,
    }),
    [
      me,
      isInProgress,
      runReloadMe,
      hasPermission,
      hasPermissionWithId,
      hasVersionSheetPermission,
      hasVersionActionPermission,
      hasSheetActionPermission,
    ]
  );

  return (
    <permissionsCtx.Provider value={permissionsCtxValue}>
      {children}
    </permissionsCtx.Provider>
  );
};

export const usePermissions = () => {
  return useContext(permissionsCtx);
};

import { cyrb53 } from "@/utils/cyrb53";
import {
  FieldPath,
  Firestore,
  SetOptions,
  and,
  arrayRemove,
  arrayUnion,
  collection,
  deleteField,
  doc,
  getFirestore,
  increment,
  orderBy,
  query,
  serverTimestamp,
  startAt,
  where,
} from "firebase/firestore";

const getClassMap = (firestore: Firestore) => {
  const cRef = collection(firestore, "/app");
  const dRef = doc(firestore, "/app/a");
  const q = query(
    cRef,
    [
      where("__name__", "==", "a"),
      where("a", "==", "b"),
      where("c", "array-contains-any", ["d", "e"]),
      where("c", "in", ["d", "e"]),
    ].reduce((a, b) => (a ? and(a, b) : b), null as any),
    orderBy("e"),
    startAt("f")
  );
  const q2 = query(
    cRef,
    [
      where("c", "array-contains", ["d", "e"]),
      where("c", "not-in", ["d", "e"]),
    ].reduce((a, b) => (a ? and(a, b) : b), null as any)
  );
  const from = new Map();
  const to = new Map();
  const constructorCollection = new Set<Function>();
  const collectConstructor = (obj: Object) => {
    if (obj instanceof Firestore) return;
    constructorCollection.add(obj.constructor);
    for (const key of Object.getOwnPropertyNames(obj)) {
      const value = (obj as any)[key];
      if (value !== null && typeof value === "object") {
        collectConstructor(value);
      }
      if (Array.isArray(value)) {
        value.forEach(collectConstructor);
      }
    }
  };
  [
    q,
    q2,
    cRef,
    dRef,
    deleteField(),
    serverTimestamp(),
    arrayUnion(),
    arrayRemove(),
    increment(1),
    new FieldPath(),
  ].forEach(collectConstructor);
  [...constructorCollection].forEach((v, i) => {
    if (v === Object || v === Array) return;
    // console.log(v.name, i);
    from.set(v, i);
    to.set(i, v);
  });
  return { from, to };
};
let classMap: ReturnType<typeof getClassMap> | null = null;

export type SWHEncodedChunk =
  | string
  | number
  | boolean
  | null
  | undefined
  | SWHEncodedChunk[]
  | {
      t: number;
      v: [string, SWHEncodedChunk][];
    }
  | {
      __swhencoded?: true;
    };
export type SWHMessageData = { id: string } & (
  | { action: "subscribe"; query: SWHEncodedChunk }
  | { action: "getDoc"; query: SWHEncodedChunk }
  | { action: "getDocs"; query: SWHEncodedChunk }
  | {
      action: "setDoc";
      query: SWHEncodedChunk;
      data: SWHEncodedChunk;
      options?: SetOptions;
    }
  | { action: "deleteDoc"; query: SWHEncodedChunk }
  | { action: "startTransaction" }
  | ({ transactionId: string; query: SWHEncodedChunk } & (
      | {
          action: "transaction.get";
        }
      | {
          action: "transaction.set";
          data: SWHEncodedChunk;
          options?: SetOptions;
        }
      | {
          action: "transaction.update";
          data: SWHEncodedChunk;
        }
      | {
          action: "transaction.delete";
        }
    ))
  | { action: "commitTransaction"; transactionId: string }
  | { action: "unsubscribe" }
  | { action: "keepalive" }
);

type SupportedFirestoreEncodeTypes =
  | string
  | number
  | boolean
  | null
  | undefined
  | {
      __swhencoded?: never;
      [key: string | symbol]: any;
    };

const __SWH_FIRESTORE = "$__SWHfirestore";

const encodeFirestoreObjectChunk = (
  c: SupportedFirestoreEncodeTypes,
  normalize: boolean
): SWHEncodedChunk => {
  if (Array.isArray(c))
    return c.map((c) => encodeFirestoreObjectChunk(c, normalize));
  if (!c || typeof c !== "object") return c;
  if (c instanceof Firestore) return __SWH_FIRESTORE;
  classMap = classMap ?? getClassMap(getFirestore());
  const t = classMap.from.get(c.constructor) ?? -1;
  const v = [...Object.entries(c)].map<[string, SWHEncodedChunk]>(([k, v]) => [
    k,
    encodeFirestoreObjectChunk(v, normalize),
  ]);
  if (normalize) v.sort(([a], [b]) => a.localeCompare(b));
  return {
    t,
    v,
  };
};
export const encodeFirestoreObject = (
  q: SupportedFirestoreEncodeTypes,
  normalize = false
) => {
  const r = encodeFirestoreObjectChunk(q, normalize);
  return r;
};
const decodeFirestoreObjectChunk = (c: SWHEncodedChunk): any => {
  if (Array.isArray(c)) return c.map(decodeFirestoreObjectChunk);
  if (c === __SWH_FIRESTORE) return getFirestore();
  if (!c || typeof c !== "object") return c;
  classMap = classMap ?? getClassMap(getFirestore());
  const { t, v } = c as {
    t: number;
    v: [string, SWHEncodedChunk][];
  };
  const cls = t !== -1 ? classMap.to.get(t) : Object;
  return Object.create(
    cls.prototype,
    Object.fromEntries(
      v.map(([k, v]: any[]) => [
        k,
        {
          configurable: true,
          enumerable: true,
          value: decodeFirestoreObjectChunk(v),
          writable: true,
        },
      ])
    )
  );
};
export const decodeFirestoreObject = (q: SWHEncodedChunk) => {
  const r = decodeFirestoreObjectChunk(q);
  return r;
};
export const computeEncodedFirestoreQueryHash = (q: SWHEncodedChunk) => {
  const json = JSON.stringify(q);
  return cyrb53(json).toString(36);
};

import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { AppConnectMasterRaw } from "@/domain/AppConnectMasters";
import { useAdminAPI } from "../hooks";

const ctx = createContext<{
  appConnectMasters: AppConnectMasterRaw[] | null;
  runReloadAppConnectMasters: () => Promise<void>;
  isLoadedAppConnectMasters: boolean;
}>({
  appConnectMasters: null,
  runReloadAppConnectMasters: async () => {
    //nop
  },
  isLoadedAppConnectMasters: false,
});

export const AdminAppConnectMastersProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: appConnectMasters,
    runReloadState: runReloadAppConnectMasters,
    isLoadedState: isLoadedAppConnectMasters,
  } = useAdminAPI<AppConnectMasterRaw[]>("appConnectMasters");

  const ctxValue = useMemo(
    () => ({
      appConnectMasters,
      runReloadAppConnectMasters,
      isLoadedAppConnectMasters,
    }),
    [appConnectMasters, runReloadAppConnectMasters, isLoadedAppConnectMasters]
  );

  return <ctx.Provider value={ctxValue}>{children}</ctx.Provider>;
};

export const useAdminAppConnectMasters = () => {
  return useContext(ctx);
};

import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FieldValue,
  Query,
  QueryCompositeFilterConstraint,
  QueryFilterConstraint,
  QuerySnapshot,
  SetOptions,
  Transaction,
  Unsubscribe,
  WhereFilterOp,
  WithFieldValue,
  and,
  collection,
  deleteField,
  doc,
  onSnapshot,
  or,
  query,
  where,
} from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useAppRouteParams } from "../AppRoutes";

import { firestore } from ".";

import { deprecatedLogger } from "@/utils/decoratedLogger";
import { SubscriptionCacheManager } from "@/utils/subscriptionCacheManager";

import {
  deleteDocInWorker,
  getDocInWorker,
  getDocsInWorker,
  onSnapshotInWorker,
  queryToSnapshotSubscribeKey,
  runWorkerTransaction,
  setDocInWorker,
} from "./snapshotWorker/port";

const collectionPathErrorMessage = "CollectionPath is not available for ";

const TRANSACTION_WRITE_LIMIT = 100;

const subscribeCollectionInDiffModeManager = new SubscriptionCacheManager<
  {
    reference: Query<any, any>;
    parser: (data: any) => any;
  },
  [any[]]
>(
  (key, onDiff, { reference, parser }) => {
    let data: any[] = [];
    let isInitialized = false;

    const unsubscribe = onSnapshotInWorker(reference, (r) => {
      if (r.type !== "query") {
        throw "invalid reference";
      }
      data = [...data];
      if (process.env.NODE_ENV === "development") {
        data.sort = () => {
          throw new Error("DO NOT MODIFY SUBSCRIBED DATA");
        };
      }
      r.changes.forEach((change) => {
        const { oldIndex, newIndex, doc, type } = change;
        const _data = doc.data;
        doc.data = () => _data;
        if (type === "modified") {
          if (oldIndex === newIndex) {
            data[newIndex] = parser(doc);
          } else {
            data.splice(oldIndex, 1);
            data.splice(newIndex, 0, parser(doc));
          }
        } else if (type === "added") {
          data.splice(newIndex, 0, parser(doc));
        } else if (type === "removed") {
          data.splice(oldIndex, 1);
        }
      });
      isInitialized = true;
      onDiff(data);
    });
    return [() => [isInitialized, () => [data]], unsubscribe];
  },
  {
    graceTime: 1000 * 5,
    maxGraces: 40,
  }
);
const subscribeCollectionInDiffMode = <
  AppModelType,
  DbModelType extends DocumentData,
  ParsedDataType,
>(
  reference: Query<AppModelType, DbModelType>,
  parser: (data: DocumentData) => ParsedDataType,
  onDiff: (data: ParsedDataType[]) => void
) => {
  return subscribeCollectionInDiffModeManager.subscribe(
    queryToSnapshotSubscribeKey(reference),
    { reference, parser },
    (data) => onDiff(data)
  );
};

export class ManagedTransaction {
  _transaction: Promise<Transaction> | null;
  _commitTransaction: (() => Promise<void>) | null;
  _queue: Promise<unknown>;
  writeCount: number;
  constructor() {
    this._transaction = null;
    this._commitTransaction = null;
    this._queue = Promise.resolve();
    this.writeCount = 0;
  }
  async commitTransaction(): Promise<void> {
    await this._commitTransaction?.();
  }
  async dispose() {
    await this.commitTransaction();
    await this._queue;
  }
  _getTransaction(): Promise<Transaction> {
    if (this._transaction) return this._transaction;
    let endTransactionAndWaitQueue: (() => Promise<void>) | null = null;
    const transactionRoundJob = this._queue.then(
      () =>
        new Promise<Transaction>((r) => {
          const transactionJob = runWorkerTransaction(
            firestore,
            (transaction) =>
              new Promise<void>((endTransaction) => {
                endTransactionAndWaitQueue = async () => {
                  endTransaction();
                  await transactionJob;
                };
                r(transaction);
              })
          );
        })
    );
    this._transaction = transactionRoundJob;
    this._commitTransaction = async () => {
      this._transaction = null;
      this._commitTransaction = null;
      const commitRoundJob = this._queue.then(async () => {
        await transactionRoundJob;
        await endTransactionAndWaitQueue!();
      });
      this._queue = commitRoundJob;
      return commitRoundJob;
    };
    this._queue = transactionRoundJob;
    return transactionRoundJob;
  }
  getTransaction(incrementWriteCount: boolean): Promise<Transaction> {
    if (incrementWriteCount) this.writeCount++;
    if (this.writeCount >= TRANSACTION_WRITE_LIMIT) {
      this.writeCount = 0;
      this.commitTransaction();
    }
    return this._getTransaction();
  }
  static async runTransaction(
    fn: (managedTransaction: ManagedTransaction) => Promise<void>
  ) {
    const managedTransaction = new ManagedTransaction();
    await fn(managedTransaction);
    await managedTransaction.dispose();
  }
  async get<T, DbModelType extends DocumentData>(
    documentRef: DocumentReference<T, DbModelType>
  ) {
    const transaction = await this.getTransaction(false);
    return transaction.get<T, DbModelType>(documentRef);
  }
  async set<T, DbModelType extends DocumentData>(
    documentRef: DocumentReference<T, DbModelType>,
    data: WithFieldValue<T>,
    options?: SetOptions
  ) {
    const transaction = await this.getTransaction(true);
    return transaction.set<T, DbModelType>(documentRef, data, options!);
  }
  async delete<T, DbModelType extends DocumentData>(
    documentRef: DocumentReference<T, DbModelType>
  ) {
    const transaction = await this.getTransaction(true);
    return transaction.delete<T, DbModelType>(documentRef);
  }
}

/**
 * @description Firestoreのコレクション情報をやり取りするためのオプション指定型
 * この型で作成したオブジェクトを、createFirestoreCollectionProsessorに渡す
 *
 * 注意：サブコレクションを持つドキュメントを作成する場合は、サブコレクションの定義をした上でimportする必要がある
 *
 * @return getCollectionPath: Firestore用の参照先パラメータをURLから取得する関数。バージョンidなどを入力とする
 * ex: `/app/spreadsheet_ui/v/1/organizations/${organizationId}/versions`
 *
 * @return toFirestore: Firestoreにオブジェクトを書き込むためにキー名を変換する関数
 *
 * @return fromFirestore: Firestoreからオブジェクトを読み込むためにキー名を変換する関数
 *
 * @alias T フロントで扱うオブジェクト型. idだけ必須.extendsは&による交差型と同等の動きをするらしい
 * @alias R Firestoreで扱うオブジェクト型. id以外にも何かしらキーを持つことが必須
 * @alias RP routeParamsのオプション用の型。でも基本はuseAppRouteParamsのreturn typeで事足りる
 *
 */
export type FirestoreCollectionProsessorOptions<
  T extends { id: string },
  R extends { id: string; [key: string]: any },
  RP extends { [key: string]: string | undefined } = Record<string, string>,
> = {
  getCollectionPath(
    params: Partial<ReturnType<typeof useAppRouteParams>> & RP
  ): string;
  toFirestore(value: T): R;
  fromFirestore(raw: R): T;
};

export class FirestoreRecursiveDeleteTransactionQueue {
  docPaths: string[];
  ended: boolean;
  constructor(docPaths: string[]) {
    this.docPaths = docPaths;
    this.ended = false;
  }
  async exec(transaction?: Transaction | ManagedTransaction) {
    if (this.ended) throw new Error("dup!");
    for (const docPath of this.docPaths) {
      if (transaction) {
        await transaction.delete(doc(firestore, docPath));
      } else {
        await deleteDocInWorker(doc(firestore, docPath));
      }
    }
    this.ended = true;
  }
}

async function getSubDocPaths(docPath: string, subCollectionDepthMap: any) {
  let result: string[] = [];
  for (const [collectionName, depthMapChunk] of Object.entries(
    subCollectionDepthMap
  )) {
    const collectionPath = docPath + "/" + collectionName;
    const docPaths: string[] = [];
    const querySnapshot = await getDocsInWorker(
      collection(firestore, collectionPath)
    );
    querySnapshot.forEach((doc) => {
      docPaths.push(collectionPath + "/" + doc.id);
    });
    for (const docPath of docPaths) {
      result = result.concat(
        await getSubDocPaths(docPath, subCollectionDepthMap[collectionName])
      );
    }
    result = result.concat(docPaths);
  }
  return result;
}

const addedCollectionDepthPaths: string[] = [];
const addedCollectionDepthMap: any = {};

export type FirestoreQuerySetting<P extends string = string> = {
  fieldPath: P;
  opStr: WhereFilterOp;
  value: unknown;
};
export type ComplexFirestoreQuerySetting<P extends string = string> =
  | FirestoreQuerySetting<P>
  | {
      logicalOp: "and" | "or";
      ops: FirestoreQuerySetting<P>[];
    };
const complexFirestoreQuerySetting2queryFilterConstraint = <
  P extends string = string,
>(
  q: ComplexFirestoreQuerySetting<P>
): QueryFilterConstraint | QueryCompositeFilterConstraint =>
  "opStr" in q
    ? where(q.fieldPath as any as string, q.opStr, q.value)
    : q.ops
        .map((c) => complexFirestoreQuerySetting2queryFilterConstraint(c))
        .reduce((a, b) => (q.logicalOp === "and" ? and(a, b) : or(a, b)));

/**
 * 引数を元に、Firestoreとやり取りする関数を返す
 * 基本的に全てasyncな無名関数をuseMemoで返している
 * isLoadは、3つの状態と定義する
 * 1.ロード完了
 * 2.ロード中
 * 3.ロード失敗
 *
 * NOTE 関数内でuseEffectを使うと、利用コンポーネントのライフサイクルに合わせてuseEffectが実行。コンポーネントの中で関数が展開されると考える
 *
 * useGet: 指定コレクション内の、指定idのドキュメントを取得
 * useGetAll: 指定コレクション内の、全ドキュメントを取得
 * useSet: 指定コレクション内の、指定idのドキュメントを作成/更新
 * useUpdateObjectFields: 指定コレクション内の、指定idのドキュメント内のオブジェクトを更新
 * useDeleteObjectFields: 指定コレクション内の、指定idのドキュメント内のオブジェクトを削除
 * useDelete: 指定コレクション内の、指定idのドキュメントを削除
 * useRecursiveDelete: 指定コレクション配下を再帰的に削除
 * useItem: 指定コレクション内の、指定idのドキュメントをsnapshotとして取得する
 * useItems: 指定コレクション内の、全ドキュメントをsnapshotとして取得する
 * useItemsQuery: 指定コレクション内でqueryを行なって、ドキュメントをsnapshotとして取得する
 * useItemsLegacyQuery: 指定コレクション内でqueryを行なって、ドキュメントをsnapshotとして取得する(deprecated)
 * useSomeItems: 複数のonSnapshotを状態として管理する
 * useSomeItemsQuery: 複数のonSnapshotをQueryをかけた上で、状態として管理する
 * useSomeItemsLegacyQuery: 複数のonSnapshotをQueryをかけた上で、状態として管理する(deprecated)
 */
type FirestoreCollectionProsessor<
  T extends { id: string },
  R extends { id: string; [key: string]: any },
  RP extends { [key: string]: string | undefined } = Record<string, string>,
> = {
  toFirestore(value: T): R;
  fromFirestore(raw: R): T;
  useGet(): (
    id: string,
    routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>,
    options?: {
      transaction?: Transaction | ManagedTransaction;
      checkRawChunk?: Partial<R>;
    }
  ) => Promise<T | null>;
  useGetAll(): (
    routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>,
    options?: {
      // @ts-ignore
      where?: ComplexFirestoreQuerySetting<keyof R>;
      transaction?: Transaction | ManagedTransaction;
    }
  ) => Promise<T[]>;
  useSet(): (
    value: T,
    routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>,
    options?: { transaction?: Transaction | ManagedTransaction }
  ) => Promise<T>;
  useUpdateObjectFields(): (
    id: string,
    objectFields: {
      targetFieldKey: keyof R;
      objects: { key: string; value: string }[];
    },
    routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>,
    options?: { transaction?: Transaction | ManagedTransaction }
  ) => Promise<string[]>;
  useDelete(): (
    id: string,
    routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>,
    options?: { transaction?: Transaction | ManagedTransaction }
  ) => Promise<void>;
  useRecursiveDelete(): (
    id: string,
    routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>,
    options?: Record<string, string>
  ) => Promise<FirestoreRecursiveDeleteTransactionQueue>;
  useDeleteObjectFields(): (
    id: string,
    objectFields: {
      targetFieldKey: keyof R;
      keys: string[];
    },
    routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>,
    options?: { transaction?: Transaction | ManagedTransaction }
  ) => Promise<void>;
  useItem(
    id: string | null,
    routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>
  ): [T | null, true] | [null, false];
  useItems(
    routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>
  ): [T[], boolean];
  useItemsQuery(
    routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>,
    getInitQuerySetting?: () => Promise<ComplexFirestoreQuerySetting | null>
  ): [
    T[],
    boolean,
    ComplexFirestoreQuerySetting | null,
    (querySettingParam: ComplexFirestoreQuerySetting | null) => void,
  ];
  useItemsLegacyQuery(
    routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>,
    getInitQuerySettings?: () => Promise<FirestoreQuerySetting[]>
  ): [
    T[],
    boolean,
    FirestoreQuerySetting[] | null,
    (querySettingsParam: FirestoreQuerySetting[]) => void,
  ];
  useSomeItems(
    routeParamsList: (RP & Partial<ReturnType<typeof useAppRouteParams>>)[]
  ): [
    {
      data: T[];
      routeParamsMap: Map<string, string | undefined>;
      collectionPath: string;
      isLoaded: boolean;
    }[],
    boolean,
  ];
  useSomeItemsQuery(
    params: {
      routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>;
      getInitQuerySetting?: () => Promise<ComplexFirestoreQuerySetting | null>;
    }[]
  ): [
    {
      data: T[];
      routeParamsMap: Map<string, string | undefined>;
      collectionPath: string;
      isLoaded: boolean;
    }[],
    boolean,
    {
      querySetting: ComplexFirestoreQuerySetting | null;
      routeParamsMap: Map<string, string | undefined>;
      collectionPath: string;
    }[],
    (
      params: {
        querySetting: ComplexFirestoreQuerySetting | null;
        routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>;
      }[]
    ) => void,
  ];
  useSomeItemsLegacyQuery(
    params: {
      routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>;
      getInitQuerySettings?: () => Promise<FirestoreQuerySetting[]>;
    }[]
  ): [
    {
      data: T[];
      routeParamsMap: Map<string, string | undefined>;
      collectionPath: string;
      isLoaded: boolean;
    }[],
    boolean,
    {
      querySettings: FirestoreQuerySetting[] | null;
      routeParamsMap: Map<string, string | undefined>;
      collectionPath: string;
    }[],
    (
      params: {
        querySettings: FirestoreQuerySetting[];
        routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>;
      }[]
    ) => void,
  ];
};
export function createFirestoreCollectionProsessor<
  T extends { id: string },
  R extends { id: string; [key: string]: any },
  RP extends { [key: string]: string | undefined } = Record<string, string>,
>({
  getCollectionPath,
  toFirestore,
  fromFirestore,
}: FirestoreCollectionProsessorOptions<T, R, RP>): FirestoreCollectionProsessor<
  T,
  R,
  RP
> {
  const testPath = getCollectionPath({} as any);
  const collectionDepthPath = testPath.replace(/(\/[^/]+)\/[^/]*/g, "$1");
  if (
    addedCollectionDepthPaths.some((c) => collectionDepthPath.startsWith(c))
  ) {
    throw new Error("subcollections should initialized before parent!");
  }
  addedCollectionDepthPaths.push(collectionDepthPath);
  const collectionDepthList = collectionDepthPath.split("/").slice(1);
  let subCollectionDepthMap = addedCollectionDepthMap;
  for (const collectionPart of collectionDepthList) {
    subCollectionDepthMap = subCollectionDepthMap[collectionPart] =
      subCollectionDepthMap[collectionPart] || {};
  }
  const hasSubCollection = !!Object.keys(subCollectionDepthMap).length;

  const processorSrc: Omit<
    FirestoreCollectionProsessor<T, R, RP>,
    "useGetAll"
  > & {
    useTransactionUnsupportedGetAll: () => (
      ...args: Parameters<
        ReturnType<FirestoreCollectionProsessor<T, R, RP>["useGetAll"]>
      >
    ) => Promise<[T[], R[]]>;
  } = {
    fromFirestore,
    toFirestore,
    useGet() {
      const appRouteParams = useAppRouteParams();
      return useMemo(
        () =>
          async (id, routeParams, { transaction, checkRawChunk } = {}) => {
            const collectionPath = getCollectionPath({
              ...appRouteParams,
              ...routeParams,
            });
            if (!collectionPath || collectionPath.indexOf("undefined") !== -1)
              throw new Error(collectionPathErrorMessage + "get");
            // 指定コレクション内の、idの識別子を持つdocへの参照
            const docRef = doc(firestore, collectionPath, id);
            let docSnap: DocumentSnapshot<DocumentData>;
            if (transaction) {
              // 排他制御をしたい時
              docSnap = await transaction.get(docRef);
            } else {
              docSnap = await getDocInWorker(docRef);
            }
            if (!docSnap.exists()) {
              return null;
            } else {
              const rawDocData = docSnap.data() as R;
              if (
                checkRawChunk &&
                Object.entries(checkRawChunk).some(
                  ([key, value]) => rawDocData[key] !== value
                )
              ) {
                return null;
              }
              return fromFirestore(rawDocData);
            }
          },
        [appRouteParams]
      );
    },
    useTransactionUnsupportedGetAll() {
      const appRouteParams = useAppRouteParams();
      return useMemo(
        () =>
          async (routeParams, { where: whereSrc, transaction } = {}) => {
            const collectionPath = getCollectionPath({
              ...appRouteParams,
              ...routeParams,
            });
            if (!collectionPath || collectionPath.indexOf("undefined") !== -1)
              throw new Error(collectionPathErrorMessage + "get all");
            const colRef = collection(firestore, collectionPath);
            let targetQuery: Query<DocumentData, DocumentData> = colRef;
            if (whereSrc) {
              targetQuery = query(
                colRef,
                complexFirestoreQuerySetting2queryFilterConstraint(
                  whereSrc as ComplexFirestoreQuerySetting<string>
                ) as QueryCompositeFilterConstraint
              );
            }
            let queSnap: QuerySnapshot<DocumentData>;
            if (transaction) {
              throw new Error("can not use transaction for get all");
            } else {
              queSnap = await getDocsInWorker(targetQuery);
            }
            const result: T[] = [];
            const rawResult: R[] = [];
            queSnap.forEach((docSnap) => {
              const rawDocData = docSnap.data() as R;
              result.push(fromFirestore(rawDocData));
              rawResult.push(rawDocData);
            });
            return [result, rawResult];
          },
        [appRouteParams]
      );
    },
    useSet() {
      const appRouteParams = useAppRouteParams();
      return useMemo(
        () =>
          async (value, routeParams, { transaction } = {}) => {
            const collectionPath = getCollectionPath({
              ...appRouteParams,
              ...routeParams,
            });
            if (!collectionPath || collectionPath.indexOf("undefined") !== -1)
              throw new Error(collectionPathErrorMessage + "set");
            const docRef = doc(firestore, collectionPath, value.id);
            if (transaction) {
              await transaction.set(docRef, toFirestore(value), {
                merge: true,
              });
            } else {
              await setDocInWorker(docRef, toFirestore(value), { merge: true });
            }
            return value;
          },
        [appRouteParams]
      );
    },
    useUpdateObjectFields() {
      const appRouteParams = useAppRouteParams();
      return useMemo(
        () =>
          async (id, objectFields, routeParams, { transaction } = {}) => {
            const collectionPath = getCollectionPath({
              ...appRouteParams,
              ...routeParams,
            });
            if (!collectionPath || collectionPath.indexOf("undefined") !== -1)
              throw new Error(
                collectionPathErrorMessage + "update object fields"
              );
            const docRef = doc(firestore, collectionPath, id);

            const updateValue: { [k: string]: { [k: string]: string } } = {};
            const { targetFieldKey, objects } = objectFields;
            const updateValueNested: { [k: string]: string } = {};
            objects.map(({ key, value }) => {
              updateValueNested[`${key}`] = value;
            });
            const _targetFieldKey = targetFieldKey as string;
            updateValue[`${_targetFieldKey}`] = updateValueNested;
            if (transaction) {
              await transaction.set(docRef, updateValue, { merge: true });
            } else {
              await setDocInWorker(docRef, updateValue, { merge: true });
            }
            return objects.map(({ value }) => value);
          },
        [appRouteParams]
      );
    },
    useDelete() {
      if (hasSubCollection)
        throw new Error("should use recursiveDelete instead");
      const appRouteParams = useAppRouteParams();
      return useMemo(
        () =>
          async (id, routeParams, { transaction } = {}) => {
            const collectionPath = getCollectionPath({
              ...appRouteParams,
              ...routeParams,
            });
            if (!collectionPath || collectionPath.indexOf("undefined") !== -1)
              throw new Error(collectionPathErrorMessage + "delete");
            const docRef = doc(firestore, collectionPath, id);
            if (transaction) {
              await transaction.delete(docRef);
            } else {
              await deleteDocInWorker(docRef);
            }
          },
        [appRouteParams]
      );
    },
    useRecursiveDelete() {
      if (!hasSubCollection) throw new Error("should use delete instead");
      const appRouteParams = useAppRouteParams();
      return useMemo(
        () => async (id, routeParams) => {
          const collectionPath = getCollectionPath({
            ...appRouteParams,
            ...routeParams,
          });
          if (!collectionPath || collectionPath.indexOf("undefined") !== -1)
            throw new Error(collectionPathErrorMessage + "recursive delete");
          const docPath = collectionPath + "/" + id;
          return new FirestoreRecursiveDeleteTransactionQueue([
            ...(await getSubDocPaths(docPath, subCollectionDepthMap)),
            docPath,
          ]);
        },
        [appRouteParams]
      );
    },
    useDeleteObjectFields() {
      const appRouteParams = useAppRouteParams();
      return useMemo(
        () =>
          async (id, objectFields, routeParams, { transaction } = {}) => {
            const collectionPath = getCollectionPath({
              ...appRouteParams,
              ...routeParams,
            });
            if (!collectionPath || collectionPath.indexOf("undefined") !== -1)
              throw new Error(
                collectionPathErrorMessage + "delete object fields"
              );
            const docRef = doc(firestore, collectionPath, id);

            const { keys, targetFieldKey } = objectFields;
            const updateValue: { [k: string]: { [k: string]: FieldValue } } =
              {};
            const updateValueNested: { [k: string]: FieldValue } = {};
            keys.map((key) => {
              updateValueNested[`${key}`] = deleteField();
            });
            const _targetFieldKey = targetFieldKey as string;
            updateValue[`${_targetFieldKey}`] = updateValueNested;

            if (transaction) {
              await transaction.set(docRef, updateValue, { merge: true });
            } else {
              await setDocInWorker(docRef, updateValue, { merge: true });
            }
          },
        [appRouteParams]
      );
    },
    useItem(id, routeParams) {
      const appRouteParams = useAppRouteParams();
      const [state, setState] = useState<[T | null, true] | [null, false]>([
        null,
        false,
      ]);
      const collectionPath = getCollectionPath({
        ...appRouteParams,
        ...routeParams,
      });
      useEffect(() => {
        setState([null, false]);
        if (
          !id ||
          !collectionPath ||
          collectionPath.indexOf("undefined") !== -1
        ) {
          return;
        }
        // nullを返す=データが存在しない
        const unsubscribe = onSnapshotInWorker(
          doc(firestore, collectionPath, id),
          (r) => {
            if (r.type !== "doc") throw new Error("invalid reference");
            setState([
              r.change.exists
                ? fromFirestore({ id, ...r.change.data } as any)
                : null,
              true,
            ]);
          }
        );
        return unsubscribe;
      }, [collectionPath, id]);
      return state;
    },
    useItems(routeParams) {
      const appRouteParams = useAppRouteParams();
      const [state, setState] = useState<[T[], boolean]>([[], false]);
      const collectionPath = getCollectionPath({
        ...appRouteParams,
        ...routeParams,
      });
      useEffect(() => {
        setState([[], false]);
        if (!collectionPath || collectionPath.indexOf("undefined") !== -1)
          return;
        const unsubscribe = subscribeCollectionInDiffMode(
          collection(firestore, collectionPath),
          (doc) => fromFirestore({ id: doc.id, ...doc.data() } as any),
          (items: T[]) => {
            setState([items, true]);
          }
        );
        return () => {
          unsubscribe();
        };
      }, [collectionPath]);
      return state;
    },
    useItemsQuery(routeParams, getInitQuerySetting = undefined) {
      const appRouteParams = useAppRouteParams();
      const [state, setState] = useState<[T[], boolean]>([[], false]);
      const collectionPath = getCollectionPath({
        ...appRouteParams,
        ...routeParams,
      });

      // 初期状態のみnull
      const [querySetting, setQuerySetting] =
        useState<ComplexFirestoreQuerySetting | null>(null);

      const changeQuerySetting = useCallback(
        (query: ComplexFirestoreQuerySetting | null) => {
          setQuerySetting(query);
        },
        [setQuerySetting]
      );

      useEffect(() => {
        let unsubscribe: Unsubscribe | undefined;
        (async () => {
          setState([[], false]);
          if (!collectionPath || collectionPath.indexOf("undefined") !== -1)
            return;
          const colRef = collection(firestore, collectionPath);
          let whereSrc: ComplexFirestoreQuerySetting | null = null;
          // 初回は、関数が渡された時のみ関数の返り値で、state設定
          if (getInitQuerySetting && querySetting === null) {
            whereSrc = await getInitQuerySetting();
            // 初回以降は、stateを読み取る
          } else if (querySetting !== null) {
            whereSrc = querySetting;
          }
          unsubscribe = subscribeCollectionInDiffMode(
            whereSrc
              ? query(
                  colRef,
                  complexFirestoreQuerySetting2queryFilterConstraint(
                    whereSrc
                  ) as QueryCompositeFilterConstraint
                )
              : colRef,
            (doc) => fromFirestore({ id: doc.id, ...doc.data() } as any),
            (items: T[]) => {
              setState([items, true]);
            }
          );
        })();
        return () => {
          unsubscribe && unsubscribe();
        };
      }, [collectionPath, querySetting]);
      return [...state, querySetting, changeQuerySetting];
    },
    useItemsLegacyQuery(routeParams, getInitQuerySettings = undefined) {
      deprecatedLogger.warn(
        "useItemsLegacyQuery is deprecated. Use useItemsQuery instead. See https://github.com/algo-artis/spreadsheet-ui-frontend/pull/760 for more technical details."
      );
      const getInitQuerySetting = getInitQuerySettings
        ? async () => {
            const querySettings = await getInitQuerySettings();
            return querySettings.length > 0
              ? {
                  logicalOp: "and" as const,
                  ops: querySettings,
                }
              : null;
          }
        : undefined;
      const [value, isLoaded, querySetting, changeQuerySettings] =
        this.useItemsQuery(routeParams, getInitQuerySetting);
      const querySettings =
        (
          querySetting as {
            logicalOp: "and";
            ops: FirestoreQuerySetting[];
          }
        )?.ops || null;
      const changeQuerySetting = useCallback(
        (querySettingsParam: FirestoreQuerySetting[] | null) =>
          changeQuerySettings(
            querySettingsParam && querySettingsParam.length > 0
              ? {
                  logicalOp: "and",
                  ops: querySettingsParam,
                }
              : null
          ),
        [changeQuerySettings]
      );
      return [value, isLoaded, querySettings, changeQuerySetting];
    },
    useSomeItems(routeParamsList) {
      const appRouteParams = useAppRouteParams();
      const [state, setState] = useState<
        {
          data: T[];
          routeParamsMap: Map<string, string | undefined>;
          collectionPath: string;
          isLoaded: boolean;
        }[]
      >([]);

      const paramObjects = routeParamsList
        .map((routeParams) => {
          return {
            collectionPath: getCollectionPath({
              ...appRouteParams,
              ...routeParams,
            }),
            routeParams,
          };
        })
        .sort((a, b) =>
          Math.sign(a.collectionPath.localeCompare(b.collectionPath))
        );
      const pathObjectsMemo = paramObjects
        .map(({ collectionPath }) => collectionPath)
        .join("___");

      // routeParamsListの長さが0の場合はロード済みとする
      const isLoaded = useMemo(() => {
        if (routeParamsList.length === 0) return true;
        return (
          state.length > 0 &&
          state.every(({ isLoaded }) => isLoaded) &&
          state.length === routeParamsList.length
        );
      }, [state, routeParamsList]);

      useEffect(() => {
        setState([]);
        const unsubscribes: Unsubscribe[] = [];
        for (const { collectionPath, routeParams } of paramObjects) {
          if (!collectionPath || collectionPath.indexOf("undefined") !== -1)
            return;
          const routeParamsMap = new Map(Object.entries(routeParams));
          const unsubscribe = subscribeCollectionInDiffMode(
            collection(firestore, collectionPath),
            (doc) => fromFirestore({ id: doc.id, ...doc.data() } as any),
            (items: T[]) => {
              setState((before) => [
                ...before.filter(
                  ({ collectionPath: _collectionPath }) =>
                    collectionPath !== _collectionPath
                ),
                {
                  data: items,
                  routeParamsMap,
                  isLoaded: true,
                  collectionPath,
                },
              ]);
            }
          );
          unsubscribes.push(unsubscribe);
        }
        return () => {
          unsubscribes.map((unsubscribe) => {
            unsubscribe();
          });
        };
      }, [pathObjectsMemo]);
      return [state, isLoaded];
    },
    useSomeItemsQuery(params) {
      const appRouteParams = useAppRouteParams();

      const [state, setState] = useState<
        {
          data: T[];
          routeParamsMap: Map<string, string | undefined>;
          collectionPath: string;
          isLoaded: boolean;
        }[]
      >([]);
      const paramObjects = params
        .map(({ routeParams, getInitQuerySetting }) => {
          return {
            collectionPath: getCollectionPath({
              ...appRouteParams,
              ...routeParams,
            }),
            routeParams,
            getInitQuerySetting,
          };
        })
        .sort((a, b) =>
          Math.sign(a.collectionPath.localeCompare(b.collectionPath))
        );

      const pathObjectsMemo = paramObjects
        .map(({ collectionPath }) => collectionPath)
        .join("___");

      const initialQuerySettingList = paramObjects.map(
        ({ collectionPath, routeParams }) => {
          const routeParamsMap = new Map(Object.entries(routeParams));
          return {
            querySetting: null,
            collectionPath,
            routeParamsMap,
          };
        }
      );

      // 各要素は、null(初期状態) or 空配列だったらクエリをかけない
      // 全体が空配列でもクエリをかけない
      const [querySettingList, setQuerySettingList] = useState<
        {
          querySetting: ComplexFirestoreQuerySetting | null;
          collectionPath: string;
          routeParamsMap: Map<string, string | undefined>;
        }[]
      >(initialQuerySettingList);

      /**
       * @description 指定したrouteParamsで取得しているonSnapshotにクエリをかける
       * 空行が指定されたら、クエリを消す
       */
      const changeQuerySetting = useCallback(
        (
          params: {
            querySetting: ComplexFirestoreQuerySetting | null;
            routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>;
          }[]
        ) => {
          setQuerySettingList((_beforeStates) => {
            const beforeStates =
              _beforeStates.length === 0
                ? initialQuerySettingList
                : _beforeStates;
            const res =
              params.length > 0
                ? [
                    ...beforeStates.map((beforeState) => {
                      // 引数とcollectionPathが一致する要素は更新
                      const querySetting = params.find(({ routeParams }) => {
                        const collectionPath = getCollectionPath({
                          ...appRouteParams,
                          ...routeParams,
                        });
                        return beforeState.collectionPath === collectionPath;
                      })?.querySetting;
                      return querySetting !== undefined
                        ? { ...beforeState, querySetting }
                        : beforeState;
                    }),
                  ]
                : [];
            return res;
          });
        },
        [setQuerySettingList, initialQuerySettingList]
      );
      const isLoaded = useMemo(() => {
        if (params.length === 0) return true;
        return (
          state.length > 0 &&
          state.every(({ isLoaded }) => isLoaded) &&
          state.length === params.length
        );
      }, [state, params]);

      useEffect(() => {
        setState([]);
        const unsubscribes: Unsubscribe[] = [];
        const getOnSnapshots = async () => {
          for (const {
            collectionPath,
            routeParams,
            getInitQuerySetting,
          } of paramObjects) {
            if (!collectionPath || collectionPath.indexOf("undefined") !== -1)
              return;
            const colRef = collection(firestore, collectionPath);
            let whereSrc: ComplexFirestoreQuerySetting | null = null;
            // collectionPathが合致するクエリ設定を抽出
            const querySetting =
              querySettingList.find(
                ({ collectionPath: _collectionPath }) =>
                  collectionPath === _collectionPath
              )?.querySetting || null;
            // 初回は、関数が渡された時のみ関数の返り値で、state設定
            if (getInitQuerySetting && querySetting === null) {
              whereSrc = await getInitQuerySetting();
            } else if (querySetting !== null) {
              // 初回以降は、stateを読み取る
              whereSrc = querySetting;
            }
            const routeParamsMap = new Map(Object.entries(routeParams));
            const unsubscribe = subscribeCollectionInDiffMode(
              whereSrc
                ? query(
                    colRef,
                    complexFirestoreQuerySetting2queryFilterConstraint(
                      whereSrc
                    ) as QueryCompositeFilterConstraint
                  )
                : colRef,
              (doc) => fromFirestore({ id: doc.id, ...doc.data() } as any),
              (items: T[]) => {
                setState((before) => {
                  return [
                    ...before.filter(
                      ({ collectionPath: _collectionPath }) =>
                        collectionPath !== _collectionPath
                    ),
                    {
                      data: items,
                      routeParamsMap,
                      isLoaded: true,
                      collectionPath,
                    },
                  ];
                });
              }
            );
            unsubscribes.push(unsubscribe);
          }
        };
        getOnSnapshots();
        return () => {
          unsubscribes.map((unsubscribe) => {
            unsubscribe();
          });
        };
      }, [pathObjectsMemo, querySettingList]);

      return [state, isLoaded, querySettingList, changeQuerySetting];
    },
    useSomeItemsLegacyQuery(params) {
      deprecatedLogger.warn(
        "useSomeItemsLegacyQuery is deprecated. Use useSomeItemsQuery instead. See https://github.com/algo-artis/spreadsheet-ui-frontend/pull/760 for more technical details."
      );
      const [state, isLoaded, querySettingList, changeQuerySetting] =
        this.useSomeItemsQuery(
          params.map(({ routeParams, getInitQuerySettings }) => ({
            routeParams,
            getInitQuerySetting: getInitQuerySettings
              ? async () => {
                  const querySettings = await getInitQuerySettings();
                  return querySettings && querySettings.length > 0
                    ? {
                        logicalOp: "and" as const,
                        ops: querySettings,
                      }
                    : null;
                }
              : undefined,
          }))
        );
      const querySettingsList = useMemo(
        () =>
          querySettingList.map(
            ({ querySetting, routeParamsMap, collectionPath }) => ({
              querySettings:
                (
                  querySetting as {
                    logicalOp: "and";
                    ops: FirestoreQuerySetting<string>[];
                  }
                )?.ops || null,
              routeParamsMap,
              collectionPath,
            })
          ),
        [querySettingList]
      );
      const changeQuerySettings = useCallback(
        (
          params: {
            querySettings: FirestoreQuerySetting[];
            routeParams: RP & Partial<ReturnType<typeof useAppRouteParams>>;
          }[]
        ) =>
          changeQuerySetting(
            params.map(({ querySettings, routeParams }) => ({
              querySetting:
                querySettings.length > 0
                  ? {
                      logicalOp: "and",
                      ops: querySettings,
                    }
                  : null,
              routeParams,
            }))
          ),
        [changeQuerySetting]
      );
      return [state, isLoaded, querySettingsList, changeQuerySettings];
    },
  };
  const processor: FirestoreCollectionProsessor<T, R, RP> = {
    ...processorSrc,
    useGetAll() {
      const get = this.useGet();
      const transactionUnsupportedGetAll =
        processorSrc.useTransactionUnsupportedGetAll();
      return useMemo(
        () =>
          async (routeParams, { where, transaction } = {}) => {
            if (!transaction) {
              return (
                await transactionUnsupportedGetAll(routeParams, {
                  where,
                  transaction,
                })
              )[0];
            }
            const testRawByFilterConstraint = (
              raw: { [key: string]: unknown },
              where: FirestoreQuerySetting<string>
            ): boolean => {
              if (where.opStr === "==") {
                return raw[where.fieldPath] === where.value;
              } else if (where.opStr === "!=") {
                return raw[where.fieldPath] !== where.value;
              } else if (where.opStr === "<") {
                return (
                  (raw[where.fieldPath] as number) < (where.value as number)
                );
              } else if (where.opStr === "<=") {
                return (
                  (raw[where.fieldPath] as number) <= (where.value as number)
                );
              } else if (where.opStr === ">") {
                return (
                  (raw[where.fieldPath] as number) > (where.value as number)
                );
              } else if (where.opStr === ">=") {
                return (
                  (raw[where.fieldPath] as number) >= (where.value as number)
                );
              } else if (where.opStr === "in") {
                return (where.value as unknown[]).includes(
                  raw[where.fieldPath]
                );
              } else if (where.opStr === "not-in") {
                return !(where.value as unknown[]).includes(
                  raw[where.fieldPath]
                );
              } else if (where.opStr === "array-contains") {
                return (where.value as unknown[]).every((v) =>
                  (raw[where.fieldPath] as unknown[]).includes(v)
                );
              } else if (where.opStr === "array-contains-any") {
                return (raw[where.fieldPath] as unknown[]).some((v) =>
                  (where.value as unknown[]).includes(v)
                );
              }
              return true;
            };
            const checkRequiredFields = (
              raw: { [key: string]: unknown },
              where: ComplexFirestoreQuerySetting<string> | undefined
            ): string[] => {
              if (!where) return [];
              if ("opStr" in where)
                return testRawByFilterConstraint(raw, where)
                  ? [where.fieldPath]
                  : [];
              if (where.logicalOp === "and") {
                return where.ops
                  .map((c) => checkRequiredFields(raw, c))
                  .flat(1);
              }
              if (where.logicalOp === "or") {
                let r: string[] = [];
                for (let i = 0; i < where.ops.length && r.length === 0; i++) {
                  r = checkRequiredFields(raw, where.ops[i]);
                }
                return r;
              }
              throw new Error("operation not supported");
            };
            const unsafeRawResult = (
              await transactionUnsupportedGetAll(routeParams, { where })
            )[1];
            const checkData = unsafeRawResult.map((raw) =>
              Object.fromEntries(
                [
                  "id",
                  ...checkRequiredFields(
                    raw,
                    where as ComplexFirestoreQuerySetting<string> | undefined
                  ),
                ].map((fieldPath) => [fieldPath, raw[fieldPath]])
              )
            ) as Partial<R>[];
            return await Promise.all(
              checkData.map(async (c) => {
                const item = await get(c.id!, routeParams, {
                  transaction,
                  checkRawChunk: c,
                });
                if (!item) {
                  throw new Error("item changed during transaction");
                }
                return item;
              })
            );
          },
        [transactionUnsupportedGetAll]
      );
    },
  };
  const bindObject = <T>(obj: T): T => {
    obj = { ...obj };
    for (const key in obj) {
      if (typeof obj[key] === "function") {
        obj[key] = (obj[key] as Function).bind(obj);
      }
    }
    return obj;
  };
  return bindObject(processor);
}

import React from "react";

import { useDialogStyles } from "@/styles";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import { ErrorCircle24Filled } from "@fluentui/react-icons";
import styles from "../index.module.css";

export const DialogErrorOffline: React.FC = () => {
  const dialogClasses = useDialogStyles();
  return (
    <Dialog>
      <DialogSurface className={dialogClasses.dialog}>
        <DialogBody>
          <DialogTitle>
            <div className={styles.titleWithIcon}>
              <ErrorCircle24Filled className={styles.errorIcon} />
              ネットワークエラー
            </div>
          </DialogTitle>
          <DialogContent>
            <div className={styles.errorResponse}>
              ネットワークがオフラインです。ネットワーク環境を確認してください。
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

import React, {
  useMemo,
  useState,
  ReactNode,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";

import { getAdminAccounts } from "@/api/admin/account";
import { getAPIErrorDetailAndMessage } from "@/api/helpers";
import { AccountRaw } from "@/domain/Account";

const adminAccountsCtx = createContext<{
  accounts: AccountRaw[];
  nextStartAfterId: string | null;
  setStartAfterId: React.Dispatch<React.SetStateAction<string | null>>;
  runReloadAccounts: () => Promise<void>;
  isLoadedAccounts: boolean;
}>({
  accounts: [],
  nextStartAfterId: null,
  setStartAfterId: () => {
    //nop
  },
  runReloadAccounts: async () => {
    //nop
  },
  isLoadedAccounts: false,
});

export const AdminAccountsProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [accounts, setAccounts] = useState<AccountRaw[]>([]);
  // reloadを走らせるための始点Id コンポーネントから受け取る
  const [startAfterId, setStartAfterId] = useState<string | null>(null);
  // 次の始点Id コンポーネントで使用
  const [nextStartAfterId, setNextStartAfterId] = useState<string | null>(null);
  const [, setError] = useState<unknown>(null);

  const [isInProgress, setIsInProgress] = useState(false);

  const runReloadAccounts = useCallback(async () => {
    try {
      setIsInProgress(true);
      const response = await getAdminAccounts(startAfterId ?? undefined);
      setAccounts(response.items);
      setNextStartAfterId(response.next_start_after_id ?? null);
    } catch (err: unknown) {
      setError(() => {
        if (typeof err == "object") {
          const e = {
            ...err,
            message: getAPIErrorDetailAndMessage(err),
          } as Error;
          throw e;
        } else {
          throw new Error("不明なエラー");
        }
      });
    } finally {
      setIsInProgress(false);
    }
  }, [startAfterId]);

  const ctxValue = useMemo(
    () => ({
      accounts,
      nextStartAfterId,
      setStartAfterId,
      runReloadAccounts,
      isLoadedAccounts: !isInProgress,
    }),
    [accounts, nextStartAfterId, runReloadAccounts, isInProgress]
  );

  useEffect(() => {
    runReloadAccounts();
  }, [runReloadAccounts]);

  return (
    <adminAccountsCtx.Provider value={ctxValue}>
      {children}
    </adminAccountsCtx.Provider>
  );
};

export const useAdminAccounts = () => {
  return useContext(adminAccountsCtx);
};

import React, { useState } from "react";

import { postResendEmailVerification } from "../../../../api/accounts";
import { firebaseSignIn } from "../../../../firebase/helper";
import aaLogo from "../../../../images/aa_logo.png";

import { getAPIErrorDetailOrMesssage } from "@/api/helpers";
import {
  Button,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
} from "@fluentui/react-components";
import { Dismiss20Regular } from "@fluentui/react-icons";
import styles from "./index.module.css";

/**
 * @description email検証を促す
 */
export const ResendEmailVerification: React.FC<{ idp_uid: string }> = ({
  idp_uid,
}) => {
  const [isApiProcessing, setIsAPIProcessing] = useState(false);
  const [messageIntent, setMessageIntent] = useState<
    "success" | "error" | null
  >(null);
  const [errorMessage, setErrorMesssage] = useState<string | null>(null);

  const handleResendEmailVerification = async () => {
    setMessageIntent(null);
    setIsAPIProcessing(true);
    try {
      await postResendEmailVerification({
        idp_uid,
      });
      setMessageIntent("success");
    } catch (error) {
      const { errorMessage } = getAPIErrorDetailOrMesssage(error);
      setMessageIntent("error");
      setErrorMesssage(errorMessage);
    } finally {
      setIsAPIProcessing(false);
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.contentBox}>
          <div className={styles.logo}>
            <img src={aaLogo} height={50} />
          </div>
          <div className={styles.message}>
            検証用のメールを送付しました。メールを確認し、再度ログインしてください。
          </div>
          {messageIntent === "success" && (
            <MessageBar intent="success" className={styles.messageWrapper}>
              <MessageBarBody className={styles.message}>
                検証メールを再送しました。検証後、再ログインしてください。
              </MessageBarBody>
              <MessageBarActions
                containerAction={
                  <Button
                    aria-label="dismiss"
                    appearance="transparent"
                    icon={<Dismiss20Regular />}
                    onClick={() => setMessageIntent(null)}
                  />
                }
              />
            </MessageBar>
          )}
          {messageIntent === "error" && (
            <MessageBar intent="error" className={styles.messageWrapper}>
              <MessageBarBody className={styles.message}>
                検証メールの再送に失敗しました。
                {errorMessage}
              </MessageBarBody>
              <MessageBarActions
                containerAction={
                  <Button
                    aria-label="dismiss"
                    appearance="transparent"
                    icon={<Dismiss20Regular />}
                    onClick={() => setMessageIntent(null)}
                  />
                }
              />
            </MessageBar>
          )}
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              onClick={handleResendEmailVerification}
              disabled={isApiProcessing}
            >
              再送する
            </Button>
            <Button
              className={styles.button}
              onClick={async () => {
                await firebaseSignIn({ isReplaceHistory: true });
              }}
              disabled={isApiProcessing}
            >
              再ログイン
            </Button>
            <Button
              className={styles.button}
              onClick={() => {
                // @ts-ignore
                window.signOut();
              }}
            >
              ログアウト
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

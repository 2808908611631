import loadable from "@loadable/component";
import { allDependSheets } from "../../../ssui/types/fields";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeHkrShipTasksByShip: StrictViewType = {
  key: "hkrShipTasksByShip",
  displayName: "船視点配船表",
  render: ViewRender as any,
  dependSheets: allDependSheets,
  configSchemas: [],
  isDisabledEditHistoryShortcuts: true,
};

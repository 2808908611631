import { AxiosRequestConfig, AxiosResponse } from "axios";

import { API_CLIENT } from "./config";

export type DownloadDataTypes = "xlsx" | "yaml" | "xls" | "json" | "zip";
// 実際にcsvをダウンロードするDOM要素を作成
const dlAnchor = document.createElement("a");
document.body.appendChild(dlAnchor);
dlAnchor.style.display = "none";

export async function downloadFileFromAPI(
  fetchURL: string,
  dataType: DownloadDataTypes,
  config?: AxiosRequestConfig | undefined
): Promise<AxiosResponse<unknown>> {
  /**
   * APIを叩いてファイルをDLさせる関数
   *
   * @param fetchURL 対象のAPI URL
   * @param dataType ファイルタイプ。拡張子として利用
   * @param config axiosで利用可能な設定値オブジェクト。クエリパラメータなど
   */

  // バイナリファイル(blob型)としてデータを受け取り、あらゆるファイルを処理できることを明示して送信
  const headers = config?.headers;
  const res = await API_CLIENT.get<Blob>(fetchURL, {
    ...config,
    responseType: "blob",
    headers: { ...headers, accept: "*/*" },
  });
  // ブラウザのメモリに保存されたblobにアクセス可能なURLを作成
  const blobURL = window.URL.createObjectURL(
    res.data
    /*new Blob([res.data], { type: "octet/stream" })*/
  );
  // 事前に作成したDOM要素にダウンロード先のURLとダウンロード時のファイル名を初期設定
  dlAnchor.href = blobURL;
  dlAnchor.download = `data.${dataType}`;
  // 受信した情報からファイル名を再設定する
  if (res.headers && res.headers["content-disposition"]) {
    const match = res.headers["content-disposition"].match(
      /filename=("(?:[^\\"]|\\\\|\\")+"|'(?:[^\\']|\\\\|\\')+'|[^;]+)/
    );
    if (match) {
      dlAnchor.download = decodeURIComponent(
        match[1]
          .replace(/^"(.+)"$/, (_: string, c: string) => c.replace('\\"', '"'))
          .replace(/^'(.+)'$/, (_: string, c: string) => c.replace("\\'", "'"))
      );
    }
  }
  // DOM要素がクリックされたとして、ブラウザメモリ => ローカル のDL処理
  dlAnchor.click();
  // URLを破棄
  window.URL.revokeObjectURL(blobURL);
  return res;
}

export const getAPIErrorDetailOrMesssage = (error: unknown) => {
  let errorMessage = "不明なエラー";
  let errorCode = "不明なステータス";
  if (typeof error === "object" && error !== null) {
    const errorObj = error as { [key: string]: any };
    errorMessage =
      errorObj?.response?.data?.detail ?? errorObj.message ?? errorMessage;
    errorCode = errorObj?.response?.status ?? errorCode;
  }
  return { errorMessage, errorCode };
};
export const getAPIErrorDetailAndMessage = (error: unknown) => {
  if (typeof error === "object" && error !== null) {
    const errorObj = error as { [key: string]: any };
    const detail = errorObj?.response?.data?.detail ?? "";
    const message = errorObj?.message ?? "";
    return detail + message;
  }
  return "";
};
export const getAPIConfigs = (organizationId?: string) => {
  const apiOrganizationIdParams = organizationId
    ? {
        params: {
          org_id: organizationId,
        },
      }
    : undefined;
  const apiHeadersWithJson = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return { apiOrganizationIdParams, apiHeadersWithJson };
};

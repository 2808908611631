import loadable from "@loadable/component";

import { dependSheets, loadSkipSheets, viewtypeContents } from "./autogen";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKwsSpreadSheetAndGraph2sEast: StrictViewType = {
  key: "nshKwsSpreadSheetAndGraph2sEast",
  displayName: "NSH-KWSスプレッドシート第二製造課(東地区)",
  render: ViewRender as any,
  dependSheets: dependSheets,
  configSchemas: [],
  querySettings: loadSkipSheets.map((sheetInfo) => ({
    sheetKey: sheetInfo.sheetKey,
    sheetFieldSchemaName: sheetInfo.sheetFieldSchemaName,
    queryFn: () => {
      return [
        {
          opStr: "==",
          value: "",
        },
      ];
    },
  })),
};

import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKwsCSVView4s: StrictViewType = {
  key: "nshKwsCSVView4s",
  displayName: "NSH-KWS S4 CSV連携ビュー（4製用）",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "rawMaterialPurchase4s",
      displayName: "rawMaterialPurchase4s",
      isReadAll: true,
    },
    {
      key: "productionAmount",
      displayName: "productionAmount",
      isReadAll: true,
    },
    {
      key: "s4Master1234s",
      displayName: "s4Master1234s",
      dependSheetFields: [
        {
          key: "itemCode",
          displayName: "品目コード",
          type: "string",
        },
        {
          key: "itemText",
          displayName: "品目テキスト",
          type: "string",
        },
        {
          key: "stockArea",
          displayName: "保管場所",
          type: "string",
        },
        {
          key: "lotSize",
          displayName: "ロットサイズ",
          type: "integer",
        },
      ],
    },
    {
      key: "s4SalesSchedule4s",
      displayName: "s4SalesSchedule4s",
      dependSheetFields: [
        {
          key: "shippingDestination",
          displayName: "出荷先",
          type: "string",
        },
        {
          key: "scheduledShippingDate",
          displayName: "出荷予定日",
          type: "date",
        },
        {
          key: "item",
          displayName: "品目",
          type: "string",
        },
        {
          key: "itemText",
          displayName: "品目テキスト",
          type: "string",
        },
        {
          key: "orderQuantity",
          displayName: "受注数量",
          type: "float",
        },
      ],
    },
    {
      key: "s4ShippingSchedule4s",
      displayName: "s4ShippingSchedule4s",
      dependSheetFields: [
        {
          key: "item",
          displayName: "品目",
          type: "string",
        },
        {
          key: "instructionQuantity",
          displayName: "指図数量",
          type: "float",
        },
        {
          key: "referenceStartDate",
          displayName: "基準開始日",
          type: "date",
        },
        {
          key: "itemText",
          displayName: "品目テキスト",
          type: "string",
        },
      ],
    },
    {
      key: "transferItemMaster",
      displayName: "transferItemMaster",
      dependSheetFields: [
        {
          key: "item",
          displayName: "品目",
          type: "string",
        },
      ],
    },
    {
      key: "productMaster",
      displayName: "productMaster",
      dependSheetFields: [
        {
          key: "productId",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "productName",
          displayName: "製品名",
          type: "string",
        },
        {
          key: "place",
          displayName: "場所",
          type: "string",
        },
        {
          key: "remark",
          displayName: "備考",
          type: "string",
        },
      ],
    },
    {
      key: "materialMaster",
      displayName: "materialMaster",
      dependSheetFields: [
        {
          key: "rawMaterialId",
          displayName: "原材料ID",
          type: "string",
        },
        {
          key: "rawMaterialName",
          displayName: "原材料名",
          type: "string",
        },
        {
          key: "place",
          displayName: "場所",
          type: "string",
        },
        {
          key: "remark",
          displayName: "備考",
          type: "string",
        },
      ],
    },
    {
      key: "tankMaster",
      displayName: "tankMaster",
      dependSheetFields: [
        {
          key: "productId",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "tankName",
          displayName: "タンク名",
          type: "string",
        },
      ],
    },
    {
      key: "production",
      displayName: "4S生産計画",
      dependSheetFields: [
        {
          key: "reactor_id",
          displayName: "反応器ID",
          type: "string",
        },
        {
          key: "tank_id",
          displayName: "タンクID",
          type: "string",
        },
        {
          key: "product_id",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "product_name",
          displayName: "製品名",
          type: "string",
        },
        {
          key: "start_datetime",
          displayName: "生産開始日時",
          type: "date-time",
        },
        {
          key: "end_datetime",
          displayName: "生産終了日時",
          type: "date-time",
        },
        {
          key: "production_type",
          displayName: "スケジュールのタイプ",
          type: "string",
        },
        {
          key: "comment",
          displayName: "コメント",
          type: "string",
        },
      ],
    },
    {
      key: "reactorProductMaster",
      displayName: "4S反応器マスタ",
      dependSheetFields: [
        {
          key: "reactor_id",
          displayName: "反応器ID",
          type: "string",
        },
        {
          key: "product_id",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "production_amount",
          displayName: "バッチあたり生産量",
          type: "float",
        },
      ],
    },
    {
      key: "switchMaster",
      displayName: "4S切り替えマスタ",
      dependSheetFields: [
        {
          key: "reactor_id",
          displayName: "反応器ID",
          type: "string",
        },
        {
          key: "prev_product_id",
          displayName: "切替前製品ID",
          type: "string",
        },
        {
          key: "next_product_id",
          displayName: "切替後製品ID",
          type: "string",
        },
      ],
    },
  ],
  configSchemas: [],
};

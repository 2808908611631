import { IDropdownOption } from "@fluentui/react";
import React, {
  useMemo,
  ReactNode,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";

import { useAppRouteParams } from "@/AppRoutes";
import { getAPIErrorDetailAndMessage } from "@/api/helpers";
import {
  getVersionCategories,
  getVersionCategory,
} from "@/api/versionCategory";
import { VersionCategoryRaw } from "@/domain/VersionCategories";
import { usePermissions } from "../PermissionsProvider";

const versionCategoriesCtx = createContext<{
  versionCategories: VersionCategoryRaw[] | null;
  categoriesDropdownV8: IDropdownOption[];
  categoriesDropdown: { value: string; text: string }[];
  isLoadedVersionCategories: boolean;
  runReloadVersionCategories: () => Promise<void>;
}>({
  versionCategories: [],
  categoriesDropdownV8: [],
  categoriesDropdown: [],
  isLoadedVersionCategories: false,
  runReloadVersionCategories: async () => {},
});

// 組織ごとのバージョンカテゴリを提供するプロバイダ
// version_categories:readとversions_version_category:read:idの
// ２つの権限を見るためuseAPIを使用しない
export const VersionCategoriesProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { organizationId } = useAppRouteParams();
  const { me, hasPermission } = usePermissions();
  const hasVersionCategoriesReadPermission = hasPermission(
    "version_categories",
    "read"
  );
  const [_, setError] = useState<unknown>(null);

  const [versionCategories, setVersionCategories] = useState<
    VersionCategoryRaw[] | null
  >(null);
  const [isLoadedVersionCategories, setIsLoadedVersionCategories] =
    useState<boolean>(false);
  const runReloadVersionCategories = useCallback(async () => {
    if (!organizationId) return;
    try {
      setIsLoadedVersionCategories(false);
      if (hasVersionCategoriesReadPermission) {
        const response = await getVersionCategories({ organizationId });
        setVersionCategories(
          [...response].sort((a, b) => Math.sign(a.sort - b.sort))
        );
      } else {
        const versionCategoryIds = me?.permissions.version_category.read;
        if (!versionCategoryIds?.length) {
          setVersionCategories(null);
          setIsLoadedVersionCategories(true);
          return;
        }
        const response = await Promise.all(
          versionCategoryIds.map((versionCategoryId) =>
            getVersionCategory({ versionCategoryId, organizationId })
          )
        );
        setVersionCategories(
          [...response].sort((a, b) => Math.sign(a.sort - b.sort))
        );
      }
    } catch (err) {
      setError(() => {
        if (typeof err == "object") {
          const e = {
            ...err,
            message: getAPIErrorDetailAndMessage(err),
          } as Error;
          throw e;
        } else {
          throw new Error("不明なエラー");
        }
      });
    } finally {
      setIsLoadedVersionCategories(true);
    }
  }, [me, organizationId]);

  useEffect(() => {
    runReloadVersionCategories();
  }, [runReloadVersionCategories]);

  const categoriesDropdown: { value: string; text: string }[] = useMemo(
    () => [
      {
        value: "all",
        text: "全ての有効なバージョン",
      },
      ...(versionCategories?.map(({ id, name }) => ({
        value: id,
        text: name,
      })) ?? []),
      ...(hasVersionCategoriesReadPermission
        ? [
            {
              value: "",
              text: "未分類",
            },
          ]
        : []),
      {
        value: "disabled",
        text: "ゴミ箱",
      },
    ],
    [versionCategories, hasVersionCategoriesReadPermission]
  );
  const categoriesDropdownV8: IDropdownOption[] = useMemo(
    () => [
      {
        key: "all",
        text: "全ての有効なバージョン",
      },
      ...(versionCategories?.map(({ id, name }) => ({
        key: id,
        text: name,
      })) ?? []),
      ...(hasVersionCategoriesReadPermission
        ? [
            {
              key: "",
              text: "未分類",
            },
          ]
        : []),
      {
        key: "disabled",
        text: "ゴミ箱",
      },
    ],
    [versionCategories, hasVersionCategoriesReadPermission]
  );

  const versionCategoriesCtxValue = useMemo(
    () => ({
      versionCategories,
      categoriesDropdownV8,
      categoriesDropdown,
      runReloadVersionCategories,
      isLoadedVersionCategories,
    }),
    [
      versionCategories,
      categoriesDropdownV8,
      categoriesDropdown,
      runReloadVersionCategories,
      isLoadedVersionCategories,
    ]
  );

  return (
    <versionCategoriesCtx.Provider value={versionCategoriesCtxValue}>
      {children}
    </versionCategoriesCtx.Provider>
  );
};

export const useVersionCategories = () => {
  return useContext(versionCategoriesCtx);
};

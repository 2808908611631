import { KoubanViewTypeDependSheet } from "./params";

const driverDependSheet: KoubanViewTypeDependSheet = {
  key: "driver",
  displayName: "乗務員",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "driverName",
      displayName: "氏名",
      type: "string",
    },
  ],
};

const driverDataDependSheet: KoubanViewTypeDependSheet = {
  key: "driverData",
  displayName: "乗務員データ",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "frontOffice",
      displayName: "フロント内勤",
      type: "string",
    },
    {
      key: "seniorGrade",
      displayName: "S級",
      type: "string",
    },
    {
      key: "workRequestType",
      displayName: "希望形態",
      type: "string",
    },
    {
      key: "prohibitOutOfRange",
      displayName: "出勤日時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeFirstWeekday",
      displayName: "第一公休日 平日 時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeFirstSaturday",
      displayName: "第一公休日 土曜日 時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeFirstDayoff",
      displayName: "第一公休日 休日 時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeSecondWeekday",
      displayName: "第二公休日 平日 時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeSecondSaturday",
      displayName: "第二公休日 土曜日 時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeSecondDayoff",
      displayName: "第二公休日 休日 時間外禁止",
      type: "string",
    },
  ],
};

const driverSkillDependSheet: KoubanViewTypeDependSheet = {
  key: "driverSkill",
  displayName: "乗務員スキル",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "skillName",
      displayName: "スキル名",
      type: "string",
    },
  ],
};

const driverTemporaryDataDependSheet: KoubanViewTypeDependSheet = {
  key: "driverTemporaryData",
  displayName: "乗務員一時データ",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "frontOffice",
      displayName: "フロント内勤",
      type: "string",
    },
    {
      key: "seniorGrade",
      displayName: "S級",
      type: "string",
    },
    {
      key: "workRequestType",
      displayName: "希望形態",
      type: "string",
    },
    {
      key: "workStatus",
      displayName: "勤務状況",
      type: "string",
    },
    {
      key: "prohibitOutOfRange",
      displayName: "当日時間外禁止",
      type: "string",
    },
    {
      key: "unableWorkTime",
      displayName: "当日勤務不可時間帯",
      type: "string",
    },
  ],
};

const rorationBasedAssignmentDependSheet: KoubanViewTypeDependSheet = {
  key: "rotationBasedAssignment",
  displayName: "予定勤務データ",
  dependSheetFields: [
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "version",
      displayName: "バージョン",
      type: "string",
    },
    {
      key: "daygroup",
      displayName: "運行区分",
      type: "string",
    },
    {
      key: "workCode",
      displayName: "勤務コード",
      type: "string",
    },
    {
      key: "workCodeSeq",
      displayName: "勤務コード枝番",
      type: "integer",
    },
    {
      key: "workBeginTime",
      displayName: "開始時間",
      type: "string",
    },
    {
      key: "workEndTime",
      displayName: "終了時間",
      type: "string",
    },
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
  ],
};

const rotationBasedAssignmentSkillDependSheet: KoubanViewTypeDependSheet = {
  key: "rotationBasedAssignmentSkill",
  displayName: "予定勤務スキル",
  dependSheetFields: [
    {
      key: "workCode",
      displayName: "勤務コード",
      type: "string",
    },
    {
      key: "workCodeSeq",
      displayName: "勤務コード枝番",
      type: "integer",
    },
    {
      key: "skillName",
      displayName: "スキル名",
      type: "string",
    },
  ],
};

const skillDependSheet: KoubanViewTypeDependSheet = {
  key: "skill",
  displayName: "スキル",
  dependSheetFields: [
    {
      key: "skillName",
      displayName: "スキル名",
      type: "string",
    },
  ],
};

const temporaryCourseSkill: KoubanViewTypeDependSheet = {
  key: "temporaryCourseSkill",
  displayName: "臨時コーススキル",
  dependSheetFields: [
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "workCode",
      displayName: "業務コード",
      type: "string",
    },
    {
      key: "workCodeSeq",
      displayName: "枝番",
      type: "integer",
    },
    {
      key: "workBeginTime",
      displayName: "開始時間(前半)",
      type: "string",
    },
    {
      key: "workEndTime",
      displayName: "終了時間(前半)",
      type: "string",
    },
    {
      key: "skillName",
      displayName: "スキル名",
      type: "string",
    },
  ],
};

const workOvertimeHistoryDependSheet: KoubanViewTypeDependSheet = {
  key: "workOvertimeHistory",
  displayName: "時間外勤務実績",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "workOverTime",
      displayName: "残業時間",
      type: "string",
    },
  ],
};

const workHistoryPlanFullTimeDependSheet: KoubanViewTypeDependSheet = {
  key: "workHistoryPlanFullTime",
  displayName: "フルタイムの勤務履歴予定",
  dependSheetFields: [
    {
      key: "officeCode",
      displayName: "事業所コード",
      type: "string",
    },
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "workCode",
      displayName: "業務コード",
      type: "string",
    },
    {
      key: "workCodeSeq",
      displayName: "枝番",
      type: "integer",
    },
    {
      key: "workBeginTime",
      displayName: "開始時間",
      type: "string",
    },
    {
      key: "workEndTime",
      displayName: "終了時間",
      type: "string",
    },
  ],
};

const workHistoryPlanPartTimeDependSheet: KoubanViewTypeDependSheet = {
  key: "workHistoryPlanPartTime",
  displayName: "契約乗務員の勤務履歴予定",
  dependSheetFields: [
    {
      key: "officeCode",
      displayName: "事業所コード",
      type: "string",
    },
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "workCode",
      displayName: "業務コード",
      type: "string",
    },
    {
      key: "workCodeSeq",
      displayName: "枝番",
      type: "integer",
    },
    {
      key: "workBeginTime",
      displayName: "開始時間",
      type: "string",
    },
    {
      key: "workEndTime",
      displayName: "終了時間",
      type: "string",
    },
  ],
};

const workPlanTodayDependSheet: KoubanViewTypeDependSheet = {
  key: "workPlanToday",
  displayName: "当日勤務予定",
  dependSheetFields: [
    {
      key: "officeCode",
      displayName: "事業所コード",
      type: "string",
    },
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "workCode",
      displayName: "業務コード",
      type: "string",
    },
    {
      key: "workCodeSeq",
      displayName: "枝番",
      type: "integer",
    },
    {
      key: "workBeginTime",
      displayName: "開始時間",
      type: "string",
    },
    {
      key: "workEndTime",
      displayName: "終了時間",
      type: "string",
    },
    {
      key: "name",
      displayName: "氏名",
      type: "string",
    },
    {
      key: "category",
      displayName: "カテゴリ",
      type: "string",
    },
  ],
};

export const KoubanDependSheets = [
  driverDependSheet,
  driverDataDependSheet,
  driverSkillDependSheet,
  driverTemporaryDataDependSheet,
  rorationBasedAssignmentDependSheet,
  rotationBasedAssignmentSkillDependSheet,
  skillDependSheet,
  temporaryCourseSkill,
  workOvertimeHistoryDependSheet,
  workHistoryPlanFullTimeDependSheet,
  workHistoryPlanPartTimeDependSheet,
  workPlanTodayDependSheet,
];

import isEqual from "lodash/isEqual";
import React, { memo, useEffect, useMemo, useState } from "react";

import { Sheet } from "../../../domain/Sheet";
import {
  SheetDataRow,
  useGetSheetDataRows,
} from "../../../domain/SheetDataRow";
import {
  SheetFieldSchema,
  useGetSheetFieldSchemas,
} from "../../../domain/SheetFieldSchema";
import {
  SheetScoreSummaryItem,
  initialSheetScoreSummaryItem,
  sheetScoreSummarySchemasEnum,
  sheetScoreSummarySchemasString,
} from "../../../domain/SheetScoreSummary";

import { parseIntegerFloat } from "./parser";

export const useSheetScoreSummaryItems = ({
  resultScoreSummary,
  versionId,
}: {
  resultScoreSummary: Sheet | null;
  versionId: string;
}) => {
  const getSheetDataRows = useGetSheetDataRows();
  const getSheetFieldSchemas = useGetSheetFieldSchemas();
  const [sheetDataRows, setSheetDataRows] = useState<SheetDataRow[]>([]);
  const [sheetFieldSchemas, setSheetFieldSchemas] = useState<
    SheetFieldSchema[]
  >([]);

  const result = useMemo(() => {
    if (sheetDataRows.length === 0 || sheetFieldSchemas.length === 0) return [];
    const nameAndTypes = sheetFieldSchemas
      .map(({ name, type }) => ({
        name,
        type,
      }))
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    // 想定しているSheetFieldSchemaと異なればnull
    // NOTE: sortして順序一致
    if (
      !isEqual(
        nameAndTypes,
        [...sheetScoreSummarySchemasString].sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
      ) &&
      !isEqual(
        nameAndTypes,
        [...sheetScoreSummarySchemasEnum].sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
      )
    )
      return [];
    const sheetScoreSummaryItems = sheetDataRows
      .map(({ sheetDataCells }) => {
        // stringで保存されているFirestore上のシートデータを、SheetFieldSchemaで定義された型にparse
        const objList = Object.entries({
          ...sheetDataCells,
        })
          .map(([sheetFieldId, value]) => {
            const schema = sheetFieldSchemas.find(
              ({ id }) => id === sheetFieldId
            )!;
            if (!schema) return undefined;
            const { name, type } = schema;
            // NOTE: string or integer or boolean以外の型は想定しないのでundefinedとする
            if (type === "integer") {
              const parsedNumber = parseIntegerFloat(String(value), "integer");
              if (parsedNumber === null) {
                return undefined;
              }
              return [name, parsedNumber];
            } else if (type === "string" || type === "enum") {
              return [name, value];
            } else if (type === "boolean") {
              return [name, value.toLowerCase() === "true" ? true : false];
            }
            return undefined;
          })
          .filter((c): c is NonNullable<typeof c> => c !== undefined);
        if (objList.length === 0) return undefined;
        const item = Object.fromEntries(objList) as SheetScoreSummaryItem;
        // NOTE: sheetDataCellsには、スキーマの値が未定義の可能性がある。そのため、その値を補完する必要がある
        return {
          ...initialSheetScoreSummaryItem,
          ...item,
        } as SheetScoreSummaryItem;
      })
      .filter((c): c is NonNullable<typeof c> => c !== undefined)
      .sort((a, b) => Math.sign(a.sort - b.sort));
    return sheetScoreSummaryItems;
  }, [sheetDataRows, sheetFieldSchemas]);

  useEffect(() => {
    const runSettingData = async () => {
      if (!resultScoreSummary || !versionId) return;
      const [_sheetFieldSchemas, _sheetDataRows] = await Promise.all([
        getSheetFieldSchemas({
          versionId,
          sheetId: resultScoreSummary.id,
        }),
        getSheetDataRows({
          versionId,
          sheetId: resultScoreSummary.id,
        }),
      ]);
      setSheetDataRows(_sheetDataRows);
      setSheetFieldSchemas(_sheetFieldSchemas);
    };
    runSettingData();
  }, [resultScoreSummary, versionId]);

  return result;
};

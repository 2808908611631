import React from "react";
import { HashRouter } from "react-router-dom";

import { AppRoutes } from "./AppRoutes";
import { AppProvider } from "./components/AppProvider";
import { ErrorWrapper } from "./components/ErrorWrapper";
import "./App.module.css";

export const App: React.FC = () => {
  return (
    <ErrorWrapper>
      <HashRouter>
        <AppProvider>
          <AppRoutes />
        </AppProvider>
      </HashRouter>
    </ErrorWrapper>
  );
};

import loadable from "@loadable/component";

import {
  NshKws4sMaterialConsumptionByGroupDependSheet,
  NshKws4sMaterialConsumptionByProductDependSheet,
  NshKws4sProductMasterDependSheet,
  NshKws4sProductMaterialMasterDependSheet,
  NshKws4sProductTankAmountDependSheet,
  NshKws4sProductionAmountDependSheet,
  NshKws4sProductionDependSheet,
  NshKws4sRawMaterialAmountDependSheet,
  NshKws4sRawMaterialConsumptionDependSheet,
  NshKws4sRawMaterialPurchaseDependSheet,
  NshKws4sReactorProductMasterDependSheet,
  NshKws4sShippingScheduleDependSheet,
  NshKws4sSwitchMasterDependSheet,
  NshKws4sTankAmountDependSheet,
  NshKws4sTankMasterDependSheet,
  NshKws4sUnavailableReactorDependSheet,
  NshKws4sUnavailableTankDependSheet,
  NshKws4sViewTypeProductGroupMasterDependSheet,
} from "@/viewType/viewTypes/Optium/nshKws/dependSheets/s4";
import { S4GanttChartViewTypeDependSheet, StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const S4GanttChartProductionDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sProductionDependSheet;

export const S4GanttChartProductMasterDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sProductMasterDependSheet;

export const S4GanttChartReactorProductMasterDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sReactorProductMasterDependSheet;

export const S4GanttChartTankMasterDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sTankMasterDependSheet;

export const S4GanttChartUnavailableReactorDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sUnavailableReactorDependSheet;

export const S4GanttChartUnavailableTankDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sUnavailableTankDependSheet;

export const S4GanttChartSwitchMasterDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sSwitchMasterDependSheet;

export const S4GanttChartProductionAmountDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sProductionAmountDependSheet;

export const S4GanttChartProductTankAmountDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sProductTankAmountDependSheet;

export const S4GanttChartTankAmountDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sTankAmountDependSheet;

export const S4GanttChartRawMaterialAmountDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sRawMaterialAmountDependSheet;

export const S4GanttChartRawMaterialConsumptionDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sRawMaterialConsumptionDependSheet;

export const S4GanttChartRawMaterialPurchaseDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sRawMaterialPurchaseDependSheet;

export const S4GanttChartShippingScheduleDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sShippingScheduleDependSheet;

export const S4GanttChartProductMaterialMasterDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sProductMaterialMasterDependSheet;

export const S4GanttChartMaterialMasterDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sProductMaterialMasterDependSheet;

export const S4GanttChartMaterialConsumptionByProductDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sMaterialConsumptionByProductDependSheet;

export const S4GanttChartMaterialConsumptionByGroupDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sMaterialConsumptionByGroupDependSheet;

export const S4GanttChartProductGroupMasterDependSheet: S4GanttChartViewTypeDependSheet =
  NshKws4sViewTypeProductGroupMasterDependSheet;

const s4GanttChartDependSheets = [
  S4GanttChartProductionDependSheet,
  S4GanttChartProductMasterDependSheet,
  S4GanttChartReactorProductMasterDependSheet,
  S4GanttChartTankMasterDependSheet,
  S4GanttChartUnavailableReactorDependSheet,
  S4GanttChartUnavailableTankDependSheet,
  S4GanttChartSwitchMasterDependSheet,
  S4GanttChartProductionAmountDependSheet,
  S4GanttChartProductTankAmountDependSheet,
  S4GanttChartTankAmountDependSheet,
  S4GanttChartRawMaterialAmountDependSheet,
  S4GanttChartRawMaterialConsumptionDependSheet,
  S4GanttChartRawMaterialPurchaseDependSheet,
  S4GanttChartShippingScheduleDependSheet,
  S4GanttChartProductMaterialMasterDependSheet,
  S4GanttChartMaterialMasterDependSheet,
  S4GanttChartMaterialConsumptionByProductDependSheet,
  S4GanttChartMaterialConsumptionByGroupDependSheet,
  S4GanttChartProductGroupMasterDependSheet,
];

export const viewTypeNshKws4sGanttChart: StrictViewType = {
  key: "nshKws4sGanttChart",
  displayName: "NSH-KWS 第四製造課ガントチャート",
  render: ViewRender as any,
  dependSheets: s4GanttChartDependSheets,
  configSchemas: [],
};

import { TemporaryCourseSkillEditorViewTypeDependSheet } from "./params";

const temporaryCourseDependSheet: TemporaryCourseSkillEditorViewTypeDependSheet =
  {
    key: "workPlanToday",
    displayName: "当日勤務予定",
    dependSheetFields: [
      {
        key: "officeCode",
        displayName: "事業所コード",
        type: "string",
      },
      {
        key: "driverId",
        displayName: "氏名コード",
        type: "string",
      },
      {
        key: "date",
        displayName: "日付",
        type: "date",
      },
      {
        key: "workCode",
        displayName: "業務コード",
        type: "string",
      },
      {
        key: "workCodeSeq",
        displayName: "枝番",
        type: "integer",
      },
      {
        key: "workBeginTime",
        displayName: "開始時間",
        type: "string",
      },
      {
        key: "workEndTime",
        displayName: "終了時間",
        type: "string",
      },
      {
        key: "name",
        displayName: "氏名",
        type: "string",
      },
      {
        key: "category",
        displayName: "カテゴリ",
        type: "string",
      },
    ],
  };

const skillMasterDependSheet: TemporaryCourseSkillEditorViewTypeDependSheet = {
  key: "skillMaster",
  displayName: "スキル",
  dependSheetFields: [
    {
      key: "skillName",
      displayName: "スキル名",
      type: "string",
    },
  ],
};

const skillRelationDependSheet: TemporaryCourseSkillEditorViewTypeDependSheet =
  {
    key: "skillRelation",
    displayName: "必要スキル",
    dependSheetFields: [
      {
        key: "date",
        displayName: "日付",
        type: "date",
      },
      {
        key: "workCode",
        displayName: "業務コード",
        type: "string",
      },
      {
        key: "workCodeSeq",
        displayName: "枝番",
        type: "integer",
      },
      {
        key: "workBeginTime",
        displayName: "開始時間",
        type: "string",
      },
      {
        key: "workEndTime",
        displayName: "終了時間",
        type: "string",
      },
      {
        key: "skillName",
        displayName: "スキル名",
        type: "string",
      },
    ],
  };

export const skillEditorTemporaryCourseDependSheets = [
  temporaryCourseDependSheet,
  skillMasterDependSheet,
  skillRelationDependSheet,
];

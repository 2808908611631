// 組織内で登録・利用するConnect関連のAPI

import { ConnectBody, ConnectRaw } from "@/domain/Connect";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrl = "/connects";
const apiConnectExecutionUrl = "/connect_job";

export const getConnects = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<ConnectRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: ConnectRaw[] }>(apiUrl, {
    ...apiOrganizationIdParams,
  });
  return response.data.items;
};

export const getConnect = async ({
  organizationId,
  connect_id,
}: {
  organizationId: string;
  connect_id: string;
}): Promise<ConnectRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ item: ConnectRaw }>(
    `${apiUrl}/${connect_id}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postCreateConnect = async ({
  organizationId,
  connect,
}: {
  organizationId: string;
  connect: ConnectBody & { connect_secrets: { [k: string]: string } };
}): Promise<ConnectRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: ConnectRaw }>(
    apiUrl,
    connect,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postEditConnect = async ({
  organizationId,
  connect_id,
  connect,
}: {
  organizationId: string;
  connect_id: string;
  connect: ConnectBody;
}): Promise<ConnectRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: ConnectRaw }>(
    `${apiUrl}/${connect_id}`,
    connect,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const getConnectSecrets = async ({
  organizationId,
  connect_id,
}: {
  organizationId: string;
  connect_id: string;
}): Promise<{ [k: string]: string }> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ item: { [k: string]: string } }>(
    `${apiUrl}/${connect_id}/connect_secrets`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postEditConnectSecrets = async ({
  organizationId,
  connect_id,
  item,
}: {
  organizationId: string;
  connect_id: string;
  item: { [k: string]: string };
}): Promise<{ secret: string }> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ secret: string }>(
    `${apiUrl}/${connect_id}/connect_secrets`,
    { item },
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data;
};

export const postDeleteConnect = async ({
  organizationId,
  connect_id,
}: {
  organizationId: string;
  connect_id: string;
}): Promise<ConnectRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: ConnectRaw }>(
    `${apiUrl}/${connect_id}/delete`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postEnableConnect = async ({
  organizationId,
  connect_id,
}: {
  organizationId: string;
  connect_id: string;
}): Promise<ConnectRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: ConnectRaw }>(
    `${apiUrl}/${connect_id}/enable`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postRemoveConnect = async ({
  organizationId,
  connect_id,
}: {
  organizationId: string;
  connect_id: string;
}): Promise<ConnectRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: ConnectRaw }>(
    `${apiUrl}/${connect_id}/remove`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// Connectを手動で起動する
export const postExecuteConnect = async ({
  organizationId,
  connect_id,
}: {
  organizationId: string;
  connect_id: string;
}): Promise<{ result: string }> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const apiParams = {
    params: { ...apiOrganizationIdParams?.params, connect_id: connect_id },
  };
  const response = await API_CLIENT.post<{ result: string }>(
    apiConnectExecutionUrl,
    undefined,
    {
      ...apiParams,
    }
  );
  return response.data;
};

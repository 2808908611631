import { s2GanttChartViewTypeDependSheet } from "./params";

const s2GanttChartBasicInfoDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "basicInfo",
  displayName: "基本情報",
  dependSheetFields: [
    {
      key: "time_base_default",
      displayName: "表示時間",
      type: "string",
    },
    {
      key: "time_base_for_material_po",
      displayName: "原料 PO 向け基準時間",
      type: "string",
    },
  ],
};

const s2GanttChartProductsDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "products",
  displayName: "製品",
  dependSheetFields: [
    {
      key: "id",
      displayName: "製品ID",
      type: "string",
    },
    {
      key: "name",
      displayName: "製品名",
      type: "string",
    },
  ],
};

const s2GanttChartDevicesDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "devices",
  displayName: "設備",
  dependSheetFields: [
    {
      key: "id",
      displayName: "設備 ID",
      type: "string",
    },
    {
      key: "name",
      displayName: "設備名",
      type: "string",
    },
  ],
};

const s2GanttChartDeviceGroupsDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "deviceGroups",
  displayName: "設備グループ",
  dependSheetFields: [
    {
      key: "id",
      displayName: "設備グループ ID",
      type: "string",
    },
    {
      key: "name",
      displayName: "設備グループ名",
      type: "string",
    },
    {
      key: "sort_number",
      displayName: "ソート",
      type: "integer",
    },
  ],
};

const s2GanttChartDeviceMappingDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "deviceMapping",
  displayName: "設備グループ・設備対応",
  dependSheetFields: [
    {
      key: "device_group_id",
      displayName: "設備グループID",
      type: "string",
    },
    {
      key: "device_id",
      displayName: "設備ID",
      type: "string",
    },
    {
      key: "sort_in_device_group",
      displayName: "グループ内ソート",
      type: "integer",
    },
  ],
};

const s2GanttChartBatchProcessPlanDependSheet: s2GanttChartViewTypeDependSheet =
  {
    key: "batchProcessPlan",
    displayName: "工程計画",
    dependSheetFields: [
      {
        key: "time_start_step_1",
        displayName: "ステップ 1 開始時間",
        type: "date-time",
      },
      {
        key: "batch_process_set_id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "time_start_step_2",
        displayName: "ステップ 2 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_3",
        displayName: "ステップ 3 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_4",
        displayName: "ステップ 4 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_5",
        displayName: "ステップ 5 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_6",
        displayName: "ステップ 6 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_7",
        displayName: "ステップ 7 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_8",
        displayName: "ステップ 8 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_9",
        displayName: "ステップ 9 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_10",
        displayName: "ステップ 10 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_11",
        displayName: "ステップ 11 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_12",
        displayName: "ステップ 12 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_13",
        displayName: "ステップ 13 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_14",
        displayName: "ステップ 14 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_15",
        displayName: "ステップ 15 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_16",
        displayName: "ステップ 16 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_17",
        displayName: "ステップ 17 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_18",
        displayName: "ステップ 18 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_19",
        displayName: "ステップ 19 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_20",
        displayName: "ステップ 20 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_21",
        displayName: "ステップ 21 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_22",
        displayName: "ステップ 22 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_23",
        displayName: "ステップ 23 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_24",
        displayName: "ステップ 24 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_25",
        displayName: "ステップ 25 開始時間",
        type: "date-time",
      },
      {
        key: "time_start_step_26",
        displayName: "ステップ 26 開始時間",
        type: "date-time",
      },
      {
        key: "time_finish_batch_process_set",
        displayName: "工程セット終了時間",
        type: "date-time",
      },
      {
        key: "comment",
        displayName: "コメント",
        type: "string",
      },
      {
        key: "unique_id",
        displayName: "unique_id",
        type: "string",
      },
    ],
  };

const s2GanttChartBatchProcessSetDependSheet: s2GanttChartViewTypeDependSheet =
  {
    key: "batchProcessSet",
    displayName: "工程セット",
    dependSheetFields: [
      {
        key: "id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "name",
        displayName: "工程セット名",
        type: "string",
      },
      {
        key: "default_start_times",
        displayName: "開始時間配列",
        type: "array",
      },
      {
        key: "default_required_time",
        displayName: "基準所要時間",
        type: "integer",
      },
      {
        key: "product_id",
        displayName: "製品ID",
        type: "string",
      },
      {
        key: "device_group_id",
        displayName: "設備グループID",
        type: "string",
      },
      {
        key: "total_steps_in_batch_process_set",
        displayName: "工程セット内全工程数",
        type: "integer",
      },
      {
        key: "max_batch_per_day",
        displayName: "1日あたりの最大バッチ数",
        type: "integer",
      },
    ],
  };

const s2GanttChartBatchProductionPlanDependSheet: s2GanttChartViewTypeDependSheet =
  {
    key: "batchProductionPlan",
    displayName: "バッチ生産計画",
    dependSheetFields: [
      {
        key: "date",
        displayName: "日付",
        type: "date",
      },
      {
        key: "batch_process_set_id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "times_batch_process_set",
        displayName: "バッチ回数",
        type: "integer",
      },
    ],
  };

const s2GanttChartBatchProcessDetailDependSheet: s2GanttChartViewTypeDependSheet =
  {
    key: "batchProcessSetDetailProcess",
    displayName: "工程セット詳細工程",
    dependSheetFields: [
      {
        key: "batch_process_set_id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "step_in_batch_process_set",
        displayName: "工程セット内工程番号",
        type: "integer",
      },
      {
        key: "name",
        displayName: "詳細工程名",
        type: "string",
      },
      {
        key: "default_required_time",
        displayName: "デフォルト所要時間",
        type: "integer",
      },
      {
        key: "default_relative_start_timing_from_first_step",
        displayName: "デフォルト開始タイミング",
        type: "integer",
      },
      {
        key: "device_type",
        displayName: "設備タイプ",
        type: "enum",
      },
      {
        key: "device_id",
        displayName: "設備ID",
        type: "string",
      },
      {
        key: "device_group_id",
        displayName: "設備グループID",
        type: "string",
      },
      {
        key: "is_blank",
        displayName: "隙間かどうか",
        type: "boolean",
      },
      {
        key: "is_first",
        displayName: "最初の工程かどうか",
        type: "boolean",
      },
      {
        key: "next_step_in_batch_process_set",
        displayName: "次の工程番号",
        type: "integer",
      },
      {
        key: "process_gantt_chart_color",
        displayName: "工程背景色",
        type: "enum",
      },
    ],
  };

// TODO: アラート詳細のdependsheetの定義
const s2GanttChartAlertDetailsDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "alerts",
  displayName: "アラートマスタ",
  dependSheetFields: [
    {
      key: "alert_id",
      displayName: "アラートID",
      type: "string",
    },
    {
      key: "alert_name",
      displayName: "アラート名",
      type: "string",
    },
    {
      key: "alert_level",
      displayName: "アラートレベル",
      type: "enum",
    },
    {
      key: "alert_target_list",
      displayName: "アラート対象リスト",
      type: "array",
    },
    {
      key: "alert_explanation",
      displayName: "アラート説明",
      type: "string",
    },
    {
      key: "alert_message_template",
      displayName: "メッセージテンプレート",
      type: "string",
    },
    {
      key: "data_cooperation_kind",
      displayName: "データ連携種別",
      type: "enum",
    },
  ],
};

// データ連携時に使うシート
const s2BatchCountMappingDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "batchCountMapping",
  displayName: "バッチ数マッピングマスタ",
  dependSheetFields: [
    {
      key: "batch_process_set_id",
      displayName: "工程セットID",
      type: "string",
    },
    {
      key: "receive_condition",
      displayName: "受け入れ条件",
      type: "enum",
    },
    {
      key: "sheet_name",
      displayName: "シート名",
      type: "enum",
    },
    {
      key: "sheet_column_name",
      displayName: "列名",
      type: "string",
    },
    {
      key: "locked_sheet_name",
      displayName: "固定シート名",
      type: "enum",
    },
    {
      key: "integration_direction",
      displayName: "連携方向",
      type: "enum",
    },
    {
      key: "is_enable",
      displayName: "有効",
      type: "boolean",
    },
  ],
};

const s2EoaDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "eoa",
  displayName: "eoa",
  dependSheetFields: [
    {
      key: "date",
      displayName: "date",
      type: "date",
    },
    {
      key: "calcohol40Consumption",
      displayName: "24K417N|カルコール40|当日消費量(kg)",
      type: "float",
    },
    {
      key: "calcohol20Consumption",
      displayName: "24K417N|カルコール20|当日消費量(kg)",
      type: "float",
    },
    {
      key: "poConsumption",
      displayName: "24K417N|PO|当日消費量(kg)",
      type: "float",
    },
    {
      key: "kohConsumption",
      displayName: "24K417N|KOH|当日消費量(kg)",
      type: "float",
    },
    {
      key: "lactateConsumption",
      displayName: "24K417N|乳酸|当日消費量(kg)",
      type: "float",
    },
    {
      key: "eoConsumption",
      displayName: "24K417N|EO消費",
      type: "float",
    },
    {
      key: "calcohol40Stock",
      displayName: "24K417N|カルコール40|在庫(kg)",
      type: "float",
    },
    {
      key: "calcohol20Stock",
      displayName: "24K417N|カルコール20|在庫(kg)",
      type: "float",
    },
    {
      key: "poStock",
      displayName: "24K417N|PO|在庫(kg)",
      type: "float",
    },
    {
      key: "kohStock",
      displayName: "24K417N|KOH|在庫(kg)",
      type: "float",
    },
    {
      key: "lactateStock",
      displayName: "24K417N|乳酸|在庫(kg)",
      type: "float",
    },
    {
      key: "TotalDailyWasted",
      displayName: "24K417N|当日廃液量",
      type: "float",
    },
    {
      key: "MonthlyWastedLimit",
      displayName: "24K417N|累計月間廃液量",
      type: "float",
    },
    {
      key: "MonthlyWastedAccumulationLimit",
      displayName: "24K417N|累計月間廃液量上限",
      type: "float",
    },
    {
      key: "MonthlyWastedViolation",
      displayName: "24K417N|月間廃液量違反",
      type: "float",
    },
    {
      key: "A12501ProductionBatch",
      displayName: "24K417N|A-12501|生産バッチ数",
      type: "integer",
    },
    {
      key: "A12502ProductionBatch",
      displayName: "24K417N|A-12502|生産バッチ数",
      type: "integer",
    },
    {
      key: "shippingEast",
      displayName: "24K417N|出荷(東地区)",
      type: "float",
    },
    {
      key: "DeaerationA12501",
      displayName: "24K417N|脱気|A-12501バッチあたり廃液量",
      type: "float",
    },
    {
      key: "DeaerationA12502",
      displayName: "24K417N|脱気|A-12502バッチあたり廃液量",
      type: "float",
    },
    {
      key: "EvaporationA12501",
      displayName: "24K417N|脱水|A-12501バッチあたり廃液量",
      type: "float",
    },
    {
      key: "EvaporationA12502",
      displayName: "24K417N|脱水|A-12502バッチあたり廃液量",
      type: "float",
    },
    {
      key: "calcohol20A12501Consumption",
      displayName: "24K417N|カルコール20|A-12501バッチあたり消費量(kg)",
      type: "float",
    },
    {
      key: "calcohol20A12502Consumption",
      displayName: "24K417N|カルコール20|A-12502バッチあたり消費量(kg)",
      type: "float",
    },
    {
      key: "calcohol40A12501Consumption",
      displayName: "24K417N|カルコール40|A-12501バッチあたり消費量(kg)",
      type: "float",
    },
    {
      key: "calcohol40A12502Consumption",
      displayName: "24K417N|カルコール40|A-12502バッチあたり消費量(kg)",
      type: "float",
    },
    {
      key: "kohA12501Consumption",
      displayName: "24K417N|KOH|A-12501バッチあたり消費量(kg)",
      type: "float",
    },
    {
      key: "kohA12502Consumption",
      displayName: "24K417N|KOH|A-12502バッチあたり消費量(kg)",
      type: "float",
    },
    {
      key: "lactate12501Consumption",
      displayName: "24K417N|乳酸|A-12501バッチあたり消費量(kg)",
      type: "float",
    },
    {
      key: "lactateA12502Consumption",
      displayName: "24K417N|乳酸|A-12502バッチあたり消費量(kg)",
      type: "float",
    },
    {
      key: "poA12501Consumption",
      displayName: "24K417N|PO|A-12501バッチあたり消費量(kg)",
      type: "float",
    },
    {
      key: "poA12502Consumption",
      displayName: "24K417N|PO|A-12502バッチあたり消費量(kg)",
      type: "float",
    },
    {
      key: "calcohol40Arrival",
      displayName: "24K417N|カルコール40|入荷(kg)",
      type: "float",
    },
    {
      key: "calcohol20Arrival",
      displayName: "24K417N|カルコール20|入荷(kg)",
      type: "float",
    },
    {
      key: "poArrival",
      displayName: "24K417N|PO|入荷(kg)",
      type: "float",
    },
    {
      key: "kohArrival",
      displayName: "24K417N|KOH|入荷(kg)",
      type: "float",
    },
    {
      key: "lactateArrival",
      displayName: "24K417N|乳酸|入荷(kg)",
      type: "float",
    },
  ],
};

const s2EoaIsVariableDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "eoaIsVariable",
  displayName: "eoaIsVariable",
  dependSheetFields: [
    {
      key: "date",
      displayName: "date",
      type: "date",
    },
    {
      key: "A12501ProductionBatch",
      displayName: "24K417N|A-12501|生産バッチ数",
      type: "boolean",
    },
    {
      key: "A12502ProductionBatch",
      displayName: "24K417N|A-12502|生産バッチ数",
      type: "boolean",
    },
    {
      key: "eoConsumption",
      displayName: "24K417N|EO消費",
      type: "boolean",
    },
  ],
};

const s2BheaDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "bhea",
  displayName: "bhea",
  dependSheetFields: [
    {
      key: "date",
      displayName: "date",
      type: "date",
    },
    {
      key: "bheaEOConsumption",
      displayName: "BHEA|EO消費",
      type: "float",
    },
    {
      key: "bheaDailyWasted",
      displayName: "BHEA|当日廃液量",
      type: "float",
    },
    {
      key: "bheaAccumulationMonthlyWasted",
      displayName: "BHEA|累計月間廃液量",
      type: "float",
    },
    {
      key: "bheaAccumulationMonthlyWastedLimit",
      displayName: "BHEA|累計月間廃液量上限",
      type: "float",
    },
    {
      key: "bheaMonthlyWastedViolation",
      displayName: "BHEA|月間廃液量違反",
      type: "float",
    },
    {
      key: "bheaBatchIgnoringMaintenance",
      displayName: "BHEA|生産バッチ数(メンテ未考慮)",
      type: "integer",
    },
    {
      key: "bheaWastedPerBatch",
      displayName: "BHEA|バッチあたり廃液量",
      type: "float",
    },
  ],
};

const s2BheaIsVariableDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "bheaIsVariable",
  displayName: "bheaIsVariable",
  dependSheetFields: [
    {
      key: "date",
      displayName: "date",
      type: "date",
    },
    {
      key: "bheaEOConsumption",
      displayName: "BHEA|EO消費",
      type: "boolean",
    },
    {
      key: "bheaBatchIgnoringMaintenance",
      displayName: "BHEA|生産バッチ数(メンテ未考慮)",
      type: "boolean",
    },
  ],
};

const s2MaterialMappingDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "materialMapping",
  displayName: "原料マッピングマスタ",
  dependSheetFields: [
    {
      key: "material_id",
      displayName: "原料ID",
      type: "string",
    },
    {
      key: "info_kind",
      displayName: "情報種別",
      type: "enum",
    },
    {
      key: "batch_process_set_id_list",
      displayName: "工程セットIDリスト",
      type: "array",
    },
    {
      key: "sheet_name",
      displayName: "シート名",
      type: "enum",
    },
    {
      key: "sheet_column_name",
      displayName: "シート列名",
      type: "string",
    },
    {
      key: "locked_sheet_name",
      displayName: "固定シート名",
      type: "enum",
    },
  ],
};

const s2WasteLiquidMappingDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "wasteLiquidMapping",
  displayName: "廃液マッピングマスタ",
  dependSheetFields: [
    {
      key: "info_kind",
      displayName: "情報種別",
      type: "enum",
    },
    {
      key: "waste_liquid_target_list",
      displayName: "廃液対象リスト",
      type: "array",
    },
    {
      key: "sheet_name",
      displayName: "シート名",
      type: "enum",
    },
    {
      key: "sheet_column_name",
      displayName: "シート列名",
      type: "string",
    },
  ],
};

const s2MaterialsDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "materials",
  displayName: "原料マスタ",
  dependSheetFields: [
    {
      key: "id",
      displayName: "原料ID",
      type: "string",
    },
    {
      key: "name",
      displayName: "原料名",
      type: "string",
    },
    {
      key: "sort_number",
      displayName: "ソート",
      type: "integer",
    },
  ],
};

const s2WasteLiquidMasterDependSheet: s2GanttChartViewTypeDependSheet = {
  key: "wasteLiquidMaster",
  displayName: "工程セット詳細工程廃液マスタ",
  dependSheetFields: [
    {
      key: "batch_process_set_id",
      displayName: "工程セットID",
      type: "string",
    },
    {
      key: "step_in_batch_process_set",
      displayName: "工程セット内工程番号",
      type: "integer",
    },
    {
      key: "waste_liquid_amount",
      displayName: "廃液量",
      type: "float",
    },
    {
      key: "timing_waste_liquid",
      displayName: "廃液排出タイミング",
      type: "enum",
    },
  ],
};

const s2MaterialConsumptionMasterDependSheet: s2GanttChartViewTypeDependSheet =
  {
    key: "materialConsumptionMaster",
    displayName: "工程セット詳細工程原料消費マスタ",
    dependSheetFields: [
      {
        key: "material_id",
        displayName: "原料ID",
        type: "string",
      },
      {
        key: "batch_process_set_id",
        displayName: "工程セットID",
        type: "string",
      },
      {
        key: "step_in_batch_process_set",
        displayName: "工程セット内工程番号",
        type: "integer",
      },
      {
        key: "consumption",
        displayName: "消費量",
        type: "float",
      },
      {
        key: "timing_consumption",
        displayName: "消費タイミング",
        type: "enum",
      },
    ],
  };

export const s2GanttChartDependSheets = [
  s2GanttChartAlertDetailsDependSheet,
  s2GanttChartBasicInfoDependSheet,
  s2GanttChartProductsDependSheet,
  s2GanttChartDevicesDependSheet,
  s2GanttChartDeviceMappingDependSheet,
  s2GanttChartBatchProcessPlanDependSheet,
  s2GanttChartBatchProcessSetDependSheet,
  s2GanttChartBatchProductionPlanDependSheet,
  s2GanttChartDeviceGroupsDependSheet,
  s2GanttChartBatchProcessDetailDependSheet,

  // データ連携用
  s2BatchCountMappingDependSheet,
  s2EoaDependSheet,
  s2EoaIsVariableDependSheet,
  s2BheaDependSheet,
  s2BheaIsVariableDependSheet,
  s2MaterialMappingDependSheet,
  s2WasteLiquidMappingDependSheet,
  s2MaterialsDependSheet,
  s2WasteLiquidMasterDependSheet,
  s2MaterialConsumptionMasterDependSheet,
];

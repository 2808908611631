import React from "react";

import { useDialogStyles } from "@/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import { ErrorCircle24Filled } from "@fluentui/react-icons";
import { DialogAPIErrorParam } from "../hooks";
import styles from "../index.module.css";

export const DialogAPIError: React.FC<DialogAPIErrorParam> = ({
  displayMessage,
  errorCode,
  setErrorCode,
  responseMessage,
  setResponseMessage,
  dialogPhase,
  setDialogPhase,
  onClick,
}) => {
  const dialogClasses = useDialogStyles();
  return (
    <Dialog open={dialogPhase === "errorAPI"}>
      <DialogSurface className={dialogClasses.dialog}>
        <DialogBody>
          <DialogTitle>
            <div className={styles.titleWithIcon}>
              <ErrorCircle24Filled className={styles.errorIcon} />
              {displayMessage}
            </div>
          </DialogTitle>
          <DialogContent>
            <div className={styles.errorInfoTitle}>エラーコード</div>
            <div className={styles.errorResponse}>{errorCode}</div>
            <div className={styles.errorInfoTitle}>エラーメッセージ</div>
            <div className={styles.errorResponse}>
              {responseMessage.map((v) => {
                return <div key={v}>{v}</div>;
              })}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogPhase(null);
                setResponseMessage([]);
                setErrorCode("");
                onClick && onClick();
              }}
            >
              戻る
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

import {
  AppConnectMasterRaw,
  AppConnectMasterRawForm,
} from "@/domain/AppConnectMasters";
import { API_CLIENT } from "../config";

const apiUrl = "/admin/app_config";
const apiUrlConnects = `${apiUrl}/masters/connects`;

// アプリケーション全体のConnectマスタの一覧を取得する
export const getAdminAppConnectMasters = async (): Promise<
  AppConnectMasterRaw[]
> => {
  const response = await API_CLIENT.get<{ items: AppConnectMasterRaw[] }>(
    apiUrlConnects
  );
  return response.data.items;
};
// アプリケーション全体のConnectマスタを新規追加する
export const postAdminCreateAppConnectMaster = async (
  appConnectMasterRawForm: AppConnectMasterRawForm
): Promise<AppConnectMasterRaw> => {
  const response = await API_CLIENT.post<{ item: AppConnectMasterRaw }>(
    apiUrlConnects,
    {
      ...appConnectMasterRawForm,
    }
  );
  return response.data.item;
};

export const getAdminAppConnectMaster = async (
  connect_master_id: string
): Promise<AppConnectMasterRaw> => {
  const response = await API_CLIENT.get<{ item: AppConnectMasterRaw }>(
    `${apiUrlConnects}/${connect_master_id}`
  );
  return response.data.item;
};

export const postAdminEditAppConnectMaster = async ({
  connect_master_id,
  appConnectMasterRawForm,
}: {
  connect_master_id: string;
  appConnectMasterRawForm: AppConnectMasterRawForm;
}): Promise<AppConnectMasterRaw> => {
  const response = await API_CLIENT.post<{ item: AppConnectMasterRaw }>(
    `${apiUrlConnects}/${connect_master_id}`,
    {
      ...appConnectMasterRawForm,
    }
  );
  return response.data.item;
};
export const postAdminDisableAppConnectMaster = async ({
  connect_master_id,
}: {
  connect_master_id: string;
}): Promise<AppConnectMasterRaw> => {
  const response = await API_CLIENT.post<{ item: AppConnectMasterRaw }>(
    `${apiUrlConnects}/${connect_master_id}/delete`
  );
  return response.data.item;
};
export const postAdminEnableAppConnectMaster = async ({
  connect_master_id,
}: {
  connect_master_id: string;
}): Promise<AppConnectMasterRaw> => {
  const response = await API_CLIENT.post<{ item: AppConnectMasterRaw }>(
    `${apiUrlConnects}/${connect_master_id}/enable`
  );
  return response.data.item;
};
export const postAdminRemoveAppConnectMaster = async ({
  connect_master_id,
}: {
  connect_master_id: string;
}): Promise<AppConnectMasterRaw> => {
  const response = await API_CLIENT.post<{ item: AppConnectMasterRaw }>(
    `${apiUrlConnects}/${connect_master_id}/remove`
  );
  return response.data.item;
};

export const getAdminAppConnectMasterCommonSecrets = async ({
  connect_master_id,
}: {
  connect_master_id: string;
}): Promise<{ [k: string]: string }> => {
  const response = await API_CLIENT.get<{ item: { [k: string]: string } }>(
    `${apiUrlConnects}/${connect_master_id}/common_secrets`
  );
  return response.data.item;
};

export const postAdminAppConnectMasterCommonSecrets = async ({
  connect_master_id,
  item,
}: {
  connect_master_id: string;
  item: { [k: string]: string };
}): Promise<{ [k: string]: string }> => {
  const response = await API_CLIENT.post<{ item: { [k: string]: string } }>(
    `${apiUrlConnects}/${connect_master_id}/common_secrets`,
    { item }
  );
  return response.data.item;
};

import { compareDesc } from "date-fns";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Accept } from "react-dropzone";

import { useAppRouteParams } from "../../../../AppRoutes";
import { postFileEvaluate, postFileOptimize } from "../../../../api/optimize";
import { DialogPhaseStatesType } from "../../../Dialog/helpers/hooks";
import { useDialog } from "../../../Dialog/helpers/hooks";
import {
  DragAndDropFields,
  DragAndDropUploadData,
} from "../../../DragAndDropFields";
import { useAlgorithmVersions } from "../../../InOrganizationProvider/AlgorithmVersionsProvider";

import styles from "./index.module.css";

import { usePermissions } from "@/components/InOrganizationProvider/PermissionsProvider";
import { useVersionCategories } from "@/components/InOrganizationProvider/VersionCategoriesProvider";
import { parseDateTimeOrISODateTime } from "@/utils/dateFnsHelper";
import {
  Checkbox,
  Dropdown,
  Field,
  Input,
  Option,
  OptionOnSelectData,
  SelectionEvents,
} from "@fluentui/react-components";

const accept: Accept = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-excel": [".xls"],
  "application/json": [".json"],
  "application/zip": [".zip"],
};

type FileOptimizeForm = Pick<
  Parameters<typeof postFileOptimize>[0],
  "comment" | "title" | "algorithm_version" | "category"
>;
const initalFileOptimizeForm: FileOptimizeForm = {
  comment: "",
  title: "",
  algorithm_version: "",
  category: "",
};

export const FileOptimizeDialog: React.FC<DialogPhaseStatesType> = ({
  dialogPhase,
  setDialogPhase,
}) => {
  const { organizationId } = useAppRouteParams();
  const { hasPermission } = usePermissions();
  const hasOptimizeWritePermission = hasPermission("optimize", "write");
  const hasEvaluateWritePermission = hasPermission("evaluate", "write");
  const { categoriesDropdown } = useVersionCategories();
  const displayedCategoriesDropdown = categoriesDropdown?.filter(
    (c) => c.value !== "all" && c.value !== "disabled"
  );
  const { renderDialogs, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });
  const [isEvaluate, setIsEvaluate] = useState(
    hasEvaluateWritePermission && !hasOptimizeWritePermission
  );
  const { algorithmVersions, isLoadedAlgorithmVersions } =
    useAlgorithmVersions();
  const algorithmVersionOptions: { value: string; text: string }[] = useMemo(
    () =>
      algorithmVersions
        ?.filter(({ is_disabled }) => !is_disabled)
        .sort((a, b) => {
          const _a = parseDateTimeOrISODateTime(a.created_at);
          const _b = parseDateTimeOrISODateTime(b.created_at);
          return _a && _b ? compareDesc(_a, _b) : 0;
        })
        .map(({ version_id, version_name }) => ({
          value: version_id,
          text: version_name,
        })) ?? [],
    [algorithmVersions]
  );

  // アップされたデータ
  const [uploadedFileList, setUploadedFileList] = useState<
    DragAndDropUploadData[]
  >([]);

  const [fileOptimizeForm, setFileOptimizeForm] = useState<FileOptimizeForm>(
    initalFileOptimizeForm
  );

  // ダイアログを閉じたときフォームを初期化する
  useEffect(() => {
    if (!dialogPhase) {
      setFileOptimizeForm(initalFileOptimizeForm);
      setUploadedFileList([]);
      setIsEvaluate(false);
    }
  }, [dialogPhase]);

  const handleChangeTextField = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      fieldName: keyof FileOptimizeForm,
      value?: string | undefined
    ) => {
      setFileOptimizeForm((prevState) => ({
        ...prevState,
        [fieldName]: value ?? "",
      }));
    },
    []
  );

  const onChangeCategory = useCallback(
    (event: SelectionEvents, data: OptionOnSelectData) => {
      data.optionValue &&
        setFileOptimizeForm((prev) => ({
          ...prev,
          category: data.optionValue!,
        }));
    },
    []
  );

  const onChangeAlgorithmVersion = useCallback(
    (event: SelectionEvents, data: OptionOnSelectData) => {
      data.optionValue &&
        setFileOptimizeForm((prev) => ({
          ...prev,
          algorithm_version: data.optionValue!,
        }));
    },
    []
  );

  const runUpload = useCallback(async () => {
    setDialogPhase("in_progress");
    try {
      const file = uploadedFileList[0].file;
      if (isEvaluate) {
        await postFileEvaluate({
          organizationId,
          file,
          ...fileOptimizeForm,
        });
      } else {
        await postFileOptimize({
          organizationId,
          file,
          ...fileOptimizeForm,
        });
      }
      setFileOptimizeForm(initalFileOptimizeForm);
      setUploadedFileList([]);
      setDialogPhase("success");
      setIsEvaluate(false);
    } catch (error) {
      showErrorAPIDialog(error);
    }
  }, [uploadedFileList, fileOptimizeForm, isEvaluate]);

  const isDisableOptimize =
    !hasOptimizeWritePermission ||
    fileOptimizeForm.title === "" ||
    uploadedFileList.length === 0 ||
    fileOptimizeForm.algorithm_version === "";

  const isDisableEvaluate =
    !hasEvaluateWritePermission ||
    fileOptimizeForm.title === "" ||
    uploadedFileList.length === 0 ||
    fileOptimizeForm.algorithm_version === "";

  return (
    <>
      {renderDialogs({
        confirm: {
          title: "ファイル最適化",
          displayMessage:
            "ファイルに対して評価 / 最適化を実行し、新しいバージョンを作成します。",
          buttonText: "実行",
          onClick: runUpload,
          onClickCancel: () => {
            setDialogPhase(null);
            setFileOptimizeForm(initalFileOptimizeForm);
            setUploadedFileList([]);
            setIsEvaluate(false);
          },
          isDisableButton: isEvaluate ? isDisableEvaluate : isDisableOptimize,
          eventBubblingEnabled: true,
          element: (
            <div className={styles.form}>
              <DragAndDropFields
                accept={accept}
                isDisableCancelButton={dialogPhase !== "confirm"}
                uploadedFileList={uploadedFileList}
                setUploadedFileList={setUploadedFileList}
                maxFileNumber={1}
                fileTypes={["excel", "json", "zip"]}
              />
              <Checkbox
                label={"評価のみ実行する"}
                checked={isEvaluate}
                onChange={(ev, data) => {
                  if (typeof data.checked === "string") return;
                  setIsEvaluate((_prev) => !!data.checked);
                }}
                disabled={
                  hasOptimizeWritePermission != hasEvaluateWritePermission
                }
              />
              <Field label="バージョンタイトル">
                <Input
                  className={styles.input}
                  value={fileOptimizeForm.title}
                  onChange={(event, data) => {
                    handleChangeTextField(event, "title", data.value);
                  }}
                  placeholder="入力してください"
                />
              </Field>
              <Field label="バージョンコメント">
                <Input
                  className={styles.input}
                  value={fileOptimizeForm.comment}
                  onChange={(event, data) => {
                    handleChangeTextField(event, "comment", data.value);
                  }}
                />
              </Field>
              {displayedCategoriesDropdown && (
                <Field label="新しいバージョンに付与するカテゴリ">
                  <Dropdown
                    className={styles.dropdown}
                    onOptionSelect={onChangeCategory}
                    placeholder={"選択してください"}
                    value={
                      displayedCategoriesDropdown.find(
                        (c) => c.value === fileOptimizeForm.category
                      )?.text ?? ""
                    }
                    selectedOptions={[
                      displayedCategoriesDropdown.find(
                        (c) => c.value === fileOptimizeForm.category
                      )?.text ?? "",
                    ]}
                  >
                    {displayedCategoriesDropdown.map((c) => (
                      <Option key={c.value} value={c.value}>
                        {c.text}
                      </Option>
                    ))}
                  </Dropdown>
                </Field>
              )}
              {algorithmVersions ? (
                <Field label="使用するアルゴリズムバージョン">
                  <Dropdown
                    className={styles.dropdown}
                    onOptionSelect={onChangeAlgorithmVersion}
                    placeholder={"選択してください"}
                    value={
                      algorithmVersionOptions.find(
                        (v) => v.value === fileOptimizeForm.algorithm_version
                      )?.text ?? ""
                    }
                    selectedOptions={[
                      algorithmVersionOptions.find(
                        (v) => v.value === fileOptimizeForm.algorithm_version
                      )?.text ?? "",
                    ]}
                  >
                    {algorithmVersionOptions.map((v) => (
                      <Option key={v.value} value={v.value}>
                        {v.text}
                      </Option>
                    ))}
                  </Dropdown>
                  {algorithmVersionOptions.length === 0 && (
                    <div className={styles.errorMessage}>
                      アルゴリズムバージョンが存在しません。作成してください
                    </div>
                  )}
                </Field>
              ) : (
                <div>アルゴリズムバージョンを閲覧する権限がありません。</div>
              )}
              <div className={styles.algorithmVersionId}>
                <div>アルゴリズムバージョンID(自動入力)</div>
                <div>{fileOptimizeForm.algorithm_version}</div>
              </div>
            </div>
          ),
        },
        inProgress: {
          title: "入力ファイルをアップロードしています",
        },
        success: {
          displayMessage: "入力ファイルのアップロードが終了しました",
        },
        errorAPI: {
          displayMessage: "入力ファイルのアップロードが失敗しました",
        },
      })}
    </>
  );
};

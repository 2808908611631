import { useCallback, useEffect, useRef, useState } from "react";

export const useResizeHandler = <T extends HTMLElement>({
  type,
  targetRef,
  onResizeStart,
  onResize,
  onResizeEnd,
  dynamicRect = false,
}: {
  type: "left" | "top" | "right" | "bottom";
  targetRef: React.RefObject<T>;
  onResizeStart?: () => void;
  onResize?: (width: number, height: number) => void;
  onResizeEnd?: () => void;
  //dynamicRect: リサイズ中にtype以外の位置が変わるときにtrueにする(多分そんなことはないが)
  dynamicRect?: boolean;
}) => {
  const eventsRef = useRef({ onResizeStart, onResize, onResizeEnd });
  eventsRef.current.onResizeStart = onResizeStart;
  eventsRef.current.onResize = onResize;
  eventsRef.current.onResizeEnd = onResizeEnd;

  const onHandlerMouseDown = useCallback(() => {
    const events = eventsRef.current;
    const target = targetRef.current;
    if (!target) return;
    let baseRect = target.getBoundingClientRect();
    const onMouseMove = (e: MouseEvent) => {
      if (dynamicRect) baseRect = target.getBoundingClientRect();
      const x = Math.min(window.innerWidth, Math.max(0, e.clientX));
      const y = Math.min(window.innerHeight, Math.max(0, e.clientY));
      let w = baseRect.width;
      let h = baseRect.height;
      if (type === "left") w = baseRect.right - x;
      else if (type === "top") h = baseRect.bottom - y;
      else if (type === "right") w = x - baseRect.x;
      else if (type === "bottom") h = y - baseRect.y;
      events.onResize?.(Math.max(0, w), Math.max(0, h));
    };
    const onMouseUp = (e: MouseEvent) => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      events.onResizeEnd?.();
    };
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    window.getSelection()?.removeAllRanges();
    events.onResizeStart?.();
  }, [type, targetRef]);
  return onHandlerMouseDown;
};

import React, {
  useMemo,
  useState,
  ReactNode,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";

import { getAPIErrorDetailAndMessage } from "@/api/helpers";
import {
  getInvitationsOrganizations,
  getOrganizations,
} from "../../../api/organizations";
import { OrganizationsItemRaw } from "../../../domain/Organization";
import { useAppAccount } from "../AppAccountProvider";

import { OrganizationServiceType } from "@/domain/Optimize";
import aaLogo from "../../../images/aa_logo_only.png";
import optiumLogo from "../../../images/optium_logo_only.png";
import planiumLogo from "../../../images/planium_logo_only.png";

const organizationCtx = createContext<{
  organizations: OrganizationsItemRaw[];
  invitedOrganizations: OrganizationsItemRaw[];
  runReloadOrganizations: () => Promise<void>;
  runReloadInvitedOrganizations: () => Promise<void>;
  isLoadedOrganizations: boolean;
  isLoadedInvitedOrganizations: boolean;
  defaultOrgServiceType: OrganizationServiceType | null;
}>({
  organizations: [],
  invitedOrganizations: [],
  runReloadOrganizations: async () => {
    // nop
  },
  runReloadInvitedOrganizations: async () => {
    // nop
  },
  isLoadedOrganizations: false,
  isLoadedInvitedOrganizations: false,
  defaultOrgServiceType: null,
});

/**
 * @description サイドメニュー, 組織設定画面などさまざまな場所で使う組織データを管理
 */
export const AppOrgsAndInvitedOrgsProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { account } = useAppAccount();
  const [organizations, setOrganizations] = useState<OrganizationsItemRaw[]>(
    []
  );
  const [invitedOrganizations, setInvitedOrganizations] = useState<
    OrganizationsItemRaw[]
  >([]);

  const [isLoadedOrganizations, setIsLoadedOrganizations] =
    useState<boolean>(false);
  const [isLoadedInvitedOrganizations, setIsInvitedLoadedOrganizations] =
    useState<boolean>(false);
  const [defaultOrgServiceType, setDefaultOrgServiceType] =
    useState<OrganizationServiceType | null>(null);

  const [_, setError] = useState<unknown>(null);

  const runReloadOrganizations = useCallback(async () => {
    try {
      setIsLoadedOrganizations(false);
      const response = await getOrganizations();
      setIsLoadedOrganizations(true);
      setOrganizations(response);
    } catch (err: unknown) {
      setError(() => {
        if (typeof err == "object") {
          const e = {
            ...err,
            message: getAPIErrorDetailAndMessage(err),
          } as Error;
          throw e;
        } else {
          throw new Error("不明なエラー");
        }
      });
    }
  }, []);

  const runReloadInvitedOrganizations = useCallback(async () => {
    try {
      setIsInvitedLoadedOrganizations(false);
      const response = await getInvitationsOrganizations();
      setIsInvitedLoadedOrganizations(true);
      response && setInvitedOrganizations(response);
    } catch (err: unknown) {
      setError(() => {
        if (typeof err == "object") {
          const e = {
            ...err,
            message: getAPIErrorDetailAndMessage(err),
          } as Error;
          throw e;
        } else {
          throw new Error("不明なエラー");
        }
      });
    }
  }, []);

  const organizationCtxValue = useMemo(
    () => ({
      organizations,
      invitedOrganizations,
      runReloadInvitedOrganizations,
      runReloadOrganizations,
      isLoadedOrganizations,
      isLoadedInvitedOrganizations,
      defaultOrgServiceType,
    }),
    [
      organizations,
      invitedOrganizations,
      runReloadOrganizations,
      runReloadInvitedOrganizations,
      isLoadedOrganizations,
      isLoadedInvitedOrganizations,
      defaultOrgServiceType,
    ]
  );

  useEffect(() => {
    runReloadOrganizations();
    runReloadInvitedOrganizations();
  }, []);

  // Change favicon and title according to organizationKindList
  useEffect(() => {
    const defaultOrganization = organizations.find(
      (org) => org.org_id === account?.default_org_id
    );
    if (!defaultOrganization) {
      setDefaultOrgServiceType(null);
    } else {
      setDefaultOrgServiceType(
        defaultOrganization.org_kind.startsWith("planium")
          ? "planium"
          : "optium"
      );
    }
  }, [organizations, account]);

  useEffect(() => {
    const link = document.querySelector<HTMLLinkElement>("link[rel*='icon']");
    if (defaultOrgServiceType === "optium") {
      link && link?.setAttribute("href", optiumLogo);
      document.title = "Optium";
    } else if (defaultOrgServiceType === "planium") {
      link && link?.setAttribute("href", planiumLogo);
      document.title = "Planium";
    } else {
      link && link?.setAttribute("href", aaLogo);
      document.title = "ALGO ARTIS 最適化サービス";
    }
  }, [defaultOrgServiceType]);

  return (
    <organizationCtx.Provider value={organizationCtxValue}>
      {children}
    </organizationCtx.Provider>
  );
};

export const useAppOrgsAndInvitedOrgs = () => {
  return useContext(organizationCtx);
};

import { Spinner } from "@fluentui/react-components";
import React from "react";

import styles from "./index.module.css";

export const LoadingCover: React.FC<{ loadingText?: string }> = ({
  loadingText = "データを読み込んでいます...",
}) => {
  return (
    <div className={styles.loadingCover}>
      <Spinner size="small" />
      <div className={styles.loadingText}>{loadingText}</div>
    </div>
  );
};

export const LoadingCoverWithoutText: React.FC = () => {
  return (
    <div className={styles.loadingCoverWithoutText}>
      <Spinner size="small" />
    </div>
  );
};

import { DemoShipViewTypeDependSheet } from "./params";
/**
 * ViewType定義時引数
 */
const demoShipShipContractsDependSheet: DemoShipViewTypeDependSheet = {
  key: "shipContracts",
  displayName: "船舶",
  dependSheetFields: [
    {
      key: "id",
      displayName: "船id",
      type: "string",
    },
    {
      key: "name",
      displayName: "船式名称",
      type: "string",
    },
    {
      key: "official_name",
      displayName: "船正式名称",
      type: "string",
    },
    {
      key: "company",
      displayName: "船会社",
      type: "string",
    },
    {
      key: "capacity",
      displayName: "積載量(t)",
      type: "integer",
    },
    {
      key: "speed_low",
      displayName: "航海日速[低速巡航](ノット)",
      type: "float",
    },
    {
      key: "fuel_efficiency_low",
      displayName: "燃料消費量[低速航行](t/km)",
      type: "float",
    },
    {
      key: "co2_efficiency_low",
      displayName: "CO2排出量[低速航行](t/km)",
      type: "float",
    },
    {
      key: "speed_high",
      displayName: "航海日速[高速航行](ノット)",
      type: "float",
    },
    {
      key: "fuel_efficiency_high",
      displayName: "燃料消費量[高速航行](t/km)",
      type: "float",
    },
    {
      key: "co2_efficiency_high",
      displayName: "CO2排出量[高速航行](t/km)",
      type: "float",
    },
    {
      key: "contract_fee_demurrage",
      displayName: "滞船料(千円/日)",
      type: "integer",
    },
  ],
};
const demoShipTasksDependSheet: DemoShipViewTypeDependSheet = {
  key: "tasks",
  displayName: "タスク",
  dependSheetFields: [
    {
      key: "amount",
      displayName: "原材料の積載量",
      type: "integer",
    },
    {
      key: "date_arrival_loading_port",
      displayName: "荷揚げ港到着時刻",
      type: "date",
    },
    {
      key: "date_arrival_unloading_port",
      displayName: "荷下ろし港到着時刻",
      type: "date",
    },
    {
      key: "date_laycan_end",
      displayName: "レイキャン終了時刻",
      type: "date",
    },
    {
      key: "date_laycan_start",
      displayName: "レイキャン開始時刻",
      type: "date",
    },
    {
      key: "date_loading_end",
      displayName: "荷揚げ終了時刻",
      type: "date",
    },
    {
      key: "date_loading_start",
      displayName: "荷揚げ開始時刻",
      type: "date",
    },
    {
      key: "date_departure_loading_port",
      displayName: "積地出発時刻",
      type: "date",
    },
    {
      key: "date_task_end",
      displayName: "タスク終了日",
      type: "date",
    },
    {
      key: "date_task_start",
      displayName: "タスク開始日",
      type: "date",
    },
    {
      key: "date_unloading_end",
      displayName: "荷下ろし終了時刻",
      type: "date",
    },
    {
      key: "date_unloading_start",
      displayName: "荷下ろし開始時刻",
      type: "date",
    },
    {
      key: "days_loading",
      displayName: "荷揚げ日数",
      type: "integer",
    },
    {
      key: "days_unloading",
      displayName: "荷下ろし日数",
      type: "integer",
    },
    {
      key: "days_task",
      displayName: "タスク日数",
      type: "integer",
    },
    {
      key: "days_to_loading_port",
      displayName: "出港日数",
      type: "integer",
    },
    {
      key: "days_to_unloading_port",
      displayName: "帰港日数",
      type: "integer",
    },
    {
      key: "is_fixed",
      displayName: "固定設定",
      type: "boolean",
    },
    {
      key: "loading_port_id",
      displayName: "積地ID",
      type: "string",
    },
    {
      key: "loading_port_name",
      displayName: "積地名",
      type: "string",
    },
    {
      key: "material_id",
      displayName: "原材料ID",
      type: "string",
    },
    {
      key: "material_name",
      displayName: "原材料名",
      type: "string",
    },
    {
      key: "ship_id",
      displayName: "船ID",
      type: "string",
    },
    {
      key: "ship_name",
      displayName: "船名",
      type: "string",
    },
    {
      key: "ship_speed_level",
      displayName: "航海速度レベル",
      type: "integer",
    },
    {
      key: "source_unloading_port_id",
      displayName: "出発地港ID",
      type: "string",
    },
    {
      key: "source_unloading_port_name",
      displayName: "出発地港名",
      type: "string",
    },
    {
      key: "task_id",
      displayName: "タスクID",
      type: "string",
    },
    {
      key: "task_kind",
      displayName: "項目種別",
      type: "string",
    },
    {
      key: "unloading_port_id",
      displayName: "揚地ID",
      type: "string",
    },
    {
      key: "unloading_port_name",
      displayName: "揚地名",
      type: "string",
    },
  ],
};
const demoShipMaterialDependSheet: DemoShipViewTypeDependSheet = {
  key: "materialContracts",
  displayName: "原材料",
  dependSheetFields: [
    {
      key: "id",
      displayName: "原材料ID",
      type: "string",
    },
    {
      key: "material_company",
      displayName: "商社略称",
      type: "string",
    },
    {
      key: "material_company_official_name",
      displayName: "商社正式名称",
      type: "string",
    },
    {
      key: "material_name",
      displayName: "原材料名称",
      type: "string",
    },
    {
      key: "material_official_name",
      displayName: "原材料正式名称",
      type: "string",
    },
    {
      key: "port_country",
      displayName: "港の国籍",
      type: "string",
    },
    {
      key: "port_default_speed_loading",
      displayName: "船への積載速度(t/日)",
      type: "integer",
    },
    {
      key: "port_default_turn_time",
      displayName: "港で積載以外でかかる日数",
      type: "integer",
    },
    {
      key: "port_name",
      displayName: "港略称",
      type: "string",
    },
    {
      key: "port_official_name",
      displayName: "港正式名称",
      type: "string",
    },
  ],
};
const demoShipPlantsDependSheet: DemoShipViewTypeDependSheet = {
  key: "plants",
  displayName: "工場",
  dependSheetFields: [
    {
      key: "id",
      displayName: "工場ID",
      type: "string",
    },
    {
      key: "name",
      displayName: "工場名称",
      type: "string",
    },
    {
      key: "official_name",
      displayName: "工場正式名称",
      type: "string",
    },
    {
      key: "stock_total_min",
      displayName: "最小在庫量(t)",
      type: "integer",
    },
    {
      key: "stock_total_max",
      displayName: "最大在庫量(t)",
      type: "integer",
    },
    {
      key: "stock_total_proper",
      displayName: "適正在庫量(t)",
      type: "integer",
    },
    {
      key: "default_stock_consumption",
      displayName: "通常在庫消費量(t/日)",
      type: "integer",
    },
    {
      key: "port_default_speed_unloading",
      displayName: "荷揚げ速度(t/日)",
      type: "integer",
    },
    {
      key: "port_default_turn_time",
      displayName: "港の荷揚げ以外にかかる日数",
      type: "integer",
    },
  ],
};
const demoShipPortDistancesDependSheet: DemoShipViewTypeDependSheet = {
  key: "portDistances",
  displayName: "距離",
  dependSheetFields: [
    {
      key: "port_id_from",
      displayName: "出発地名",
      type: "string",
    },
    {
      key: "port_id_to",
      displayName: "目的地名",
      type: "string",
    },
    {
      key: "distance_km",
      displayName: "距離(km)",
      type: "integer",
    },
  ],
};

export const demoShipDependSheets = [
  demoShipTasksDependSheet,
  demoShipShipContractsDependSheet,
  demoShipMaterialDependSheet,
  demoShipPlantsDependSheet,
  demoShipPortDistancesDependSheet,
];

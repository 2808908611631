import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeDeliveryPlanDemo: StrictViewType = {
  key: "deliveryPlan",
  displayName: "販売計画ビュー（デモ）",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "deliveryOrderSheet",
      displayName: "販売予定",
      dependSheetFields: [
        {
          key: "orderId",
          displayName: "販売予定ID",
          type: "string",
        },
        {
          key: "dueDate",
          displayName: "出荷予定日",
          type: "date",
        },
        {
          key: "itemName",
          displayName: "品目名",
          type: "string",
        },
        {
          key: "quantity",
          displayName: "数量",
          type: "float",
        },
      ],
    },
    {
      key: "deliveryPlanSheet",
      displayName: "出荷計画",
      dependSheetFields: [
        {
          key: "orderId",
          displayName: "販売予定ID",
          type: "string",
        },
        {
          key: "deliveryDate",
          displayName: "出荷日",
          type: "date",
        },
        {
          key: "storageName",
          displayName: "保管庫",
          type: "string",
        },
      ],
    },
    {
      key: "itemMasterSheet",
      displayName: "品目マスタ",
      dependSheetFields: [
        {
          key: "itemName",
          displayName: "品目名",
          type: "string",
        },
        {
          key: "unit",
          displayName: "単位",
          type: "string",
        },
      ],
    },
    {
      key: "itemStorageMasterSheet",
      displayName: "保管所品目マスタ",
      dependSheetFields: [
        { key: "storageName", displayName: "保管所", type: "string" },
        { key: "itemName", displayName: "品目名", type: "string" },
      ],
    },
    {
      key: "alertSheet",
      displayName: "アラートデータ",
      dependSheetFields: [
        {
          key: "startDate",
          displayName: "開始日",
          type: "date-time",
        },
        { key: "endDate", displayName: "終了日", type: "date-time" },
        {
          key: "alertCategory",
          displayName: "アラートのカテゴリ",
          type: "string",
        },
        {
          key: "alertDetail",
          displayName: "アラートの詳細",
          type: "string",
        },
        {
          key: "alertSummary",
          displayName: "アラートの概要",
          type: "string",
        },
        {
          key: "viewInfo",
          displayName: "アラートの情報",
          type: "string",
        },
      ],
    },
  ],
  configSchemas: [],
};

import { DriverEditorViewTypeDependSheet } from "./params";

const driverMasterDependSheet: DriverEditorViewTypeDependSheet = {
  key: "driverMaster",
  displayName: "乗務員",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "driverName",
      displayName: "氏名",
      type: "string",
    },
  ],
};

const driverDataMasterDependSheet: DriverEditorViewTypeDependSheet = {
  key: "driverDataMaster",
  displayName: "乗務員編集データ",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "frontOffice",
      displayName: "フロント内勤",
      type: "string",
    },
    {
      key: "seniorGrade",
      displayName: "S級",
      type: "string",
    },
    {
      key: "workRequestType",
      displayName: "希望形態",
      type: "string",
    },
    {
      key: "prohibitOutOfRange",
      displayName: "出勤日時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeFirstWeekday",
      displayName: "第一公休日 平日 時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeFirstSaturday",
      displayName: "第一公休日 土曜日 時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeFirstDayoff",
      displayName: "第一公休日 休日 時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeSecondWeekday",
      displayName: "第二公休日 平日 時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeSecondSaturday",
      displayName: "第二公休日 土曜日 時間外禁止",
      type: "string",
    },
    {
      key: "prohibitOutOfRangeSecondDayoff",
      displayName: "第二公休日 休日 時間外禁止",
      type: "string",
    },
  ],
};

export const driverEditorDependSheets = [
  driverMasterDependSheet,
  driverDataMasterDependSheet,
];

import React from "react";

import { View, useSetView } from "../../../../../domain/View";
import {
  useGetViewConfigSchemas,
  useSetViewConfigSchema,
} from "../../../../../domain/ViewConfigSchema";
import {
  useGetViewDependSheets,
  useSetViewDependSheet,
} from "../../../../../domain/ViewDependSheet";
import {
  useGetViewDependSheetFields,
  useSetViewDependSheetField,
} from "../../../../../domain/ViewDependSheetField";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";
import {
  duplicateRecursiveViewData,
  getNewDuplicationViewTitleWithNumbering,
} from "../helper";

export const DialogViewDuplicate: React.FC<
  {
    views: View[];
    selectedViewIds: string[];
    setSelectedViewIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  views,
  selectedViewIds,
  setSelectedViewIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const setView = useSetView();
  const setViewConfigSchema = useSetViewConfigSchema();
  const setViewDependSheet = useSetViewDependSheet();
  const setViewDependSheetField = useSetViewDependSheetField();
  const getViewConfigSchemas = useGetViewConfigSchemas();
  const getViewDependSheetFields = useGetViewDependSheetFields();
  const getViewDependSheets = useGetViewDependSheets();

  const { renderDialogs, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const viewId = selectedViewIds[0];
  const duplicateView = views.find((view) => view.id === viewId);
  const runDuplicate = async () => {
    if (!duplicateView) return;
    setDialogPhase("in_progress");
    const newTitle = getNewDuplicationViewTitleWithNumbering(
      views,
      duplicateView.title
    );
    try {
      await duplicateRecursiveViewData({
        duplicateView,
        duplicateViewTitle: newTitle,
        getViewDependSheets,
        getViewConfigSchemas,
        getViewDependSheetFields,
        setView,
        setViewConfigSchema,
        setViewDependSheet,
        setViewDependSheetField,
        sort: views.length,
      });
      setDialogPhase("success");
      setSelectedViewIds([]);
    } catch (error) {
      showErrorAPIDialog(error);
    }
  };
  const messages = getDialogMessages({
    isSome: false,
    actionType: "duplicate",
    targetType: "view",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runDuplicate,
          onClickCancel: () => {
            setSelectedViewIds([]);
          },
          ...messages.confirm,
        },
        inProgress: {
          ...messages.inProgress,
        },
        success: { ...messages.success },
        errorAPI: {
          ...messages.error,
        },
      })}
    </>
  );
};

import { ViewMasterRaw } from "../../domain/ViewMaster";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrl = "admin/view_masters";

// 組織ごとの View の権限管理、利用可能な View のリスト表示
export const getAdminViewMasters = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<ViewMasterRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: ViewMasterRaw[] }>(apiUrl, {
    ...apiOrganizationIdParams,
  });
  return response.data.items;
};
export const getAdminViewMaster = async ({
  organizationId,
  viewMasterId,
}: {
  organizationId: string;
  viewMasterId: string;
}): Promise<ViewMasterRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ item: ViewMasterRaw }>(
    `${apiUrl}/${viewMasterId}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postAdminEnableViewMaster = async ({
  organizationId,
  viewMasterId,
}: {
  organizationId: string;
  viewMasterId: string;
}): Promise<ViewMasterRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: ViewMasterRaw }>(
    `${apiUrl}/${viewMasterId}/enable`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};
export const postAdminDisableViewMaster = async ({
  organizationId,
  viewMasterId,
}: {
  organizationId: string;
  viewMasterId: string;
}): Promise<ViewMasterRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: ViewMasterRaw }>(
    `${apiUrl}/${viewMasterId}/disable`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

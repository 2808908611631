import React, { useState, useCallback } from "react";

import { getAPIErrorDetailOrMesssage } from "../../../api/helpers";

import { DialogAPIError } from "./DialogAPIError";
import { DialogConfirm } from "./DialogConfirm";
import { DialogInProgress } from "./DialogInProgress";
import { DialogLocalError } from "./DialogLocalError";
import { DialogSuccess } from "./DialogSuccess";
/**
 * @description API実行時のステータスやダイアログの管理を行う
 */
export type DialogPhaseType =
  | "confirm"
  | "in_progress"
  | "errorAPI"
  | "errorLocal"
  | "success"
  | null;

export type DialogPhaseStatesType = {
  dialogPhase: DialogPhaseType;
  setDialogPhase: React.Dispatch<React.SetStateAction<DialogPhaseType>>;
};

export type DialogConfirmParam = {
  title: string;
  displayMessage?: string;
  buttonText: string;
  onClick: () => void | Promise<void>;
  onClickCancel?: () => void;
  element?: React.ReactElement;
  isThemeRed?: boolean;
  isDisableButton?: boolean;
  eventBubblingEnabled?: boolean;
} & DialogPhaseStatesType;

export type DialogAPIErrorParam = {
  displayMessage: string;
  errorCode: string;
  responseMessage: string[];
  setErrorCode: React.Dispatch<React.SetStateAction<string>>;
  setResponseMessage: React.Dispatch<React.SetStateAction<string[]>>;
  onClick?: () => void;
} & DialogPhaseStatesType;

export type DialogLocalErrorParam = {
  onClick?: () => void;
  displayMessage: string;
  errorMessages: string[];
} & DialogPhaseStatesType;

export type DialogInProgressParam = {
  title: string;
  subText?: string;
  count?: {
    current: number;
    /** NOTE: count.total(分母) には、削除前後で変動しない値を渡すこと */
    total: number;
  };
};

export type DialogSuccessParam = {
  displayMessage: string;
  onClick?: () => void;
  buttonText?: string;
  displaySubText?: string;
  externalButtonInfo?: {
    onClick: () => void;
    buttonText: string;
  };
} & DialogPhaseStatesType;

export const useDialog = (params: DialogPhaseStatesType) => {
  const { dialogPhase, setDialogPhase } = params;
  const [isInProgress, setIsInProgress] = useState(false);

  const [responseMessage, setResponseMessage] = useState<string[]>([]);
  const [errorCode, setErrorCode] = useState<string>("");

  const [errorMessagesState, setErrorMessagesState] = useState<string[]>([]);

  const renderDialogs = useCallback(
    (parameters: {
      confirm?: Omit<DialogConfirmParam, keyof DialogPhaseStatesType>;
      success?: Omit<DialogSuccessParam, keyof DialogPhaseStatesType>;
      errorAPI?: Pick<DialogAPIErrorParam, "onClick" | "displayMessage">;
      errorLocal?: Pick<DialogLocalErrorParam, "onClick" | "displayMessage">;
      inProgress?: DialogInProgressParam;
    }) => {
      const { confirm, success, errorAPI, inProgress, errorLocal } = parameters;
      return (
        <>
          {confirm ? (
            <DialogConfirm
              setDialogPhase={setDialogPhase}
              dialogPhase={dialogPhase}
              {...confirm}
            />
          ) : null}
          {inProgress ? (
            <DialogInProgress
              title={inProgress?.title ?? "処理中"}
              subText={inProgress?.subText ?? `完了するまでお待ち下さい...`}
              count={inProgress.count}
              dialogPhase={dialogPhase}
            />
          ) : null}
          {success ? (
            <DialogSuccess
              dialogPhase={dialogPhase}
              setDialogPhase={setDialogPhase}
              {...success}
            />
          ) : null}
          {errorAPI ? (
            <DialogAPIError
              errorCode={errorCode}
              setErrorCode={setErrorCode}
              responseMessage={responseMessage}
              setResponseMessage={setResponseMessage}
              dialogPhase={dialogPhase}
              setDialogPhase={setDialogPhase}
              {...errorAPI}
            />
          ) : null}
          {errorLocal ? (
            <DialogLocalError
              dialogPhase={dialogPhase}
              setDialogPhase={setDialogPhase}
              errorMessages={errorMessagesState}
              {...errorLocal}
            />
          ) : null}
        </>
      );
    },
    [dialogPhase, errorCode, responseMessage, errorMessagesState]
  );

  const showErrorAPIDialog = (error: unknown) => {
    const { errorCode, errorMessage } = getAPIErrorDetailOrMesssage(error);
    setResponseMessage([...errorMessage.split(/\n/)]);
    setErrorCode(errorCode);
    setDialogPhase("errorAPI");
  };
  const showErrorLocalDialog = (errorMessages: string[]) => {
    setErrorMessagesState(errorMessages);
    setDialogPhase("errorLocal");
  };

  return {
    renderDialogs,
    isInProgress,
    setIsInProgress,
    setDialogPhase,
    showErrorAPIDialog,
    showErrorLocalDialog,
  };
};

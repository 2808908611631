import { RoleRaw, RoleRawForm } from "@/domain/roles";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const adminAPIURLRoles = "/admin/roles";

// 組織IDを指定してロール一覧を取得
export const getAdminRoles = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<RoleRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: RoleRaw[] }>(
    `${adminAPIURLRoles}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.items;
};

// 個別のロールを取得
export const getAdminRole = async ({
  roleId,
  organizationId,
}: {
  roleId: RoleRaw["id"];
  organizationId: string;
}): Promise<RoleRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ item: RoleRaw }>(
    `${adminAPIURLRoles}/${roleId}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// ロールを作成
export const postAdminCreateRole = async ({
  roleRawForm,
  organizationId,
}: {
  roleRawForm: RoleRawForm;
  organizationId: string;
}): Promise<RoleRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: RoleRaw }>(
    `${adminAPIURLRoles}`,
    roleRawForm,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// ロールを更新
export const postAdminUpdateRole = async ({
  roleId,
  roleRawForm,
  organizationId,
}: {
  roleId: RoleRaw["id"];
  roleRawForm: RoleRawForm;
  organizationId: string;
}): Promise<RoleRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: RoleRaw }>(
    `${adminAPIURLRoles}/${roleId}`,
    roleRawForm,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// ロールを論理削除
export const postAdminDisableRole = async ({
  roleId,
  organizationId,
}: {
  roleId: RoleRaw["id"];
  organizationId: string;
}): Promise<RoleRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: RoleRaw }>(
    `${adminAPIURLRoles}/${roleId}/delete`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// ロールを有効化
export const postAdminEnableRole = async ({
  roleId,
  organizationId,
}: {
  roleId: RoleRaw["id"];
  organizationId: string;
}): Promise<RoleRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: RoleRaw }>(
    `${adminAPIURLRoles}/${roleId}/enable`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// ロールを物理削除
export const postAdminRemoveRole = async ({
  roleId,
  organizationId,
}: {
  roleId: RoleRaw["id"];
  organizationId: string;
}) => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  await API_CLIENT.post<{ item: RoleRaw }>(
    `${adminAPIURLRoles}/${roleId}/remove`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
};

import React from "react";
import { useNavigate } from "react-router";

import { useAppRouteParams } from "../../../../../AppRoutes";
import {
  Sheet,
  useRecursiveDeleteSheet,
  useSetSheet,
} from "../../../../../domain/Sheet";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { ReferredFromState, getDialogMessages } from "../../helper";
import { deleteRecursiveSheetData } from "../helper";

import { useDeleteReferredFrom } from "@/domain/ReferredFrom";

export const DialogSheetDelete: React.FC<
  {
    sheets: Sheet[];
    selectedSheetIds: string[];
    setSelectedSheetIds: React.Dispatch<React.SetStateAction<string[]>>;
    isIncludingReferencedSheets: ReferredFromState;
  } & DialogPhaseStatesType
> = ({
  sheets,
  selectedSheetIds,
  setSelectedSheetIds,
  isIncludingReferencedSheets,
  dialogPhase,
  setDialogPhase,
}) => {
  const navigate = useNavigate();
  const { organizationId, versionId, sheetId } = useAppRouteParams();
  const recursiveDeleteSheet = useRecursiveDeleteSheet();
  const deleteReferredFrom = useDeleteReferredFrom();
  const setSheet = useSetSheet();
  const { renderDialogs, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const runDelete = async () => {
    if (selectedSheetIds.includes(sheetId)) {
      navigate(`/organizations/${organizationId}/versions/${versionId}`);
    }
    setDialogPhase("in_progress");
    try {
      await deleteRecursiveSheetData({
        sheets,
        selectedSheetIds,
        recursiveDeleteSheet,
        deleteReferredFrom,
        versionId,
        setSheet,
        setProgressCount: () => {},
      });
      setDialogPhase("success");
    } catch (error) {
      showErrorAPIDialog(error);
    }
  };

  const messages = getDialogMessages({
    isSome: false,
    actionType: "delete",
    targetType: "sheet",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          ...messages.confirm,
          onClick: runDelete,
          onClickCancel: () => {
            setSelectedSheetIds([]);
          },
          isThemeRed: true,
          displayMessage:
            isIncludingReferencedSheets === "including"
              ? "他のシートから参照されているシートのため、表示が破壊されることがあります。シートを削除してもよろしいですか？"
              : "シートを削除してもよろしいですか？",
        },
        inProgress: {
          ...messages.inProgress,
        },
        success: {
          ...messages.success,
          onClick: () => {
            setSelectedSheetIds([]);
          },
        },
        errorAPI: {
          ...messages.error,
        },
      })}
    </>
  );
};

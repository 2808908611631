import { useSortable } from "@dnd-kit/sortable";
import {
  ContextualMenu,
  DirectionalHint,
  FontIcon,
  IContextualMenuItem,
  IconButton,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import classNames from "classnames";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppRouteParams } from "../../../AppRoutes";
import { Sheet } from "../../../domain/Sheet";
import {
  SheetScoreSummaryItem,
  parseScoreValue,
} from "../../../domain/SheetScoreSummary";
import { Version } from "../../../domain/Version";
import { contextMenuStyle } from "../../../styles";
import { themePrimaryDark } from "../../../themes";

import { useSheetScoreSummaryItems } from "./hooks";
import styles from "./index.module.css";
import { parseIntegerFloat } from "./parser";

import { useVersions } from "@/components/InOrganizationProvider/VersionsProvider";
import { useAuthorizedSheets } from "@/hooks/useAuthorizedCollections";
import { parseDateTimeOrISODateTime } from "@/utils/dateFnsHelper";

const nop = () => {};

export const TmpMenuSetChevronDownIcon: React.FC = () => (
  <FontIcon iconName="More" style={{ fontSize: "12px" }} />
);

export const TmpMenuSetDivider: React.FC = () => {
  return <div className={styles.divider} />;
};

const buttonStyle: React.CSSProperties = {
  display: "inline-block",
  width: "22px",
  height: "22px",
  textAlign: "center",
  color: "inherit",
};

/**
 * @description 各ページなどのリンクを貼るコンポーネント
 * 管理者モードの時は色が反転
 * @param id 主にソート用の識別子
 * @param text リンクタイトル
 * @param active 現在のリンクにいるかどうか。trueだと背景が変わる
 * @param to クリック時のリンク先string
 * @param onClick リンククリック時の起動関数。ダイアログオープン処理など. toより優先して起動
 * @param contextMenuItems リンク右のボタンを押した時のメニュー一覧. fluent UI
 * @param isChildren 他のTmpMenuSetの子供かどうか。marginが変化
 * @param leftIconName 左側に出るメインのアイコン名
 */
export const SortableTmpMenuSet: React.FC<{
  id: string;
  text?: string;
  to?: string;
  referencedSheetVersionId?: string;
  active?: boolean;
  onClick?: () => void;
  contextMenuItems?: IContextualMenuItem[];
  iconName?: string;
  iconColor?: string;
  isChildren?: boolean;
  isSortable?: boolean;
  isDisableTooltip?: boolean;
  isAdminMode?: boolean;
  nestedElement?: React.ReactElement;
}> = ({
  id,
  text = "",
  referencedSheetVersionId,
  active = false,
  to,
  onClick,
  contextMenuItems,
  iconName = "",
  iconColor = "var(--figma-color-sideMenuText)",
  isChildren = false,
  isSortable = false,
  isDisableTooltip = false,
  isAdminMode = false,
  nestedElement,
}) => {
  const { versions } = useVersions();
  const menuNodeId = useId();
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [inHover, setInHover] = useState(false);
  const [isOpenNestedElement, setIsOpenNestedElement] = useState(false);
  const navigate = useNavigate();

  /**
   * dnd-kit
   */
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const dndStyle: React.CSSProperties = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : "",
    transition,
  };

  const highlightStyle = (() => {
    //  const isHeighlight = active || inHover|| isContextMenuVisible || isOpenNestedElement;
    const isOpen = isContextMenuVisible || isOpenNestedElement;
    const isActive = active || inHover;
    return {
      ...(isOpen
        ? {
            backgroundColor: "var(--figma-color-sideMenuHover)",
            cursor: "pointer",
          }
        : {}),
      ...(isActive
        ? {
            backgroundColor: "var(--figma-color-sideMenuSelected)",
            cursor: "pointer",
          }
        : {}),
    };
  })();

  const menuNode = (
    <div
      onMouseEnter={() => {
        setInHover(true);
      }}
      onMouseLeave={() => {
        setInHover(false);
      }}
    >
      <div
        id={menuNodeId}
        className={classNames(styles.menuNode, {
          [styles.children]: isChildren,
        })}
        style={highlightStyle}
      >
        <FontIcon
          iconName={iconName}
          style={{
            display: "inline-block",
            width: "40px",
            height: "40px",
            fontSize: "14px",
            lineHeight: "40px",
            textAlign: "center",
            color: iconColor,
          }}
        />
        <div
          className={classNames(styles.text, {
            [styles.isAdminMode]: isAdminMode,
          })}
        >
          <TooltipHost
            content={text}
            id={`sortable_tmp_menu_set_${id}`}
            hidden={transform !== null || !inHover || isDisableTooltip}
            onMouseEnter={() => {
              setInHover(false);
            }}
          >
            {referencedSheetVersionId ? (
              <>
                {`${text}`}{" "}
                <span
                  className={styles.referencedSheetDisplayName}
                >{`（${versions.find((v) => v.id === referencedSheetVersionId)?.title ?? "不明なバージョン"}）`}</span>
              </>
            ) : (
              text
            )}
          </TooltipHost>
        </div>
        {inHover && contextMenuItems ? (
          <div onMouseDown={(e) => e.stopPropagation()}>
            {nestedElement ? (
              <IconButton
                iconProps={{
                  iconName: `${
                    isOpenNestedElement ? "ChevronUpSmall" : "ChevronDownSmall"
                  }`,
                }}
                styles={{ icon: { fontSize: "12px" } }}
                style={buttonStyle}
                theme={themePrimaryDark}
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpenNestedElement((state) => !state);
                }}
              />
            ) : null}
            <IconButton
              iconProps={{ iconName: "More" }}
              style={buttonStyle}
              theme={themePrimaryDark}
              onClick={(e) => {
                e.preventDefault();
                setIsContextMenuVisible((b) => !b);
              }}
            />
            {isSortable ? (
              <IconButton
                iconProps={{ iconName: "GripperDotsVertical" }}
                style={buttonStyle}
                theme={themePrimaryDark}
                {...listeners}
                {...attributes}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      {nestedElement && isOpenNestedElement ? (
        <div
          className={styles.nestedElement}
          style={{
            ...highlightStyle,
          }}
        >
          {nestedElement}
        </div>
      ) : null}
      {contextMenuItems ? (
        <ContextualMenu
          styles={contextMenuStyle}
          target={`#${menuNodeId}`}
          directionalHint={DirectionalHint.rightTopEdge}
          items={contextMenuItems}
          hidden={!isContextMenuVisible}
          onDismiss={() => {
            setIsContextMenuVisible(false);
          }}
        />
      ) : null}
    </div>
  );
  return (
    <div
      ref={setNodeRef}
      style={{
        display: "block",
        width: "100%",
        textAlign: "left",
        ...dndStyle,
        ...(isOpenNestedElement
          ? {
              marginBottom: "3px",
            }
          : {}),
      }}
      onMouseDown={() => {
        // NOTE: onClick設定時はリンクは起動しない
        if (onClick) {
          onClick();
          return;
        }
        if (to) navigate(to);
      }}
    >
      {menuNode}
    </div>
  );
};

const diffTypeKinds = [
  { label: "▲", color: "red" },
  { label: "―", color: "#999" },
  { label: "▼", color: "green" },
];

const diffTypeKindsHigherRated = [
  { label: "▼", color: "red" },
  { label: "―", color: "#999" },
  { label: "▲", color: "green" },
];

const ParentScoreItem: React.FC<
  {
    isHaveChildItems?: boolean;
    isOpenChildItems: boolean;
    setIsOpenChildItems: React.Dispatch<React.SetStateAction<boolean>>;
    comparedSheetScoreSummaryItems: SheetScoreSummaryItem[];
  } & SheetScoreSummaryItem
> = memo(
  ({
    name,
    unit,
    value,
    value_type,
    is_higher_rated,
    isHaveChildItems = false,
    isOpenChildItems,
    setIsOpenChildItems,
    comparedSheetScoreSummaryItems,
  }) => {
    // 評価アイコン
    const compareSheetScoreItem = comparedSheetScoreSummaryItems.find(
      ({ name: _name, value_type: _value_type }) =>
        name === _name && value_type === _value_type
    );
    const diffType = useMemo(() => {
      // NOTE: 0以下だと悪い結果
      const diff = (() => {
        if (!compareSheetScoreItem || is_higher_rated === undefined) return 0;
        if (
          (value_type === "float" || value_type === "integer") &&
          (compareSheetScoreItem.value_type === "float" ||
            compareSheetScoreItem.value_type === "integer")
        ) {
          const compareBorderScore =
            parseIntegerFloat(
              compareSheetScoreItem.value,
              compareSheetScoreItem.value_type
            ) ?? 0;
          const score = parseIntegerFloat(value, value_type) ?? 0;

          return !is_higher_rated
            ? compareBorderScore - score
            : score - compareBorderScore;
        }
        return 0;
      })();
      return !is_higher_rated
        ? diffTypeKinds[Math.sign(diff) + 1]
        : diffTypeKindsHigherRated[Math.sign(diff) + 1];
    }, [
      compareSheetScoreItem?.value,
      compareSheetScoreItem?.value_type,
      name,
      unit,
      value,
      value_type,
      is_higher_rated,
    ]);

    const parsedValue = parseScoreValue({ value, value_type });
    return (
      <div className={styles.parentItem}>
        <div className={styles.nameAndIcon}>
          <TooltipHost content={name + ": " + parsedValue + unit}>
            <div className={styles.name}> {name}</div>
          </TooltipHost>
          {isHaveChildItems ? (
            <IconButton
              className={styles.icon}
              iconProps={{
                iconName: `${
                  isOpenChildItems ? "ChevronUpSmall" : "ChevronDownSmall"
                }`,
              }}
              styles={{ icon: { fontSize: "12px" } }}
              theme={themePrimaryDark}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsOpenChildItems((state) => !state);
              }}
            />
          ) : null}
        </div>
        <div className={styles.score}>
          <div className={styles.valueAndUnit}>
            <div className={styles.value}>{unit ? parsedValue : ""}</div>
            <div className={styles.unit}>{unit ?? ""}</div>
          </div>
          <div className={styles.diff} style={{ color: diffType.color }}>
            {diffType.label}
          </div>
        </div>
      </div>
    );
  }
);

const SummaryScoreNode: React.FC<{
  nestRank: number;
  parentScoreSummaryItem: SheetScoreSummaryItem;
  childSummaryNodeList: React.ReactNode[];
  comparedSheetScoreSummaryItems: SheetScoreSummaryItem[];
}> = ({
  nestRank,
  parentScoreSummaryItem,
  childSummaryNodeList,
  comparedSheetScoreSummaryItems,
}) => {
  const [isOpenChildItems, setIsOpenChildItems] = useState(false);
  const isVisibleChildItems =
    childSummaryNodeList.length > 0 && (isOpenChildItems || nestRank === 0);
  return (
    <div>
      {nestRank === 0 ? null : (
        <ParentScoreItem
          key={parentScoreSummaryItem.name}
          isOpenChildItems={isOpenChildItems}
          setIsOpenChildItems={setIsOpenChildItems}
          isHaveChildItems={childSummaryNodeList.length > 0}
          comparedSheetScoreSummaryItems={comparedSheetScoreSummaryItems}
          {...parentScoreSummaryItem}
        />
      )}
      {isVisibleChildItems ? (
        <div className={nestRank === 0 ? styles.rootItems : styles.childItems}>
          {childSummaryNodeList.map((childItemNode, index) => (
            <div key={`${parentScoreSummaryItem.name}_childNodeList_${index}`}>
              {childItemNode}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const renderRecursiveScoreSummary = ({
  parentScoreSummaryItem,
  restItems,
  comparedSheetScoreSummaryItems,
  nestRank,
}: {
  parentScoreSummaryItem: SheetScoreSummaryItem;
  restItems: SheetScoreSummaryItem[];
  comparedSheetScoreSummaryItems: SheetScoreSummaryItem[];
  nestRank: number;
}): {
  summaryScoreNode: React.ReactNode;
  restItems: SheetScoreSummaryItem[];
  comparedSheetScoreSummaryItems: SheetScoreSummaryItem[];
} => {
  // NOTE: 初回かどうかで処理が分岐
  const childItems = restItems.filter((restItem) => {
    if (nestRank === 0) return restItem.parent === "";
    return restItem.parent === parentScoreSummaryItem.name;
  });
  // 親を除く
  let newRestItems = [...restItems].filter(({ name }) => {
    if (nestRank === 0) return true;
    return name !== parentScoreSummaryItem.name;
  });

  // 再起処理実行
  // NOTE: childItemsの長さが0なら何もしない
  const childSummaryNodeList: React.ReactNode[] = [];

  // nestRankはひとまず視認性のため3までとする
  // todo いずれ無限にネストを許容する
  if (nestRank < 3) {
    childItems.forEach((newParentScoreItem) => {
      // 子供に対して実行
      const { summaryScoreNode, restItems: gotRestItems } =
        renderRecursiveScoreSummary({
          parentScoreSummaryItem: newParentScoreItem,
          restItems: newRestItems,
          comparedSheetScoreSummaryItems,
          nestRank: nestRank + 1,
        });
      // restItemsを更新
      newRestItems = [...gotRestItems];
      childSummaryNodeList.push(summaryScoreNode);
    });
  }

  return {
    summaryScoreNode: (
      <SummaryScoreNode
        nestRank={nestRank}
        parentScoreSummaryItem={parentScoreSummaryItem}
        childSummaryNodeList={childSummaryNodeList}
        comparedSheetScoreSummaryItems={comparedSheetScoreSummaryItems}
      />
    ),
    restItems: newRestItems,
    comparedSheetScoreSummaryItems,
  };
};

export const TmpMenuSetOptimizeSummary: React.FC<{
  version: Version;
  compareTargetResultScoreSummary?: Sheet | null;
}> = ({ version, compareTargetResultScoreSummary = null }) => {
  const { versionId } = useAppRouteParams();
  const [sheets, isLoadedSheets] = useAuthorizedSheets(version.id);

  // 自身のスコアサマリを探索
  const resultScoreSummary: Sheet | null = useMemo(
    () =>
      sheets.find(
        ({ sheetOptimizeType }) =>
          sheetOptimizeType === "result_score_summaries"
      ) ?? null,
    [sheets]
  );
  // 自身のスコアサマリ
  const sheetScoreSummaryItems = useSheetScoreSummaryItems({
    resultScoreSummary,
    versionId: version.id,
  });
  // 比較対象のスコアサマリ
  const comparedSheetScoreSummaryItems = useSheetScoreSummaryItems({
    resultScoreSummary: compareTargetResultScoreSummary,
    versionId,
  });
  // 一番上の親のサマリを出す
  const parentScoreSummaryItem = useMemo(
    () =>
      sheetScoreSummaryItems.find(({ parent }) => parent === "") ?? {
        ...sheetScoreSummaryItems[0],
      },
    [sheetScoreSummaryItems]
  );

  // ここでスコアオープンに関するstateを持って、下位に分配する
  // オープンしている親リストみたいな
  const { summaryScoreNode, restItems } = renderRecursiveScoreSummary({
    parentScoreSummaryItem,
    restItems: sheetScoreSummaryItems,
    comparedSheetScoreSummaryItems,
    nestRank: 0,
  });

  const parsedDate = parseDateTimeOrISODateTime(version.createdAt);

  if (!isLoadedSheets) {
    return null;
  }
  return (
    <div className={styles.optimizeSummaryContainer}>
      <TooltipHost content={`ID: ${version.id}`}>
        <div className={styles.top}>
          <div className={styles.versionId}>ID: {version.id}</div>
        </div>
      </TooltipHost>
      {sheetScoreSummaryItems.length === 0 ? (
        // NOTE: createdAtのパースに成功し、かつ作成(複製)時間から2分以内なら更新中とみなす
        parsedDate && parsedDate.getTime() + 120 * 1000 > Date.now() ? (
          <div>-データ準備中- 数分後に更新してください</div>
        ) : (
          <div className={styles.noSummary}>-サマリが存在しません-</div>
        )
      ) : (
        <>
          {summaryScoreNode}
          {restItems.map((item) => {
            return (
              <ParentScoreItem
                key={item.name}
                isOpenChildItems={false}
                setIsOpenChildItems={nop}
                comparedSheetScoreSummaryItems={comparedSheetScoreSummaryItems}
                {...item}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

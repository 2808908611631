import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { VersionCategoryRaw } from "@/domain/VersionCategories";
import { useAdminAPI } from "../hooks";

const ctx = createContext<{
  versionCategories: VersionCategoryRaw[] | null;
  runReloadVersionCategories: () => Promise<void>;
  isLoadedVersionCategories: boolean;
  isAdminMode: boolean;
}>({
  versionCategories: null,
  runReloadVersionCategories: async () => {
    // nop
  },
  isLoadedVersionCategories: false,
  isAdminMode: true,
});

export const AdminOrganizationVersionCategoriesProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: versionCategories,
    runReloadState: runReloadVersionCategories,
    isLoadedState: isLoadedVersionCategories,
  } = useAdminAPI<VersionCategoryRaw[]>("version_categories");

  const ctxValue = useMemo(
    () => ({
      versionCategories,
      runReloadVersionCategories,
      isLoadedVersionCategories,
      isAdminMode: true,
    }),
    [versionCategories, runReloadVersionCategories, isLoadedVersionCategories]
  );

  return <ctx.Provider value={ctxValue}>{children}</ctx.Provider>;
};

export const useAdminOrganizationVersionCategories = () => {
  return useContext(ctx);
};

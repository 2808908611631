import React, { useState } from "react";
import { useNavigate } from "react-router";

import { useAppRouteParams } from "../../../../../AppRoutes";
import { Version } from "../../../../../domain/Version";
import { useRecursiveDeleteVersion } from "../../../../../domain/Version";
import { ManagedTransaction } from "../../../../../firebase/firestore";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";

import { useDeleteReferredFrom } from "@/domain/ReferredFrom";
import { Sheet, useGetSheets } from "@/domain/Sheet";

export const DialogVersionRemove: React.FC<
  {
    versions: Version[];
    selectedVersionIds: string[];
    setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  versions,
  selectedVersionIds,
  setSelectedVersionIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const selectedVersion = versions.find(
    ({ id }) => selectedVersionIds?.[0] === id
  );

  const { organizationId, versionId } = useAppRouteParams();
  const navigate = useNavigate();
  const recursiveDeleteVersion = useRecursiveDeleteVersion();

  const { renderDialogs, setIsInProgress, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const getSheets = useGetSheets();
  const deleteReferredFrom = useDeleteReferredFrom();

  const runRemove = async () => {
    if (selectedVersionIds.includes(versionId)) {
      navigate(`/organizations/${organizationId}`);
    }
    setDialogPhase("in_progress");
    try {
      // 削除対象のバージョン内の、referencing sheetのidをキー、
      // referenced sheetのversionIdとsheetIdを値とするMap
      const referencedSheetsMap = new Map<
        string,
        { versionId: string; sheetId: string }
      >();
      const sheets = await getSheets({ versionId: selectedVersionIds[0] });
      sheets
        .filter(({ referTo }) => !!referTo)
        .forEach(({ id, referTo }) =>
          referencedSheetsMap.set(`${selectedVersionIds[0]}:${id}`, referTo!)
        );

      const deleteQueueList = await Promise.all(
        selectedVersionIds.map(
          async (versionId) => await recursiveDeleteVersion(versionId, {})
        )
      );
      await ManagedTransaction.runTransaction(async (transaction) => {
        // 参照先のreferred_fromを削除
        for (const [
          connectedId,
          { versionId: refVersionId, sheetId: refSheetId },
        ] of referencedSheetsMap.entries()) {
          await deleteReferredFrom(
            connectedId,
            {
              versionId: refVersionId,
              sheetId: refSheetId,
            },
            { transaction }
          );
        }

        for (const deleteQueue of deleteQueueList) {
          await deleteQueue.exec(transaction);
        }
      });

      setSelectedVersionIds([]);
      setDialogPhase("success");
    } catch (error) {
      showErrorAPIDialog(error);
    }
  };
  const messages = getDialogMessages({
    isSome: false,
    actionType: "remove",
    targetType: "version",
  });
  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runRemove,
          onClickCancel: () => {
            setSelectedVersionIds([]);
          },
          isThemeRed: true,
          ...messages.confirm,
        },
        inProgress: {
          ...messages.inProgress,
        },
        success: {
          ...messages.success,
        },
        errorAPI: { ...messages.error },
      })}
    </>
  );
};

import React, {
  useMemo,
  useState,
  ReactNode,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";

import { getAPIErrorDetailAndMessage } from "@/api/helpers";
import { useAppRouteParams } from "../../../AppRoutes";
import { getAccount } from "../../../api/accounts";
import {
  AccountRaw,
  getIsAAAdminOrAbove,
  getIsAADeveloperOrAbove,
  getIsAAMemberOrAbove,
} from "../../../domain/Account";
import { DialogPhaseType, useDialog } from "../../Dialog/helpers/hooks";
import { useAppAuth } from "../AppAuthProvider";

const accountCtx = createContext<{
  account: AccountRaw | null;
  runReloadAccount: () => Promise<void>;
  isLoadedAccount: boolean;
  setAccount: React.Dispatch<React.SetStateAction<AccountRaw | null>>;
  isAAMemberOrAbove: boolean;
  isAAAdminOrAbove: boolean;
  isAADeveloperOrAbove: boolean;
}>({
  account: null,
  runReloadAccount: async () => {
    //nop
  },
  isLoadedAccount: false,
  setAccount: () => {
    //nop
  },
  isAAMemberOrAbove: false,
  isAAAdminOrAbove: false,
  isAADeveloperOrAbove: false,
});

/**
 * @description view, sheet, AppTopNavなどさまざまな場所で使うaccountデータを管理
 */
export const AppAccountProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { user } = useAppAuth();
  const { organizationId } = useAppRouteParams();

  const [account, setAccount] = useState<AccountRaw | null>(null);
  const [_, setError] = useState<unknown>(null);

  const [dialogPhase, setDialogPhase] = useState<DialogPhaseType>(null);
  const { setIsInProgress, isInProgress } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const { isAAMemberOrAbove, isAAAdminOrAbove, isAADeveloperOrAbove } =
    useMemo(() => {
      const isAAMemberOrAbove = getIsAAMemberOrAbove(account);
      const isAADeveloperOrAbove = getIsAADeveloperOrAbove(account);
      const isAAAdminOrAbove = getIsAAAdminOrAbove(account);
      return { isAAMemberOrAbove, isAAAdminOrAbove, isAADeveloperOrAbove };
    }, [account]);

  const runReloadAccount = useCallback(async () => {
    if (!user) return;
    try {
      setIsInProgress(true);
      const response = await getAccount({
        uid: user?.uid,
      });
      setAccount(response);
      setIsInProgress(false);
    } catch (err: unknown) {
      setError(() => {
        if (typeof err == "object") {
          const e = {
            ...err,
            message: getAPIErrorDetailAndMessage(err),
          } as Error;
          throw e;
        } else {
          throw new Error("不明なエラー");
        }
      });
    }
  }, [getAccount, organizationId, user]);

  const accountCtxValue = useMemo(
    () => ({
      account,
      runReloadAccount,
      isLoadedAccount: !isInProgress,
      setAccount,
      isAAMemberOrAbove,
      isAAAdminOrAbove,
      isAADeveloperOrAbove,
    }),
    [
      account,
      runReloadAccount,
      isInProgress,
      isAAMemberOrAbove,
      isAAAdminOrAbove,
      isAADeveloperOrAbove,
    ]
  );

  useEffect(() => {
    runReloadAccount();
  }, [runReloadAccount]);

  return (
    <accountCtx.Provider value={accountCtxValue}>
      {children}
    </accountCtx.Provider>
  );
};

export const useAppAccount = () => {
  return useContext(accountCtx);
};

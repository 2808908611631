import React from "react";

import { ErrorWrapper } from "../ErrorWrapper";

import { AlgorithmVersionsProvider } from "./AlgorithmVersionsProvider";
import { ConnectMastersProvider } from "./ConnectMastersProvider";
import { ConnectsProvider } from "./ConnectsProvider";
import { FrontendAddonProvider } from "./FrontendAddonsProvider";
import { MembersProvider } from "./MembersProvider";
import { OrganizationProvider } from "./OrganizationProvider";
import { PermissionsProvider } from "./PermissionsProvider";
import { RolesProvider } from "./RolesProvider";
import { SessionProvider } from "./SessionProvider";
import { VersionCategoriesProvider } from "./VersionCategoriesProvider";
import { VersionsProvider } from "./VersionsProvider";
import { ViewTypesProvider } from "./ViewTypesProvider";

/**
 * @description 組織内で使われるさまざまなデータをまとめたプロバイダ
 */
export const InOrganizationProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // NOTE: ThecsasのSnapshotLoadingWrapperを参考に、全部のAPI情報をcontextにまとめた方がいいか？
  return (
    <ErrorWrapper
      isRenderAccountSettingButton={true}
      isRenderLoginButton={true}
      isRenderLogoutButton={true}
    >
      <SessionProvider>
        <PermissionsProvider>
          <VersionsProvider>
            <OrganizationProvider>
              <MembersProvider>
                <RolesProvider>
                  <VersionCategoriesProvider>
                    <AlgorithmVersionsProvider>
                      <ViewTypesProvider>
                        <ConnectMastersProvider>
                          <ConnectsProvider>
                            <FrontendAddonProvider>
                              {children}
                            </FrontendAddonProvider>
                          </ConnectsProvider>
                        </ConnectMastersProvider>
                      </ViewTypesProvider>
                    </AlgorithmVersionsProvider>
                  </VersionCategoriesProvider>
                </RolesProvider>
              </MembersProvider>
            </OrganizationProvider>
          </VersionsProvider>
        </PermissionsProvider>
      </SessionProvider>
    </ErrorWrapper>
  );
};

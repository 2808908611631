import { ParseError, parse } from "papaparse";
import { ChangeEvent, useState } from "react";

import { loadEncodingJapanese } from "./extLoader";

// NOTE:こっちは記述が簡単だけど、shift-jisに限定
// export const parseUploadedCSV = async (
//   event: ChangeEvent<HTMLInputElement>
// ): Promise<any[]> => {
//   const file = event.target.files?.[0];
//   if (file) {
//     return new Promise<any[]>((resolve, reject) => {
//       parse(file, {
//         header: true,
//         encoding: "Shift-JIS",
//         complete: (results) => {
//           resolve(results.data);
//         },
//         error: (error) => {
//           reject(error);
//         },
//       });
//     });
//   }
//   throw new Error("No file provided");
// };

export const parseUploadedCSV = (
  event: ChangeEvent<HTMLInputElement>
): Promise<any[]> => {
  return new Promise<any[]>((resolve, reject) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (reader.result && typeof reader.result !== "string") {
          // バイナリを読み込み、文字コードを推定 => UNICODEに変換 => 文字列に戻す
          const Encoding = await loadEncodingJapanese();
          const codes = new Uint8Array(reader.result);
          const encoding = Encoding.detect(codes);
          const unicodeString = Encoding.convert(codes, {
            to: "UNICODE",
            from: encoding ? encoding : undefined,
            type: "string",
          });
          // 文字列からparse
          parse(unicodeString, {
            header: true,
            complete: (results) => {
              resolve(results.data);
            },
            error: (error: any) => {
              reject(new Error("Failed to parse file."));
            },
          });
        }
      };
      reader.onerror = (event) => {
        reject(new Error("Failed to read file."));
      };
      reader.readAsArrayBuffer(file);
    } else {
      reject(new Error("No file provided."));
    }
  });
};

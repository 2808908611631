import React, { useEffect, useState } from "react";

import { Sheet, useGetSheets, useSetSheet } from "../../../../../domain/Sheet";
import {
  useGetSheetDataRows,
  useSheetDataRowUtils,
} from "../../../../../domain/SheetDataRow";
import {
  useGetSheetFieldSchemas,
  useSetSheetFieldSchema,
} from "../../../../../domain/SheetFieldSchema";
import { Version, useSetVersion } from "../../../../../domain/Version";
import { View, useGetViews, useSetView } from "../../../../../domain/View";
import {
  useGetViewConfigSchemas,
  useSetViewConfigSchema,
} from "../../../../../domain/ViewConfigSchema";
import {
  useGetViewDependSheets,
  useSetViewDependSheet,
} from "../../../../../domain/ViewDependSheet";
import {
  useGetViewDependSheetFields,
  useSetViewDependSheetField,
} from "../../../../../domain/ViewDependSheetField";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";
import { duplicateRecursiveVersionData } from "../helper";

import { useSetReferredFrom } from "@/domain/ReferredFrom";
import { Input } from "@fluentui/react-components";

import styles from "./index.module.css";

export const DialogVersionDuplicateSome: React.FC<
  {
    versions: Version[];
    selectedVersionIds: string[];
    setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  versions,
  selectedVersionIds,
  setSelectedVersionIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const setVersion = useSetVersion();

  const setSheet = useSetSheet();
  const setSheetFieldSchema = useSetSheetFieldSchema();

  const setView = useSetView();
  const setViewConfigSchema = useSetViewConfigSchema();
  const setViewDependSheet = useSetViewDependSheet();
  const setViewDependSheetField = useSetViewDependSheetField();
  const setReferredFrom = useSetReferredFrom();

  const getSheets = useGetSheets();
  const getSheetDataRows = useGetSheetDataRows();
  const getSheetFieldSchemas = useGetSheetFieldSchemas();
  const sheetDataRowUtils = useSheetDataRowUtils();

  const getViews = useGetViews();
  const getViewConfigSchemas = useGetViewConfigSchemas();
  const getViewDependSheets = useGetViewDependSheets();
  const getViewDependSheetFields = useGetViewDependSheetFields();

  const { renderDialogs, setIsInProgress, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const selectedVersions = versions.filter(({ id }) =>
    selectedVersionIds.includes(id)
  );

  const [versionTitles, setVersionTitles] = useState<
    { oldTitle: string; newTitle: string }[]
  >([]);

  useEffect(() => {
    setVersionTitles(
      selectedVersions.map((version) => ({
        oldTitle: version.title,
        newTitle: `${version.title}_copy`,
      }))
    );
  }, [selectedVersionIds]);

  const progressTotal = selectedVersionIds.length;
  const [progressCount, setProgressCount] = useState(0);

  const runDuplicate = async () => {
    setProgressCount(0);
    setDialogPhase("in_progress");
    try {
      for (const [idx, version] of selectedVersions.entries()) {
        await duplicateRecursiveVersionData({
          version,
          duplicateVersionName: versionTitles[idx].newTitle,
          getSheets,
          getViews,
          getSheetDataRows,
          getSheetFieldSchemas,
          getViewDependSheets,
          getViewConfigSchemas,
          getViewDependSheetFields,
          setVersion,
          setSheet,
          setSheetFieldSchema,
          setView,
          setViewConfigSchema,
          setViewDependSheet,
          setViewDependSheetField,
          setReferredFrom,
          sheetDataRowUtils,
        });
        setProgressCount((cnt) => cnt + 1);
      }
      setDialogPhase("success");
    } catch (error) {
      showErrorAPIDialog(error);
    }
  };
  const messages = getDialogMessages({
    isSome: true,
    actionType: "duplicate",
    targetType: "version",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runDuplicate,
          onClickCancel: () => {
            setVersionTitles([]);
            setSelectedVersionIds([]);
          },
          element: (
            <div>
              {versionTitles.map(({ oldTitle, newTitle }, idx) => (
                <div key={`version-title-${idx}`} className={styles.inputRow}>
                  {oldTitle}
                  <Input
                    className={styles.versionTitleInput}
                    value={newTitle}
                    onChange={(e) =>
                      setVersionTitles((prev) =>
                        prev.map((v, i) =>
                          i === idx ? { ...v, newTitle: e.target.value } : v
                        )
                      )
                    }
                  />
                </div>
              ))}
            </div>
          ),
          title: "バージョンの複製",
          isDisableButton:
            versionTitles.some(({ newTitle }) => !newTitle) ||
            selectedVersions.length !== versionTitles.length,
          displayMessage: "新しいバージョンの名前を入力してください。",
          buttonText: "複製",
        },
        inProgress: {
          ...messages.inProgress,
          count: {
            current: progressCount,
            total: progressTotal,
          },
        },
        success: {
          onClick: () => {
            setSelectedVersionIds([]);
          },
          ...messages.success,
        },
        errorAPI: { ...messages.error },
      })}
    </>
  );
};

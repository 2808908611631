import { SeniorParttimeCalendarViewTypeDependSheet } from "./params";

const seniorParttimeDependSheet: SeniorParttimeCalendarViewTypeDependSheet = {
  key: "seniorParttime",
  displayName: "シニア・パートタイム勤務情報",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "乗務員ID",
      type: "string",
    },
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "value",
      displayName: "値",
      type: "string",
    },
  ],
};

const driverDependSheet: SeniorParttimeCalendarViewTypeDependSheet = {
  key: "driver",
  displayName: "乗務員",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "乗務員ID",
      type: "string",
    },
    {
      key: "driverName",
      displayName: "氏名",
      type: "string",
    },
  ],
};

const rotationBasedAssignmentDependSheet: SeniorParttimeCalendarViewTypeDependSheet =
  {
    key: "rotationBasedAssignment",
    displayName: "予定勤務データ",
    dependSheetFields: [
      {
        key: "date",
        displayName: "日付",
        type: "date",
      },
    ],
  };
export const seniorParttimeCalendarDependSheets = [
  seniorParttimeDependSheet,
  driverDependSheet,
  rotationBasedAssignmentDependSheet,
];

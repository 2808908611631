import { ViewType } from "@/domain/ViewType";
import { viewTypeBerthScheduleView } from "@/viewType/viewTypes/Optium/cptDom/views/berthScheduleView";
import { viewTypeShipAllocationView } from "@/viewType/viewTypes/Optium/cptDom/views/shipAllocationView";
import { viewTypeDepotInputView } from "./depotInputView";
import { viewTypeDepotStockTableView } from "./depotStockTableView";

export const viewTypesCptDom: ViewType<any, any>[] = [
  viewTypeShipAllocationView,
  viewTypeBerthScheduleView,
  viewTypeDepotInputView,
  viewTypeDepotStockTableView,
];

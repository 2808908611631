import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { ViewMasterRaw } from "@/domain/ViewMaster";
import { useAdminAPI } from "../hooks";

const ctx = createContext<{
  adminOrganizationViewMasters: ViewMasterRaw[] | null;
  runReloadAdminViewMasters: () => Promise<void>;
  isLoadedAdminViewMasters: boolean;
}>({
  adminOrganizationViewMasters: [],
  runReloadAdminViewMasters: async () => {
    // nop
  },
  isLoadedAdminViewMasters: false,
});

export const AdminOrganizationViewMastersProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: adminOrganizationViewMasters,
    runReloadState: runReloadAdminViewMasters,
    isLoadedState: isLoadedAdminViewMasters,
  } = useAdminAPI<ViewMasterRaw[]>("viewMasters");

  const ctxValue = useMemo(
    () => ({
      adminOrganizationViewMasters,
      runReloadAdminViewMasters,
      isLoadedAdminViewMasters,
    }),
    [
      adminOrganizationViewMasters,
      runReloadAdminViewMasters,
      isLoadedAdminViewMasters,
    ]
  );

  return <ctx.Provider value={ctxValue}>{children}</ctx.Provider>;
};

/*
 * @description ひとつの組織のビューマスタ一覧を取得する
 */
export const useAdminOrganizationViewMaster = () => {
  return useContext(ctx);
};

import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

import { RoleRaw, RoleRawForm } from "@/domain/roles";

const apiUrlRoles = "/roles";

// 組織IDを指定してロール一覧を取得
export const getRoles = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<RoleRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: RoleRaw[] }>(
    `${apiUrlRoles}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.items;
};

// ロールを作成
export const postCreateRole = async ({
  roleRawForm,
  organizationId,
}: {
  roleRawForm: RoleRawForm;
  organizationId: string;
}): Promise<RoleRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: RoleRaw }>(
    `${apiUrlRoles}`,
    roleRawForm,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// 個別のロールを取得
export const getRole = async ({
  roleId,
  organizationId,
}: {
  roleId: RoleRaw["id"];
  organizationId: string;
}): Promise<RoleRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ item: RoleRaw }>(
    `${apiUrlRoles}/${roleId}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// ロールを更新
export const postUpdateRole = async ({
  roleId,
  roleRawForm,
  organizationId,
}: {
  roleId: string;
  roleRawForm: RoleRawForm;
  organizationId: string;
}): Promise<RoleRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: RoleRaw }>(
    `${apiUrlRoles}/${roleId}`,
    roleRawForm,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// ロールを論理削除
export const postDisableRole = async ({
  roleId,
  organizationId,
}: {
  roleId: string;
  organizationId: string;
}): Promise<RoleRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: RoleRaw }>(
    `${apiUrlRoles}/${roleId}/delete`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// ロールを有効化
export const postEnableRole = async ({
  roleId,
  organizationId,
}: {
  roleId: string;
  organizationId: string;
}): Promise<RoleRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: RoleRaw }>(
    `${apiUrlRoles}/${roleId}/enable`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

// ロールを論理削除
export const postRemoveRole = async ({
  roleId,
  organizationId,
}: {
  roleId: string;
  organizationId: string;
}) => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  await API_CLIENT.post<{ item: RoleRaw }>(
    `${apiUrlRoles}/${roleId}/remove`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
};

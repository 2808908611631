import loadable from "@loadable/component";

import { demoShipDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeDemoShipView: StrictViewType = {
  key: "demoShipsView",
  displayName: "配船デモ（船ビュー）",
  dependSheets: demoShipDependSheets,
  configSchemas: [],
  render: ViewRender as any,
};

import { View, useSetView } from "../../../../domain/View";
import {
  useGetViewConfigSchemas,
  useSetViewConfigSchema,
} from "../../../../domain/ViewConfigSchema";
import {
  useGetViewDependSheets,
  useSetViewDependSheet,
} from "../../../../domain/ViewDependSheet";
import {
  useGetViewDependSheetFields,
  useSetViewDependSheetField,
} from "../../../../domain/ViewDependSheetField";
import { ManagedTransaction } from "../../../../firebase/firestore";

import { generateId } from "@/utils/generateId";

export const duplicateRecursiveViewData = async ({
  duplicateView,
  duplicateViewTitle,
  getViewDependSheets,
  getViewConfigSchemas,
  getViewDependSheetFields,
  setView,
  setViewConfigSchema,
  setViewDependSheet,
  setViewDependSheetField,
  sort,
}: {
  duplicateView: View;
  duplicateViewTitle: View["title"];
  getViewDependSheets: ReturnType<typeof useGetViewDependSheets>;
  getViewConfigSchemas: ReturnType<typeof useGetViewConfigSchemas>;
  getViewDependSheetFields: ReturnType<typeof useGetViewDependSheetFields>;
  setView: ReturnType<typeof useSetView>;
  setViewConfigSchema: ReturnType<typeof useSetViewConfigSchema>;
  setViewDependSheet: ReturnType<typeof useSetViewDependSheet>;
  setViewDependSheetField: ReturnType<typeof useSetViewDependSheetField>;
  sort: number;
}) => {
  const newViewId = generateId();
  const viewId = duplicateView.id;
  const viewDependSheets = await getViewDependSheets({ viewId });
  const viewDependSheetFieldsWithId = await Promise.all(
    viewDependSheets.map(async ({ id: viewDependSheetId }) => {
      const viewDependSheetFields = await getViewDependSheetFields({
        viewId,
        dependSheetId: viewDependSheetId,
      });
      return { viewDependSheetFields, viewDependSheetId };
    })
  );
  const viewConfigSchemas = await getViewConfigSchemas({ viewId });

  // 処理開始
  await setView(
    {
      ...duplicateView,
      id: newViewId,
      title: duplicateViewTitle,
      sort,
    },
    {},
    {}
  );
  await ManagedTransaction.runTransaction(async (transaction) => {
    for (const viewConfigSchema of viewConfigSchemas) {
      await setViewConfigSchema(
        {
          ...viewConfigSchema,
        },
        { viewId: newViewId },
        { transaction }
      );
    }
    for (const viewDependSheet of viewDependSheets) {
      await setViewDependSheet(
        {
          ...viewDependSheet,
        },
        { viewId: newViewId },
        { transaction }
      );
    }
  });

  const viewDependSheetFields = viewDependSheetFieldsWithId
    .map(({ viewDependSheetFields, viewDependSheetId }) => {
      return viewDependSheetFields.map((viewDependSheetField) => ({
        viewDependSheetId,
        viewDependSheetField,
      }));
    })
    .flat();
  await ManagedTransaction.runTransaction(async (transaction) => {
    for (const {
      viewDependSheetField,
      viewDependSheetId,
    } of viewDependSheetFields) {
      await setViewDependSheetField(
        {
          ...viewDependSheetField,
        },
        { viewId: newViewId, dependSheetId: viewDependSheetId },
        { transaction }
      );
    }
  });
};

const escapeRegExp = (value: string) =>
  value.replace(/[\\^$.*+?()[\]{}|]/g, "\\$&");

/**
 *
 * {コピー元の名前}_copy{連番} という形式の名前を返す
 * (例)
 * 1回目: {コピー元の名前}_copy
 * 2回目: {コピー元の名前}_copy2
 *
 * @param views
 * @param value
 */
export const getNewDuplicationViewTitleWithNumbering = (
  views: View[],
  value: string
) => {
  const regex = new RegExp(`^${escapeRegExp(value)}_copy(\\d*)$`);

  const numberingList = views
    .filter((v) => regex.test(v.title))
    .map((v) => v.title.match(regex)?.[1])
    .map((v) => (v ? Number(v) : 0));

  if (numberingList.length === 0) {
    return `${value}_copy`;
  }

  const maxNumbering = Math.max(...numberingList);

  return `${value}_copy${maxNumbering + 1}`;
};

import { ViewType } from "../domain/ViewType";

import { useActiveFrontendAddons } from "@/components/AppProvider/AppFrontendAddonProvider";
import { viewTypesHKRShip } from "@/viewType/viewTypes/Optium/hkrShip/views";
import { viewTypesCptDom } from "./viewTypes/Optium/cptDom/views";
import { viewTypesNgrKouban } from "./viewTypes/Optium/ngr/views";
import { viewTypesNshKws } from "./viewTypes/Optium/nshKws/views";
import { viewTypesPlaniumChemical } from "./viewTypes/Planium/Chemical/views";
import { viewTypeDemoPlantLines } from "./viewTypes/Platform/demoPlant/demoPlantLines";
import { viewTypeDemoPlantStockGraph } from "./viewTypes/Platform/demoPlant/demoPlantStockGraph";
import { viewTypeDemoShipView } from "./viewTypes/Platform/demoShip/demoShipView";
import { viewTypeExample } from "./viewTypes/Platform/example";
import { viewTypeMatrix } from "./viewTypes/Platform/matrix";
import { viewTypeMatrixMultiType } from "./viewTypes/Platform/matrixMultiType";

/**
 * @description 各ビューの定義を列挙する。
 * 新しくviewを定義したらここに追加する
 */
const internalViewTypes: ViewType<any, any>[] = [
  viewTypeExample,
  viewTypeDemoPlantLines,
  viewTypeDemoPlantStockGraph,
  viewTypeMatrix,
  viewTypeMatrixMultiType,
  viewTypeDemoShipView,
  ...viewTypesPlaniumChemical,
  ...viewTypesHKRShip,
  ...viewTypesNshKws,
  ...viewTypesCptDom,
  ...viewTypesNgrKouban,
];
export const useViewTypes = () => {
  const addonViewTypes = useActiveFrontendAddons().viewTypes;
  return [...internalViewTypes, ...addonViewTypes];
};

import {
  DialogAPIErrorParam,
  DialogConfirmParam,
  DialogInProgressParam,
  DialogLocalErrorParam,
  DialogSuccessParam,
} from "../helpers/hooks";

// 被参照情報の読み込みを管理する型
export type ReferredFromState = "waiting" | "including" | "notIncluding";

export const dialogActionNames = {
  delete: "削除",
  remove: "完全削除",
  duplicate: "複製",
  enable: "復元",
  disable: "無効化",
  updateCategory: "カテゴリ更新",
};

export const dialogTargetNames = {
  view: "ビュー",
  sheet: "シート",
  version: "バージョン",
};

export const getDialogMessages = ({
  isSome,
  actionType,
  targetType,
}: {
  isSome: boolean;
  actionType: keyof typeof dialogActionNames;
  targetType: keyof typeof dialogTargetNames;
}) => {
  const target = dialogTargetNames[targetType];
  const action = dialogActionNames[actionType];
  const prefix = isSome ? "選択" : "";
  const messages: {
    inProgress: Pick<DialogInProgressParam, "title">;
    confirm: Pick<
      DialogConfirmParam,
      "displayMessage" | "title" | "buttonText"
    >;
    success: Pick<DialogSuccessParam, "displayMessage">;
    error:
      | Pick<DialogAPIErrorParam, "displayMessage">
      | Pick<DialogLocalErrorParam, "displayMessage">;
  } = {
    confirm: {
      displayMessage: prefix + `${target}を${action}してもよろしいですか？`,
      title: `${target}の${action}`,
      buttonText: action,
    },
    inProgress: {
      title: prefix + `${target}を${action}しています`,
    },
    success: {
      displayMessage: prefix + `${target}の${action}完了`,
    },
    error: {
      displayMessage: prefix + `${target}の${action}失敗`,
    },
  };
  return messages;
};

import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import { ErrorCircle24Filled } from "@fluentui/react-icons";
import { DialogLocalErrorParam } from "../hooks";
import styles from "../index.module.css";

export const DialogLocalError: React.FC<DialogLocalErrorParam> = ({
  onClick,
  displayMessage,
  errorMessages,
  dialogPhase,
  setDialogPhase,
}) => {
  return (
    <Dialog open={dialogPhase === "errorLocal"}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            <div className={styles.titleWithIcon}>
              <ErrorCircle24Filled className={styles.errorIcon} />
              {displayMessage}
            </div>
          </DialogTitle>
          <DialogContent>
            <div className={styles.errorInfoTitle}>エラーメッセージ</div>
            {errorMessages.map((msg, index) => (
              <div
                key={`errorDialog_${index}`}
                className={styles.errorResponse}
              >
                {msg}
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onClick && onClick();
                setDialogPhase(null);
              }}
            >
              戻る
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

import {
  AlgorithmVersionRaw,
  AlgorithmVersionRawForm,
} from "../../domain/AlgorithmVersion";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrlAlgorithmVersions = "/algorithm_versions";

export const getAlgorithmVersions = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<AlgorithmVersionRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: AlgorithmVersionRaw[] }>(
    `${apiUrlAlgorithmVersions}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.items;
};
export const getAlgorithmVersion = async ({
  organizationId,
  algorithmVersionId,
}: {
  organizationId: string;
  algorithmVersionId: AlgorithmVersionRaw["version_id"];
}): Promise<AlgorithmVersionRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ item: AlgorithmVersionRaw }>(
    `${apiUrlAlgorithmVersions}/${algorithmVersionId}`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

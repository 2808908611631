import loadable from "@loadable/component";

import { seniorParttimeCalendarDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const seniorParttimeCalendarView: StrictViewType = {
  key: "ngrKoubanSeniorParttimeCalendar",
  displayName: "NGR交番:シニアパートタイム",
  render: ViewRender as any,
  dependSheets: seniorParttimeCalendarDependSheets,
  configSchemas: [],
};

import {
  OrganizationRaw,
  OrganizationsItemRaw,
} from "../../domain/Organization";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrlOrganizations = "/organizations";
const apiUrlInivitaionsOrganizations = "/invitations" + apiUrlOrganizations;

export const getOrganization = async ({
  organizationId,
}: {
  organizationId: OrganizationRaw["org_id"];
}): Promise<OrganizationRaw> => {
  const response = await API_CLIENT.get<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}`,
    {}
  );
  return response.data.item;
};
export const getOrganizations = async (): Promise<OrganizationsItemRaw[]> => {
  const response = await API_CLIENT.get<{ items: OrganizationsItemRaw[] }>(
    `${apiUrlOrganizations}`
  );
  return response.data.items;
};
export const postEditOrganization = async ({
  organizationId,
  organizationRawForm,
}: {
  organizationId: OrganizationRaw["org_id"];
  organizationRawForm: OrganizationRaw;
}): Promise<OrganizationRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}`,
    organizationRawForm,
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postCreateOrganization = async ({
  organizationRawForm,
}: {
  organizationRawForm: OrganizationRaw;
}): Promise<OrganizationRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}`,
    organizationRawForm,
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};
export const postEditIsMfaOrganization = async ({
  organizationId,
  is_mfa_required,
}: {
  organizationId: OrganizationRaw["org_id"];
  is_mfa_required: OrganizationRaw["is_mfa_required"];
}): Promise<OrganizationRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs();
  const response = await API_CLIENT.post<{ item: OrganizationRaw }>(
    `${apiUrlOrganizations}/${organizationId}/is_mfa_required`,
    { is_mfa_required },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};

export const getInvitationsOrganizations = async () => {
  const response = await API_CLIENT.get(`${apiUrlInivitaionsOrganizations}`);
  return response.data.items;
};
export const postAcceptIvitationOrganization = async ({
  organizationId,
  is_accept,
}: {
  organizationId: OrganizationRaw["org_id"];
  is_accept: boolean;
}): Promise<OrganizationsItemRaw> => {
  const { apiHeadersWithJson } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: OrganizationsItemRaw }>(
    `${apiUrlInivitaionsOrganizations}/${organizationId}`,
    { is_accept },
    {
      ...apiHeadersWithJson,
    }
  );
  return response.data.item;
};

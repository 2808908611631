import { generateId } from "@/utils/generateId";
import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
  SetOptions,
  Transaction,
} from "firebase/firestore";
import {
  SWHMessageData,
  computeEncodedFirestoreQueryHash,
  encodeFirestoreObject,
} from "./bridgeUtils";
import type {
  DocumentSnapshotChange,
  QuerySnapshotChange,
} from "./onSnapshotManager";

type SnapshotWorkerTypedPort = {
  child: {
    on: (id: string, listener: (data: any) => void) => void;
    once: <T>(id: string, listener: (data: any) => T) => Promise<T>;
    off: (id: string) => void;
  };
  on: (
    type: "message",
    listener: (this: MessagePort, ev: MessageEvent) => void
  ) => void;
  off: (
    type: "message",
    listener: (this: MessagePort, ev: MessageEvent) => void
  ) => void;
  postMessage: (data: SWHMessageData) => void;
  postMessageWithResult: <T = void>(
    data: SWHMessageData,
    listener?: (data: any) => T
  ) => Promise<T>;
};
let _snapshotWorkerTypedPort: SnapshotWorkerTypedPort | null = null;
const _snapshotWorkerListenerMap: Map<string, (data: any) => void> = new Map();
const initSnapshotWorker = (): SnapshotWorkerTypedPort => {
  const worker = new SharedWorker(new URL("./index.ts", import.meta.url), {
    name: "snapshotWorker",
  });
  worker.onerror = (e) => {
    console.log(e);
  };
  worker.port.addEventListener("message", (e) => {
    const { id, ...data } = e.data;
    _snapshotWorkerListenerMap.get(id)?.(data);
  });
  worker.port.start();
  setInterval(() => {
    worker.port.postMessage({
      action: "keepalive",
    });
  }, 1000);
  _snapshotWorkerListenerMap.set("$error", ({ desc }) => {
    console.error("Error inside snapshotWorker:", desc);
  });
  const child_once = <T>(id: string, listener: (data: any) => T): Promise<T> =>
    new Promise((resolve, reject) => {
      const onceListener = (data: any) => {
        _snapshotWorkerListenerMap.delete(id);
        if (data.$error) {
          reject(data.$error);
          return;
        }
        resolve(listener(data));
      };
      _snapshotWorkerListenerMap.set(id, onceListener);
    });
  return {
    child: {
      on: (id, listener) => _snapshotWorkerListenerMap.set(id, listener),
      once: child_once,
      off: (id) => _snapshotWorkerListenerMap.delete(id),
    },
    on: (type, listener) => worker.port.addEventListener(type, listener),
    off: (type, listener) => worker.port.removeEventListener(type, listener),
    postMessage: (data) => worker.port.postMessage(data),
    postMessageWithResult: <T = void>(data: any, listener: any) => {
      const job = child_once(
        data.id,
        listener ?? (() => undefined)
      ) as Promise<T>;
      worker.port.postMessage(data);
      return job;
    },
  };
};
const getSnapshotWorkerTypedPort = () => {
  if (!_snapshotWorkerTypedPort)
    _snapshotWorkerTypedPort = initSnapshotWorker();
  return _snapshotWorkerTypedPort;
};
export const onSnapshotInWorker = <
  AppModelType,
  DbModelType extends DocumentData,
>(
  reference:
    | Query<AppModelType, DbModelType>
    | DocumentReference<AppModelType, DbModelType>,
  onRecv: (
    r:
      | {
          type: "doc";
          change: DocumentSnapshotChange;
        }
      | {
          type: "query";
          changes: QuerySnapshotChange[];
        }
  ) => void
) => {
  const id = generateId();
  const port = getSnapshotWorkerTypedPort();
  port.postMessage({
    action: "subscribe",
    id,
    query: encodeFirestoreObject(reference, true),
  });
  const onMsg = ({
    data,
  }: MessageEvent<
    | { id: string; type: "doc"; change: DocumentSnapshotChange }
    | { id: string; type: "query"; changes: QuerySnapshotChange[] }
  >) => {
    if (data.id !== id) return;
    if (data.type === "doc") {
      onRecv({ type: data.type, change: data.change });
    } else {
      onRecv({ type: data.type, changes: data.changes });
    }
  };
  port.on("message", onMsg);
  return () => {
    port.off("message", onMsg);
    port.postMessage({
      action: "unsubscribe",
      id,
    });
  };
};

export const getDocInWorker = <AppModelType, DbModelType extends DocumentData>(
  reference: DocumentReference<AppModelType, DbModelType>
) => {
  const id = generateId();
  const port = getSnapshotWorkerTypedPort();
  return port.postMessageWithResult(
    {
      action: "getDoc",
      id,
      query: encodeFirestoreObject(reference),
    },
    ({ doc }) =>
      ({
        id: doc.id,
        data: () => doc.data,
        exists: () => doc.exists,
      }) as unknown as DocumentSnapshot<any, any>
  );
};
export const getDocsInWorker = <AppModelType, DbModelType extends DocumentData>(
  reference: Query<AppModelType, DbModelType>
) => {
  const id = generateId();
  const port = getSnapshotWorkerTypedPort();
  return port.postMessageWithResult(
    {
      action: "getDocs",
      id,
      query: encodeFirestoreObject(reference),
    },
    ({ docs }: { docs: any[] }) => {
      docs = docs.map(
        (doc) =>
          ({
            id: doc.id,
            data: () => doc.data,
          }) as unknown as DocumentSnapshot<any, any>
      );
      return {
        docs,
        forEach(callback: (doc: DocumentSnapshot<any, any>) => void) {
          docs.forEach((doc) => callback(doc));
        },
      } as unknown as QuerySnapshot<any, any>;
    }
  );
};
export const setDocInWorker = <AppModelType, DbModelType extends DocumentData>(
  reference: DocumentReference<AppModelType, DbModelType>,
  data: any,
  options?: SetOptions
) => {
  const id = generateId();
  const port = getSnapshotWorkerTypedPort();
  return port.postMessageWithResult({
    action: "setDoc",
    id,
    query: encodeFirestoreObject(reference),
    data: encodeFirestoreObject(data),
    options,
  });
};
export const deleteDocInWorker = <
  AppModelType,
  DbModelType extends DocumentData,
>(
  reference: DocumentReference<AppModelType, DbModelType>
) => {
  const id = generateId();
  const port = getSnapshotWorkerTypedPort();
  return port.postMessageWithResult({
    action: "deleteDoc",
    id,
    query: encodeFirestoreObject(reference),
  });
};

type ToQuickDescriptorMap<T> = {
  [K in keyof T]: { value: T[K] };
};
export const runWorkerTransaction = async <T>(
  firestore: unknown,
  updateFunction: (transaction: Transaction) => Promise<T>,
  options?: unknown
) => {
  const transactionId = generateId();
  const port = getSnapshotWorkerTypedPort();
  await port.postMessageWithResult({
    action: "startTransaction",
    id: transactionId,
  });
  let transaction: Transaction | null = null;
  const descriptorMap: ToQuickDescriptorMap<Transaction> = {
    get: {
      value: (docRef) => {
        const id = generateId();
        return port.postMessageWithResult(
          {
            action: "transaction.get",
            id,
            transactionId,
            query: encodeFirestoreObject(docRef),
          },
          ({ doc }) =>
            ({
              id: doc.id,
              data: () => doc.data,
              exists: () => doc.exists,
            }) as unknown as DocumentSnapshot<any, any>
        );
      },
    },
    set: {
      value: (docRef, data, options: SetOptions | undefined = undefined) => {
        const id = generateId();
        port.postMessage({
          action: "transaction.set",
          id,
          transactionId,
          query: encodeFirestoreObject(docRef),
          data: encodeFirestoreObject(data as any),
          options,
        });
        return transaction!;
      },
    },
    update: {
      value: (docRef, data) => {
        const id = generateId();
        port.postMessage({
          action: "transaction.update",
          id,
          transactionId,
          query: encodeFirestoreObject(docRef),
          data: encodeFirestoreObject(data),
        });
        return transaction!;
      },
    },
    delete: {
      value: (docRef) => {
        const id = generateId();
        port.postMessage({
          action: "transaction.delete",
          id,
          transactionId,
          query: encodeFirestoreObject(docRef),
        });
        return transaction!;
      },
    },
  };
  transaction = Object.create(Transaction.prototype, descriptorMap);
  try {
    await updateFunction(transaction!);
  } finally {
    await port.postMessageWithResult({
      action: "commitTransaction",
      id: generateId(),
      transactionId,
    });
  }
};

export const queryToSnapshotSubscribeKey = (query: Query<any, any>) =>
  computeEncodedFirestoreQueryHash(encodeFirestoreObject(query, true));

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

import { FIREBASE_CONFIG } from "../config/firebase";

// Initialize Firebase
export const firebaseApp = initializeApp(FIREBASE_CONFIG);
export const database = getDatabase(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { useAPI } from "../hooks";

import { useActiveFrontendAddons } from "@/components/AppProvider/AppFrontendAddonProvider";
import { ViewMasterRaw } from "@/domain/ViewMaster";
import { useViewTypes } from "@/viewType";

const viewTypesCtx = createContext<{
  availableViewTypes: ViewMasterRaw[] | null;
  runReloadAvailableViewTypes: () => Promise<void>;
  isLoadedAvailableViewTypes: boolean;
}>({
  availableViewTypes: [],
  runReloadAvailableViewTypes: async () => {
    // nop
  },
  isLoadedAvailableViewTypes: false,
});

// 組織ごとに利用可能なビュータイプを提供するプロバイダ
export const ViewTypesProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: availableInternalViewTypes,
    runReloadState: runReloadAvailableInternalViewTypes,
    isLoadedState: isLoadedAvailableInternalViewTypes,
  } = useAPI<ViewMasterRaw[]>("view_masters");
  const addonViewTypes = useActiveFrontendAddons().viewTypes;
  const availableAddonViewTypes = useMemo(
    () =>
      addonViewTypes.map((vt) => ({
        doc_id: vt.key,
        view_master_id: vt.key,
        is_enable: true,
        name: vt.displayName,
        description: "",
        view_type: vt.key,
        privacy_setting: "private" as const,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      })),
    [addonViewTypes]
  );
  const viewTypesCtxValue = useMemo(
    () => ({
      availableViewTypes: availableInternalViewTypes
        ? [...availableInternalViewTypes, ...availableAddonViewTypes]
        : null,
      runReloadAvailableViewTypes: runReloadAvailableInternalViewTypes,
      isLoadedAvailableViewTypes: isLoadedAvailableInternalViewTypes,
    }),
    [
      availableAddonViewTypes,
      availableInternalViewTypes,
      runReloadAvailableInternalViewTypes,
      isLoadedAvailableInternalViewTypes,
    ]
  );

  return (
    <viewTypesCtx.Provider value={viewTypesCtxValue}>
      {children}
    </viewTypesCtx.Provider>
  );
};

export const useAvailableViewTypes = () => {
  return useContext(viewTypesCtx);
};

import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { MemberRaw } from "../../../domain/Member";

import { useAdminAPI } from "../hooks";

const adminOrganizationMemberCtx = createContext<{
  members: MemberRaw[] | null;
  invitedMembers: MemberRaw[] | null;
  runReloadMembers: () => Promise<void>;
  isLoadedMembers: boolean;
  isAdminMode: boolean;
}>({
  members: null,
  invitedMembers: null,
  runReloadMembers: async () => {
    // nop
  },
  isLoadedMembers: false,
  isAdminMode: true,
});

/**
 * @description 管理者ビュー内の組織詳細内・メンバー一覧で使うmembersデータを管理
 */
export const AdminOrganizationMembersProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state,
    runReloadState: runReloadMembers,
    isLoadedState: isLoadedMembers,
  } = useAdminAPI<{ members: MemberRaw[]; invitedMembers: MemberRaw[] }>(
    "members"
  );

  const { members, invitedMembers } = state ?? {
    members: null,
    invitedMembers: null,
  };

  const memberCtxValue = useMemo(
    () => ({
      members,
      invitedMembers,
      runReloadMembers,
      isLoadedMembers,
      isAdminMode: true,
    }),
    [members, invitedMembers, runReloadMembers, isLoadedMembers]
  );

  return (
    <adminOrganizationMemberCtx.Provider value={memberCtxValue}>
      {children}
    </adminOrganizationMemberCtx.Provider>
  );
};

export const useAdminOrganizationMembers = () => {
  return useContext(adminOrganizationMemberCtx);
};

import { SkillEditorViewTypeDependSheet } from "./params";

const driverMasterDependSheet: SkillEditorViewTypeDependSheet = {
  key: "driverMaster",
  displayName: "乗務員",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "driverName",
      displayName: "氏名",
      type: "string",
    },
  ],
};

const skillMasterDependSheet: SkillEditorViewTypeDependSheet = {
  key: "skillMaster",
  displayName: "スキル",
  dependSheetFields: [
    {
      key: "skillName",
      displayName: "スキル名",
      type: "string",
    },
  ],
};

const driverSkillRelationDependSheet: SkillEditorViewTypeDependSheet = {
  key: "driverSkillRelation",
  displayName: "乗務員の持つスキル",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "skillName",
      displayName: "スキル名",
      type: "string",
    },
  ],
};

export const skillEditorDependSheets = [
  driverMasterDependSheet,
  skillMasterDependSheet,
  driverSkillRelationDependSheet,
];

import { runTransaction } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router";

import { useAppRouteParams } from "../../../../../AppRoutes";
import {
  View,
  useRecursiveDeleteView,
  useSetView,
} from "../../../../../domain/View";
import { firestore } from "../../../../../firebase";
import { ManagedTransaction } from "../../../../../firebase/firestore";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";

export const DialogViewDeleteSome: React.FC<
  {
    views: View[];
    selectedViewIds: string[];
    setSelectedViewIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  views,
  selectedViewIds,
  setSelectedViewIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const navigate = useNavigate();
  const { organizationId, versionId, viewId: nowViewId } = useAppRouteParams();
  const recursiveDeleteView = useRecursiveDeleteView();
  const setView = useSetView();

  const { renderDialogs, setIsInProgress, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const runDelete = async () => {
    try {
      if (nowViewId && selectedViewIds.includes(nowViewId)) {
        navigate(`/organizations/${organizationId}/versions/${versionId}`);
      }
      setDialogPhase("in_progress");
      const otherViews = views.filter(
        ({ id }) => !selectedViewIds.includes(id)
      );
      const deleteQueueList = await Promise.all(
        selectedViewIds.map(
          async (viewId) => await recursiveDeleteView(viewId, {})
        )
      );
      await ManagedTransaction.runTransaction(async (transaction) => {
        // view削除
        for (const deleteQueue of deleteQueueList) {
          await deleteQueue.exec(transaction);
        }
        // ソート番号振り直し
        for (const [index, view] of otherViews.entries()) {
          await setView(
            { ...view, sort: index },
            { viewId: view.id },
            { transaction }
          );
        }
      });
      setSelectedViewIds([]);
      setDialogPhase("success");
    } catch (error) {
      showErrorAPIDialog(error);
    }
  };
  const messages = getDialogMessages({
    isSome: true,
    actionType: "delete",
    targetType: "view",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runDelete,
          onClickCancel: () => {
            setSelectedViewIds([]);
          },
          isThemeRed: true,
          ...messages.confirm,
        },
        inProgress: {
          ...messages.inProgress,
        },
        success: { ...messages.success },
        errorAPI: {
          ...messages.error,
        },
      })}
    </>
  );
};

import React, { useEffect, useState } from "react";

import { Sheet, useGetSheets, useSetSheet } from "../../../../../domain/Sheet";
import {
  useGetSheetDataRows,
  useSheetDataRowUtils,
} from "../../../../../domain/SheetDataRow";
import {
  SheetFieldSchema,
  useGetSheetFieldSchemas,
  useSetSheetFieldSchema,
} from "../../../../../domain/SheetFieldSchema";
import {
  Version,
  checkIsPublishedByFileUpload,
  useSetVersion,
} from "../../../../../domain/Version";
import { View, useGetViews, useSetView } from "../../../../../domain/View";
import {
  ViewConfigSchema,
  useGetViewConfigSchemas,
  useSetViewConfigSchema,
} from "../../../../../domain/ViewConfigSchema";
import {
  ViewDependSheet,
  useGetViewDependSheets,
  useSetViewDependSheet,
} from "../../../../../domain/ViewDependSheet";
import {
  useGetViewDependSheetFields,
  useSetViewDependSheetField,
} from "../../../../../domain/ViewDependSheetField";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";
import { duplicateRecursiveVersionData } from "../helper";

import { useSetReferredFrom } from "@/domain/ReferredFrom";
import { Input } from "@fluentui/react-components";

import styles from "./index.module.css";

export const DialogVersionDuplicate: React.FC<
  {
    versions: Version[];
    selectedVersionIds: string[];
    setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  versions,
  selectedVersionIds,
  setSelectedVersionIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const setVersion = useSetVersion();

  const setSheet = useSetSheet();
  const setSheetFieldSchema = useSetSheetFieldSchema();
  const sheetDataRowUtils = useSheetDataRowUtils();

  const setView = useSetView();
  const setViewConfigSchema = useSetViewConfigSchema();
  const setViewDependSheet = useSetViewDependSheet();
  const setViewDependSheetField = useSetViewDependSheetField();
  const setReferredFrom = useSetReferredFrom();

  const getSheets = useGetSheets();
  const getSheetDataRows = useGetSheetDataRows();
  const getSheetFieldSchemas = useGetSheetFieldSchemas();

  const getViews = useGetViews();
  const getViewConfigSchemas = useGetViewConfigSchemas();
  const getViewDependSheets = useGetViewDependSheets();
  const getViewDependSheetFields = useGetViewDependSheetFields();

  const { renderDialogs, setIsInProgress, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const versionId = selectedVersionIds[0];
  const version = versions.find(({ id }) => id === versionId);

  const [newVersionTitle, setNewVersionTitle] = useState("");
  useEffect(() => {
    setNewVersionTitle(`${version?.title}_copy`);
  }, [version]);

  const runDuplicate = async () => {
    // NOTE: 簡易最適化は複製しない
    if (version && !checkIsPublishedByFileUpload(version)) {
      setDialogPhase("in_progress");
      try {
        await duplicateRecursiveVersionData({
          version,
          duplicateVersionName: newVersionTitle,
          getSheets,
          getViews,
          getSheetDataRows,
          getSheetFieldSchemas,
          getViewDependSheets,
          getViewConfigSchemas,
          getViewDependSheetFields,
          setVersion,
          setSheet,
          setSheetFieldSchema,
          setView,
          setViewConfigSchema,
          setViewDependSheet,
          setViewDependSheetField,
          setReferredFrom,
          sheetDataRowUtils,
        });
        setDialogPhase("success");
      } catch (error) {
        showErrorAPIDialog(error);
      } finally {
        setIsInProgress(false);
      }
    }
  };
  const messages = getDialogMessages({
    isSome: false,
    actionType: "duplicate",
    targetType: "version",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runDuplicate,
          onClickCancel: () => {
            setNewVersionTitle("");
            setSelectedVersionIds([]);
          },
          element: (
            <div>
              <Input
                hidden={!selectedVersionIds.length}
                className={styles.versionTitleInput}
                value={newVersionTitle}
                onChange={(e) => setNewVersionTitle(e.target.value)}
              />
            </div>
          ),
          title: "バージョンの複製",
          isDisableButton: !newVersionTitle,
          displayMessage: "新しいバージョンの名前を入力してください。",
          buttonText: "複製",
        },
        inProgress: {
          ...messages.inProgress,
        },
        success: {
          onClick: () => {
            setSelectedVersionIds([]);
          },
          ...messages.success,
        },
        errorAPI: { ...messages.error },
      })}
    </>
  );
};

import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { useAPI } from "../hooks";

import { Role } from "@/domain/roles";

const rolesCtx = createContext<{
  roles: Role[] | null;
  isLoadedRoles: boolean;
  runReloadRoles: () => Promise<void>;
}>({
  roles: [],
  isLoadedRoles: false,
  runReloadRoles: async () => {},
});

// 組織ごとのロールを提供するプロバイダ
export const RolesProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: roles,
    runReloadState: runReloadRoles,
    isLoadedState: isLoadedRoles,
  } = useAPI<Role[]>("roles");

  const rolesCtxValue = useMemo(
    () => ({
      roles,
      isLoadedRoles,
      runReloadRoles,
    }),
    [roles, isLoadedRoles, runReloadRoles]
  );

  return (
    <rolesCtx.Provider value={rolesCtxValue}>{children}</rolesCtx.Provider>
  );
};

export const useRoles = () => {
  return useContext(rolesCtx);
};

import React, {
  useMemo,
  useState,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";

import { useAppRouteParams } from "../../../AppRoutes";
import { getAlgorithmVersions } from "../../../api/algorithmVersions";
import { getAPIErrorDetailAndMessage } from "../../../api/helpers";
import { AlgorithmVersionRaw } from "../../../domain/AlgorithmVersion";
import { useAppAuth } from "../../AppProvider/AppAuthProvider";
import { useAPI } from "../hooks";

const algorithmVersionCtx = createContext<{
  algorithmVersions: AlgorithmVersionRaw[] | null;
  runReloadAlgorithmVersions: () => Promise<void>;
  isLoadedAlgorithmVersions: boolean;
}>({
  algorithmVersions: [],
  runReloadAlgorithmVersions: async () => {
    // nop
  },
  isLoadedAlgorithmVersions: false,
});

export const AlgorithmVersionsProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: algorithmVersions,
    runReloadState: runReloadAlgorithmVersions,
    isLoadedState: isLoadedAlgorithmVersions,
  } = useAPI<AlgorithmVersionRaw[]>("algorithm_versions");

  const algorithmVersionCtxValue = useMemo(
    () => ({
      algorithmVersions,
      runReloadAlgorithmVersions,
      isLoadedAlgorithmVersions,
    }),
    [algorithmVersions, runReloadAlgorithmVersions, isLoadedAlgorithmVersions]
  );

  return (
    <algorithmVersionCtx.Provider value={algorithmVersionCtxValue}>
      {children}
    </algorithmVersionCtx.Provider>
  );
};

export const useAlgorithmVersions = () => {
  return useContext(algorithmVersionCtx);
};

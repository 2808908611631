import loadable from "@loadable/component";

import { demoPlantDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeDemoPlantStockGraph: StrictViewType = {
  key: "demoPlantStockGraph",
  displayName: "生産デモ（製品在庫グラフ）",
  dependSheets: demoPlantDependSheets,
  configSchemas: [],
  render: ViewRender as any,
};

import loadable from "@loadable/component";

import { KoubanDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const koubanView: StrictViewType = {
  key: "ngrKoubanView",
  displayName: "NGR交番:交番表画面",
  render: ViewRender as any,
  dependSheets: KoubanDependSheets,
  configSchemas: [],
};

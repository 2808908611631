import loadable from "@loadable/component";
import { allDependSheets } from "../../../ssui/types/fields";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeHkrShipTasksByPlant: StrictViewType = {
  key: "hkrShipTasksByPlant",
  displayName: "発電所視点配船表",
  render: ViewRender as any,
  dependSheets: allDependSheets,
  configSchemas: [],
  isDisabledEditHistoryShortcuts: true,
};

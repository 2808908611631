import {
  Button,
  MessageBar,
  MessageBarActions,
  MessageBarGroup,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import classNames from "classnames";
import React from "react";

import { useAppNotificationState } from "../AppProvider/AppNotificationProvider";

import styles from "./index.module.css";

/**
 * @description Notificationの描画
 * onDismissgがundefinedの時、クローズボタンはない
 */
export const AppNotifications: React.FC = () => {
  const { notifications } = useAppNotificationState();

  return (
    <div className={styles.notifications}>
      {notifications.map(
        ({
          id,
          type,
          msg,
          disposed,
          fixed,
          hideNotification,
          onNotificationClick,
          onNotificationMouseEnter,
          onNotificationMouseLeave,
        }) => {
          return (
            <MessageBarGroup
              className={classNames(styles.notificationContainer, {
                [styles.disposed]: disposed,
              })}
              onClick={onNotificationClick}
              onMouseEnter={onNotificationMouseEnter}
              onMouseLeave={onNotificationMouseLeave}
              key={id}
            >
              <div className={styles.notification}>
                <MessageBar className={styles.messageBar} intent={type}>
                  {msg}
                  {fixed && (
                    <MessageBarActions
                      containerAction={
                        <Button
                          onClick={hideNotification}
                          aria-label="dismiss"
                          appearance="transparent"
                          icon={<DismissRegular />}
                        />
                      }
                    />
                  )}
                </MessageBar>
              </div>
            </MessageBarGroup>
          );
        }
      )}
    </div>
  );
};

import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKwsCSVViewManagement: StrictViewType = {
  key: "nshKwsCSVViewManagement",
  displayName: "NSH-KWS S4 CSV連携ビュー（生産管理センター用）",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "eo",
      displayName: "eo",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "eoChidoriProduction",
          displayName: "EO千鳥|生産量",
          type: "float",
        },
        {
          key: "eoUkishimaProduction",
          displayName: "EO浮島|生産量",
          type: "float",
        },
      ],
    },
    {
      key: "general",
      displayName: "general",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "generativeEOChidori",
          displayName: "用役費千鳥|EO|生成EO",
          type: "float",
        },
        {
          key: "generativeEOUkishima",
          displayName: "用役費浮島|EO|生成EO",
          type: "float",
        },
      ],
    },
    {
      key: "eg",
      displayName: "eg",
      dependSheetFields: [
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "egChidoriMegProduction",
          displayName: "EG千鳥|MEG生産",
          type: "float",
        },
        {
          key: "egUkishimaMegProduction",
          displayName: "EG浮島|MEG生産",
          type: "float",
        },
        {
          key: "egChidoriDegProduction",
          displayName: "EG千鳥|DEG生産",
          type: "float",
        },
        {
          key: "egUkishimaDegProduction",
          displayName: "EG浮島|DEG生産",
          type: "float",
        },
        {
          key: "egUkishimaMegYProduction",
          displayName: "EG浮島|MEG-Y生産",
          type: "float",
        },
      ],
    },
    {
      key: "internalIdToItemCode",
      displayName: "internalIdToItemCode",
      dependSheetFields: [
        {
          key: "internalId",
          displayName: "内部ID",
          type: "string",
        },
        {
          key: "itemCode",
          displayName: "品目コード",
          type: "string",
        },
      ],
    },
    {
      key: "s4Master1234s",
      displayName: "s4Master1234s",
      dependSheetFields: [
        {
          key: "itemCode",
          displayName: "品目コード",
          type: "string",
        },
        {
          key: "itemText",
          displayName: "品目テキスト",
          type: "string",
        },
        {
          key: "stockArea",
          displayName: "保管場所",
          type: "string",
        },
        {
          key: "lotSize",
          displayName: "ロットサイズ",
          type: "integer",
        },
      ],
    },
  ],
  configSchemas: [],
};

import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

import { MemberRaw } from "@/domain/Member";
import { MeRaw } from "@/domain/me";

const apiUrlMembers = "/members";

// 自身のメンバ情報およびロール情報を取得
export const getMe = async ({
  organizationId,
  uid,
}: {
  organizationId: string;
  uid: MemberRaw["uid"];
}): Promise<MeRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<MeRaw>(`${apiUrlMembers}/${uid}/me`, {
    ...apiOrganizationIdParams,
  });
  return response.data;
};

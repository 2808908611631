import { compareDesc } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";

import { usePermissions } from "./PermissionsProvider";
import { APIReturnType } from "./types";

import { useAppRouteParams } from "@/AppRoutes";
import { getAdminFrontendAddons } from "@/api/admin/frontendAddons";
import { getAlgorithmVersions } from "@/api/algorithmVersions";
import { getConnectMasters } from "@/api/connectMasters";
import { getConnects } from "@/api/connects";
import { getFrontendAddons } from "@/api/frontendAddons";
import { getAPIErrorDetailAndMessage } from "@/api/helpers";
import { getInvitedMembers, getMembers } from "@/api/members";
import { getOrganization } from "@/api/organizations";
import { getRoles } from "@/api/role";
import { getViewMasters } from "@/api/viewMasters";
import { PermissionsKey, Role } from "@/domain/roles";
import { parsePermissions } from "@/helpers/permissions";
import { parseDateTimeOrISODateTime } from "@/utils/dateFnsHelper";
import { useAppAccount } from "../AppProvider/AppAccountProvider";

// 組織内プロバイダで使用するread系のエンドポイントへのアクセスを一括で管理するカスタムフック
// 権限がない場合はstateとしてnullを返す
export const useAPI = <T extends APIReturnType>(
  permissionKey: PermissionsKey
) => {
  const { me, hasPermission, isLoadedMe } = usePermissions();
  const { organizationId } = useAppRouteParams();

  const [state, setState] = useState<T | null>(null);
  const [isLoadedState, setIsLoadedState] = useState<boolean>(false);
  const [_, setError] = useState<unknown>(null);
  const { isAADeveloperOrAbove } = useAppAccount();

  const runReloadState = useCallback(async () => {
    try {
      if (!organizationId) {
        setState(null);
        return;
      }
      setIsLoadedState(false);
      if (!hasPermission(permissionKey, "read")) {
        setState(null);
        setIsLoadedState(true);
        return;
      }
      switch (permissionKey) {
        // OrganizationsProvider
        case "organizations": {
          const response = await getOrganization({
            organizationId,
          });
          setState(response as T);
          break;
        }
        // MembersProvider
        // メンバ、招待中メンバ両方を取得
        case "members": {
          const memberResponse = await getMembers({
            organizationId,
          });
          const invitedMemberResponse = await getInvitedMembers({
            organizationId,
          });
          setState({
            members: memberResponse,
            invitedMembers: invitedMemberResponse,
          } as T);
          break;
        }
        // RolesProvider
        case "roles": {
          const response = await getRoles({ organizationId });
          response
            .filter((role) => !role.is_disabled)
            .sort((a, b) => {
              const _a = parseDateTimeOrISODateTime(a.created_at);
              const _b = parseDateTimeOrISODateTime(b.created_at);
              return _a && _b ? compareDesc(_a, _b) : 0;
            });
          const parsedRole: Role[] = response.map((role) => ({
            ...role,
            permissions: parsePermissions(role.permissions),
          }));
          setState(parsedRole as T);
          break;
        }
        // AlgorithmVersionsProvider
        case "algorithm_versions": {
          const response = await getAlgorithmVersions({ organizationId });
          setState(
            [...response].sort((a, b) => {
              const _a = parseDateTimeOrISODateTime(a.created_at);
              const _b = parseDateTimeOrISODateTime(b.created_at);
              return _a && _b ? compareDesc(_a, _b) : 0;
            }) as T
          );
          break;
        }
        // ViewTypesProvider
        case "view_masters": {
          const response = await getViewMasters({
            organizationId,
          });
          setState(response as T);
          break;
        }
        // ConnectMastersProvider
        case "connect_masters": {
          const response = await getConnectMasters({ organizationId });
          setState(
            [...response].sort((a, b) => {
              const _a = parseDateTimeOrISODateTime(a.created_at);
              const _b = parseDateTimeOrISODateTime(b.created_at);
              return _a && _b ? compareDesc(_a, _b) : 0;
            }) as T
          );
          break;
        }
        // ConnectsProvider
        case "connects": {
          const response = await getConnects({ organizationId });
          setState(
            [...response].sort((a, b) => {
              const _a = parseDateTimeOrISODateTime(a.created_at);
              const _b = parseDateTimeOrISODateTime(b.created_at);
              return _a && _b ? compareDesc(_a, _b) : 0;
            }) as T
          );
          break;
        }
        // FrontendAddonProvider
        case "frontend_addons": {
          // frontend addonについては開発者以上の権限が必要
          if (!isAADeveloperOrAbove) {
            setState(null);
            return;
          }
          const response = await getAdminFrontendAddons({ organizationId });
          setState(
            [...response].sort((a, b) => {
              const _a = parseDateTimeOrISODateTime(a.created_at);
              const _b = parseDateTimeOrISODateTime(b.created_at);
              return _a && _b ? compareDesc(_a, _b) : 0;
            }) as T
          );
          break;
        }
        // 上記以外のPermissonsKeyの場合は、何もしない
        // 特にversion_categoriesは、VersionCategoriesProviderで管理する
        default: {
          setState(null);
          break;
        }
      }
    } catch (err) {
      setError(() => {
        if (typeof err == "object") {
          const e = {
            ...err,
            message: getAPIErrorDetailAndMessage(err),
          } as Error;
          throw e;
        } else {
          throw new Error("不明なエラー");
        }
      });
    } finally {
      setIsLoadedState(true);
    }
  }, [me, organizationId]);

  useEffect(() => {
    runReloadState();
  }, [runReloadState]);

  const stateCtxValue = useMemo(
    () => ({
      state,
      runReloadState,
      isLoadedState: isLoadedState && isLoadedMe,
    }),
    [state, runReloadState, isLoadedState]
  );

  return stateCtxValue;
};

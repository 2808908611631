import {
  AppViewMasterRaw,
  AppViewMasterRawForm,
} from "../../domain/AppViewMasters";
import { API_CLIENT } from "../config";

const apiUrl = "/admin/app_config";
const apiUrlViews = `${apiUrl}/masters/views`;

// アプリケーション全体のビューマスタの一覧を取得する
export const getAdminAppViewMasters = async (): Promise<AppViewMasterRaw[]> => {
  const response = await API_CLIENT.get<{ items: AppViewMasterRaw[] }>(
    apiUrlViews
  );
  return response.data.items;
};
// アプリケーション全体のビューマスタを新規追加する
export const postAdminCreateAppViewMaster = async ({
  name,
  description,
  view_type,
  privacy_setting,
}: AppViewMasterRawForm): Promise<AppViewMasterRaw> => {
  const response = await API_CLIENT.post<{ item: AppViewMasterRaw }>(
    apiUrlViews,
    { name, description, view_type, privacy_setting }
  );
  return response.data.item;
};
export const getAdminAppViewMaster = async (
  view_master_id: string
): Promise<AppViewMasterRaw> => {
  const response = await API_CLIENT.get<{ item: AppViewMasterRaw }>(
    `${apiUrlViews}/${view_master_id}`
  );
  return response.data.item;
};
export const postAdminEditAppViewMaster = async ({
  view_master_id,
  name,
  description,
  view_type,
  privacy_setting,
}: {
  view_master_id: string;
} & AppViewMasterRawForm): Promise<AppViewMasterRaw> => {
  const response = await API_CLIENT.post<{ item: AppViewMasterRaw }>(
    `${apiUrlViews}/${view_master_id}`,
    { name, description, view_type, privacy_setting }
  );
  return response.data.item;
};
export const postAdminDisableAppViewMaster = async ({
  view_master_id,
}: {
  view_master_id: string;
}): Promise<AppViewMasterRaw> => {
  const response = await API_CLIENT.post<{ item: AppViewMasterRaw }>(
    `${apiUrlViews}/${view_master_id}/delete`
  );
  return response.data.item;
};
export const postAdminEnableAppViewMaster = async ({
  view_master_id,
}: {
  view_master_id: string;
}): Promise<AppViewMasterRaw> => {
  const response = await API_CLIENT.post<{ item: AppViewMasterRaw }>(
    `${apiUrlViews}/${view_master_id}/enable`
  );
  return response.data.item;
};
export const postAdminRemoveAppViewMaster = async ({
  view_master_id,
}: {
  view_master_id: string;
}): Promise<AppViewMasterRaw> => {
  const response = await API_CLIENT.post<{ item: AppViewMasterRaw }>(
    `${apiUrlViews}/${view_master_id}/remove`
  );
  return response.data.item;
};

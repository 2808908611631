import { DefaultPalette, PrimaryButton } from "@fluentui/react";
import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router";

import { useAppRouteParams } from "../../../../AppRoutes";
import { getFileVersionView } from "../../../../api/files";
import { View } from "../../../../domain/View";
import { themePrimaryRed } from "../../../../themes";
import { useAppNotificationManager } from "../../../AppProvider/AppNotificationProvider";
import { DialogPhaseType } from "../../helpers/hooks";

import { DialogViewDelete } from "./DialogViewDelete";
import { DialogViewDeleteSome } from "./DialogViewDeleteSome";
import { DialogViewDuplicate } from "./DialogViewDuplicate";
import { DialogViewDuplicateSome } from "./DialogViewDuplicateSome";

import { ContextMenuItem } from "@/components/GridTable/helpers";
import { useVersionPermission } from "@/hooks/useCollectionPermission";

/**
 * @description 複数の場所で利用されるcontextItemsとダイアログを生成
 */
export const useDialogContextMenuItemsView = () => {
  const navigate = useNavigate();
  const { organizationId, versionId } = useAppRouteParams();
  const [hasVersionWritePermission] = useVersionPermission("write");
  const { showSuccessNotification, showErrorNotification } =
    useAppNotificationManager();

  const [selectedViewIds, setSelectedViewIds] = useState([] as string[]);
  const [deleteDialogPhaseType, setDeleteDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [duplicateDialogPhaseType, setDuplicateDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [deleteSomeDialogPhaseType, setDeleteSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [duplicateSomeDialogPhaseType, setDuplicateSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);

  const runGetFireVersionView = useCallback(
    async ({
      viewId,
      dataType,
    }: {
      viewId: View["id"];
    } & Pick<Parameters<typeof getFileVersionView>[0], "dataType">) => {
      try {
        await getFileVersionView({
          organizationId,
          versionId,
          viewId,
          dataType,
        });
        showSuccessNotification("ダウンロードに成功しました");
      } catch (error) {
        showErrorNotification("ダウンロード中にエラーが発生しました", error);
      }
    },
    [organizationId, versionId]
  );

  const getContextMenuItemsView = useCallback(
    (view: View): ContextMenuItem[] => {
      return [
        {
          key: "i0",
          text: "ビュー設定の確認・変更",
          // iconProps: { iconName: "Settings" },
          onClick: () =>
            navigate(
              `/organizations/${organizationId}/versions/${versionId}/views/${view.id}/edit`
            ),
          disabled: !hasVersionWritePermission,
        },
        {
          key: "i1",
          text: "ビューを複製",
          // iconProps: { iconName: "DuplicateRow" },
          onClick: () => {
            setSelectedViewIds([view.id]);
            setDuplicateDialogPhaseType("confirm");
          },
          disabled: !hasVersionWritePermission,
        },
        {
          key: "i2",
          text: "ビューを削除",
          // iconProps: { iconName: "Delete" },
          onClick: () => {
            setSelectedViewIds([view.id]);
            setDeleteDialogPhaseType("confirm");
          },
          style: {
            color: hasVersionWritePermission ? DefaultPalette.red : "",
          },
          disabled: !hasVersionWritePermission,
        },
      ];
    },
    [hasVersionWritePermission, organizationId, versionId, navigate]
  );

  const renderDialogsView = useCallback(
    (views: View[]) => {
      return (
        <>
          <DialogViewDelete
            views={views}
            selectedViewIds={selectedViewIds}
            setSelectedViewIds={setSelectedViewIds}
            dialogPhase={deleteDialogPhaseType}
            setDialogPhase={setDeleteDialogPhaseType}
          />
          <DialogViewDuplicate
            views={views}
            selectedViewIds={selectedViewIds}
            setSelectedViewIds={setSelectedViewIds}
            dialogPhase={duplicateDialogPhaseType}
            setDialogPhase={setDuplicateDialogPhaseType}
          />
          <DialogViewDeleteSome
            views={views}
            selectedViewIds={selectedViewIds}
            setSelectedViewIds={setSelectedViewIds}
            dialogPhase={deleteSomeDialogPhaseType}
            setDialogPhase={setDeleteSomeDialogPhaseType}
          />
          <DialogViewDuplicateSome
            views={views}
            selectedViewIds={selectedViewIds}
            setSelectedViewIds={setSelectedViewIds}
            dialogPhase={duplicateSomeDialogPhaseType}
            setDialogPhase={setDuplicateSomeDialogPhaseType}
          />
        </>
      );
    },
    [
      selectedViewIds,
      deleteDialogPhaseType,
      duplicateDialogPhaseType,
      deleteSomeDialogPhaseType,
      duplicateSomeDialogPhaseType,
    ]
  );

  const isDisableButton =
    selectedViewIds.length === 0 || !hasVersionWritePermission;

  const renderDeleteViewsButton = useCallback(
    () => (
      <PrimaryButton
        text="削除"
        onClick={() => {
          setDeleteSomeDialogPhaseType("confirm");
        }}
        theme={themePrimaryRed}
        disabled={isDisableButton}
      />
    ),
    [selectedViewIds, isDisableButton]
  );
  const renderDuplicateViewsButton = useCallback(
    () => (
      <PrimaryButton
        text="複製"
        onClick={() => {
          setDuplicateSomeDialogPhaseType("confirm");
        }}
        disabled={isDisableButton}
      />
    ),
    [selectedViewIds, isDisableButton]
  );
  return {
    renderDialogsView,
    getContextMenuItemsView,
    selectedViewIds,
    setSelectedViewIds,
    renderDeleteViewsButton,
    renderDuplicateViewsButton,
  };
};

import loadable from "@loadable/component";

import { driverTemporaryEditorDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const driverTemporaryEditorView: StrictViewType = {
  key: "ngrKoubanDriverTemporaryEditor",
  displayName: "NGR交番:乗務員当日編集画面",
  render: ViewRender as any,
  dependSheets: driverTemporaryEditorDependSheets,
  configSchemas: [],
};

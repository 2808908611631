import { ConnectMasterRaw } from "@/domain/ConnectMaster";
import React, { useMemo, ReactNode, createContext, useContext } from "react";
import { useAPI } from "../hooks";

const connectMastersCtx = createContext<{
  availableConnectMasters: ConnectMasterRaw[] | null;
  runReloadAvailableConnectMasters: () => Promise<void>;
  isLoadedAvailableConnectMasters: boolean;
}>({
  availableConnectMasters: [],
  runReloadAvailableConnectMasters: async () => {
    // nop
  },
  isLoadedAvailableConnectMasters: false,
});

// 組織ごとに利用可能なConnectマスタを提供するプロバイダ
export const ConnectMastersProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: availableConnectMasters,
    runReloadState: runReloadAvailableConnectMasters,
    isLoadedState: isLoadedAvailableConnectMasters,
  } = useAPI<ConnectMasterRaw[]>("connect_masters");

  const connectMastersCtxValue = useMemo(
    () => ({
      availableConnectMasters,
      isLoadedAvailableConnectMasters,
      runReloadAvailableConnectMasters,
    }),
    [
      availableConnectMasters,
      isLoadedAvailableConnectMasters,
      runReloadAvailableConnectMasters,
    ]
  );

  return (
    <connectMastersCtx.Provider value={connectMastersCtxValue}>
      {children}
    </connectMastersCtx.Provider>
  );
};

export const useAvailableConnectMasters = () => {
  return useContext(connectMastersCtx);
};

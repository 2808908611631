import { SkillEditorRotationBasedAssignmentViewTypeDependSheet } from "./params";

const rotationBasedAssignmentDependSheet: SkillEditorRotationBasedAssignmentViewTypeDependSheet =
  {
    key: "rotationBasedAssignment",
    displayName: "予定勤務",
    dependSheetFields: [
      {
        key: "date",
        displayName: "日付",
        type: "date",
      },
      {
        key: "version",
        displayName: "バージョン",
        type: "string",
      },
      {
        key: "daygroup",
        displayName: "グループ",
        type: "string",
      },
      {
        key: "workCode",
        displayName: "業務コード",
        type: "string",
      },
      {
        key: "workCodeSeq",
        displayName: "枝番",
        type: "integer",
      },
      {
        key: "workBeginTime",
        displayName: "開始時間",
        type: "string",
      },
      {
        key: "workEndTime",
        displayName: "終了時間",
        type: "string",
      },
      {
        key: "driverId",
        displayName: "氏名コード",
        type: "string",
      },
    ],
  };

const skillMasterDependSheet: SkillEditorRotationBasedAssignmentViewTypeDependSheet =
  {
    key: "skillMaster",
    displayName: "スキル",
    dependSheetFields: [
      {
        key: "skillName",
        displayName: "スキル名",
        type: "string",
      },
    ],
  };

const skillRelationDependSheet: SkillEditorRotationBasedAssignmentViewTypeDependSheet =
  {
    key: "skillRelation",
    displayName: "予定勤務とスキル",
    dependSheetFields: [
      {
        key: "workCode",
        displayName: "業務コード",
        type: "string",
      },
      {
        key: "workCodeSeq",
        displayName: "枝番",
        type: "integer",
      },
      {
        key: "skillName",
        displayName: "スキル名",
        type: "string",
      },
    ],
  };

export const skillEditorDependSheets = [
  rotationBasedAssignmentDependSheet,
  skillMasterDependSheet,
  skillRelationDependSheet,
];

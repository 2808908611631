import { ConnectRaw } from "@/domain/Connect";
import React, { useMemo, ReactNode, createContext, useContext } from "react";
import { useAPI } from "../hooks";

const connectsCtx = createContext<{
  connects: ConnectRaw[] | null;
  isLoadedConnects: boolean;
  runReloadConnects: () => Promise<void>;
}>({
  connects: null,
  isLoadedConnects: false,
  runReloadConnects: async () => {},
});

// 組織ごとのConnectを提供するプロバイダ
export const ConnectsProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: connects,
    runReloadState: runReloadConnects,
    isLoadedState: isLoadedConnects,
  } = useAPI<ConnectRaw[]>("connects");

  const connectsCtxValue = useMemo(
    () => ({
      connects,
      isLoadedConnects,
      runReloadConnects,
    }),
    [connects, isLoadedConnects, runReloadConnects]
  );

  return (
    <connectsCtx.Provider value={connectsCtxValue}>
      {children}
    </connectsCtx.Provider>
  );
};

export const useConnects = () => {
  return useContext(connectsCtx);
};

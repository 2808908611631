import { DefaultPalette } from "@fluentui/react";
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useNavigate } from "react-router";

import { useAppRouteParams } from "../../../../AppRoutes";
import {
  Version,
  checkIsEnableDownloadResult,
  checkIsPublishedByFileUpload,
} from "../../../../domain/Version";
import { DialogPhaseType } from "../../helpers/hooks";
import { ReferredFromState } from "../helper";

import { DialogVersionDelete } from "./DialogVersionDelete";
import { DialogVersionDeleteSome } from "./DialogVersionDeleteSome";
import { DialogVersionDuplicate } from "./DialogVersionDuplicate";
import { DialogVersionDuplicateSome } from "./DialogVersionDuplicateSome";
import { DialogVersionEnable } from "./DialogVersionEnable";
import { DialogVersionEnableSome } from "./DialogVersionEnableSome";
import { DialogVersionRemove } from "./DialogVersionRemove";
import { DialogVersionRemoveSome } from "./DialogVersionRemoveSome";

import {
  getFileVersionMultiInputs,
  getFileVersionMultiResults,
} from "@/api/files";
import { useAppNotificationManager } from "@/components/AppProvider/AppNotificationProvider";
import { useAppOrgsAndInvitedOrgs } from "@/components/AppProvider/AppOrgsAndInvitedOrgsProvider";
import { ContextMenuItem } from "@/components/GridTable/helpers";
import { usePermissions } from "@/components/InOrganizationProvider/PermissionsProvider";
import { useVersionCategories } from "@/components/InOrganizationProvider/VersionCategoriesProvider";
import { useVersions } from "@/components/InOrganizationProvider/VersionsProvider";
import { useGetReferredFroms } from "@/domain/ReferredFrom";
import { Sheet, useGetSheets } from "@/domain/Sheet";
import {
  Button,
  Menu,
  MenuGroupHeader,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  Tooltip,
} from "@fluentui/react-components";
import {
  ArrowReset24Regular,
  CopyAdd24Regular,
  Delete24Regular,
  MoreHorizontal24Filled,
} from "@fluentui/react-icons";
import { DialogVersionUpdateCategorySome } from "./DialogVersionUpdateCategorySome";

const getDisabledDescription = (action: string) => {
  return (
    <>
      <div>{action}</div>
      <div>対象バージョンを選択してください</div>
    </>
  );
};

/**
 * @description 複数の場所で利用されるcontextItemsとダイアログを生成
 */
export const useDialogContextMenuItemsVersion = () => {
  const { organizations } = useAppOrgsAndInvitedOrgs();
  const { organizationId } = useAppRouteParams();
  const organization = organizations.find(
    (org) => org.org_id === organizationId
  );
  const [selectedVersionIds, setSelectedVersionIds] = useState([] as string[]);
  const { hasVersionActionPermission, hasPermission } = usePermissions();
  const hasVersionsWritePermission = hasPermission("versions", "write");
  const { versions } = useVersions();
  const { versionCategories } = useVersionCategories();
  const navigate = useNavigate();
  const { showSuccessNotification, showErrorNotification } =
    useAppNotificationManager();
  const getSheets = useGetSheets();
  const getReferredFroms = useGetReferredFroms();
  const [isIncludingReferencedSheets, setIsIncludingReferencedSheets] =
    useState<ReferredFromState>("waiting");

  // 選択バージョンが被参照シートを含んでいるかどうかをチェック
  useEffect(() => {
    if (selectedVersionIds.length === 0) {
      setIsIncludingReferencedSheets("waiting");
      return;
    }
    (async () => {
      try {
        const versionSheetsMap = new Map<string, Sheet[]>();
        for (const versionId of selectedVersionIds) {
          const sheets = await getSheets({ versionId });
          versionSheetsMap.set(versionId, sheets);
        }
        const referredFroms = await Promise.all(
          Array.from(versionSheetsMap.entries()).map(
            async ([versionId, sheets]) =>
              await Promise.all(
                sheets.map((sheet) =>
                  getReferredFroms({ versionId, sheetId: sheet.id })
                )
              )
          )
        );
        if (referredFroms.flat().some((r) => r.length > 0)) {
          setIsIncludingReferencedSheets("including");
        } else {
          setIsIncludingReferencedSheets("notIncluding");
        }
      } catch (error) {
        showErrorNotification("シート参照のチェックに失敗しました", error);
        setIsIncludingReferencedSheets("notIncluding");
      }
    })();

    return () => setIsIncludingReferencedSheets("waiting");
  }, [selectedVersionIds]);

  const [deleteDialogPhaseType, setDeleteDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [enableDialogPhaseType, setEnableDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [duplicateDialogPhaseType, setDuplicateDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [removeDialogPhaseType, setRemoveDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [deleteSomeDialogPhaseType, setDeleteSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [enableSomeDialogPhaseType, setEnableSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [duplicateSomeDialogPhaseType, setDuplicateSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [removeSomeDialogPhaseType, setRemoveSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [
    updateCategorySomeDialogPhaseType,
    setUpdateCategorySomeDialogPhaseType,
  ] = useState<DialogPhaseType>(null);

  const selectedVersionCategoryIdRef = useRef<string | null>(null);

  const getContextMenuItemsVersion = useCallback(
    (version: Version): ContextMenuItem[] => {
      const hasVersionWritePermission = hasVersionActionPermission(
        version,
        "write"
      );
      return [
        {
          key: "k0",
          text: "バージョン情報を確認・編集",
          // iconProps: { iconName: "DuplicateRow" },
          onClick: () => {
            navigate(
              `/organizations/${organizationId}/versions/${version.id}/version_info`
            );
          },
          disabled: false,
        },
        ...(organization?.is_sheet_enabled
          ? [
              {
                key: "k1",
                text: "バージョンを複製",
                // iconProps: { iconName: "DuplicateRow" },
                onClick: () => {
                  setSelectedVersionIds([version.id]);
                  setDuplicateDialogPhaseType("confirm");
                },
                disabled:
                  !hasVersionsWritePermission ||
                  checkIsPublishedByFileUpload(version),
              },
            ]
          : []),
        {
          key: "k2",
          text: "バージョンを削除",
          onClick: () => {
            setSelectedVersionIds([version.id]);
            setDeleteDialogPhaseType("confirm");
          },
          disabled: !hasVersionWritePermission,
          style: {
            color: hasVersionWritePermission ? DefaultPalette.red : "",
          },
        },
      ];
    },
    [hasVersionsWritePermission, hasVersionActionPermission, organization]
  );

  // 論理削除されたバージョンだけ利用可能
  const getContextMenuItemsDisableVersion = useCallback(
    (version: Version): ContextMenuItem[] => {
      const hasVersionWritePermission = hasVersionActionPermission(
        version,
        "write"
      );
      const hasVersionDeletePermission = hasVersionActionPermission(
        version,
        "delete"
      );
      return [
        {
          key: "k1",
          text: "復元",
          onClick: () => {
            setSelectedVersionIds([version.id]);
            setEnableDialogPhaseType("confirm");
          },
          disabled: !hasVersionWritePermission,
        },
        {
          key: "k2",
          text: "完全削除",
          onClick: () => {
            setSelectedVersionIds([version.id]);
            setRemoveDialogPhaseType("confirm");
          },
          disabled: !hasVersionDeletePermission,
          style: {
            color: hasVersionDeletePermission ? DefaultPalette.red : "",
          },
        },
      ];
    },
    [hasVersionActionPermission]
  );

  const isDialogReady = isIncludingReferencedSheets !== "waiting";

  const {
    selectedMultiResultDownloadIds,
    selectedMultiInputDownloadIds,
    isDisableDownloadMultiResults,
    isDisableDownloadMultiInputs,
  } = useMemo(() => {
    // NOTE:結果は最適化/評価の成功か、もしくは入力検証エラー時にダウンロード可能
    const selectedMultiResultDownloadIds = versions
      .filter(
        (v) =>
          selectedVersionIds.includes(v.id) && checkIsEnableDownloadResult(v)
      )
      .map(({ id }) => id);
    // NOTE:ファイル最適化で作成されたバージョンであれば入力をダウンロード可能
    const selectedMultiInputDownloadIds = versions
      .filter(
        (v) =>
          selectedVersionIds.includes(v.id) && checkIsPublishedByFileUpload(v)
      )
      .map(({ id }) => id);
    const isDisableDownloadMultiResults =
      selectedMultiResultDownloadIds.length === 0 ||
      selectedMultiResultDownloadIds.length > 10;
    const isDisableDownloadMultiInputs =
      selectedMultiInputDownloadIds.length === 0 ||
      selectedMultiInputDownloadIds.length > 10;
    return {
      selectedMultiResultDownloadIds,
      selectedMultiInputDownloadIds,
      isDisableDownloadMultiResults,
      isDisableDownloadMultiInputs,
    };
  }, [versions, selectedVersionIds]);

  const runMultiResultsDownload = useCallback(async () => {
    if (isDisableDownloadMultiResults) return;
    try {
      await getFileVersionMultiResults({
        organizationId,
        versionIds: selectedMultiResultDownloadIds,
      });
      showSuccessNotification("選択バージョンの結果ダウンロードに成功しました");
    } catch (error) {
      showErrorNotification(
        "選択バージョンの結果ダウンロードに失敗しました。",
        error
      );
    }
  }, [
    isDisableDownloadMultiResults,
    selectedMultiResultDownloadIds,
    showSuccessNotification,
    showErrorNotification,
  ]);
  const runMultiInputsDownload = useCallback(async () => {
    if (isDisableDownloadMultiInputs) return;
    try {
      await getFileVersionMultiInputs({
        organizationId,
        versionIds: selectedMultiInputDownloadIds,
      });
      showSuccessNotification("選択バージョンの入力ダウンロードに成功しました");
    } catch (error) {
      showErrorNotification(
        "選択バージョンの入力ダウンロードに失敗しました。",
        error
      );
    }
  }, [
    isDisableDownloadMultiInputs,
    selectedMultiInputDownloadIds,
    showSuccessNotification,
    showErrorNotification,
  ]);

  const renderDownloadsMenu = useCallback(() => {
    return (
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuItem
            disabled={
              isDisableDownloadMultiInputs && isDisableDownloadMultiResults
            }
          >
            入力・結果ダウンロード
          </MenuItem>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuItem
              onMouseDown={runMultiInputsDownload}
              disabled={isDisableDownloadMultiInputs}
            >
              {`${selectedMultiInputDownloadIds.length}個の入力をダウンロード (最大10個まで)`}
            </MenuItem>
            <MenuItem
              onMouseDown={runMultiResultsDownload}
              disabled={isDisableDownloadMultiResults}
            >
              {`${selectedMultiResultDownloadIds.length}個の結果をダウンロード (最大10個まで)`}
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  }, [
    isDisableDownloadMultiInputs,
    isDisableDownloadMultiResults,
    selectedMultiInputDownloadIds,
    selectedMultiResultDownloadIds,
  ]);

  const renderDialogsVersion = useCallback(
    (versions: Version[]) => {
      return (
        <>
          <DialogVersionDuplicate
            versions={versions}
            selectedVersionIds={selectedVersionIds}
            setSelectedVersionIds={setSelectedVersionIds}
            dialogPhase={duplicateDialogPhaseType}
            setDialogPhase={setDuplicateDialogPhaseType}
          />
          {/* メッセージちらつきをおさえるための処理 */}
          {isDialogReady && (
            <DialogVersionDelete
              versions={versions}
              selectedVersionIds={selectedVersionIds}
              setSelectedVersionIds={setSelectedVersionIds}
              isIncludingReferencedSheets={isIncludingReferencedSheets}
              dialogPhase={deleteDialogPhaseType}
              setDialogPhase={setDeleteDialogPhaseType}
            />
          )}
          <DialogVersionEnable
            versions={versions}
            selectedVersionIds={selectedVersionIds}
            setSelectedVersionIds={setSelectedVersionIds}
            dialogPhase={enableDialogPhaseType}
            setDialogPhase={setEnableDialogPhaseType}
          />
          <DialogVersionRemove
            versions={versions}
            setSelectedVersionIds={setSelectedVersionIds}
            selectedVersionIds={selectedVersionIds}
            dialogPhase={removeDialogPhaseType}
            setDialogPhase={setRemoveDialogPhaseType}
          />
          <DialogVersionDuplicateSome
            versions={versions}
            selectedVersionIds={selectedVersionIds}
            setSelectedVersionIds={setSelectedVersionIds}
            dialogPhase={duplicateSomeDialogPhaseType}
            setDialogPhase={setDuplicateSomeDialogPhaseType}
          />
          {/* メッセージちらつきをおさえるための処理 */}
          {isDialogReady && (
            <DialogVersionDeleteSome
              versions={versions}
              selectedVersionIds={selectedVersionIds}
              setSelectedVersionIds={setSelectedVersionIds}
              isIncludingReferencedSheets={isIncludingReferencedSheets}
              dialogPhase={deleteSomeDialogPhaseType}
              setDialogPhase={setDeleteSomeDialogPhaseType}
            />
          )}
          <DialogVersionEnableSome
            versions={versions}
            selectedVersionIds={selectedVersionIds}
            setSelectedVersionIds={setSelectedVersionIds}
            dialogPhase={enableSomeDialogPhaseType}
            setDialogPhase={setEnableSomeDialogPhaseType}
          />
          <DialogVersionRemoveSome
            versions={versions}
            setSelectedVersionIds={setSelectedVersionIds}
            selectedVersionIds={selectedVersionIds}
            dialogPhase={removeSomeDialogPhaseType}
            setDialogPhase={setRemoveSomeDialogPhaseType}
          />
          <DialogVersionUpdateCategorySome
            versions={versions}
            selectedVersionIds={selectedVersionIds}
            setSelectedVersionIds={setSelectedVersionIds}
            newCategoryId={selectedVersionCategoryIdRef.current}
            dialogPhase={updateCategorySomeDialogPhaseType}
            setDialogPhase={setUpdateCategorySomeDialogPhaseType}
          />
        </>
      );
    },
    [
      selectedVersionIds,
      deleteDialogPhaseType,
      removeDialogPhaseType,
      duplicateDialogPhaseType,
      enableDialogPhaseType,
      deleteSomeDialogPhaseType,
      removeSomeDialogPhaseType,
      duplicateSomeDialogPhaseType,
      enableSomeDialogPhaseType,
      selectedVersionCategoryIdRef,
      updateCategorySomeDialogPhaseType,
      isIncludingReferencedSheets,
    ]
  );

  // 複数バージョン操作
  const isDuplicateableVersionIds = useMemo(
    () =>
      selectedVersionIds.filter((id) => {
        const v = versions.find(({ id: vid }) => vid === id);
        // NOTE: ファイル最適化で作成されたバージョンは複製不可能
        return v ? !checkIsPublishedByFileUpload(v) : false;
      }),
    [versions, selectedVersionIds]
  );
  // 選択バージョンの全てに対しwrite権限がなければ削除不可
  const isDisableDeleteVersions =
    selectedVersionIds.length === 0 ||
    !selectedVersionIds.every((versionId) =>
      hasVersionActionPermission(
        versions.find(({ id }) => id === versionId) ?? null,
        "write"
      )
    );
  const isDisableUpdateVersionsCategory = isDisableDeleteVersions;
  // 選択バージョンの全てに対しdelete権限がなければ完全削除不可
  const isDisableRemoveVersions =
    selectedVersionIds.length === 0 ||
    !selectedVersionIds.every((versionId) =>
      hasVersionActionPermission(
        versions.find(({ id }) => id === versionId) ?? null,
        "delete"
      )
    );
  // versions:writeがなければ複製不可
  const isDisableDuplicateVersions =
    !hasVersionsWritePermission ||
    organization?.is_sheet_enabled !== true ||
    isDuplicateableVersionIds.length === 0;

  const renderUpdateCategoryMenu = useCallback(() => {
    return (
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuItem
            disabled={
              !selectedVersionIds.length || isDisableUpdateVersionsCategory
            }
          >
            カテゴリ更新
          </MenuItem>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuGroupHeader>新しいカテゴリを選択</MenuGroupHeader>
            {versionCategories?.map((category) => (
              <MenuItem
                key={category.id}
                onClick={() => {
                  selectedVersionCategoryIdRef.current = category.id;
                  setUpdateCategorySomeDialogPhaseType("confirm");
                }}
              >
                {category.name}
              </MenuItem>
            ))}
            <MenuItem
              key="未分類"
              onClick={() => {
                selectedVersionCategoryIdRef.current = null;
                setUpdateCategorySomeDialogPhaseType("confirm");
              }}
            >
              未分類
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  }, [
    selectedVersionIds,
    versionCategories,
    selectedVersionCategoryIdRef,
    isDisableUpdateVersionsCategory,
  ]);

  const renderVersionsToolBar = useCallback(
    (isSelectedEnabledVersions: boolean) => {
      return (
        <Toolbar>
          {isSelectedEnabledVersions ? (
            <>
              <Tooltip
                content={
                  isDisableDuplicateVersions
                    ? getDisabledDescription("複製")
                    : "複製"
                }
                relationship="label"
              >
                <ToolbarButton
                  icon={<CopyAdd24Regular />}
                  onClick={() => {
                    setDuplicateSomeDialogPhaseType("confirm");
                  }}
                  disabled={isDisableDuplicateVersions}
                />
              </Tooltip>
              <Tooltip
                content={
                  isDisableDeleteVersions
                    ? getDisabledDescription("削除")
                    : "削除"
                }
                relationship="label"
              >
                <ToolbarButton
                  icon={<Delete24Regular />}
                  onClick={() => {
                    setDeleteSomeDialogPhaseType("confirm");
                  }}
                  disabled={isDisableDeleteVersions}
                />
              </Tooltip>
              <ToolbarDivider />
              <Menu>
                <MenuTrigger>
                  <Tooltip
                    content={
                      selectedVersionIds.length === 0
                        ? getDisabledDescription("その他アクション")
                        : "その他アクション"
                    }
                    relationship="label"
                  >
                    <ToolbarButton
                      aria-label="More"
                      icon={<MoreHorizontal24Filled />}
                      disabled={selectedVersionIds.length === 0}
                    />
                  </Tooltip>
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    {renderUpdateCategoryMenu()}
                    {renderDownloadsMenu()}
                  </MenuList>
                </MenuPopover>
              </Menu>
            </>
          ) : (
            <>
              <Tooltip
                content={
                  isDisableDeleteVersions
                    ? getDisabledDescription("復元")
                    : "復元"
                }
                relationship="label"
              >
                <ToolbarButton
                  icon={<ArrowReset24Regular />}
                  onClick={() => {
                    setEnableSomeDialogPhaseType("confirm");
                  }}
                  disabled={isDisableDeleteVersions}
                />
              </Tooltip>
              <Tooltip
                content={
                  isDisableRemoveVersions
                    ? getDisabledDescription("完全削除")
                    : "完全削除"
                }
                relationship="label"
              >
                <ToolbarButton
                  icon={<Delete24Regular />}
                  onClick={() => {
                    setRemoveSomeDialogPhaseType("confirm");
                  }}
                  disabled={isDisableRemoveVersions}
                />
              </Tooltip>
            </>
          )}
        </Toolbar>
      );
    },
    [
      isDisableDuplicateVersions,
      isDisableDeleteVersions,
      isDisableRemoveVersions,
      renderUpdateCategoryMenu,
      renderDownloadsMenu,
      selectedVersionIds,
    ]
  );

  return {
    renderDialogsVersion,
    getContextMenuItemsVersion,
    getContextMenuItemsDisableVersion,
    selectedVersionIds,
    setSelectedVersionIds,
    renderVersionsToolBar,
  };
};

class DecoratedLogger {
  decoArgs: string[];
  dontShowSameMessageTwice: boolean;
  oldMessages: Set<string>;
  constructor({
    title,
    color,
    backgroundColor,
    dontShowSameMessageTwice = false,
  }: {
    title: string;
    color: string;
    backgroundColor: string;
    dontShowSameMessageTwice?: boolean;
  }) {
    this.decoArgs =
      process.env.NODE_ENV === "development"
        ? [
            `%c${title}`,
            `color:${color};background-color:${backgroundColor};padding:0 4px;border-radius:4px;`,
          ]
        : [];
    this.dontShowSameMessageTwice = dontShowSameMessageTwice;
    this.oldMessages = new Set();
    this.log = this.log.bind(this);
    this.warn = this.warn.bind(this);
    this.error = this.error.bind(this);
  }
  _call(type: "log" | "warn" | "error", args: Parameters<typeof console.log>) {
    if (process.env.NODE_ENV === "development") {
      if (typeof args[0] === "string" && this.dontShowSameMessageTwice) {
        const message = args[0];
        if (this.oldMessages.has(message)) {
          return;
        }
        this.oldMessages.add(message);
      }
      console[type](...this.decoArgs, ...args);
    }
  }
  log(...args: Parameters<typeof console.log>) {
    return this._call("log", args);
  }
  warn(...args: Parameters<typeof console.warn>) {
    return this._call("warn", args);
  }
  error(...args: Parameters<typeof console.error>) {
    return this._call("error", args);
  }
}
export const deprecatedLogger = new DecoratedLogger({
  title: "Deprecated",
  color: "#900",
  backgroundColor: "#ff0",
  dontShowSameMessageTwice: true,
});

import { Transaction, runTransaction } from "firebase/firestore";
import { useMemo } from "react";

import {
  FirestoreCollectionProsessorOptions,
  createFirestoreCollectionProsessor,
} from "../firebase/firestore";

import { Sheet, useGetSheet, useSetSheet } from "./Sheet";

import { useAppRouteParams } from "@/AppRoutes";
import { firestore } from "@/firebase";
import { runWorkerTransaction } from "@/firebase/snapshotWorker/port";
import { deprecatedLogger } from "@/utils/decoratedLogger";
import { generateId } from "@/utils/generateId";
import { SheetFieldSchema } from "./SheetFieldSchema";

export const SheetDataRowQueryPrefix = "sheet_data_cells";

export type SheetDataRow = {
  id: string;
  nextRowId: string | null;
  isRequired: boolean;
  sheetDataCells: {
    [fieldId: string]: string;
  };
};
type SheetDataRowRaw = {
  id: string;
  next_row_id: string | null;
  is_required: boolean;
  sheet_data_cells: {
    [field_id: string]: string;
  };
};

const processorOptions: FirestoreCollectionProsessorOptions<
  SheetDataRow,
  SheetDataRowRaw,
  Record<string, string>
> = {
  getCollectionPath: ({ organizationId, versionId, sheetId }) =>
    `/app/spreadsheet_ui/v/1/organizations/${organizationId}/versions/${versionId}/sheets/${sheetId}/sheet_data_rows`,
  toFirestore: ({ id, nextRowId, isRequired, sheetDataCells }) => ({
    id,
    next_row_id: nextRowId,
    is_required: isRequired,
    sheet_data_cells: sheetDataCells,
  }),
  fromFirestore: ({ id, next_row_id, is_required, sheet_data_cells }) => ({
    id,
    nextRowId: next_row_id,
    isRequired: is_required,
    sheetDataCells: sheet_data_cells,
  }),
};

const {
  useGet: useGetSheetDataRow,
  useGetAll: useGetSheetDataRows,
  useSet: _useSetSheetDataRow,
  useDelete: useDeleteSheetDataRow,
  useUpdateObjectFields: useUpdateSheetDataRowCells,
  useDeleteObjectFields: useDeleteSheetDataRowCells,
  useItems: useSheetDataRows,
  useItemsQuery: useSheetDataRowsQuery,
  useItemsLegacyQuery: useSheetDataRowsLegacyQuery,
  useSomeItems: useSomeSheetDataRows,
  useSomeItemsQuery: useSomeSheetDataRowsQuery,
  useSomeItemsLegacyQuery: useSomeSheetDataRowsLegacyQuery,
} = createFirestoreCollectionProsessor(processorOptions);

const TRANSACTION_WITH_SHEET_LOCK_RETRY_COUNT = 10;

let queuePromise: Promise<void> = Promise.resolve();
const waitQueueAndGetBaton = () =>
  new Promise<() => void>((resolve) => {
    queuePromise = queuePromise.then(
      () =>
        new Promise((baton) => {
          resolve(baton);
        })
    );
  });

const useSheetDataRowUtils = () => {
  const { sheetId } = useAppRouteParams();
  const getSheet = useGetSheet();
  const setSheet = useSetSheet();
  const setSheetDataRow = _useSetSheetDataRow();
  const getSheetDataRows = useGetSheetDataRows();
  const deleteSheetDataRow = useDeleteSheetDataRow();
  const updateSheetDataRowObjectFields = useUpdateSheetDataRowCells();
  const runTransactionWithSheetLock = async <T = void>(
    updateFunction: ({
      sheet,
      transaction,
      setReturnValue,
    }: {
      sheet: Sheet;
      transaction: Transaction;
      setReturnValue: (v: T) => void;
    }) => Promise<Partial<Sheet> | void>,
    routeParmas: Partial<ReturnType<typeof useAppRouteParams>>
  ): Promise<T> => {
    const passTheBaton = await waitQueueAndGetBaton();
    let returnValue: T | null = null;
    const setReturnValue = (v: T) => {
      returnValue = v;
    };
    let unresolvedError: unknown = null;
    for (let i = 0; i < TRANSACTION_WITH_SHEET_LOCK_RETRY_COUNT; i++) {
      unresolvedError = null;
      try {
        await runWorkerTransaction(
          firestore,
          async (transaction): Promise<void> => {
            const sheet = await getSheet(
              routeParmas.sheetId || sheetId,
              routeParmas,
              { transaction }
            );
            if (!sheet) throw new Error("Sheet not found");
            const sheetUpdateChunk = await updateFunction({
              sheet,
              transaction,
              setReturnValue,
            });
            await setSheet(
              {
                ...sheet,
                ...sheetUpdateChunk,
                updatedAt: new Date().toISOString(),
                // 時刻は偶然一致してロックされないリスクがあるためランダムIDで更新する
                lastTransactionId: generateId(),
              },
              routeParmas,
              { transaction }
            );
          }
        );
        break;
      } catch (e) {
        unresolvedError = e;
        if (i < TRANSACTION_WITH_SHEET_LOCK_RETRY_COUNT - 1) {
          console.warn(e);
          console.warn(
            `[runTransactionWithSheetLock] An error occurred in transaction. retrying...(${i + 1}/${TRANSACTION_WITH_SHEET_LOCK_RETRY_COUNT})`
          );
          await new Promise((resolve) =>
            setTimeout(resolve, Math.pow(2, i) * 50)
          );
        }
      }
    }
    passTheBaton();
    if (unresolvedError) {
      console.error(
        "[runTransactionWithSheetLock] Maximum retry count exceeded. Failed to run transaction."
      );
      throw unresolvedError;
    }
    return returnValue!;
  };
  const splitArray = <T>(arr: T[], chunkSize: number): T[][] => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      res.push(arr.slice(i, i + chunkSize));
    }
    return res;
  };
  const _addRowsByData = async (
    data: {
      id?: string;
      isRequired?: boolean;
      sheetDataCells?: {
        [fieldId: string]: string;
      };
    }[],
    routeParams: Partial<ReturnType<typeof useAppRouteParams>>,
    {
      insertAfterRowId,
      insertAtTop,
    }:
      | { insertAfterRowId: string; insertAtTop?: never }
      | { insertAfterRowId?: never; insertAtTop?: boolean } = {}
  ) => {
    if (data.length === 0) return [];
    return await runTransactionWithSheetLock<SheetDataRow[]>(
      async ({ sheet, transaction, setReturnValue }) => {
        const sheetUpdateChunk: Partial<Sheet> = {};
        const sheetDataRowsSrc = data.map(
          ({ id, isRequired, sheetDataCells }) => ({
            id: id ?? generateId(),
            isRequired: isRequired ?? false,
            sheetDataCells: sheetDataCells ?? {},
          })
        );
        const newSheetDataRows: SheetDataRow[] = sheetDataRowsSrc.map(
          ({ id, isRequired, sheetDataCells }, index) => {
            return {
              id,
              nextRowId:
                index === data.length - 1
                  ? null
                  : sheetDataRowsSrc[index + 1].id,
              isRequired,
              sheetDataCells,
            };
          }
        );
        if (sheet.firstRowId === null) {
          sheetUpdateChunk.firstRowId = newSheetDataRows[0].id;
        } else if (insertAtTop) {
          sheetUpdateChunk.firstRowId = newSheetDataRows[0].id;
          newSheetDataRows[newSheetDataRows.length - 1].nextRowId =
            sheet.firstRowId;
        } else {
          const lastRow = (
            await getSheetDataRows(routeParams, {
              transaction,
              where: insertAfterRowId
                ? {
                    fieldPath: "id",
                    opStr: "==",
                    value: insertAfterRowId,
                  }
                : { fieldPath: "next_row_id", opStr: "==", value: null },
            })
          )[0];
          if (!lastRow) throw new Error("Last row cannot found");
          newSheetDataRows[newSheetDataRows.length - 1].nextRowId =
            lastRow.nextRowId;
          lastRow.nextRowId = newSheetDataRows[0].id;
          await setSheetDataRow(lastRow, routeParams, { transaction });
        }
        for (const sheetDataRow of newSheetDataRows) {
          await setSheetDataRow(sheetDataRow, routeParams, { transaction });
        }
        setReturnValue(newSheetDataRows);
        return sheetUpdateChunk;
      },
      routeParams
    );
  };
  const addRowsByData: typeof _addRowsByData = async (
    data,
    routeParams,
    options
  ) => {
    const MAX_BATCH_SIZE = 100;
    const addedRows: SheetDataRow[] = [];
    for (const chunk of splitArray(data, MAX_BATCH_SIZE)) {
      const rows = await _addRowsByData(chunk, routeParams, options);
      options = {
        insertAfterRowId: rows[rows.length - 1].id,
      };
      addedRows.push(...rows);
    }
    return addedRows;
  };
  const addRows = (
    count: number,
    sheetFieldSchemas: SheetFieldSchema[],
    routeParmas: Partial<ReturnType<typeof useAppRouteParams>>,
    options: Parameters<typeof addRowsByData>[2] = {}
  ) =>
    addRowsByData(
      [...Array(count)].map(() => ({
        sheetDataCells: Object.fromEntries(
          sheetFieldSchemas.map(({ id }) => [id, ""])
        ),
      })),
      routeParmas,
      options
    );
  const _deleteRowsByIds = async (
    deleteTargetRowIds: string[],
    routeParams: Partial<ReturnType<typeof useAppRouteParams>>,
    { ignoreAlreadyDeleted = false }: { ignoreAlreadyDeleted?: boolean } = {}
  ) => {
    const deleteTargetRowIdsSet = new Set(deleteTargetRowIds);
    return await runTransactionWithSheetLock<SheetDataRow[]>(
      async ({ sheet, transaction, setReturnValue }) => {
        sheet = { ...sheet };
        const sheetUpdateChunk: Partial<Sheet> = {};
        const sheetDataRows: SheetDataRow[] = [];
        /*
         docs: https://cloud.google.com/firestore/docs/query-data/queries?hl=ja#limits_on_or_queries
         クエリの計算コストが高くならないようにするために、
         Firestore では、結合できる AND 句と OR 句の数が制限されています。
         この制限を適用するために、Firestore は論理 OR オペレーション
         （or、in、array-contains-any）を実行するクエリを
         分離された正規形式（OR の AND とも呼ばれます）に変換します。
         Firestore では、クエリの分離句の通常の形式に基づいて、
         クエリを最大 30 の分離に制限します。
        */
        for (const deleteTargetRowIdsChunk of splitArray(
          deleteTargetRowIds,
          15
        )) {
          sheetDataRows.push(
            ...(await getSheetDataRows(routeParams, {
              transaction,
              where: {
                logicalOp: "or",
                ops: [
                  {
                    fieldPath: "id",
                    opStr: "in",
                    value: deleteTargetRowIdsChunk,
                  },
                  {
                    fieldPath: "next_row_id",
                    opStr: "in",
                    value: deleteTargetRowIdsChunk,
                  },
                ],
              },
            }))
          );
        }
        const sheetDataRowsMap = {} as { [key: string]: SheetDataRow };
        const beforeRowsMap = {} as { [key: string]: SheetDataRow };
        for (const row of sheetDataRows) {
          sheetDataRowsMap[row.id] = row;
          if (row.nextRowId !== null) beforeRowsMap[row.nextRowId] = row;
        }
        const sheetDataRowsUpdateQueue: { [key: string]: SheetDataRow } = {};
        const deletedRows: SheetDataRow[] = [];
        for (const deleteTargetRowId of deleteTargetRowIds) {
          const deleteTargetRow: SheetDataRow | undefined =
            sheetDataRowsMap[deleteTargetRowId];
          if (!deleteTargetRow) {
            if (ignoreAlreadyDeleted) {
              console.warn(
                `Delete target row not found.(id=${deleteTargetRowId}) ignored by ignoreAlreadyDeleted option.`
              );
              continue;
            } else {
              throw new Error("Delete target row not found");
            }
          }
          const beforeRow = beforeRowsMap[deleteTargetRowId];
          if (!beforeRow && sheet.firstRowId !== deleteTargetRowId)
            throw new Error("Before row not found");
          if (beforeRow) {
            if (!deleteTargetRowIdsSet.has(beforeRow.id)) {
              sheetDataRowsUpdateQueue[beforeRow.id] = beforeRow;
            }
            if (deleteTargetRow.nextRowId !== null) {
              beforeRowsMap[deleteTargetRow.nextRowId] = beforeRow;
            }
            beforeRow.nextRowId = deleteTargetRow.nextRowId;
          } else {
            if (deleteTargetRow.nextRowId !== null) {
              delete beforeRowsMap[deleteTargetRow.nextRowId];
            }
            sheet.firstRowId = sheetUpdateChunk.firstRowId =
              deleteTargetRow.nextRowId;
          }
          // NOTE: ..してもいいけど別に消す必要ないのでコメントアウト
          // delete sheetDataRowsMap[deleteTargetRowId];
          delete beforeRowsMap[deleteTargetRowId];
          deletedRows.push(deleteTargetRow);
        }
        for (const deleteTargetRowId of deleteTargetRowIds) {
          await deleteSheetDataRow(deleteTargetRowId, routeParams, {
            transaction,
          });
        }
        for (const row of Object.values(sheetDataRowsUpdateQueue)) {
          await setSheetDataRow(row, routeParams, { transaction });
        }
        setReturnValue(deletedRows);
        return sheetUpdateChunk;
      },
      routeParams
    );
  };
  const deleteRowsByIds: typeof _deleteRowsByIds = async (
    deleteTargetRowIds: string[],
    routeParams: Partial<ReturnType<typeof useAppRouteParams>>,
    options
  ) => {
    const MAX_BATCH_SIZE = 100;
    const deletedRows: SheetDataRow[] = [];
    for (const chunk of splitArray(deleteTargetRowIds, MAX_BATCH_SIZE)) {
      const rows = await _deleteRowsByIds(chunk, routeParams, options);
      deletedRows.push(...rows);
    }
    return deletedRows;
  };
  const _updateRows = async (
    updateData: {
      id: string;
      sheetDataCells: {
        [fieldId: string]: string;
      };
    }[],
    routeParams: Partial<ReturnType<typeof useAppRouteParams>>
  ) => {
    await runTransactionWithSheetLock<SheetDataRow[]>(
      async ({ sheet, transaction, setReturnValue }) => {
        for (const { id, sheetDataCells } of updateData) {
          updateSheetDataRowObjectFields(
            id,
            {
              targetFieldKey: "sheet_data_cells",
              objects: [
                ...Object.entries(sheetDataCells).map(([key, value]) => ({
                  key,
                  value,
                })),
              ],
            },
            routeParams,
            { transaction }
          );
        }
      },
      routeParams
    );
    return updateData;
  };
  const updateRows: typeof _updateRows = async (updateData, routeParams) => {
    const MAX_BATCH_SIZE = 100;
    for (const chunk of splitArray(updateData, MAX_BATCH_SIZE)) {
      await _updateRows(chunk, routeParams);
    }
    return updateData;
  };
  const updateRowCell = async (
    {
      rowId: id,
      fieldId,
      value,
    }: {
      rowId: string;
      fieldId: string;
      value: string;
    },
    routeParams: Partial<ReturnType<typeof useAppRouteParams>>
  ) => updateRows([{ id, sheetDataCells: { [fieldId]: value } }], routeParams);
  const moveRowsAfterTargetRow = async (
    rowIds: string[],
    targetRowId: string,
    routeParams: Partial<ReturnType<typeof useAppRouteParams>>
  ) => {
    // TODO: improve performance
    const deletedRows = await deleteRowsByIds(rowIds, routeParams);
    return await addRowsByData(
      deletedRows.map(({ id, isRequired, sheetDataCells }) => ({
        id,
        isRequired,
        sheetDataCells,
      })),
      routeParams,
      { insertAfterRowId: targetRowId }
    );
  };
  const moveRowsToTop = async (
    rowIds: string[],
    routeParams: Partial<ReturnType<typeof useAppRouteParams>>
  ) => {
    // TODO: improve performance
    const deletedRows = await deleteRowsByIds(rowIds, routeParams);
    return await addRowsByData(
      deletedRows.map(({ id, isRequired, sheetDataCells }) => ({
        id,
        isRequired,
        sheetDataCells,
      })),
      routeParams,
      { insertAtTop: true }
    );
  };
  return useMemo(
    () => ({
      addRows,
      addRowsByData,
      deleteRowsByIds,
      updateRows,
      updateRowCell,
      moveRowsAfterTargetRow,
      moveRowsToTop,
    }),
    [sheetId]
  );
};

const useSetSheetDataRow = (
  ...args: Parameters<typeof _useSetSheetDataRow>
): ReturnType<typeof _useSetSheetDataRow> => {
  deprecatedLogger.warn(
    "useSetSheetDataRow is deprecated. Use useSheetDataRowUtils.* instead."
  );
  return _useSetSheetDataRow(...args);
};

export {
  useGetSheetDataRow,
  useGetSheetDataRows,
  useSetSheetDataRow,
  useDeleteSheetDataRow,
  useUpdateSheetDataRowCells,
  useDeleteSheetDataRowCells,
  useSheetDataRows,
  useSheetDataRowsQuery,
  useSheetDataRowsLegacyQuery,
  useSomeSheetDataRows,
  useSomeSheetDataRowsQuery,
  useSomeSheetDataRowsLegacyQuery,
  useSheetDataRowUtils,
};

import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

/**
 * @description 数値だけ操作可能なmatrixビュー
 * todo クエリ設定を入れる
 */
export const viewTypeMatrix: StrictViewType = {
  key: "matrix",
  displayName: "matrixビュー",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "matrixSheet",
      displayName: "データシート",
      dependSheetFields: [
        {
          key: "id1",
          displayName: "id1",
          type: "string",
        },
        {
          key: "id2",
          displayName: "id2",
          type: "string",
        },
        {
          key: "data",
          displayName: "data",
          type: "float",
        },
      ],
    },
  ],
  configSchemas: [],
};

import { DriverTemporaryEditorViewTypeDependSheet } from "./params";

const driverMasterDependSheet: DriverTemporaryEditorViewTypeDependSheet = {
  key: "driverMaster",
  displayName: "乗務員",
  dependSheetFields: [
    {
      key: "driverId",
      displayName: "氏名コード",
      type: "string",
    },
    {
      key: "driverName",
      displayName: "氏名",
      type: "string",
    },
  ],
};

const driverTemporaryDataMasterDependSheet: DriverTemporaryEditorViewTypeDependSheet =
  {
    key: "driverTemporaryDataMaster",
    displayName: "乗務員編集データ",
    dependSheetFields: [
      {
        key: "driverId",
        displayName: "氏名コード",
        type: "string",
      },
      {
        key: "frontOffice",
        displayName: "フロント内勤",
        type: "string",
      },
      {
        key: "seniorGrade",
        displayName: "S級",
        type: "string",
      },
      {
        key: "workRequestType",
        displayName: "希望形態",
        type: "string",
      },
      {
        key: "workStatus",
        displayName: "勤務状況",
        type: "string",
      },
      {
        key: "prohibitOutOfRange",
        displayName: "当日時間外禁止",
        type: "string",
      },
      {
        key: "unableWorkTime",
        displayName: "当日勤務不可時間帯",
        type: "string",
      },
    ],
  };

export const driverTemporaryEditorDependSheets = [
  driverMasterDependSheet,
  driverTemporaryDataMasterDependSheet,
];

import { ViewTypeDependSheet } from "@/domain/ViewTypeDependSheet";
import { MaterialConsumptionByGroupRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/materialConsumptionByGroup";
import { MaterialConsumptionByProductRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/materialConsumptionByProduct";
import { MaterialMasterRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/materialMaster";
import { ProductGroupMasterRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/productGroupMaster";
import { ProductMasterRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/productMaster";
import { ProductMaterialMasterRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/productMaterialMaster";
import { ProductTankAmountRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/productTankAmount";
import { ProductionRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/production";
import { ProductionAmountRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/productionAmount";
import { RawMaterialAmountRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/rawMaterialAmount";
import { RawMaterialConsumptionRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/rawMaterialConsumption";
import { RawMaterialPurchaseRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/rawMaterialPurchase";
import { ReactorProductMasterRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/reactorProductMaster";
import { ShippingScheduleRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/shippingSchedule";
import { SwitchMasterRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/switchMaster";
import { TankAmountRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/tankAmount";
import { TankMasterRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/tankMaster";
import { UnavailableReactorRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/unavailableReactor";
import { UnavailableTankRaw } from "@/viewType/viewTypes/Optium/nshKws/schema/unavailableTank";

export type NshKws4sViewTypeDependSheets = {
  production: ProductionRaw;
  productMaster: ProductMasterRaw;
  reactorProductMaster: ReactorProductMasterRaw;
  tankMaster: TankMasterRaw;
  unavailableReactor: UnavailableReactorRaw;
  unavailableTank: UnavailableTankRaw;
  switchMaster: SwitchMasterRaw;
  productionAmount: ProductionAmountRaw;
  productTankAmount: ProductTankAmountRaw;
  tankAmount: TankAmountRaw;
  rawMaterialAmount: RawMaterialAmountRaw;
  rawMaterialConsumption: RawMaterialConsumptionRaw;
  rawMaterialPurchase: RawMaterialPurchaseRaw;
  shippingSchedule: ShippingScheduleRaw;
  productMaterialMaster: ProductMaterialMasterRaw;
  materialMaster: MaterialMasterRaw;
  materialConsumptionByProduct: MaterialConsumptionByProductRaw;
  materialConsumptionByGroup: MaterialConsumptionByGroupRaw;
  productGroupMaster: ProductGroupMasterRaw;
};

export type NshKws4sRowKeys = {
  [k in keyof NshKws4sViewTypeDependSheets]: keyof NshKws4sViewTypeDependSheets[k] &
    string;
};

export type NshKws4sSheetKeys = keyof NshKws4sRowKeys & string;

export type NshKws4sViewTypeDependSheet = ViewTypeDependSheet<
  NshKws4sSheetKeys,
  NshKws4sRowKeys
>;

export const NshKws4sProductionDependSheet: NshKws4sViewTypeDependSheet = {
  key: "production",
  displayName: "生産計画",
  dependSheetFields: [
    {
      key: "reactor_id",
      displayName: "装置ID",
      type: "string",
    },
    {
      key: "product_id",
      displayName: "製品ID",
      type: "string",
    },
    {
      key: "product_name",
      displayName: "製品名",
      type: "string",
    },
    {
      key: "tank_id",
      displayName: "タンクID",
      type: "string",
    },
    {
      key: "start_datetime",
      displayName: "開始日時",
      type: "date-time",
    },
    {
      key: "end_datetime",
      displayName: "終了日時",
      type: "date-time",
    },
    {
      key: "production_type",
      displayName: "生産タイプ",
      type: "string",
    },
    {
      key: "is_fixed",
      displayName: "固定か",
      type: "boolean",
    },
    {
      key: "task_id",
      displayName: "タスクID",
      type: "string",
    },
    {
      key: "comment",
      displayName: "コメント",
      type: "string",
    },
  ],
};

export const NshKws4sProductMasterDependSheet: NshKws4sViewTypeDependSheet = {
  key: "productMaster",
  displayName: "製品マスタ",
  dependSheetFields: [
    {
      key: "product_id",
      displayName: "製品ID",
      type: "string",
    },
    {
      key: "product_name",
      displayName: "製品名",
      type: "string",
    },
    {
      key: "group_name",
      displayName: "グループ名",
      type: "string",
    },
  ],
};

export const NshKws4sReactorProductMasterDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "reactorProductMaster",
    displayName: "装置製品マスタ",
    dependSheetFields: [
      {
        key: "product_id",
        displayName: "製品ID",
        type: "string",
      },
      {
        key: "reactor_id",
        displayName: "装置ID",
        type: "string",
      },
      {
        key: "reactor_production_time",
        displayName: "装置生産時間",
        type: "integer",
      },
      {
        key: "shipping_production_time",
        displayName: "出荷生産時間",
        type: "integer",
      },
      {
        key: "production_amount",
        displayName: "生産量",
        type: "integer",
      },
      {
        key: "tank_id",
        displayName: "タンクID",
        type: "string",
      },
      {
        key: "direct",
        displayName: "直接",
        type: "string",
      },
    ],
  };

export const NshKws4sTankMasterDependSheet: NshKws4sViewTypeDependSheet = {
  key: "tankMaster",
  displayName: "タンクマスタ",
  dependSheetFields: [
    {
      key: "tank_name",
      displayName: "タンク名",
      type: "string",
    },
    {
      key: "tank_id",
      displayName: "タンクID",
      type: "string",
    },
    {
      key: "product_id",
      displayName: "製品ID",
      type: "string",
    },
    {
      key: "capacity_upper_limit",
      displayName: "容量上限",
      type: "integer",
    },
    {
      key: "capacity_lower_limit",
      displayName: "容量下限",
      type: "integer",
    },
    {
      key: "target_stock_amount",
      displayName: "目標在庫量",
      type: "integer",
    },
  ],
};

export const NshKws4sUnavailableReactorDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "unavailableReactor",
    displayName: "使用不可装置",
    dependSheetFields: [
      {
        key: "id",
        displayName: "id",
        type: "string",
      },
      {
        key: "reactor_id",
        displayName: "装置ID",
        type: "string",
      },
      {
        key: "start_datetime",
        displayName: "開始日時",
        type: "date-time",
      },
      {
        key: "end_datetime",
        displayName: "終了日時",
        type: "date-time",
      },
    ],
  };

export const NshKws4sUnavailableTankDependSheet: NshKws4sViewTypeDependSheet = {
  key: "unavailableTank",
  displayName: "使用不可タンク",
  dependSheetFields: [
    {
      key: "tank_id",
      displayName: "タンクID",
      type: "string",
    },
    {
      key: "start_datetime",
      displayName: "開始日時",
      type: "date-time",
    },
    {
      key: "end_datetime",
      displayName: "終了日時",
      type: "date-time",
    },
  ],
};

export const NshKws4sSwitchMasterDependSheet: NshKws4sViewTypeDependSheet = {
  key: "switchMaster",
  displayName: "切り替えマスタ",
  dependSheetFields: [
    {
      key: "reactor_id",
      displayName: "反応器ID",
      type: "string",
    },
    {
      key: "prev_product_id",
      displayName: "切替前製品ID",
      type: "string",
    },
    {
      key: "next_product_id",
      displayName: "切替後製品ID",
      type: "string",
    },
    {
      key: "switching_time",
      displayName: "洗浄時間",
      type: "integer",
    },
  ],
};

export const NshKws4sProductionAmountDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "productionAmount",
    displayName: "生産量",
    isReadAll: true,
  };
export const NshKws4sProductTankAmountDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "productTankAmount",
    displayName: "製品在庫",
    isReadAll: true,
  };

export const NshKws4sTankAmountDependSheet: NshKws4sViewTypeDependSheet = {
  key: "tankAmount",
  displayName: "タンク在庫",
  isReadAll: true,
};

export const NshKws4sRawMaterialAmountDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "rawMaterialAmount",
    displayName: "原材料在庫",
    isReadAll: true,
  };

export const NshKws4sRawMaterialConsumptionDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "rawMaterialConsumption",
    displayName: "原材料消費",
    isReadAll: true,
  };

export const NshKws4sRawMaterialPurchaseDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "rawMaterialPurchase",
    displayName: "原材料購買",
    isReadAll: true,
  };

export const NshKws4sShippingScheduleDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "shippingSchedule",
    displayName: "出荷計画",
    isReadAll: true,
  };
export const NshKws4sProductMaterialMasterDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "productMaterialMaster",
    displayName: "製品原材料マスタ",
    dependSheetFields: [
      {
        key: "reactor_id",
        displayName: "反応器ID",
        type: "string",
      },
      {
        key: "product_id",
        displayName: "製品ID",
        type: "string",
      },
      {
        key: "raw_material_id",
        displayName: "原材料ID",
        type: "string",
      },
      {
        key: "required_amount",
        displayName: "原材料消費量",
        type: "float",
      },
    ],
  };
export const NshKws4sViewTypeMaterialMasterDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "materialMaster",
    displayName: "原材料マスタ",
    dependSheetFields: [
      {
        key: "raw_material_id",
        displayName: "原材料ID",
        type: "string",
      },
      {
        key: "raw_material_name",
        displayName: "原材料名",
        type: "string",
      },
    ],
  };
export const NshKws4sMaterialConsumptionByProductDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "materialConsumptionByProduct",
    displayName: "製品ごと原材料消費",
    isReadAll: true,
  };
export const NshKws4sMaterialConsumptionByGroupDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "materialConsumptionByGroup",
    displayName: "グループごと原材料消費",
    isReadAll: true,
  };
export const NshKws4sViewTypeProductGroupMasterDependSheet: NshKws4sViewTypeDependSheet =
  {
    key: "productGroupMaster",
    displayName: "製品グループマスタ",
    dependSheetFields: [
      {
        key: "group_name",
        displayName: "グループ名",
        type: "string",
      },
    ],
  };

import loadable from "@loadable/component";

import { allDependSheets } from "../../ssui/types/fields";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeHkrShipSheets: StrictViewType = {
  key: "hkrShipCustomSheets",
  displayName: "HKR-SHIP 発電所スケジュール",
  render: ViewRender as any,
  dependSheets: allDependSheets,
  configSchemas: [],
  isDisabledEditHistoryShortcuts: true,
};

const MAX_DUPLICATION_COUNT = 100;

/**
 *
 * {value}({連番}) という形式の名前を返す
 * (例)
 * 1回目: {value}(1)
 * 2回目: {value}(2)
 *
 * nameについてはExcelシート名の制約を受けないため、
 * 31文字を超える場合でもそのまま連番をつける
 *
 * @param sheets
 * @param key
 * @param value
 */
export const createNewSheetNameOrThrow = (names: string[], value: string) => {
  const match = value.match(/^(.*?)(\((\d+)\))?$/);
  const base = match ? match[1] : value;
  let number = match && match[3] ? parseInt(match[3], 10) : 0;

  let newName = number > 0 ? `${base}(${number + 1})` : `${value}(1)`;
  let count = 1;

  // 同名が存在していれば連番を増やす
  while (names.map((name) => name).includes(newName)) {
    number++;
    newName = `${base}(${number + 1})`;
    count += 1;
    if (count > MAX_DUPLICATION_COUNT) {
      throw new Error(
        "複製回数が上限を超えました: 表示名を変更した上で再度お試しください"
      );
    }
  }

  return newName;
};

/**
 *
 * Excelシートの複製ルールに合わせる
 * {value}({連番}) という形式の名前を返す
 * (例)
 * 1回目: {value}(1)
 * 2回目: {value}(2)
 *
 * 複製後31文字を超える場合は、元の名前を削った上で連番をつける
 *
 * @param sheetNames
 * @param key
 * @param value
 */
export const createNewDisplayNameOrThrow = (
  displayNames: string[],
  value: string
) => {
  const match = value.match(/^(.*?)(\((\d+)\))?$/);
  const base = match ? match[1] : value;
  let number = match && match[3] ? parseInt(match[3], 10) : 0;

  let newSheetName = number > 0 ? `${base}(${number + 1})` : `${value}(1)`;

  // 新しい表示名は31文字を超えないようにする
  if (newSheetName.length > 31) {
    // Subtract the excess length from the base string
    const excessLength = newSheetName.length - 31;
    const newBase = base.slice(0, base.length - excessLength);
    newSheetName = `${newBase}(${number + 1})`;
  }

  let count = 1;
  // 同名が存在していれば連番を増やす
  while (
    displayNames
      .map((name) => name.toLowerCase())
      .includes(newSheetName.toLowerCase())
  ) {
    number++;
    newSheetName = `${base}(${number + 1})`;

    if (newSheetName.length > 31) {
      const excessLength = newSheetName.length - 31;
      const newBase = base.slice(0, base.length - excessLength);
      newSheetName = `${newBase}(${number + 1})`;
    }

    count += 1;
    if (count > MAX_DUPLICATION_COUNT) {
      throw new Error(
        "複製回数が上限を超えました: 表示名を変更した上で再度お試しください"
      );
    }
  }

  return newSheetName;
};

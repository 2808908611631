import { Sheet } from "../domain/Sheet";
import { SheetDataRow } from "../domain/SheetDataRow";

/**
 * @description sheetの最終行など各種情報を取得
 */
export const getSinglyLinkedSheetDataRowList = (
  sheet: Sheet | null,
  sheetDataRows: SheetDataRow[]
) => {
  const rowAndIndexMap = new Map<SheetDataRow["id"], [number, SheetDataRow]>();
  // クエリ未使用時、もしくはクエリパラメータが存在しない場合は単方向リストを作る
  if (!sheet?.isSheetQueryOn || !sheet?.sheetQueryParameters.length) {
    const firstRowId = sheet?.firstRowId || null;
    const rows: SheetDataRow[] = [];
    let lastRowId: string | null = null;
    let index = 0; // index 変数を初期化
    for (let nextRowId = firstRowId; nextRowId; ) {
      lastRowId = nextRowId;
      const row = sheetDataRows.find((c) => c.id === nextRowId);
      if (!row) break;
      rows.push(row);
      nextRowId = row.nextRowId;
      rowAndIndexMap.set(row.id, [index, row]);
      index++; // 次の要素の index に進む
    }
    return { rows, rowAndIndexMap, firstRowId, lastRowId };
  } else {
    // クエリONかつクエリパラメータが存在する場合は単方向リストを破壊
    // クエリON時は、firstRowIdは無効化
    const firstRowId = null;
    const rows: SheetDataRow[] = [...sheetDataRows];
    rows.forEach((row, index) => rowAndIndexMap.set(row.id, [index, row]));
    const lastRowId: string | null =
      sheetDataRows.length > 0 ? sheetDataRows.slice(-1)[0].id : null;
    return { rows, rowAndIndexMap, firstRowId, lastRowId };
  }
};
